import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import LoadingFallback from './LoadingFallback';
import request from 'utils/request';
import { getWebglobalContext } from 'contexts/webglobal';

const WebEntry = () => {
    const { id } = useParams();
    const { setSettings } = React.useContext(getWebglobalContext);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        goFetch();
    }, []);

    const goFetch = async () => {
        const res = await request({ url: window.urls.web + `/web/${id}` });
        window.urls.webId = id
        setLoading(false);
        if (res.data.renderData) {
            if (res.data.renderData.font) window.fonts = res.data.renderData.font;
            if (res.data.renderData.color) window.colors = res.data.renderData.color;
        }
        setSettings({
            userDefined: { ...res.data.renderData },
            web: { ...res.data },
        });
    };

    return loading ? <LoadingFallback /> : <Outlet/>;
};

export default WebEntry;
