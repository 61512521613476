import { getCrossDomainContext } from 'contexts/crossDomain';
import Div from 'elements/Div';
import React from 'react';
import Select from '../InputTypes/Select';
import { populateState } from 'utils/vanillaFunctions';
import styled from 'styled-components';
import { getBuilderContext } from 'contexts/builder';
import useSync from 'utils/useSync';
import ToggleButton from 'components/ToggleButton';

const setState = ({ instance, setInstance }) => {
    const { frame } = React.useContext(getCrossDomainContext);
    const { selectedComponent } = React.useContext(getBuilderContext)
    const [availableStates, setAvailableStates] = React.useState([]);
    const [populatedStates, setPopulatedStates] = React.useState([]);
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: instance.sync, operation: { type: 'setState', instance, setInstance } })
    React.useEffect(() => {
        const target = frame.document.querySelector(`[data-track="${selectedComponent.indices}"]`);
        const populatedStates = populateState(target);
        setPopulatedStates(populatedStates)
        setAvailableStates(Object.keys(populatedStates))
    }, [])

    return (
        <Container>
            <Div c="input-wrap">
                <label>Choose state</label>
                <Select options={availableStates} value={instance.stateName} onChange={(v) => setInstance({ ...instance, stateName: v })} />
            </Div>
            <Div c="input-wrap" style={{ position: 'relative' }}>
                <label>Setting value</label>
                <Div c='sync'>
                    {instance.sync ? syncElement() :
                        <Div c='type-wrap'>
                            {populatedStates[instance.stateName]?.type == 'select' ?
                                <Select options={populatedStates[instance.stateName]?.options} value={instance.settingValue} onChange={(e) => setInstance({ ...instance, settingValue: e })} />
                                :
                                populatedStates[instance.stateName]?.type == 'boolean' ?
                                    <ToggleButton isToggled={instance.settingValue ? instance.settingValue : false} setIsToggled={(v) => setInstance({ ...instance, settingValue: v })} />
                                    :
                                    <input value={instance.settingValue} onChange={(e) => setInstance({ ...instance, settingValue: e.target.value })} />
                            }
                        </Div>
                    }
                    {syncTrigger()}
                </Div>
                {syncSuggestion()}
            </Div>
        </Container>
    );
};

export default setState;

const Container = styled.div`
width: 100%;
.input-wrap{
    margin-bottom: 15px;
}
.sync{
    display: flex;
    align-items: center;
    .type-wrap{
        width: 100%;
    }
}
`