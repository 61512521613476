import Library from 'siro_lib';

export const populateElements = () => {
    const storeReducedTypes = []
    Object.keys(Library).forEach(type => {
        const storeReducedComponents = [];
        const components = Library[type]
        Object.keys(components).forEach((name) => {
            const injectingProps = {}
            const propData = { ...components[name].propData };
            Object.keys(propData).forEach((propKey) => {
                const prop = propData[propKey].defaultValue;
                injectingProps[propKey] = prop
                // Ignore unneccessary props here
                // delete injectingProps._icon
            });
            injectingProps.component = name
            injectingProps.type = type
            storeReducedComponents.push(injectingProps)
        });
        storeReducedTypes.push({ type: type, components: storeReducedComponents })
    })
    return storeReducedTypes
}
export const addRipple = (e) => {
    const target = e.target;
    const circle = document.createElement('span');
    const diameter = Math.max(target.clientWidth, target.clientHeight);
    const radius = diameter / 2;
    const clientRect = target.getBoundingClientRect();

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${e.clientX - (clientRect.left + radius)}px`;
    circle.style.top = `${e.clientY - (clientRect.top + radius)}px`;
    circle.classList.add('ripple');

    const ripple = target.getElementsByClassName('ripple')[0];

    if (ripple) {
        ripple.remove();
    }

    target.appendChild(circle);
};
export const findOuterMostAppendable = (element) => {
    if (!element || element.getAttribute('data-appendable') === 'true') {
        return element;
    }
    return findOuterMostAppendable(element.parentElement);
};
export function generateId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let id = '';
    id += letters.charAt(Math.floor(Math.random() * letters.length));
    for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        id += characters[randomIndex];
    }
    return id;
}

export const populateState = (targetNode) => {
    let statesToReturn = {}
    targetNode.dispatchEvent(new CustomEvent('getInternalState', {
        detail: {
            callback: passedStates => {
                statesToReturn = passedStates
            }
        }
    }))
    return statesToReturn
    // if(!targetNode) return console.warn('No such target in populateState')
    // const thisStateDomObject = targetNode.dataset;
    // const allState = {};
    // Object.keys(thisStateDomObject).forEach((string) => {
    //     if (string.includes('state')) {
    //         const stateKey = string.replace('state', '').toLocaleLowerCase();
    //         const stateInfo = thisStateDomObject[string].split('||');
    //         allState[stateKey] = { value: stateInfo[0], hook: stateInfo[1], type: stateInfo[2] };
    //     }
    // });
    // return allState;
};
export const populateDataProps = (targetNode) => {
    let propsToReturn = {}
    targetNode.dispatchEvent(new CustomEvent('getProps', {
        detail: {
            callback: passedDataProps => {
                propsToReturn = passedDataProps
            }
        }
    }))
    return propsToReturn
    // if(!targetNode) return console.warn('No such target in populateState')
    // const thisStateDomObject = targetNode.dataset;
    // const allState = {};
    // Object.keys(thisStateDomObject).forEach((string) => {
    //     if (string.includes('state')) {
    //         const stateKey = string.replace('state', '').toLocaleLowerCase();
    //         const stateInfo = thisStateDomObject[string].split('||');
    //         allState[stateKey] = { value: stateInfo[0], hook: stateInfo[1], type: stateInfo[2] };
    //     }
    // });
    // return allState;
};
const bindDataSuggestionStyle = `
    #bindDataSuggestion {
        background:white;
        border:1px solid rgba(0,0,0,0.1);
        z-index:2;
        position:absolute;
        border-bottom:none;
        max-height:300px;
        overflow-y:scroll;
        min-width:200px;
        box-shadow:1px 1px 3px rgba(0,0,0,0.1);
        border-radius:6px;
    }
    #bindDataSuggestion > .propWraps {
        padding:15px 0px;
        border-bottom:1px solid rgba(0,0,0,0.1);
    }
    #bindDataSuggestion > .propWraps > p {
        font-weight:300;
        padding:0px 15px;
        padding-bottom:15px;
        border-bottom:1px solid rgba(0,0,0,0.1);
    }
    #bindDataSuggestion > .propWraps > .fieldWraps{
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:4px 15px;
    }
    #bindDataSuggestion > .propWraps > .fieldWraps:hover{
        cursor:pointer;
        background:rgba(0,0,0,0.1);
    }
    #bindDataSuggestion .fieldWraps > img {
        height:30px;
    }
`;
export const makeDataBindSuggestion = ({ event, component, doc, onSelect }) => {
    const target = doc.querySelector(`[data-track="${component.indices}"]`);
    const eventCallback = (passedProps) => {
        if (!passedProps) return console.warn('Selected target has no passed props');
        document.querySelector('#bindDataSuggestion')?.remove();
        document.querySelector('#bindDataSuggestionStyle')?.remove();
        const wrapper = document.createElement('div');
        wrapper.setAttribute('id', 'bindDataSuggestion');
        const style = document.createElement('style');
        style.setAttribute('id', 'bindDataSuggestionStyle');
        style.innerText = bindDataSuggestionStyle;
        Object.keys(passedProps).forEach((propKey) => {
            const thisProp = passedProps[propKey];
            const propWrap = document.createElement('div');
            propWrap.setAttribute('class', 'propWraps');
            const propWrapTitle = document.createElement('p');
            propWrapTitle.innerText = propKey;
            propWrap.appendChild(propWrapTitle);

            thisProp.fields.forEach((thisField) => {
                const fieldWrap = document.createElement('div');
                fieldWrap.setAttribute('class', 'fieldWraps');
                fieldWrap.innerHTML = `<span>${thisField.label}</span><img src="/img/icons/${thisField.type}.svg"/>`;
                fieldWrap.addEventListener('click', () => onSelect({ propKey, field: thisField }))
                propWrap.appendChild(fieldWrap);
            });
            wrapper.appendChild(propWrap);
        });
        wrapper.addEventListener('mouseleave', () => wrapper.remove())
        //Repositioning and append
        const top = event.currentTarget.offsetTop;
        const left = event.currentTarget.offsetLeft + event.currentTarget.offsetWidth;
        wrapper.style.top = top + 'px';
        wrapper.style.left = left + 'px';
        document.body.appendChild(wrapper);
        document.head.appendChild(style);
        const appended = document.querySelector('#bindDataSuggestion');
        const appWidth = appended.offsetWidth;
        const appHeight = appended.offsetHeight;
        if (top > 500) appended.style.marginTop = -appHeight - 15 + 'px';
        else appended.style.marginTop = event.currentTarget.offsetHeight + 9 + 'px';
        appended.style.marginLeft = -appWidth + 'px';
    };
    const getEvent = new CustomEvent('getProps', {
        detail: { callback: eventCallback },
    });
    target.dispatchEvent(getEvent);
};
const bindStateSuggestionStyle = `
    #bindStateSuggestion {
        background:white;
        border:1px solid rgba(0,0,0,0.1);
        z-index:2;
        position:absolute;
        border-bottom:none;
        max-height:300px;
        overflow-y:scroll;
        min-width:200px;
        box-shadow:1px 1px 3px rgba(0,0,0,0.1);
        border-radius:6px;
    }
    #bindStateSuggestion > div {
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:4px 15px;
    }
    #bindStateSuggestion > div:hover {
        cursor:pointer;
        background:rgba(0,0,0,0.1);
    }
    #bindStateSuggestion > div > img {
        height:30px;
    }
`;
export const makeStateBindSuggestion = ({ event, component, doc, onSelect }) => {
    const target = doc.querySelector(`[data-track="${component.indices}"]`);
    const spreadState = populateState(target)
    if (!Object.keys(spreadState).length) return console.warn('Selected target has no state');
    document.querySelector('#bindStateSuggestion')?.remove();
    document.querySelector('#bindStateSuggestionStyle')?.remove();
    const wrapper = document.createElement('div');
    wrapper.setAttribute('id', 'bindStateSuggestion');
    const style = document.createElement('style');
    style.setAttribute('id', 'bindStateSuggestionStyle');
    style.innerText = bindStateSuggestionStyle;
    Object.keys(spreadState).forEach(stateKey => {
        const thisState = spreadState[stateKey]
        const childDiv = document.createElement('div')
        childDiv.innerHTML = `<span>${stateKey}</span><img src="/img/icons/${thisState.type}.svg"/>`
        childDiv.addEventListener('click', () => onSelect({ stateKey, thisState }))
        wrapper.appendChild(childDiv)
    })
    wrapper.addEventListener('mouseleave', () => wrapper.remove())
    //Repositioning and append
    const top = event.currentTarget.offsetTop;
    const left = event.currentTarget.offsetLeft + event.currentTarget.offsetWidth;
    wrapper.style.top = top + 'px';
    wrapper.style.left = left + 'px';
    document.body.appendChild(wrapper);
    document.head.appendChild(style);
    const appended = document.querySelector('#bindStateSuggestion');
    const appWidth = appended.offsetWidth;
    const appHeight = appended.offsetHeight;
    if (top > 500) appended.style.marginTop = -appHeight - 15 + 'px';
    else appended.style.marginTop = event.currentTarget.offsetHeight + 9 + 'px';
    appended.style.marginLeft = -appWidth + 'px';
}
export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return isNaN(str);
}

export const selectedComponentValues = (data) => {
    let selectedComponentValue
    switch (data) {
        case "Image":
            selectedComponentValue = ['ObjectFit', 'Width', 'Height', 'Border', 'BorderRadius', 'Opacity', 'BoxShadow', 'Rotate', 'Scale', 'Blur', 'Display', 'Zindex']
            break;
        case "Icon":
            selectedComponentValue = ['Width', 'Height', 'FontSize', 'FontColor']
            break;
        case "Video":
            selectedComponentValue = ['ObjectFit']
            break;
        case "Text":
        case "Link":
            selectedComponentValue = ['FontAlign', 'TextTransform', 'FontColor', 'FontSize', 'FontFamily', 'TextShadow', 'FontWeight', 'LineHeight', 'LetterSpacing', 'Opacity', 'Rotate', 'Scale', 'Cursor', 'Blur', 'TextDecoration', 'Zindex', 'Display']
            break;
        case "Box":
            selectedComponentValue = ['Direction', 'FlexDirectionReverse', 'BackgroundColor', 'Align', 'BackgroundImage', 'Wrap', 'Spacing', 'Gap', 'BackgroundRepeat', 'BackgroundSize', 'BackgroundPosition', 'Zindex', 'Display', 'FontFamily', 'Width', 'Height', 'Border', 'BorderRadius', 'Overflow', 'Opacity', 'BoxShadow', 'Rotate', 'Scale', 'Cursor', 'Blur', 'TransitionProperty', 'TransitionDelay', 'TransitionDuration', 'TransitionTimingFunction']
            break;
        case "Grid":
            selectedComponentValue = ['AutoScale', 'CustomCellCount', 'CustomGap', 'CustomGapVertical', 'CustomMaxWidth', 'BackgroundColor', 'Display', 'FontFamily', 'BackgroundImage', 'BackgroundRepeat', 'BackgroundSize', 'BackgroundPosition']
            break;
        case "Button":
            selectedComponentValue = ['BackgroundColor', 'Zindex', 'Display', 'Spacing', 'Direction', 'Gap', 'Align', 'Wrap', 'Width', 'Height', 'Border', 'BorderRadius', 'Opacity', 'BoxShadow', 'Rotate', 'Scale', 'Cursor', 'Blur', 'FontColor', 'FontSize', 'FontFamily', 'FontWeight', 'LineHeight', 'LetterSpacing', 'TextTransform', 'FontAlign']
            break;
        case "Repeatgrid":
        case "Datagrid":
            selectedComponentValue = ['Direction', 'CountPerRow', 'RepeatGap', 'Wrap']
            break;
        default:
            selectedComponentValue = ['']
            break;
    }
    return { selectedComponentValue }
}

export const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if this is the cookie you're looking for
        if (cookie.startsWith(name + '=')) {
            // Return the cookie value
            return cookie.substring(name.length + 1);
        }
    }
    // Cookie not found
    return null;
}
export const setCookie = (name, value, days = 7) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};