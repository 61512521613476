import React from "react";
import styled from "styled-components";

const OpacityController = ({ value, forwardChange }) => {
    const defaultValue = value === undefined ? 1 : value;

    const handleBlur = (e) => {
        const newValue = e.target.value;
        setTimeout(() => {
            forwardChange(newValue)
        }, 150);
    };

    const handleKeyDown = (e) => {
        const newValue = e.target.value;
        if (e.keyCode === 13) {
            setTimeout(() => {
                forwardChange(newValue)
            }, 150);
        }
    };

    const handleSliderChange = (e) => {
        const newValue = e.target.value;
        forwardChange(newValue);
    };

    return (
        <Container>
            <input
                className="slider"
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={defaultValue}
                onChange={handleSliderChange}
            />
            <input type="number" value={defaultValue} onChange={handleSliderChange} onKeyDown={handleKeyDown} onBlur={handleBlur} />
        </Container>
    )
}

export default OpacityController

const Container = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 10px;
& > input[type='number']{
    width: 34px;
    height: 34px;
    text-align: center;
    background: ${({theme}) => theme.bg2};
    border: none;
    border-radius: 4px;
    color: ${({theme}) => theme.color};
    &:focus{
        outline: none;
    }
}
input[type='range'] {
    width: 100%;
    -webkit-appearance: none;
    position: relative;
}
input[type='range']::-webkit-slider-runnable-track {
    height: 2px;
    background: ${({ theme }) => theme.bg3};
}
input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;
    background: ${({ theme }) => theme.mainColor};
    transition: 0.5s all ease;
    margin-top: -3.5px;
}
`