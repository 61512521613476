import React from 'react';
import styled from 'styled-components';
import Button from 'elements/Button';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'utils/svg';

const HeadingLeft = () => {
    const navigate = useNavigate()
    return (
        <Container>
            <Button c="wrap" onClick={() => navigate(-1)}>
                <ArrowLeft />
            </Button>
        </Container>
    )
}

export default HeadingLeft;

const Container = styled.div`
display: flex;
align-items: center;
height: 100%;
.wrap {
    border-radius: 0;
    padding: 10px 16px;
    border-right: 1px solid ${({ theme }) => theme.sectionBorderColor};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: background-color 0.5s ease;
    background-color: transparent;
    width: 56px;
    &:hover{
        background-color: ${({ theme }) => theme.lightMainColor};
        svg{
            path{
                fill: ${({ theme }) => theme.mainColor};
            }
        }
    }
    svg{
        path{
            fill: ${({ theme }) => theme.color};
        }
    }
}
`;
