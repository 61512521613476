import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getReferenceContext } from 'contexts/reference';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const HandleEmptyRoot = () => {

    const { frame, composition, setComposition } = React.useContext(getCrossDomainContext);
    const { addComponent } = React.useContext(getBuilderContext)
    const { theme } = React.useContext(getCustomThemeContext);
    const { InsertingElement, setSectionPopupShow } = React.useContext(getReferenceContext)
    const doc = frame.document;
    const emptyRootStyleId = "emptyRoot"

    React.useEffect(() => {
        watchIsRootEmpty()
        checkIsRootEmpty() //CHECK ONCE WHEN APP STARTS
        watchRootDrop()
    }, [])

    React.useEffect(() => {
        doc.addEventListener('click', watchRootClick)
        return () => {
            doc.removeEventListener('click', watchRootClick)
        }
    }, [composition])

    const watchRootClick = (e) => {
        if (!e.target || e.target.nodeType !== 1) return null;
        const id = e.target.getAttribute('id')
        if(id !== 'root') return null
        if(composition.length !== 0) return null
        setSectionPopupShow(true)
    }

    const watchIsRootEmpty = () => {
        const observer = new MutationObserver(() => {
            checkIsRootEmpty()
        })
        const root = doc.querySelector('#root');
        observer.observe(root, { childList: true });
    }

    const checkIsRootEmpty = () => {
        const root = doc.querySelector('#root');
        if(root.hasChildNodes()) {
            const styleTagExists = doc.querySelector(`#${emptyRootStyleId}`)
            if(styleTagExists) styleTagExists.remove()
        } else {
            const styleTag = doc.createElement('style')
            styleTag.setAttribute('id', emptyRootStyleId)
            styleTag.innerHTML = `
                #root {
                    width: 100vw;
                    height:100vh;
                    background-color: #b7875600;
                    background-size: 10px 10px;
                    background-image: repeating-linear-gradient(45deg, rgba(4, 150, 255,0.6) 0, rgba(4, 150, 255,0.6) 1px, rgba(229,229,247,0) 0, rgba(229,229,247,0) 50%);
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    pointer-events: auto;
                }
                #root:before {
                    display:block;
                    content: "+ Add section";
                    color: white;
                    width:200px;
                    height:50px;
                    border-radius:8px;
                    background:${theme.mainColor};
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    font-size:14px;
                    font-family: sans-serif;
                    cursor:pointer;
                }
            `
            doc.head.appendChild(styleTag)
        }
    }

    const watchRootDrop = () => {
        const root = doc.querySelector('#root');
        root.addEventListener('drop', (e) => {
            if(e.target !== root) return null
            const nodes = [...doc.querySelectorAll('[data-depth="1"]')]
            if(!nodes.length) return setComposition([InsertingElement.current])
            const lastNode = nodes[nodes.length - 1]
            const targetIndex = lastNode.dataset.track
            addComponent(InsertingElement.current, targetIndex, 'd')
        })
        root.addEventListener('dragover', (e) => {
            const allowedOperation = e.dataTransfer.effectAllowed;
            if (allowedOperation === 'move' || allowedOperation === 'copy') {
                const id = e.target.getAttribute('id')
                if(id === 'root') e.preventDefault(); //Preventing default allows us to handle drop event
            }
        })
    }

    return null
};

export default HandleEmptyRoot;