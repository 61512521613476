import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import React from 'react';

const InitClickComponentNew = () => {
    const { frame } = React.useContext(getCrossDomainContext)
    const doc = frame.document
    const { selectComponent, selectedComponent, selectedParallels, mutateParallelIndex, emptyParallelIndex } = React.useContext(getBuilderContext)
    React.useEffect(() => {
        doc.addEventListener('click', initClick)
        return () => {
            doc.removeEventListener('click', initClick)
        }
    }, [selectedComponent, selectedParallels])
    const initClick = (e) => {
        // e.stopPropagation()
        e.preventDefault()
        // Basic builder component check
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        if(!e.target.hasAttribute('kind')) return null

        // If no component selected currently, simply select it
        if(!selectedComponent) return selectComponent(e.target.dataset.track)

        // If no shift key detected, empty parallel index
        if(!e.shiftKey) {
            emptyParallelIndex()
            return selectComponent(e.target.dataset.track)
        }

        const kind = e.target.getAttribute('kind')
        if(kind === 'component' || kind === 'block') mutateParallelIndex(e.target.dataset.track)

    }
    return null
};

export default InitClickComponentNew;
