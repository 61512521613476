import Div from 'elements/Div';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import { getCustomThemeContext } from 'contexts/theme';

const InputColor = ({value: initialValue, onChange: dispatchChange, attribute}) => {
    const {theme} = React.useContext(getCustomThemeContext)
    const [value, setValue] = React.useState(initialValue)
    const [showPicker, setShowPicker] = React.useState(false)
    const itself = React.useRef()
    const pickerRef = React.useRef()
    React.useEffect(() => {
        watchSketch()
    }, [])
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    React.useEffect(() => {
        if(showPicker) {
            const sketchNode = document.querySelector('.sketch-picker')
            if(sketchNode) {
                sketchNode.addEventListener('mouseleave', e => {
                    setShowPicker(false)
                })
            }
        }
    }, [showPicker])
    const watchSketch = () => {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if(mutation.addedNodes.length) {
                    initEvents(mutation.addedNodes[0])
                    //
                } else if (mutation.removedNodes.length) {
                    //
                }
            })
        })
        const config = {childList: true, subtree: false}
        observer.observe(itself.current, config)
    }
    const initEvents = (target) => {
        const button = document.createElement('button')
        button.innerText = 'Done ✓'
        button.style.cssText = `
            width:calc(100% - 10px);
            background:${theme.mainColor};
            color:white;
            padding:5px;
            border-radius:4px;
        `
        button.addEventListener('click', () => setShowPicker(false))

        const divs = [...target.querySelectorAll('.flexbox-fix')]
        const lastDiv = divs[divs.length-1]

        const wrapper = document.createElement('div')
        wrapper.style.cssText = `
            width:calc(100% - 10px);
            padding:10px;
            border:1px solid rgba(0,0,0,0.1);
            color:black;
            margin-bottom:10px;
            display:flex;
            flex-wrap:wrap;
            gap:10px;
        `
        const title = document.createElement('p')
        title.innerText = "Saved colors:";
        title.style.cssText = `
            padding-bottom:10px;
            border-bottom:1px solid rgba(0,0,0,0.2);
            font-weight:bold;
            opacity:0.4;
            width:100%;
        `
        wrapper.appendChild(title)

        if(window.colors) {
            window.colors.forEach(color => {
                const colorDiv = document.createElement('div')
                colorDiv.style.cssText = `
                    width:fit-content;
                    display:flex;
                    align-items:center;
                    gap:4px;
                    padding:3px 4px;
                    border:1px solid rgba(0,0,0,0.2);
                    border-radius:4px;
                    cursor:pointer;
                `
                colorDiv.setAttribute('data-color', color.value)
                colorDiv.addEventListener('click', handleCustomColorClick)
                const nameSpan = document.createElement('span')
                nameSpan.style.cssText = `pointer-events:none;`
                const colorSpan = document.createElement('span')
                nameSpan.innerText = color.key
                colorSpan.style.cssText = `
                    width:16px;
                    height:16px;
                    background:${color.value};
                    border-radius:3px;
                    border:1px solid rgba(0,0,0,0.2);
                    pointer-events:none;
                `;
                colorDiv.appendChild(nameSpan)
                colorDiv.appendChild(colorSpan)
                wrapper.appendChild(colorDiv)
            })
        }

        lastDiv.appendChild(wrapper)
        lastDiv.appendChild(button)

    }
    const handleCustomColorClick = (e) => {
        const colorValue = e.target.dataset.color
        if(colorValue) dispatchChange(colorValue)
    }
    const handleColor = ({rgb: e}) => {
        const rgba = `rgba(${e.r}, ${e.g}, ${e.b}, ${e.a})`
        dispatchChange(rgba)
        setValue(rgba)
    }
    const handleInputChange = (e) => {
        dispatchChange(e.target.value)
        setValue(e.target.value)
    }

    return (
        <Container ref={itself} value={value} data-attribute>
            <Div c="toggle" onClick={()=>setShowPicker(!showPicker)}></Div>
            <input value={value} onChange={handleInputChange}/>
            {showPicker ? <SketchPicker ref={pickerRef} color={value} onChange={handleColor}/> : ''}
        </Container>
    );
};

export default InputColor;

const Container = styled.div`
    position: relative;
    & > .sketch-picker {
        position:absolute;
        z-index:1;
        right:0;
        left: 0;
        margin: auto;
        top:calc(100% + 15px);
        padding-bottom:10px !important;
        width: 100% !important;
        max-width: 200px !important;
    }
    & > .toggle {
        position:absolute;
        left:6px;
        top:6px;
        bottom:6px;
        cursor:pointer;
        width: 20px;
        height: 20px;
        border-radius:4px;
        background:${({value}) => value};
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    }
    input {
        outline: none;
        background: ${({ theme }) => theme.bg2};
        color: ${({ theme }) => theme.color};
        font-weight: 600;
        border: none;
        height: 32px;
        padding:6px;
        padding-left:32px;
        border-radius: 4px;
        width: 100%;
        &::placeholder{
            color: ${({ theme }) => theme.color};
            font-weight: 600;
        }
    }
`
InputColor.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    attribute: PropTypes.string,
};
