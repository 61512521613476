import React from 'react';

const index = () => {
    return (
        <div>
            Effects
        </div>
    );
};

export default index;