import { getCrossDomainContext } from "contexts/crossDomain";
import Div from "elements/Div";
import React from "react";
import styled from "styled-components";

const LanguageSwitch = ({ setLanguageSwitching }) => {
    const { availableLanguages, changeLanguage, language } = React.useContext(getCrossDomainContext)
    const change = (val) => {
        changeLanguage(val)
        setLanguageSwitching(false)
    }
    return (
        <Container>
            <Div c="lang-wrap">
                {availableLanguages && availableLanguages.map((lang, i) => (
                    <Div c={`lang ${language == lang.key && 'active'}`} key={i} onClick={() => change(lang.key)}>
                        <img src={lang.img} alt="mn" />
                        <span>{lang.name}</span>
                    </Div>
                ))}
            </Div>
        </Container>
    )
}

export default LanguageSwitch

const Container = styled.div`
.lang-wrap{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
    padding: 12px 18px;
    .lang{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        border-radius: 8px;
        gap: 8px;
        transition: 0.3s ease;
        width: calc((100% - 12px) / 2);
        border: 1px solid ${({ theme }) => theme.sectionBorderColor};
        border-radius: 8px;
        cursor: pointer;
        img{
            width: 24px;
            height: 24px;
            object-fit: contain;
        }
        span{
            font-weight: 500;
        }
        &:hover{
            background: ${({ theme }) => theme.bg2};
        }
        &.active{
            background: ${({ theme }) => theme.lightMainColor2};
        }
    }
}
`