import React from 'react';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { dispatchChange, getStyleValue } from 'utils/styleHelpers';
import PropTypes from 'prop-types';
import InputColor from './InputColor';

const GenericColor = ({ attributeName }) => {
    const [value, setValue] = React.useState('');
    const targetRef = React.useRef();
    const { selectedComponent, editComponent } =
        React.useContext(getBuilderContext);
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext);
    const doc = frame.document;
    const [timeoutId, setTimeoutId] = React.useState();
    const debounceDuration = 100

    React.useEffect(() => {
        targetRef.current = doc.querySelector(
            `[data-track="${selectedComponent.indices}"]`
        );
        setInitialValue();
    }, [selectedComponent]);

    React.useEffect(() => {
        setInitialValue();
    }, [frameUpdate]);

    const setInitialValue = () => {
        const styleValue = getStyleValue(selectedComponent, attributeName, doc);
        setValue(styleValue);
    };

    const forwardChange = (changingValue) => {
        const debounceCallback = () => {
            dispatchChange(editComponent, selectedComponent, attributeName, changingValue)
        }
        debounce(debounceCallback, debounceDuration, timeoutId, setTimeoutId)();
    }

    const debounce = (fn, delay, timeout, settimeout) => {
        return function (...args) {
            clearTimeout(timeout);
            settimeout(
                setTimeout(() => {
                    fn.apply(this, args);
                }, delay)
            );
        };
    };

    return (
        <InputColor
            attribute={attributeName}
            value={value}
            onChange={forwardChange}
        />
    );
};

export default GenericColor;

GenericColor.propTypes = {
    attributeName: PropTypes.string
}