import { getBuilderContext } from 'contexts/builder'
import React from 'react'
import { useParams } from 'react-router-dom';
import { recursiveCopy } from 'utils/recursive'
import request from 'utils/request';

const useBookingSlot = (object, selectedType) => {
    const { id } = useParams();
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext)

    React.useEffect(() => {
        if (selectedType === 'Booking Slot') {
            mainFunction()
        }
    }, [selectedType])

    const mainFunction = () => {
        const copiedObject = recursiveCopy(object, true)
        if (copiedObject.sourceDetail) {
            const newLanguageObject = { ...copiedObject.sourceDetail.value, webId: id }
            copiedObject.sourceDetail.value = newLanguageObject
        }
        editComponent(selectedComponent.indices, 'options', 'dataSource', copiedObject)
    }
    return null
}

export default useBookingSlot