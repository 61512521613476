import React from 'react';
import ScrollAnimation from './ScrollAnimation';
import ScrollEffects from './ScrollEffects';

const Animations = () => {
    return (
        <div>
            <ScrollAnimation/>
            <ScrollEffects/>
        </div>
    );
};

export default Animations;