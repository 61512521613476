import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getReferenceContext } from 'contexts/reference';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const InitRightClickParent = () => {
    const { frame } = React.useContext(getCrossDomainContext);
    const { theme } = React.useContext(getCustomThemeContext);
    const { setTemplateInstance } = React.useContext(getReferenceContext);
    const { setLocalComponentInstance } = React.useContext(getReferenceContext);
    const { cloneComponent, deleteComponent } = React.useContext(getBuilderContext);
    const doc = frame.document;
    const selector = 'bl-context';
    const handleClone = (target) => cloneComponent(target.dataset.track);
    const handleDelete = (target) => deleteComponent(target.dataset.track);
    const handleSave = (target) => setTemplateInstance({index: target.dataset.track, updateData: true})
    const handleSaveLocal = (target) => setLocalComponentInstance({index: target.dataset.track, updateData: true})
    const contextMenus = [
        { name: 'Clone element', handler: handleClone },
        { name: 'Delete element', handler: handleDelete },
        { name: 'Save as template', handler: handleSave },
        { name: 'Save as local template', handler: handleSaveLocal }
    ];
    React.useEffect(() => {
        document.addEventListener('contextmenu', (e) => {
            if (e.target.parentElement.getAttribute('kind') === 'inspect') {
                drawContext(e);
                e.preventDefault();
            }
        });
        document.addEventListener('click', () => {
            document.querySelector(`#${selector}`)?.remove();
            document.querySelector(`#${selector}-style`)?.remove();
        });
    }, []);

    const drawContext = (e) => {
        //CLEANUP
        document.querySelector(`#${selector}`)?.remove();
        document.querySelector(`#${selector}-style`)?.remove();

        const mousePos = { x: e.clientX + frame.scrollX, y: e.clientY + frame.scrollY };

        const styleTag = document.createElement('style');
        styleTag.setAttribute('id', `${selector}-style`);
        styleTag.innerText = `
            #${selector} {
                position:absolute;
                left: ${mousePos.x}px;
                top: ${mousePos.y}px;
                border:1px solid ${theme.sectionBorderColor};
                font-size:12px;
                font-family:sans-serif;
                pointer-events:auto;
            }
            #${selector} > div {
                padding:6px 6px;
                background:${theme.bg};
                color:${theme.color2};
                pointer-events:auto;
            }
            #${selector} > div:hover {
                background:${theme.mainColor};
                color:white;
                cursor:pointer;
            }
        `;

        const parentDiv = document.createElement('div');
        parentDiv.setAttribute('id', `${selector}`);

        contextMenus.forEach((menu) => {
            const div = document.createElement('div');
            div.innerText = menu.name;
            div.addEventListener('click', () => menu.handler(e.target.parentElement));
            parentDiv.appendChild(div);
        });

        document.body.appendChild(parentDiv);
        document.head.appendChild(styleTag);
    };
    return null;
};

export default InitRightClickParent;
