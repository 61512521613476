import Div from "elements/Div";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import request from 'utils/request';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import { getReferenceContext } from 'contexts/reference';

const Collections = () => {
    const [isActive, setActive] = React.useState(false)
    const { id } = useParams();
    const { setDynamicPagesSection } = React.useContext(getReferenceContext)
    const [collections, setCollections] = React.useState([])

    const fetchCollections = async () => {
        try {
            const result = await request({
                url: window.urls.content + `/collection/ct/listCt`,
                method: 'post',
                headers: { webid: id },
                body: {},
            });
            setCollections(result.data)
        } catch (e) {
            console.log(e, 'err fetching collection')
        }
    }

    React.useEffect(() => {
        fetchCollections()
    }, [])

    return (
        <Container>
            <Div c={`title-wrap ${isActive && "active"}`} onClick={() => setActive(!isActive)}>
                <Div c="title">Collections</Div>
                <Div c="icons">
                    <Div c="arrow">
                        <IoIosArrowDown />
                    </Div>
                </Div>
            </Div>
            {isActive &&
                <Div c="main-wrap">
                    <Div c="pages-wrap">
                        {collections.map((page) => (
                            <Div c='name-wrap' key={page._id} onClick={() => setDynamicPagesSection({name: 'collection', data: page})}><span>{page._source.name}</span></Div>
                        ))}
                    </Div>
                </Div>
            }
        </Container>
    )
}

export default Collections

const Container = styled.div`
& > .title-wrap{
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .title{
        font-weight: 600;
        line-height: 24px;
    }
    .icons{
        display: flex;
        align-items: center;
        gap: 5px;
        .add, .arrow{
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            transition: all 0.3s linear;
            border-radius: 4px;
            svg{
                width: 16px;
                height: 16px;
            }
            &:hover{
                background: ${({ theme }) => theme.bg2};
                svg{
                    path{
                        fill: ${({ theme }) => theme.color2};
                    }
                }
            }
        }
    }
    &.active{
        & > .icons{
            & > .arrow{
                rotate: 180deg;
            }
        }
    }
}
& > .main-wrap{
    & > .pages-wrap {
        display: flex;
        flex-direction: column;
        .name-wrap{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: flex;
            align-items: flex-end;
            gap: 5px;
            padding: 15px 35px;
            cursor: pointer;
            span{
                font-size: ${({ theme }) => theme.fs};
                font-weight: 500;
                color: ${({ theme }) => theme.color};
                text-transform: capitalize;
            }
            &:hover{
                background-color: ${({ theme }) => theme.bg2};
            }
        }
    }
}
`