import React from 'react';
import styled from 'styled-components';
import DarkModeToggle from './DarkModeToggle';
import Heading from './Heading';
import Body from './Body';
import DraggableModule from './DraggableModule';
import { getReferenceContext } from 'contexts/reference';

const MainLayout = () => {
    const { draggableModule, setDraggableModule } = React.useContext(getReferenceContext)
    return (
        <Container>
            <Heading />
            <Body />
            {/* FIXED FRAGMENT CONTAINER */}
            <>
                {draggableModule && <DraggableModule close={setDraggableModule} />}
                <DarkModeToggle />
            </>
        </Container>
    );
};

export default MainLayout;

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display:flex;
    flex-direction:column;
    color: ${({ theme }) => theme.color};
    background: ${({ theme }) => theme.bg};
    position: relative;
`;
