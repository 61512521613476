import Div from 'elements/Div';
import React from 'react';

const removeClass = ({instance, setInstance}) => {
    return (
        <Div c="input-wrap">
            <label>Class name</label>
            <input value={instance.className} onChange={e => setInstance({...instance, className: e.target.value})}/>
        </Div>
    );
};

export default removeClass;
