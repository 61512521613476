import React from 'react';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { dispatchChange, getStyleValue } from 'utils/styleHelpers';
import styled from 'styled-components';
import OpacityController from './OpacityController';

const Opacity = () => {
    const [value, setValue] = React.useState(null)
    const { selectedComponent, editComponent } =
        React.useContext(getBuilderContext);
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext);
    const doc = frame.document;

    React.useEffect(() => {
        setInitialValue();
    }, [selectedComponent]);

    React.useEffect(() => {
        setInitialValue();
    }, [frameUpdate]);

    const setInitialValue = () => {
        const styleValue = getStyleValue(selectedComponent, 'opacity', doc);
        setValue(styleValue);
    }

    const forwardChange = (changingValue) => {
        dispatchChange(editComponent, selectedComponent, 'opacity', changingValue)
    }

    return (
        <Container>
            <OpacityController value={value} forwardChange={forwardChange} />
        </Container>
    );
};

export default Opacity;

const Container = styled.div`
width: 100%;
`