import LinkEditor from 'components/LinkEditor';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { Settings } from 'utils/svg';
import useSync from 'utils/useSync';

const Link = ({ object, forwardKey, forwardChange }) => {
    const [value, setValue] = React.useState(object.value)
    const [linkEditor, setLinkEditor] = React.useState(false)
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: object.sync, operation: { type: 'option', forwardKey, forwardChange } })
    const handleChange = (e) => {
        setValue(e.target.value)
        forwardChange(forwardKey, e.target.value)
    }
    const handleSubmit = (linkData) => {
        setValue(linkData)
        forwardChange(forwardKey, linkData)
        setLinkEditor(false)
    }

    return (
        <Container>
            <div className='caption'>{forwardKey}</div>
            {object.sync ? syncElement() : (<Div c='flex'>
                <Div c='input-wrap'>
                    <input value={value.slug ? value.slug : value} onChange={handleChange} placeholder="https://example.com" />
                    <Div c='icon'><Settings onClick={() => setLinkEditor(true)} /></Div>
                </Div>
                {syncTrigger()}
            </Div>)}
            {syncSuggestion()}
            {linkEditor && <LinkEditor linkData={value} setLinkData={setValue} closePopup={() => setLinkEditor(false)} handleSubmit={handleSubmit} />}
        </Container>
    )
}

export default Link;

const Container = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items:center;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.flex{
    display: flex;
    .input-wrap{
        background: ${({ theme }) => theme.bg2};
        max-width: 180px;
        border-radius: 4px;
        position: relative;
        input{
            border: none;
            padding: 8px 12px;
            background: transparent;
            color: ${({ theme }) => theme.color};
            font-weight: 500;
            &:focus{
                outline: none;
            }
        }
        .icon{
            position: absolute;
            right: 0;
            width: 30px;
            top: 0;
            margin: auto;
            bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: ${({ theme }) => theme.bg2};
            svg{
                width: 18px;
                height: 18px;
                z-index: 1;
                cursor: pointer;
            }
        }
    }
}
`