import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import { toast } from 'react-hot-toast';
import { FiPlus } from "react-icons/fi";
import { useParams } from 'react-router-dom';
import LoadingFallback from 'router-entries/LoadingFallback';
import styled from 'styled-components';
import request from 'utils/request';
import MediaIndex from 'siro-media-library';
import { useAuth } from 'utils/useAuth';
import { getBuilderContext } from 'contexts/builder';
import Types from './Types';

const AddSectionPopup = ({ templateInstance, close }) => {
    const { getComponent } = React.useContext(getBuilderContext)
    const [currentComponent, setCurrentComponent] = React.useState({})
    const [currentImg, setCurrentImg] = React.useState(null)
    const [currentType, setCurrentType] = React.useState(null)
    const [currentKeywords, setCurrentKeywords] = React.useState(null)

    const { id: webId } = useParams()
    const containerRef = React.useRef()
    const [loading, setLoading] = React.useState(false)
    const [showMediaLibrary, setShowMediaLibrary] = React.useState(false)
    const { accessToken } = useAuth()

    React.useEffect(() => {
        checkIndex()
    }, [])

    const checkIndex = () => {
        const extractedComponent = getComponent(templateInstance.index)
        setCurrentImg(extractedComponent.saved?.imageUrl)
        setCurrentType(extractedComponent.saved?.type)
        setCurrentKeywords(extractedComponent.saved?.keywords)
        setCurrentComponent(extractedComponent)
    }

    // console.log(currentType?.map(item => item.id), 'currentType.map(item => item.id)')

    const handleSubmit = async (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        try {
            setLoading(true);
            if (currentComponent.saved) {
                const result = await request({
                    url: `${window.urls.web}/section/${currentComponent.saved.id}`,
                    body: { name: currentComponent.name, imageUrl: currentImg, typeIds: currentType.map(item => item.id), keywords: currentKeywords, data: currentComponent },
                    method: 'put',
                    headers: { webId }
                })
                toast.success(result.message);
            } else {
                const result = await request({
                    url: `${window.urls.web}/section/`,
                    body: { name: currentComponent.name, imageUrl: currentImg, typeIds: currentType.map(item => item.id), keywords: currentKeywords, data: currentComponent },
                    method: 'post',
                    headers: { webId }
                })
                toast.success(result.message);
            }
        } catch (e) {
            toast.error(e.message);
            console.log(e, 'err in submit ')
        } finally {
            close()
            setLoading(false);
        }

    }

    const onMediaSelect = (val) => {
        setCurrentImg(val.url)
        setShowMediaLibrary(false)
    }

    return (
        <Container onClick={(e) => e.target === containerRef.current && close()} ref={containerRef}>
            {loading ? (
                <LoadingFallback height={260} />
            ) : (
                <Div c="main-content">
                    <Div c="title">Section</Div>
                    <Div c='content-wrap'>
                        <Div c='left'>
                            <Div c="input">
                                <label>Name</label>
                                <input
                                    onChange={(e) => setCurrentComponent({ ...currentComponent, name: e.target.value })}
                                    value={currentComponent.name}
                                    required
                                    name="name"
                                />
                            </Div>
                            <Types currentType={currentType} setCurrentType={setCurrentType} />
                            <Div c="input">
                                <label>Keywords</label>
                                <input type='text' value={currentKeywords?.toString() ?? ''} onChange={e => setCurrentKeywords(e.target.value.split(','))} />
                            </Div>
                        </Div>
                        <Div c='right'>
                            <Div c="image-wrap">
                                <label>Thumbnail</label>
                                <Div c="media-preview">
                                    {currentImg ?
                                        <div className='image'><img src={currentImg} /></div>
                                        :
                                        <div className='image empty'>
                                            <div className='add' onClick={() => setShowMediaLibrary(!showMediaLibrary)}><FiPlus /></div>
                                            <span>Add an Image</span>
                                        </div>
                                    }
                                    {currentImg && <div className='bottom'><Button type="button" onClick={() => setShowMediaLibrary(!showMediaLibrary)}>Choose a photo</Button></div>}
                                </Div>
                                <MediaIndex setImage={onMediaSelect} onCancel={() => setShowMediaLibrary(!showMediaLibrary)} open={showMediaLibrary} accessToken={accessToken} page={false} webId={webId} />
                            </Div>
                        </Div>
                    </Div>
                    <Div c="footer">
                        <Button c="cancel" onClick={() => close()}>Close</Button>
                        <Button c="save" type="submit" onClick={handleSubmit}>Save</Button>
                    </Div>
                </Div>
            )}
        </Container>
    );
};

export default AddSectionPopup;

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    & > .main-content {
        background: ${({ theme }) => theme.popupBg};
        width: calc(100vw - 50px);
        height: calc(100vh - 5em);
        max-height: calc(100vh - 50px);
        max-width: calc((100vh - 50px) * 1.6);
        padding: 50px 40px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: space-between;
        & > .title {
            color: ${({ theme }) => theme.color};
            font-weight: 600;
            font-size: ${({ theme }) => theme.fs5};
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-transform: capitalize;
        }
        & > .content-wrap {
            display: flex;
            gap: 30px;
            width: 100%;
            height: calc(100% - 140px);
            .left{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 15px;
                overflow-y: auto;
                .input{
                    width: 100%;
                    label {
                        font-weight: 600;
                        text-transform: uppercase;
                        color: ${({ theme }) => theme.color2};
                        font-size: 10px;
                        line-height: 20px;
                        margin-bottom: 5px;
                    }
                    input {
                        width:100%;
                        border: none;
                        border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
                        padding: 8px 0;
                        transition: all 0.6s linear;
                        background: transparent;
                        color: ${({ theme }) => theme.color};
                        &:focus{
                            outline: none;
                            border-bottom: 1px solid ${({ theme }) => theme.color};
                        }
                    }
                }
            }
            .right{
                width: 50%;
                height: 100%;
                .image-wrap{
                    width: 100%;
                    height: 100%;
                    label {
                        font-weight: 600;
                        text-transform: uppercase;
                        color: ${({ theme }) => theme.color2};
                        font-size: 10px;
                        line-height: 20px;
                        margin-bottom: 5px;
                    }
                    .media-preview{
                        border-radius: 4px;
                        width: 100%;
                        margin-top: 5px;
                        height: calc(100% - 30px);
                        .image{
                            width: 100%;
                            height: calc(100% - 45px);
                            border-radius: 4px 4px 0 0;
                            background: ${({ theme }) => theme.bg3};
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                            &.empty{
                                background: transparent;
                                border: 1px dashed ${({ theme }) => theme.sectionBorderColor};
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                height: 100%;
                                .add{
                                    background: ${({ theme }) => theme.bg2};
                                    border-radius: 50%;
                                    width: 36px;
                                    height: 36px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;
                                    & > svg{
                                        width: 18px;
                                        height: 18px;
                                        color: ${({ theme }) => theme.color};
                                    }
                                }
                                span{
                                    color: ${({ theme }) => theme.color2};
                                }
                            }
                        }
                        .bottom{
                            background: ${({ theme }) => theme.bg2};
                            padding: 10px;
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            border-radius: 0 0 4px 4px;
                            height: 45px;
                            button{
                                font-weight: 600;
                                text-transform: uppercase;
                                cursor: pointer;
                                color: ${({ theme }) => theme.color2};
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
        .footer{
            display: flex;
            gap: 10px;
            justify-content: end;
            button {
                text-transform: uppercase;
                background-color: transparent;
                transition: background-color 0.3s;
                padding: 11px 22px;
                border-radius: 0;
                color: ${({ theme }) => theme.color};
                &.save{
                    background-color: ${({ theme }) => theme.color};
                    color: ${({ theme }) => theme.bg};
                }
                &.cancel{
                    &:hover{
                        background-color: ${({ theme }) => theme.bg2};
                    }
                }
            }
        }
    }
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
`;
