import Div from "elements/Div";
import React from "react";
import styled from "styled-components";

const VideoUrl = ({ close, object, handleMediaUrl }) => {
    const [value, setValue] = React.useState(object.value.url ? object.value.url : '')
    return (
        <Container>
            <Div c='url-wrap'>
                <label>Add a video link</label>
                <input placeholder="https:// ..." type="text" value={value} onChange={(e) => setValue(e.target.value)} />
            </Div>
            <Div c='buttons'>
                <span onClick={close}>Cancel</span>
                <span onClick={() => handleMediaUrl(value)} className={value == object.value?.url || value == '' ? 'inactive' : ''}>Add</span>
            </Div>
        </Container>
    )
}

export default VideoUrl

const Container = styled.div`
background: ${({ theme }) => theme.bg};
box-shadow: rgba(0, 0, 0, 0.12) 0px 16px 64px 0px, rgba(0, 0, 0, 0.08) 0px 0px 1px 0px;
padding: 10px;
width: 95%;
position: absolute;
bottom: 10px;
left: 0;
right: 0;
margin: auto;
.url-wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    label{
        font-size: 9.75px;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        line-height: 16px;
        color: ${({ theme }) => theme.color2};
        font-weight: 500;
    }
    input{
        margin: 10px 0;
        font-size: 14px;
        line-height: 22px;
        border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
        border-top: none;
        border-left: none;
        border-right: none;
        padding-bottom: 8px;
        &:focus{
            outline: none;
        }
    }
}
.buttons{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    span{
        font-size: 12px;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        padding: 3px 0;
        color: ${({ theme }) => theme.color};
        &.inactive{
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
`