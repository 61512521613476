import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import Select from '../InputTypes/Select';
import * as CaseTypes from './CaseTypes'

const CasePopup = ({ instance: firstInstance, setInstance: setFirstInstance, dispatchChange }) => {
    const [instance, setInstance] = React.useState(firstInstance)
    const outerWrap = React.useRef()
    const caseOptions = ['stateChange', 'dataChange']
    const handleSave = () => {
        dispatchChange(instance)
        setFirstInstance(null)
        // editComponent(selectedComponent.indices, 'cases', instance.name, [])
    }

    React.useEffect(() => {
        if (instance.type) {
            if (instance.type == 'dataChange')
                delete instance.stateName;
            else if (instance.type == 'stateChange')
                delete instance.dataProps;

        }
    }, [instance])
    const CaseType = CaseTypes[instance.type]
    return (
        <Container ref={outerWrap} onClick={(e) => e.target === outerWrap.current && setFirstInstance(null)}>
            <Div c="wrap">
                <Div c="title">Edit case</Div>
                <Div c="content-wrap">
                    <Div c="input-wrap">
                        <label>Type</label>
                        <Select options={caseOptions} value={instance.type} onChange={v => setInstance({ ...instance, type: v })} />
                    </Div>
                    {CaseType && <CaseType.default instance={instance} setInstance={setInstance} />}
                </Div>
                <Div c='button'>
                    <Button onClick={handleSave}>Save</Button>
                </Div>
            </Div>
        </Container>
    );
};

export default CasePopup;

const Container = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    & > .wrap {
        background: ${({ theme }) => theme.popupBg};
        width: 500px;
        height: fit-content;
        max-height: 80vh;
        padding: 40px;
        overflow-y: auto;
        & > .title {
            color: ${({ theme }) => theme.color};
            font-weight: 600;
            font-size: ${({ theme }) => theme.fs4};
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-transform: capitalize;
            margin-bottom: 30px;
            & > svg {
                cursor: pointer;
            }
        }
        & > .content-wrap {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: flex-end;
            .input-wrap {
                width: 100%;
                label {
                    font-weight: 600;
                    text-transform: uppercase;
                    color: ${({ theme }) => theme.color2};
                    font-size: 10px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }
            }
        }
        & > .button{
            width: 100%;
            display: flex;
            justify-content: end;
            margin-top: 30px;
            button {
                text-transform: uppercase;
                padding: 11px 22px;
                border-radius: 0;
                background-color: ${({ theme }) => theme.color};
                color: ${({ theme }) => theme.bg};
            }
        }
    }
`;
