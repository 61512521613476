import React from 'react';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { dispatchChange, getStyleValue } from 'utils/styleHelpers';
import styled from 'styled-components';
import TextTransformController from './TextTransformController';

const TextTransform = () => {
    const [value, setValue] = React.useState('')
    const { selectedComponent, editComponent } =
        React.useContext(getBuilderContext);
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext);
    const doc = frame.document;

    React.useEffect(() => {
        setInitialValue();
    }, [selectedComponent]);

    React.useEffect(() => {
        setInitialValue();
    }, [frameUpdate]);

    const setInitialValue = () => {
        const styleValue = getStyleValue(selectedComponent, 'textTransform', doc);
        setValue(styleValue);
    }

    const forwardChange = (changingValue) => {
        dispatchChange(editComponent, selectedComponent, 'textTransform', changingValue)
    }

    return (
        <Container>
            <TextTransformController value={value} forwardChange={forwardChange} />
        </Container>
    );
};

export default TextTransform;

const Container = styled.div`
width: 100%;
`