import BuilderContextProvider from 'contexts/builder';
import { ReferenceContextProvider } from 'contexts/reference';
import { CustomThemeContextProvider } from 'contexts/theme';
import React from 'react';
import { Outlet } from 'react-router-dom';

const BuilderEntry = () => {
    return (
        <React.Fragment>
            <CustomThemeContextProvider>
                <BuilderContextProvider>
                    <ReferenceContextProvider>
                        <Outlet />
                    </ReferenceContextProvider>
                </BuilderContextProvider>
            </CustomThemeContextProvider>
        </React.Fragment>
    );
};

export default BuilderEntry;
