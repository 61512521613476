import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const InitParallelComponents = () => {
    const { frame, frameUpdate, frameScreen } = React.useContext(getCrossDomainContext);
    const { selectedParallels } = React.useContext(getBuilderContext);
    const { theme } = React.useContext(getCustomThemeContext);
    const doc = frame.document;
    const className = 'bl-parallel';

    React.useEffect(() => {
        if(frame) {
            if (selectedParallels.length) drawParallels();
            else clearParallels();
        }
    }, [selectedParallels, frameUpdate, frameScreen]);

    const drawParallels = () => {
        clearParallels()
        const styleTag = doc.createElement('style');
        styleTag.setAttribute('class', `${className}-style`);
        styleTag.innerText = `
            .${className} {
                position:absolute;
                pointer-events:none;
                box-sizing:border-box;
                border:1px solid ${theme.mainColor};
                opacity:1;
                z-index:1000;
            }
            .${className}:after {
                content:"";
                display:block;
                width:100%;
                height:100%;
                background: ${theme.mainColor};
                opacity:0.1;
                position:absolute;
            }
        `;
        doc.head.appendChild(styleTag);

        selectedParallels.forEach((parallel) => {
            const target = doc.querySelector(`[data-track="${parallel}"]`);
            const { top, left, width, height } = target.getBoundingClientRect();

            const el = doc.createElement('div');
            el.setAttribute('class', `${className}`);
            el.style.top = `${top + frame.scrollY}px`;
            el.style.left = `${left + frame.scrollX}px`;
            el.style.width = `${width}px`;
            el.style.height = `${height}px`;
            doc.body.appendChild(el);
        });
    };
    const clearParallels = () => {
        const styleTag = doc.querySelector(`.${className}-style`)
        styleTag?.remove()

        const parallels = [...doc.querySelectorAll(`.${className}`)]
        parallels.forEach(parallel => parallel?.remove())
    };
    return null;
};

export default InitParallelComponents;
