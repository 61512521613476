import React from "react";
import styled from "styled-components";

const Controller = ({ value, forwardChange }) => {
    const styles = ['none', 'overline', 'line-through', 'underline', 'underline overline']
    return (
        <Container>
            <div className='box'>
                {styles.map((style) => (
                    <div key={style} className={`item ${value === style && 'active'}`} style={{ 'textDecoration': style }} onClick={() => forwardChange(style)}>{style == 'none' ? '-' : 'A'}</div>
                ))}
            </div>
        </Container>
    )
}

export default Controller

const Container = styled.div`
width: 100%;
.box{
    width: 100%;
    display: flex;
    padding: 2px;
    background: ${({ theme }) => theme.bg2};
    border-radius: 4px;
    .item{
        width: calc(100% / 5);
        background: transparent;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: ${({ theme }) => theme.fs};
        color: ${({ theme }) => theme.color2};
        font-weight: 500;
        border: 1px solid ${({ theme }) => theme.bg2};
        line-height: 24px;
        &.active, &:hover {
            background:${({ theme }) => theme.bg3};
            color: ${({ theme }) => theme.color};
        }
    }
}
`