import React from "react";
import request from 'utils/request';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import Div from "elements/Div";
import LoadingFallback from 'router-entries/LoadingFallback';
import { Cube, Search, Trash } from "utils/svg";
import { recursiveCopy } from "utils/recursive";
import { getBuilderContext } from "contexts/builder";
import Button from "elements/Button";

const ComponentList = ({ closePopup }) => {
    const { id: webId } = useParams()
    const [loading, setLoading] = React.useState(false)
    const [templateTypes, setTemplateTypes] = React.useState([])
    const [component, setComponent] = React.useState([])
    const { selectedComponent, addComponent } = React.useContext(getBuilderContext)
    const [size, setSize] = React.useState(10)
    const [searchTerm, setSearchTerm] = React.useState('');

    React.useEffect(() => {
        fetchTypes()
    }, [])

    React.useEffect(() => {
        if (templateTypes.length > 0) {
            fetchComponents();
        }
    }, [templateTypes, size]);

    const handleAddComponent = (component) => {
        const copiedElement = recursiveCopy(component.data, true)
        copiedElement.saved = {
            type: 'public-component',
            id: component.id,
            imageUrl: component.imageUrl,
            keywords: component.keywords,
        }
        copiedElement.name = component.name
        addComponent(copiedElement, selectedComponent.indices, 'a')
        closePopup()
    }

    const fetchComponents = async () => {
        try {
            setLoading(true);
            const allComponents = [];
            for (const type of templateTypes) {
                const result = await request({
                    url: window.urls.web + `/template?type_id=${type.id}&size=${size}`,
                    headers: { webId }
                });
                allComponents.push({ type: type.name, data: result.data });
            }
            setComponent(allComponents);
        } catch (e) {
            console.log(e, 'err component')
        } finally { setLoading(false) }
    }

    const fetchTypes = async () => {
        try {
            setLoading(true)
            const result = await request({ url: window.urls.web + `/template_type`, headers: { webId } })
            setTemplateTypes(result.data)
        } catch (e) {
            console.log(e, 'err component type')
        } finally { setLoading(false) }
    }

    const deleteComponent = async (id) => {
        try {
            if (!window.confirm('Are you sure to delete it?')) return null
            setLoading(true)
            await request({ url: window.urls.web + `/template/${id}`, method: 'delete' })
        } catch (e) {
            console.log('err deleting template', e)
        } finally {
            setLoading(false)
            fetchComponents()
        }
    }

    const filteredComponents = component.map((type) => ({
        type: type.type,
        data: type.data.filter((data) =>
            data.keywords.some((keyword) => keyword.toLowerCase().includes(searchTerm.toLowerCase()))
        ),
    }))
    return (
        <Container>
            {loading ?
                <Div c='loading'><LoadingFallback /></Div>
                :
                templateTypes.length > 0 ?
                    <>
                        <Div c="search-wrap">
                            <Div c="search-bar">
                                <Search />
                                <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </Div>
                        </Div>
                        <Div c='content-wrap'>
                            {filteredComponents.map((type, i) => (
                                <Div c='component-wrap' key={i}>
                                    <Div c="type-caption">{type.type}</Div>
                                    <Div c='wrap'>
                                        {type.data.length > 0 ? type.data.map((data, i) => (
                                            <Div c="component" key={i}>
                                                <Div c="section-img" key={i} onClick={() => handleAddComponent(data)}>
                                                    <img src={data.imageUrl ? data.imageUrl : "https://sanantoniosports.org/wp-content/uploads/2022/07/placeholder-image.jpeg"} alt={data.name} />
                                                </Div>
                                                <Div c="detail"><span>{data.name}</span><Trash onClick={() => deleteComponent(data.id)} /></Div>
                                            </Div>
                                        )) : searchTerm ? `Result: ${searchTerm} not found` : 'no components found'}
                                    </Div>
                                </Div>
                            ))}
                        </Div>
                        {filteredComponents > 0 && size > 9 && <Button onClick={() => setSize(size + 10)} c="loadmore">
                            Load more
                        </Button>}
                    </> : <Div c="empty">
                        <Cube />
                        <h5>No components created yet</h5>
                        <span>Components let you reuse designs in your site. To create a component, right-click an element on the canvas and select “Save as component”.</span>
                    </Div>
            }
        </Container>
    )
}

export default ComponentList;

const Container = styled.div`
height: 400px;
.empty{
    padding: 100px 25px 25px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    h5{
        font-size: 18px;
    }
    span{
        font-size: 14px;
        line-height: 1.3;
    }
}
.search-wrap{
    padding: 25px;
    position: fixed;
    background:${({ theme }) => theme.bg};
    z-index: 1;
    width: 400px;
    border-radius:8px 8px 0 0;
    .search-bar{
        padding:10px;
        display:flex;
        align-items:center;
        gap:10px;
        background:${({ theme }) => theme.bg2};
        border-radius:4px;
        svg{
            width: 22px;
            height: 22px;
        }
        input{
            width: 100%;
            border: none;
            background: transparent;
            color: ${({ theme }) => theme.color};
            &:focus{
                outline: none;
            }
        }
    }
}
.loadmore{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0;
}
.content-wrap{
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 100px 25px 25px 25px;
    .component-wrap{
        display: flex;
        flex-direction: column;
        gap: 15px;
        .type-caption{
            font-size: ${({ theme }) => theme.fs2};
            font-weight: 600;
            color: ${({ theme }) => theme.color};
            text-transform: capitalize;
        }
        .wrap{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            .component{
                width: calc((100% - 15px) / 2);
                .section-img{
                    cursor: pointer;
                    width: 100%;
                    height: 140px;
                    background-color: transparent;
                    transition: background-color 0.5s ease;
                    img{
                        margin-bottom: 5px;
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                    &:hover{
                        background-color: ${({ theme }) => theme.bg2};
                    }
                }
                .detail{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span{
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: 1.4;
                        font-size: ${({ theme }) => theme.fs2};
                        color: ${({ theme }) => theme.color2};
                    }
                    svg{
                        width: 14px;
                        height: 14px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
`
