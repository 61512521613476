import Dropdown from 'components/Dropdown';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import request from 'utils/request';

const CollectionType = ({ object, forwardKey, forwardChange }) => {
    const { id } = useParams();
    const [colTypes, setColTypes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [categories, setCategories] = React.useState(null)
    React.useEffect(() => {
        fetchColTypes();
    }, []);
    const fetchColTypes = async () => {
        try {
            setLoading(true)
            const result = await request({
                url: window.urls.content + `/collection/ct/listCt`,
                method: 'post',
                headers: { webid: id },
                body: {},
            })
            setColTypes(result.data)
        } catch (e) {
            console.log(e, 'err fetching colTypes')
        } finally {
            setLoading(false)
        }
    };
    React.useEffect(() => {
        const foundOne = colTypes.find((c) => c._id === object.value?.id)
        if (foundOne) {
            const filterCategories = foundOne._source.fields.filter(field => field.type === 'category')
            if (filterCategories.length) setCategories(filterCategories)
            else setCategories(null)
        } else {
            setCategories(null)
        }
    }, [colTypes, object.value?.id])

    const handleChange = (val) => {
        if(val !== null){
            const selected = colTypes.find((c) => c._id === val)
            forwardChange(forwardKey, {
                id: selected._id,
                fields: selected._source.fields,
                name: selected._source.name,
                webId: id,
            })
        }else{
            forwardChange(forwardKey, {
                id: null,
                fields: null,
                name: null,
                webId: null,
            })
        }
    }
    const handleCategory = (e) => {
        const value = e.target.value
        if (value === 'null') forwardChange(forwardKey, { ...object.value, categoryId: null })
        else forwardChange(forwardKey, { ...object.value, categoryId: value })
    }

    return (
        <Container>
            <Dropdown data={colTypes} width="130px" object={object} handleChange={handleChange} />
            {categories && (
                <>
                    <div className='caption'>Available categories</div>
                    <select onChange={(e) => handleCategory(e)}>
                        <option selected value={'null'}>Choose one</option>
                        {categories.map(category => {
                            return (
                                <option value={category.key} key={category.key}>{category.label}</option>
                            )
                        })}
                    </select>
                </>
            )}
        </Container>
    );
};

export default CollectionType;

const Container = styled.div`
    & > select {
        width:100%;
        outline:none;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 6px 12px;
        border-radius: 4px;
        text-transform:capitalize;
    }
    & > .caption {
        opacity:0.5;
        margin:15px 0px 10px;
        font-weight:500;
    }
`;
