import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AwsConfigAuth } from 'config';

Amplify.configure({ Auth: AwsConfigAuth });
const authContext = createContext({});

export const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
    return useContext(authContext);
};

const useProvideAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = React.useState(null)
    const [accessToken, setAccessToken] = React.useState('')

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((result) => {
                setUser(result)
                setAccessToken(result.signInUserSession.accessToken.jwtToken)
                setIsAuthenticated(true);
            })
            .catch(async (err) => {
                console.warn('Failed to authenticate', err)
                await signIn('99801406', 'Killer@951988')
            });
    }, []);

    const signIn = async (username, password) => {
        try {
            const result = await Auth.signIn(username, password);
            setUser(result)
            setIsAuthenticated(true);
            setAccessToken(result.signInUserSession.accessToken.jwtToken)
            console.log('Successfully signed in')
        } catch (error) {
            console.log('sign in fail catch, ', error.message)
        }
    };

    return {
        isAuthenticated,
        signIn,
        user,
        accessToken
    };
};
