import { getReferenceContext } from 'contexts/reference';
import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import LoadingFallback from 'router-entries/LoadingFallback';
import styled from 'styled-components';
import request from 'utils/request';

const Templates = ({ templateId }) => {
    const { id: webId } = useParams();
    const [templates, setTemplates] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [size, setSize] = React.useState(10);
    const { setTemplateInstance, InsertingElement } = React.useContext(getReferenceContext);
    const dragImage = React.useRef();
    const fetchTemplates = async () => {
        try {
            setLoading(true);
            const result = await request({ url: window.urls.web + `/template?type_id=${templateId}&size=${size}`, headers: { webId } });
            setTemplates(result.data);
        } catch (e) {
            console.log('err fetching template', e);
        } finally {
            setLoading(false);
        }
    };
    const deleteTemplate = async (id) => {
        try {
            if (!window.confirm('Are you sure to delete it?')) return null;
            setLoading(true);
            await request({ url: window.urls.web + `/template/${id}`, method: 'delete' });
        } catch (e) {
            console.log('err deleting template', e);
        } finally {
            setLoading(false);
        }
    };
    React.useEffect(() => {
        //SETTING DRAG IMAGE
        const img = document.createElement('img');
        img.src = '/img/dragImage.jpg';
        dragImage.current = img;
    }, []);
    React.useEffect(() => {
        setSize(10);
        setTemplates([])
    }, [templateId]);
    React.useEffect(() => {
        fetchTemplates();
    }, [templateId, size]);
    const handleDrag = (e, template) => {
        e.stopPropagation();
        e.dataTransfer.effectAllowed = 'copy'; //Operation instruction
        e.dataTransfer.setDragImage(dragImage.current, 0, 0);
        InsertingElement.current = { ...template.data, templateId: template.id }; //Storing element to insert
    };
    return (
        <Container data-scrollable="true">
            {templates.map((template) => (
                <Button c="wrap" key={template.id} draggable onDragStart={(e) => handleDrag(e, template)}>
                    <img src={template.imageUrl} />
                    <Div c="name">{template.name}</Div>
                    <Div c="icons-wrap">
                        <AiOutlineEdit onClick={() => setTemplateInstance({ id: template.id })} />
                        <AiOutlineDelete onClick={() => deleteTemplate(template.id)} className="delete" />
                    </Div>
                </Button>
            ))}
            {loading ? (
                <LoadingFallback height={33} />
            ) : (
                <Button onClick={() => setSize(size + 10)} c="loadmore">
                    Load more
                </Button>
            )}
        </Container>
    );
};

export default Templates;

const Container = styled.div`
    position: relative;
    gap: 15px;
    height: 100%;
    overflow-y: scroll;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    & > .loadmore {
        width: 100%;
        color: ${({ theme }) => theme.mainColor};
        font-weight: 500;
        padding: 10px;
    }
    & > .wrap {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px;
        position: relative;
        width: 100%;
        // background:${({ theme }) => theme.bg2};
        box-shadow: 0px 0px 1px 1px ${({ theme }) => theme.sectionBorderColor};
        flex-direction: column;
        img {
            width: 100%;
            border-radius: 8px;
            border: 1px solid ${({ theme }) => theme.sectionBorderColor};
            object-fit: cover;
        }
        .icons-wrap {
            position: absolute;
            top: 16px;
            right: 16px;
            font-size: 16px;
            display: flex;
            gap: 4px;
            color: ${({ theme }) => theme.color2};
            pointer-events: auto;
            opacity: 0;
            transition: 0.3s ease;
            padding: 4px;
            color: white;
            background: ${({ theme }) => theme.color2};
            border-radius: 4px;
            & > .delete {
                color: rgba(255, 0, 0, 0.8);
            }
            svg:hover {
                cursor: pointer;
            }
        }
        &:hover {
            .icons-wrap {
                opacity: 1;
            }
        }
    }
`;

