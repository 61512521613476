import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const DisablePointerEvents = () => {
    const { frame } = React.useContext(getCrossDomainContext);
    const { theme } = React.useContext(getCustomThemeContext);
    const { selectedComponent } = React.useContext(getBuilderContext)
    const doc = frame.document;

    React.useEffect(() => {
        applyStyles()
    }, [selectedComponent])

    const applyStyles = () => {
        doc.querySelector('#builder-general-css')?.remove()
        const rulesToApply = `
            html > * {
                pointer-events:none;
            }
            body::-webkit-scrollbar {
                width: 0px;
                height:0px;
                background:transparent;
            }
            body::-webkit-scrollbar-thumb {
                background-color: rgba(0,0,0,0.0);
            }
            #root > * {
                pointer-events:none;
            }
            #root {
                pointer-events:auto;
                height:100vh !important;
            }
            [kind="component"] > *, [kind="grid"] > *, [kind="block"] > * {
                pointer-events:none;
            }
            [data-emptyappendable="true"]{
                border:1px dashed ${theme.mainColor};
            }
            [data-emptyappendable="true"] > * {
                pointer-events:auto;
            }
            #root [kind="component"] {
                pointer-events:auto;
            }
            #root iframe[kind="component"] > * {
                pointer-events:none !important;
            }
            #root [kind="grid"] {
                pointer-events:auto;
            }
            #root [kind="component"].dragging > * {
                pointer-events:none !important;
            }
            [kind="component"] {
                cursor:default;
            }
            [kind="grid"] > [kind="component"] {
                cursor:grab;
            }
            #root [kind="component"].grid-dragging {
                position:relative;
                z-index:2;
            }
            #root [kind="component"].grid-dragging > * {
                pointer-events:none !important;
            }
            #root [kind="component"].grid-dragging.drop-bg-effect {
                background:rgba(255,255,255,0.6) !important;
                border:1px solid rgba(0,0,0,0.3);
            }
            #root [kind="component"].invisible {
                visibility: hidden !important;
                cursor:grabbing;
            }
            #root[data-highlight] [kind="component"]{
                outline:1px solid ${theme.mainColor2} !important;
            }
            .resize-children-draggable-removed-temp {
                pointer-events:none !important;
            }
            .resize-sibling-draggable-removed-temp {
                pointer-events:none !important;
            }
            // #root [kind="component"].dragging-source{
            //     animation: colorChange 1s infinite;
            // }
            // @keyframes colorChange {
            //     0% {
            //       background-color: transparent;
            //     }
            //     50% {
            //       background-color: #f5458c40;
            //     }
            //     100% {
            //       background-color: transparent;
            //     }
            // }
        `;
        const style = doc.createElement('style');
        style.setAttribute('id', 'builder-general-css');
        style.type = 'text/css';
        style.appendChild(doc.createTextNode(rulesToApply));
        doc.head.appendChild(style);
    };

    return null;
};

export default DisablePointerEvents;
