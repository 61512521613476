import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const InputNumber = ({ value, forwardChange }) => {
    const [data, setData] = React.useState(0)

    React.useEffect(() => {
        if (value !== undefined && typeof value === 'string') {
            const match = value.match(/-?\d*\.?\d+/);
            if (match) {
                const extractedNumber = parseFloat(match[0]);
                setData(extractedNumber)
            }
        }
        else {
            setData(0)
        }
    }, [value])

    const handleBlur = () => {
        setTimeout(() => {
            forwardChange(data)
        }, 150);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                forwardChange(data)
            }, 150);
        }
    };
    return (
        <Container>
            <input type="number" value={data} onChange={(e) => setData(e.target.value)} onKeyDown={handleKeyDown} onBlur={handleBlur} />
        </Container>
    );
};

InputNumber.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    attribute: PropTypes.string,
};

export default InputNumber;

const Container = styled.div`
width: 100%;
input{
    width: 100%;
    padding: 8px 12px;
    background: ${({ theme }) => theme.bg2};
    border: none;
    border-radius: 4px;
    height: 32px;
    color: ${({theme}) => theme.color};
    &:focus{
        outline: none;
    }
}
`;
