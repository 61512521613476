import React from "react";
import styled from "styled-components";
import Div from 'elements/Div';
import request from 'utils/request';
import { useParams } from 'react-router-dom';
import LoadingFallback from 'router-entries/LoadingFallback';
import { getBuilderContext } from "contexts/builder";
import { populateElements } from "utils/vanillaFunctions";
import { getCrossDomainContext } from "contexts/crossDomain";
import { IoIosArrowDown } from "react-icons/io";
import { recursiveCopy } from "utils/recursive";
import { Column, Column2, Column3, Trash } from "utils/svg";

const SectionPopup = ({ close, PointingAt }) => {
    const { selectedComponent, addComponent, selectComponent } = React.useContext(getBuilderContext)
    const { setComposition, composition } = React.useContext(getCrossDomainContext)
    const containerRef = React.useRef()
    const [loading, setLoading] = React.useState(false)
    const [section, setSection] = React.useState([])
    const [sectionTypes, setSectionTypes] = React.useState([])
    const [isActive, setActive] = React.useState(0)
    const { id: webId } = useParams()
    const [size, setSize] = React.useState(10)
    const [sectionColumn, setSectionColumn] = React.useState(2)

    React.useEffect(() => {
        fetchTypes()
    }, [])

    React.useEffect(() => {
        if (sectionTypes.length > 0) {
            fetchTemplates()
        }
    }, [isActive, sectionTypes, size])

    const fetchTypes = async () => {
        try {
            setLoading(true)
            const result = await request({ url: window.urls.web + `/section_type`, headers: { webId } })
            setSectionTypes(result.data);
        } catch (e) {
            console.log(e, 'err section type')
        } finally { setLoading(false) }
    }

    const fetchTemplates = async () => {
        try {
            setLoading(true)
            const result = await request({ url: window.urls.web + `/section?type_id=${sectionTypes[isActive].id}&size=${size}`, headers: { webId } })
            setSection(result.data)
        } catch (e) {
            console.log(e, 'err section')
        } finally { setLoading(false) }
    }

    const handleBlankSection = (isFlex) => {
        // Populate base grid element
        const cType = isFlex ? 'Layout' : 'Layout'
        const cComponent = isFlex ? 'Datagrid' : 'Grid'
        const populatedElements = populateElements()
        const layoutElements = populatedElements.find(p => p.type === cType).components
        const gridElement = layoutElements.find(g => g.component === cComponent)
        const clonedGridElement = recursiveCopy(gridElement, true)
        // Theres only 2 situation
        if (!composition.length) { // sitation 1: Empty composition

            // Set the once and only grid and close popup window
            setComposition([clonedGridElement])
            close()

            // Its okay to make the function run after
            // setComposition since it has no effect in lifecycle
            setTimeout(() => selectComponent('0'), 0);

        } else { // situation 2: increase or decrease last outermost

            if (!PointingAt.current || !selectedComponent) return null
            addComponent(clonedGridElement, selectedComponent.indices, PointingAt.current)
            close()

            // To distinguish the pointer, run a little script to manipulate
            let incrementedNumber
            if (PointingAt.current === 'u') incrementedNumber = selectedComponent.indices;
            else incrementedNumber = (parseInt(selectedComponent.indices, 10) + 1).toString()
            setTimeout(() => selectComponent(incrementedNumber), 0);
        }
    }

    const handleAddSection = (section) => {
        const copiedElement = recursiveCopy(section.data, true)
        copiedElement.saved = {
            type: section.type,
            id: section.id,
            imageUrl: section.imageUrl,
            keywords: section.keywords,
        }
        copiedElement.name = section.name
        if (!composition.length) {
            setComposition([copiedElement])
            close()
            setTimeout(() => selectComponent('0'), 0);
        } else {
            if (!PointingAt.current || !selectedComponent) return null
            addComponent(copiedElement, selectedComponent.indices, PointingAt.current)
            close()

            let incrementedNumber
            if (PointingAt.current === 'u') incrementedNumber = selectedComponent.indices;
            else incrementedNumber = (parseInt(selectedComponent.indices, 10) + 1).toString()
            setTimeout(() => selectComponent(incrementedNumber), 0);
        }
    }

    const deleteSection = async (id) => {
        try {
            if (!window.confirm('Are you sure to delete it?')) return null
            setLoading(true)
            await request({ url: window.urls.web + `/section/${id}`, method: 'delete' })
        } catch (e) {
            console.log('err deleting section', e)
        } finally {
            setLoading(false)
            fetchTemplates()
        }
    }
    const deleteType = async (id) => {
        try {
            if (!window.confirm('You sure you want to delete type?')) return null
            setLoading(true)
            await request({ url: window.urls.web + `/section_type/${id}`, method: 'delete' });
        } catch (e) {
            console.log(e, 'err delete section type')
        } finally {
            setLoading(false)
            fetchTypes()
        }
    }

    const renderSections = (columns) => {
        const sectionsByColumn = Array.from({ length: columns }, (_, columnIndex) => (
            section.filter((_, i) => i % columns === columnIndex)
        ))

        return sectionsByColumn.map((column, columnIndex) => (
            <Div c="column" key={columnIndex}>
                {column.map((data, i) => (
                    <Div c="section-img" key={i}>
                        <Div c='trash' onClick={() => deleteSection(data.id)}>
                            <Trash />
                        </Div>
                        <img onClick={() => handleAddSection(data)} src={data.imageUrl ? data.imageUrl : "https://sanantoniosports.org/wp-content/uploads/2022/07/placeholder-image.jpeg"} alt={data.name} />
                    </Div>
                ))}
            </Div>
        ))
    }

    return (
        <Container onClick={(e) => e.target === containerRef.current && close()} ref={containerRef}>
            <Div c="main-content">
                <Div c='left'>
                    <Div c="title">Add a Section</Div>
                    <Div c='menu-wrap'>
                        <Div c="menus">
                            <Div c='caption'>
                                <span onClick={()=>handleBlankSection()}>Add a blank section</span>
                                <Div c='line'></Div>
                            </Div>
                            <Div c='caption'>
                                <span onClick={()=>handleBlankSection(true)}>Add a flex section</span>
                                <Div c='line'></Div>
                            </Div>
                            <Div c='caption'>
                                <span>Saved sections</span>
                                <Div c='line'></Div>
                            </Div>
                        </Div>
                        <Div c="menus">
                            {sectionTypes.map((type, i) => (
                                <Div c={`caption ${isActive == i && 'active'}`} key={i}>
                                    <span onClick={() => setActive(i)}>{type.name}</span>
                                    <Div c='line'></Div>
                                </Div>
                            ))}
                        </Div>
                    </Div>
                </Div>
                <Div c="right">
                    {loading ? <LoadingFallback height={260} /> : section.length ?
                        <Div c='column-wrap'>
                            <Div c='column-control'>
                                <Div c='box-wrap'>
                                    <Div c={`box ${sectionColumn == 3 && 'active'}`} onClick={() => setSectionColumn(3)}><Column3 /></Div>
                                    <Div c={`box ${sectionColumn == 2 && 'active'}`} onClick={() => setSectionColumn(2)}><Column2 /></Div>
                                    <Div c={`box ${sectionColumn == 1 && 'active'}`} onClick={() => setSectionColumn(1)}><Column /></Div>
                                </Div>
                                <Div c="close" onClick={() => close()}>
                                    <span>CLOSE</span>
                                    <Div c='line'></Div>
                                </Div>
                            </Div>
                            <Div c='sections-wrap'>
                                {renderSections(sectionColumn)}
                            </Div>
                        </Div>
                        :
                        sectionTypes.length > 0 && <Div c='empty' onClick={(e) => { e.stopPropagation(); deleteType(sectionTypes[isActive].id) }}>delete {sectionTypes[isActive]?.name} type </Div>
                    }
                    {section.length > 9 && <Div c="loadmore"><Div onClick={() => setSize(size + 10)}>See More <IoIosArrowDown /></Div></Div>}
                </Div>
            </Div>
        </Container>
    )
}

export default SectionPopup

const Container = styled.div`
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
position: fixed;
left: 0;
top: 0;
background: rgba(0, 0, 0, 0.5);
z-index: 1;
animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
& > .main-content{
    background: ${({ theme }) => theme.popupBg};
    width: calc(100vw - 50px);
    height: calc(100vh - 5em);
    max-height: calc(100vh - 50px);
    max-width: calc((100vh - 50px) * 1.6);
    display: flex;
    & > .left{
        width: 35%;
        padding: 50px 20px 50px 40px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        overflow-y: auto;
        & > .title{
            color: ${({ theme }) => theme.color};
            font-weight: 500;
            font-size: ${({ theme }) => theme.fs5};
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        & > .menu-wrap{
            display: flex;
            flex-direction: column;
            gap: 30px;
            & > .menus{
                font-size: ${({ theme }) => theme.fs3};
                display: flex;
                flex-direction: column;
                gap: 8px;
                .caption{
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    & > span{
                        line-height: 22px;
                        width: fit-content;
                        cursor: pointer;
                        font-weight: 500;
                        color: ${({ theme }) => theme.color};
                    }
                    .line{
                        max-width: 0;
                        transition: max-width .6s;
                        height: 2px;
                        background: ${({ theme }) => theme.color};
                    }
                    &:hover, &.active{
                        .line{
                            max-width: 150px;
                        }
                    }
                }
            }
        }
    }
    & > .right{
        width: 65%;
        padding: 50px 40px 50px 20px;
        .column-wrap{
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 100%;
            .column-control{
                width: 100%;
                display: flex;
                justify-content: space-between;
                .box-wrap{
                    display: flex;
                    border-radius: 2px;
                    background: ${({ theme }) => theme.bg2};
                    padding: 2px;
                    .box{
                        padding: 4px;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        &.active{
                            background: ${({ theme }) => theme.bg};
                        }
                    }
                }
                .close{
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    & > span{
                        line-height: 22px;
                        width: fit-content;
                        cursor: pointer;
                        font-weight: 500;
                        color: ${({ theme }) => theme.color};
                    }
                    .line{
                        max-width: 0;
                        transition: max-width .6s;
                        height: 2px;
                        background: ${({ theme }) => theme.color};
                    }
                    &:hover, &.active{
                        .line{
                            max-width: 150px;
                        }
                    }
                }
            }
            .sections-wrap{
                display: flex;
                gap: 30px;
                .column{
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    width: 100%;
                    & > .section-img{
                        cursor: pointer;
                        width: 100%;
                        filter: drop-shadow(rgba(0, 0, 0, 0.12) 0px 4px 8px) drop-shadow(rgba(0, 0, 0, 0.12) 0px 0px 1px);
                        transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, filter;
                        height: fit-content;
                        filter: drop-shadow(rgba(0, 0, 0, 0.12) 0px 4px 8px) drop-shadow(rgba(0, 0, 0, 0.12) 0px 0px 1px);
                        position: relative;
                        &:hover{
                            filter: drop-shadow(rgba(0, 0, 0, 0.12) 0px 8px 16px) drop-shadow(rgba(0, 0, 0, 0.12) 0px 0px 1px);
                            transform: translateY(-5px);
                        }
                        .trash{
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            z-index:1;
                            background: ${({ theme }) => theme.bg};
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 32px;
                            height: 32px;
                            cursor: pointer;
                            svg{
                                width: 18px;
                                height: 18px;
                            }
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
`