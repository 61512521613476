import React from 'react';
import ListGroups from './components/ListGroups';
import { BsStack } from 'react-icons/bs';
import { AiFillLayout } from 'react-icons/ai';
import { FcTemplate, FcFolder, FcRating } from 'react-icons/fc';
import { SiFormstack } from 'react-icons/si'
import SearchBar from './components/SearchBar';
// import ListTemplates from './components/ListTemplates'; //Can delete it later

const index = () => {
    return (
        <>
            <SearchBar />
            <ListGroups
                groups={[
                    {
                        name: 'Basics',
                        types: [
                            {
                                name: 'Elements',
                                componentKey: 'Elements',
                                icon: BsStack,
                            },
                            {
                                name: 'Layout',
                                componentKey: 'Layouts',
                                icon: AiFillLayout,
                            },
                            {
                                name: 'Form',
                                componentKey: 'Form',
                                icon: SiFormstack
                            }
                        ],
                    },
                    {
                        name: 'Components',
                        types: [
                            {
                                name: 'Templates',
                                componentKey: 'TemplateTypes',
                                icon: FcTemplate,
                            },
                            {
                                name: 'My saved',
                                componentKey: 'MySaved',
                                icon: FcFolder,
                            },
                            {
                                name: 'Starred',
                                componentKey: 'Layouts',
                                icon: FcRating,
                            },
                        ],
                    },
                ]}
            />
        </>
    );
};

export default index;
