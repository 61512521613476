import Div from "elements/Div";
import React from "react";
import { AiOutlineSearch } from 'react-icons/ai';
import styled from "styled-components";

const SearchModule = ({ searchTerm, setSearchTerm, width }) => {
    return (
        <Container>
            <Div c="search-bar" style={{width: width}}>
                <AiOutlineSearch />
                <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </Div>
        </Container>
    )
}

export default SearchModule

const Container = styled.div`
.search-bar{
    padding:10px;
    display:flex;
    align-items:center;
    gap:10px;
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    position: fixed;
    background: ${({theme}) => theme.bg};
    z-index: 2;
    svg{
        width: 22px;
        height: 22px;
        path{
            fill: ${({theme}) => theme.color2};
        }
    }
    input{
        width: 100%;
        border: none;
        background: transparent;
        color: ${({theme}) => theme.color};
        font-weight: 500;
        &:focus{
            outline: none;
        }
    }
}
`