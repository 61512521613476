import React from 'react';
import { BoxBlack, Button, Check, Data, Form, ImageBlack, Link, Radio, Select, SocialLink, TextBlack, Video } from './svg';

const DictionaryElement = ({ data }) => {
    let componentToRender;
    switch (data) {
        case "Button":
        case "Submit":
            componentToRender = <Button />;
            break;
        case "Text":
        case "Label":
            componentToRender = <TextBlack />;
            break;
        case "Image":
        case "Icon":
            componentToRender = <ImageBlack />;
            break;
        case "Link":
            componentToRender = <SocialLink />;
            break;
        case "Datalink":
            componentToRender = <Link />;
            break;
        case "Form":
            componentToRender = <Form />;
            break;
        case "Datagrid":
        case "Repeat grid":
        case "Repeat box":
            componentToRender = <Data />;
            break;
        case "Video":
            componentToRender = <Video />;
            break;
        case "Select":
            componentToRender = <Select />;
            break;
        case "RadioGroup":
        case "Radio":
            componentToRender = <Radio />;
            break;
        case "CheckGroup":
        case "Checkbox":
            componentToRender = <Check />;
            break;
        default:
            componentToRender = <BoxBlack />;
            break;
    }
    return <>{componentToRender}</>;
};

export default DictionaryElement;