import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import LeftSection from './LeftSection';
import MainSection from './MainSection';

const Body = () => {
    return (
        <Container>
            <LeftSection />
            <MainSection />
        </Container>
    );
};

export default Body;

const Container = styled.div`
    flex: 1;
    display:flex;
`;
