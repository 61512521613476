import React from 'react';
import { Player } from 'video-react';
import MediaIndex from 'siro-media-library';
import { useAuth } from 'utils/useAuth';
import { useParams } from 'react-router-dom';
import Div from 'elements/Div';
import styled from 'styled-components';
import useSync from 'utils/useSync';
const Media = ({ object, forwardKey, forwardChange }) => {

    const [showMediaLibrary, setShowMediaLibrary] = React.useState(false)
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: object.sync, operation: { type: 'option', forwardKey, forwardChange } })
    const { accessToken } = useAuth()
    const { id: webId } = useParams()
    const onMediaSelect = (media) => {
        forwardChange(forwardKey, media.url)
        setShowMediaLibrary(false)
    }
    return (
        <Div style={{ position: 'relative', width: '100%', padding: '10px 0' }}>
            {object.sync ? syncElement() : (
                <Container>
                    <Div c="image-container">
                        <Div c="image">{object.kind === "photo" ?
                            <img src={object.value} alt="preview_img" />
                            :
                            <Player
                                src={object.value}
                                playsInline
                                className="preview_video"
                            />
                        }
                        </Div>
                        <Div c="bottom">
                            <Div c='type'>{object.kind}</Div>
                            <Div c='flex'>
                                <span onClick={() => setShowMediaLibrary(true)}>Replace</span>
                                {syncTrigger()}
                            </Div>
                        </Div>
                    </Div>
                    <MediaIndex setImage={onMediaSelect} onCancel={() => setShowMediaLibrary(!showMediaLibrary)} open={showMediaLibrary} accessToken={accessToken} page={false} webId={webId} />
                </Container>
            )}
            {syncSuggestion()}
        </Div>
    );
};

export default Media;

const Container = styled.div`
padding: 0 20px;
   .image-container{
    border-radius: 4px;
    width: 100%;
    background: ${({ theme }) => theme.bg3};
    .image{
        width: 100%;
        height: 120px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .bottom{
        background: ${({ theme }) => theme.bg2};
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        border-radius: 0 0 4px 4px;
        .type{
            color: ${({ theme }) => theme.color2};
        }
        .flex{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            span{
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                color: ${({ theme }) => theme.color3};
                line-height: 24px;
            }
        }
    }
   }
`;