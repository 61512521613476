import { getReferenceContext } from 'contexts/reference';
import Div from 'elements/Div';
import React from 'react';
import { BsSquare } from 'react-icons/bs';
import styled from 'styled-components';

const DragBox = ({ component }) => {
    const { InsertingElement } = React.useContext(getReferenceContext);
    const dragImage = React.useRef()
    const dragStart = (e) => {
        e.stopPropagation();
        e.dataTransfer.effectAllowed = 'copy'; //Operation instruction
        e.dataTransfer.setDragImage(dragImage.current, 0, 0)
        InsertingElement.current = component; //Storing element to insert
    };
    React.useEffect(() => {
        //SETTING DRAG IMAGE
        const img = document.createElement('img')
        img.src = "/img/dragImage.jpg"
        dragImage.current = img
    }, [])
    return (
        <Container className="cardbox" onDragStart={dragStart} draggable>
            <BsSquare/>
            <Div c="name">{component.name}</Div>
        </Container>
    );
};

export default DragBox;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.sectionBorderColor};
    background: ${({ theme }) => theme.bg2};
    & > svg {
        font-size: 20px;
    }
    &:hover {
        cursor: pointer;
        background: ${({ theme }) => theme.bg3};
    }
`;
