import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import LoadingFallback from 'router-entries/LoadingFallback';
import styled from 'styled-components';
import request from 'utils/request';
import PagePopup from './PagePopup';
import { toast } from 'react-hot-toast';
import MainPages from './MainPages';

const PagesList = () => {
    const { id: webId } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [pages, setPages] = React.useState([]);
    const [searchParams] = useSearchParams();
    const slug = searchParams.get('slug');
    const [popupInstance, setPopupInstance] = React.useState(null)

    React.useEffect(() => {
        goFetch()
    }, [])

    const goFetch = async () => {
        try {
            setLoading(true)
            const result = await request({ url: window.urls.web + `/page`, headers: { 'webId': `${webId}` } })
            setPages(result.data)
        } catch (e) {
            toast.error(e)
        } finally {
            setLoading(false)
        }
    };
    const handleDelete = async (e, pageId) => {
        e.preventDefault()
        e.stopPropagation()
        try {
            if (!window.confirm('Are you sure to delete it?')) return null
            setLoading(true)
            const result = await request({ url: window.urls.web + `/page/${pageId}`, method: 'delete' })
            toast.success(result.message)
        } catch (e) {
            toast.error(e)
        } finally {
            setLoading(false);
            goFetch()
        }
    }

    return loading ? (
        <LoadingFallback />
    ) : (
        <Container>
            <MainPages setPopupInstance={setPopupInstance} pages={pages} handleDelete={handleDelete} slug={slug} webId={webId} />
            {popupInstance && <PagePopup type="page" refetch={goFetch} close={() => setPopupInstance(null)} instance={popupInstance} />}
        </Container>
    );
};

export default PagesList;

const Container = styled.div`
`;
