import React from 'react';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { dispatchChange, getStyleValue } from 'utils/styleHelpers';
import PropTypes from 'prop-types';
import InputNumber from './InputNumber';

const GenericNumber = ({ attributeName }) => {
    const [value, setValue] = React.useState({})
    const targetRef = React.useRef();
    const { selectedComponent, editComponent } =
        React.useContext(getBuilderContext);
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext);
    const doc = frame.document;

    React.useEffect(() => {
        targetRef.current = doc.querySelector(
            `[data-track="${selectedComponent.indices}"]`
        );
        setInitialValue();
    }, [selectedComponent]);

    React.useEffect(() => {
        setInitialValue();
    }, [frameUpdate]);

    const setInitialValue = () => {
        const styleValue = getStyleValue(selectedComponent, attributeName, doc)
        setValue(styleValue);
    }

    const forwardChange = (changingValue) => {
        dispatchChange(editComponent, selectedComponent, attributeName, `${changingValue}px`)
    }

    return (
        <InputNumber value={value} forwardChange={forwardChange} />
    );
};

export default GenericNumber;

GenericNumber.propTypes = {
    attributeName: PropTypes.string
}