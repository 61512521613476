import React from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import Button from 'elements/Button';
import styled from 'styled-components';
import Div from 'elements/Div';
import ToggleButton from 'components/ToggleButton';

const Style = ({ object, forwardKey, forwardChange }) => {
    const [code, setCode] = React.useState(object.value);
    const handleEnter = () => {
        forwardChange(forwardKey, code)
    }

    return (
        <Container>
            <div className='caption'>{forwardKey}</div>
            <Div c="body">
                <CodeEditor
                    value={code}
                    language="scss"
                    placeholder="Write your custom style"
                    onChange={e => setCode(e.target.value)}
                    padding={8}
                    style={{
                        fontSize: 12,
                        fontFamily: 'sans-serif',
                        backgroundColor: 'transparent',
                    }}
                />
                <Button onClick={handleEnter}>Submit</Button>
            </Div>
        </Container>
    );
};

export default Style;

const Container = styled.div`
width: 100%;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
& > .body {
    padding-bottom: 10px;
    .w-tc-editor-text {
        width: 100% !important;
        background: ${({ theme }) => theme.bg2} !important;
        border-radius: 4px !important;
        & > textarea{
            color: ${({ theme }) => theme.color} !important;
        }
    }
    & > button {
        padding:6px 12px;
        background:${({ theme }) => theme.bg2};
        color:${({ theme }) => theme.color2};
        border:1px solid ${({ theme }) => theme.sectionBorderColor};
        margin-top:10px;
        border-radius:4px;
    }
}
`;
