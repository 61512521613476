import FlexDivider from 'components/FlexDivider';
import { getReferenceContext } from 'contexts/reference';
import Div from 'elements/Div';
import React from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import request from 'utils/request';

const PublicTemplate = () => {
    const [localTemplates, setLocalTemplates] = React.useState([]);
    const {InsertingElement} = React.useContext(getReferenceContext)
    const { id: webId } = useParams();
    const navigate = useNavigate()
    const fetchData = async () => {
        const result = await request({ url: window.urls.web + `/template`, headers: { 'web-id': webId } });
        setLocalTemplates(result.data);
    };
    React.useEffect(() => {
        fetchData();
    }, []);
    const handleDrag = (e, composition) => {
        e.stopPropagation();
        e.dataTransfer.effectAllowed = 'copy'; //Operation instruction
        InsertingElement.current = composition; //Storing element to insert
    }
    return (
        <Container data-scrollable>
            <FlexDivider grid={2}>
                {localTemplates.map((template) => (
                    <Div onDragStart={(e)=>handleDrag(e, template.data)} draggable c="cardbox template" key={template.id}>
                        <Div c="screenshot"></Div>
                        <Div c="name">{template.name}</Div>
                        <Div c="actions">
                            <Div c="action" onClick={e=>navigate(`/${webId}?publicTemplate=${template.id}`)}><AiOutlineEdit/></Div>
                            <Div c="action delete"><AiOutlineDelete/></Div>
                        </Div>
                    </Div>
                ))}
            </FlexDivider>
        </Container>
    );
};

export default PublicTemplate;

const Container = styled.div`
    flex:1;
    overflow-y:scroll;
    & > div {
        & > .template {
            background: ${({ theme }) => theme.bg2};
            padding:8px;
            border-radius:4px;
            cursor:pointer;
            position:relative;
            .screenshot {
                height: 52px;
                border: 1px solid ${({ theme }) => theme.sectionBorderColor};
                background:white;
                margin-bottom:6px;
                border-radius:4px;
            }
            .name {
                text-align:center;
                color:${({theme})=>theme.color2};
            }
            & > .actions {
                position:absolute;
                right:12px;
                top:12px;
                align-items:center;
                gap:2px;
                display:none;
                & > .action {
                    color: black;
                    svg{
                        font-size:14px;
                        opacity:0.7;
                    }
                    &.delete {
                        color:rgba(255,0,0,1);
                    }
                }
            }
            &:hover {
                & > .actions {
                    display:flex;
                }
            }
        }
    }
`;
