import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getReferenceContext } from 'contexts/reference';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';
import toast from 'react-hot-toast';

const InitRightClick = () => {
    const { frame } = React.useContext(getCrossDomainContext)
    const { theme } = React.useContext(getCustomThemeContext)
    const { setTemplateInstance, setLocalComponentInstance, setSectionInstance } = React.useContext(getReferenceContext)
    const { cloneComponent, deleteComponent, getComponent, addComponent } = React.useContext(getBuilderContext)
    const doc = frame.document
    const selector = 'bl-context'
    const handleClone = (target) => cloneComponent(target.dataset.track)
    const handleDelete = (target) => deleteComponent(target.dataset.track)
    const handleSave = (target) => setTemplateInstance({ index: target.dataset.track, updateData: true })
    const handleSaveLocal = (target) => setLocalComponentInstance({ index: target.dataset.track, updateData: true })
    const handleSaveSection = (target) => setSectionInstance({ index: target.dataset.track, updateData: true })
    const pasteComponent = async (target) => {
        const json = await navigator.clipboard.readText()
        const component = JSON.parse(json)
        console.log(component, 'component')
        if(!component || !component.component) toast.error('Failed to paste')
        addComponent(component, target.dataset.track, 'a')
    }
    const contextMenus = [
        { name: 'Clone element', handler: handleClone },
        { name: 'Delete element', handler: handleDelete },
        { name: 'Save as component', handler: handleSave },
        { name: 'Save as local component', handler: handleSaveLocal },
        { name: 'Paste component', handler: pasteComponent }
    ]
    const contextGridMenu = [
        { name: 'Save section', handler: handleSaveSection },
    ]
    React.useEffect(() => {
        doc.addEventListener('contextmenu', (e) => {
            if (e.target.getAttribute('kind') === 'component') {
                drawContext(e);
                e.preventDefault();
            }
            if (e.target.getAttribute('kind') === 'grid') {
                drawContextGrid(e);
                e.preventDefault();
            }
        })
        doc.addEventListener('click', () => {
            doc.querySelector(`#${selector}`)?.remove()
            doc.querySelector(`#${selector}-style`)?.remove()
        })
    }, [])
    const drawContext = (e) => {
        //CLEANUP
        doc.querySelector(`#${selector}`)?.remove()
        doc.querySelector(`#${selector}-style`)?.remove()

        const mousePos = { x: e.clientX + frame.scrollX, y: e.clientY + frame.scrollY }
        const styleTag = doc.createElement('style')
        styleTag.setAttribute('id', `${selector}-style`)
        styleTag.innerText = `
            #${selector} {
                position:absolute;
                left: ${mousePos.x}px;
                top: ${mousePos.y}px;
                font-family:'Inter', sans-serif;
                pointer-events:auto;
                border-radius: 8px;
                padding: 4px;
                background: ${theme.bg};
                font-size: 12px;
                font-weight: 600;
                z-index: 10000;
            }
            #${selector} > div {
                padding: 8px 12px;
                color:${theme.color};
                pointer-events:auto;
                border-radius: 4px;
                background-color: transparent;
                transition: background-color 300ms linear;
            }
            #${selector} > div:hover {
                background-color:${theme.bg2};
                cursor:pointer;
            }
        `;

        const parentDiv = doc.createElement('div')
        parentDiv.setAttribute('id', `${selector}`)

        contextMenus.forEach((menu) => {
            const div = doc.createElement('div')
            div.innerText = menu.name;
            div.addEventListener('click', () => menu.handler(e.target))
            parentDiv.appendChild(div)
        })

        doc.body.appendChild(parentDiv);
        doc.head.appendChild(styleTag);
    }

    const drawContextGrid = (e) => {
        //CLEANUP
        doc.querySelector(`#${selector}`)?.remove()
        doc.querySelector(`#${selector}-style`)?.remove()

        const mousePos = { x: e.clientX + frame.scrollX, y: e.clientY + frame.scrollY }
        const styleTag = doc.createElement('style')
        styleTag.setAttribute('id', `${selector}-style`)
        styleTag.innerText = `
            #${selector} {
                position:absolute;
                left: ${mousePos.x}px;
                top: ${mousePos.y}px;
                font-family:'Inter', sans-serif;
                pointer-events:auto;
                border-radius: 8px;
                padding: 4px;
                background: ${theme.bg};
                font-size: 12px;
                font-weight: 600;
                z-index: 10000;
            }
            #${selector} > div {
                padding: 8px 12px;
                color:${theme.color};
                pointer-events:auto;
                border-radius: 4px;
                background-color: transparent;
                transition: background-color 300ms linear;
            }
            #${selector} > div:hover {
                background-color:${theme.bg2};
                cursor:pointer;
            }
        `;

        const parentDiv = doc.createElement('div')
        parentDiv.setAttribute('id', `${selector}`)

        contextGridMenu.forEach((menu) => {
            const div = doc.createElement('div')
            div.innerText = menu.name;
            div.addEventListener('click', () => menu.handler(e.target))
            parentDiv.appendChild(div)
        })

        doc.body.appendChild(parentDiv);
        doc.head.appendChild(styleTag);
    }
    return null
}

export default InitRightClick;
