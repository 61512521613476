import React from 'react';
import styled from 'styled-components';
import * as OptionComponents from '../index';

const Instance = ({ object: options, forwardKey, forwardChange, index }) => {
    const handleChange = (key, value, alternativeOption) => {
        forwardChange(key, value, index, alternativeOption)
    }
    return (
        <Container>
            {Object.keys(options).map((optionKey) => {
                if (options[optionKey].type === 'hidden') return null
                const OptionType = OptionComponents[options[optionKey].type]
                return (
                    <div className="option_instance" key={forwardKey + index + optionKey}>
                        <OptionType object={options[optionKey]} forwardKey={optionKey} forwardChange={handleChange} />
                    </div>
                )
            })}
        </Container>
    )
}

export default Instance;

const Container = styled.div`
.option_instance{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    & > div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }
}
`;