import ToggleButton from "components/ToggleButton";
import GenericColor from "modules/Styles/GenericColor";
import GenericSize from "modules/Styles/GenericSize";
import { MdOutlineLock, MdOutlineLockOpen } from "react-icons/md";
import React from "react";
import styled from "styled-components";

const BorderController = ({ value, forwardChange }) => {
    const [activate, setActivate] = React.useState(false)
    const [all, setAll] = React.useState(false)
    const [borderWidth, setBorderWidth] = React.useState({
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    })

    const handleActivate = (activate) => {
        if (activate) {
            forwardChange('default')
        } else {
            forwardChange('off')
        }
        setActivate(activate)
    }
    const handleInputChange = (e, field) => {
        const { value } = e.target
        if (all == true)
            setBorderWidth({
                top: value,
                right: value,
                bottom: value,
                left: value,
            })
        else
            setBorderWidth(prevData => ({
                ...prevData,
                [field]: value
            }))
    }
    const handleBlur = () => {
        setTimeout(() => {
            const combinedString = `${borderWidth.top}px ${borderWidth.right}px ${borderWidth.bottom}px ${borderWidth.left}px`
            forwardChange(combinedString)
        }, 150)
    }
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                const combinedString = `${borderWidth.top}px ${borderWidth.right}px ${borderWidth.bottom}px ${borderWidth.left}px`;
                forwardChange(combinedString)
            }, 150)
        }
    }
    const parseBorderProperties = (width) => {
        const widthString = String(width)
        const values = widthString.split(' ')
        const numericalValues = values.map(val => parseFloat(val)).filter(val => !isNaN(val))
        if (numericalValues.length == 4) {
            const areAllValuesEqual =
                numericalValues[0] === numericalValues[1] &&
                numericalValues[0] === numericalValues[2] &&
                numericalValues[0] === numericalValues[3]

            if (areAllValuesEqual)
                setAll(true)
            else
                setAll(false)

            setBorderWidth({ top: numericalValues[0], right: numericalValues[1], bottom: numericalValues[2], left: numericalValues[3] })
        } else if (numericalValues.length == 3) {
            setBorderWidth({ top: numericalValues[0], right: numericalValues[1], bottom: numericalValues[2], left: numericalValues[1] })
        } else if (numericalValues.length == 2) {
            setBorderWidth({ top: numericalValues[0], right: numericalValues[1], bottom: numericalValues[0], left: numericalValues[1] })
        } else {
            setAll(true)
            setBorderWidth({ top: numericalValues[0], right: numericalValues[0], bottom: numericalValues[0], left: numericalValues[0] })
        }
    }
    React.useEffect(() => {
        if (value != undefined && typeof value === 'string' && value !== 'none') {
            setActivate(true)
            parseBorderProperties(value)
        }
        else {
            setActivate(false)
        }
    }, [value])
    return (
        <Controller>
            <div className="border-controller">
                <div className="title" onClick={() => handleActivate(!activate)}>
                    <span>Borders</span>
                    <ToggleButton isToggled={activate} setIsToggled={setActivate} />
                </div>
                {activate && <div className='controller-wrapper'>
                    <div className='width-controller'>
                        <div className="mainBox">
                            <div className="icon" onClick={() => setAll(!all)}>
                                {all ? <MdOutlineLock /> : <MdOutlineLockOpen />}
                            </div>
                        </div>
                        <div className="absoluteBoxes top"><input type="text" value={borderWidth.top} onChange={(e) => handleInputChange(e, 'top')} onKeyDown={handleKeyDown} onBlur={handleBlur} /></div>
                        <div className="absoluteBoxes bottom"><input type="text" value={borderWidth.bottom} onChange={(e) => handleInputChange(e, 'bottom')} onKeyDown={handleKeyDown} onBlur={handleBlur} /></div>
                        <div className="absoluteBoxes left"><input type="text" value={borderWidth.left} onChange={(e) => handleInputChange(e, 'left')} onKeyDown={handleKeyDown} onBlur={handleBlur} /></div>
                        <div className="absoluteBoxes right"><input type="text" value={borderWidth.right} onChange={(e) => handleInputChange(e, 'right')} onKeyDown={handleKeyDown} onBlur={handleBlur} /></div>
                    </div>
                    <div className='style-wrap'>
                        <span>Color</span>
                        <GenericColor attributeName='borderColor' />
                    </div>
                    <div className='style-wrap'>
                        <span>Style</span>
                        <GenericSize attributeName='borderStyle' />
                    </div>
                </div>}
            </div>
        </Controller>
    )
}

export default BorderController

const Controller = styled.div`
.border-controller{
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 44px;
        span{
            font-weight: 500;
            color: ${({ theme }) => theme.color};
        }
        & > div{
            pointer-events: none;
        }
    }
    .controller-wrapper{
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
        .width-controller{
            width: fit-content;
            display: grid;
            place-items: center;
            gap: 5px;
            align-items: center;
            grid-template-columns: auto 1fr auto;
            & > .mainBox{
                grid-column-start: 2;
                grid-row-start: 2;
                .icon{
                    background: ${({ theme }) => theme.bg2};
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    & > svg{
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            & > .absoluteBoxes {
                & > input{
                    padding: 0;
                    text-align:center;
                    width: 30px;
                    height: 30px;
                    background: ${({ theme }) => theme.bg2};
                    border-radius: 4px;
                    border: none;
                    &:focus{
                        outline:none;
                    }
                }
                &.top{
                    grid-column-start: 2;
                    grid-row-start: 1;
                }
                &.right{
                    grid-column-start: 3;
                    grid-row-start: 2;
                }
                &.bottom{
                    grid-column-start: 2;
                    grid-row-start: 3;
                }
                &.left{
                    grid-column-start: 1;
                    grid-row-start: 2;
                }
            }
        }
        .style-wrap{
            width: 100%;
            display: flex;
            gap: 6px;
            align-items: center;
            flex-direction: column;
            span{
                color: ${({ theme }) => theme.color2};
                width: 100%;
                font-weight: 500;
                font-size: 9px;
                line-height: 16px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
            & > div{
                width: 100%;
            }
        }
    }
}
`