import React from 'react';
import IconPicker from 'siro-icon-library'
import styled from 'styled-components';
import Div from 'elements/Div';

const Text = ({ object, forwardKey, forwardChange }) => {
    const [value, setValue] = React.useState(object.value)
    const [isOpen, setIsOpen] = React.useState(false)
    const handleChange = (val) => {
        setValue(val)
        forwardChange(forwardKey, val)
    }
    return (
        <Container>
            <Div c="image-container">
                <div className='svg-wrapper' onClick={() => setIsOpen(true)} dangerouslySetInnerHTML={{ __html: value }}></div>
                <Div c="bottom">
                    <Div c='type'>Icon</Div>
                    <span onClick={() => setIsOpen(true)}>Replace</span>
                </Div>
            </Div>
            {isOpen ? <IconPicker setData={handleChange} onClose={() => setIsOpen(false)} /> : null}
        </Container>
    );
};

export default Text;

const Container = styled.div`
width: 100%;
padding: 10px 20px;
.image-container{
    border-radius: 4px;
    width: 100%;
    background: ${({ theme }) => theme.bg3};
    .svg-wrapper{
        width: 100%;
        height: 120px;
        svg{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .bottom{
        background: ${({ theme }) => theme.bg2};
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        border-radius: 0 0 4px 4px;
        .type{
            color: ${({ theme }) => theme.color2};
        }
        span{
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            color: ${({ theme }) => theme.color3};
            line-height: 24px;
        }
    }
}
`