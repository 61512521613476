import Button from 'elements/Button';
import React from 'react';
import styled from 'styled-components';
import StatePopup from './StatePopup';
import { getBuilderContext } from 'contexts/builder';
import Div from 'elements/Div';
import * as FormInputs from '../InputTypes';
import { populateState } from 'utils/vanillaFunctions';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { FiPlus } from "react-icons/fi";
import { Trash } from 'utils/svg';
import { recursiveCopy } from 'utils/recursive';
import { MdOutlineSync } from 'react-icons/md';

const StateList = () => {
    const [popupInstance, setPopupInstance] = React.useState(null);
    const { selectedComponent, editComponent, getComponent } = React.useContext(getBuilderContext);
    const { frame } = React.useContext(getCrossDomainContext)
    const node = frame.document.querySelector(`[data-track="${selectedComponent.indices}"]`)
    const [spreadStateStore, setSpreadStateStore] = React.useState({})
    const syncButton = React.useRef()
    React.useEffect(() => {
        revisionSpreadState()
    }, [selectedComponent, syncButton])
    const revisionSpreadState = () => {
        const spreadState = populateState(node)
        const revisionedStates = {}
        Object.keys(spreadState).forEach(stateKey => {
            const {type, value, hook} = spreadState[stateKey]
            const sourceNode = frame.document.querySelector(`[data-track][contenthook="${hook}"]`)
            const sourceIndices = sourceNode.getAttribute('data-track')
            if(!sourceIndices) return null
            const sourceComponent = getComponent(sourceIndices)
            const componentStates = sourceComponent.state ?? {}
            const foundState = Object.keys(componentStates).find(compStateKey => compStateKey === stateKey)
            if(foundState) {
                revisionedStates[stateKey] = spreadState[stateKey]
            }
        })
        setSpreadStateStore(revisionedStates)
    }
    const handleChange = (indices, stateKey, state) => {
        const stateSource = frame.document.querySelector(`[contenthook="${state.hook}"]`)
        if (!stateSource) return console.warn('Cannot mutate state. State source not found')
        const event = new CustomEvent('setInternalState', { detail: [{ stateName: stateKey, value: state.value }] })
        stateSource.dispatchEvent(event)
        setTimeout(() => {revisionSpreadState()}, 0);
    };
    const debouncedChange = (...args) => {
        debounce(() => handleChange(...args), 200)
    }
    const debounceTimer = React.useRef(null);

    // Debounce function
    const debounce = (func, delay) => {
        clearTimeout(debounceTimer.current);
        debounceTimer.current = setTimeout(func, delay);
    };

    const handleDelete = (indices, stateKey, hook) => {
        const stateSource = frame.document.querySelector(`[contenthook="${hook}"]`)
        stateSource.removeAttribute(`data-state-${stateKey}`)
        editComponent(stateSource.dataset.track, 'delete state', stateKey, null)
        setTimeout(() => {revisionSpreadState()}, 0);
    }

    const handleSelectOption = (selected, indices, stateKey, state) => {
        // const copiedState = recursiveCopy(state, true)
        // copiedState.value = selected;
        // handleChange(indices, stateKey, copiedState)
        const stateSource = frame.document.querySelector(`[contenthook="${state.hook}"]`)
        if (!stateSource) return console.warn('Cannot mutate state. State source not found')
        const event = new CustomEvent('setInternalState', { detail: [{ stateName: stateKey, value: selected }] })
        stateSource.dispatchEvent(event)
        setTimeout(() => {revisionSpreadState()}, 0);
    }
    return (
        <Container>
            <Div c='head-wrap'>
                <Div c="caption"><span>State settings </span><MdOutlineSync onClick={revisionSpreadState}/></Div>
                <Button c="add-state" onClick={() => setPopupInstance({})}>
                    <FiPlus />
                </Button>
            </Div>
            <Div c="states-wrap">
                {Object.keys(spreadStateStore).map((stateKey) => {
                    const state = spreadStateStore[stateKey];
                    const InputType = FormInputs[state.type];
                    if (!InputType) return null
                    const indices = selectedComponent.indices;
                    return (
                        <Div c="each" key={indices + stateKey}>
                            <Trash className='delete' onClick={() => handleDelete(indices, stateKey, state.hook)} />
                            <Div c="name">{stateKey}</Div>
                            <Div c="input">
                                {state.type == 'select' ?
                                    <InputType options={state.options} value={state.value} onChange={v => handleSelectOption(v, indices, stateKey, state)} />
                                    : state.type == 'object' ?
                                        <InputType object={state.object} value={state.value} onChange={v => console.log(v)} />
                                        :
                                        <InputType
                                            value={state.value}
                                            defaultValue={state.value}
                                            onChange={(v) => debouncedChange(indices, stateKey, { ...state, value: v })}
                                        />
                                }
                            </Div>
                        </Div>
                    );
                })}
            </Div>
            {popupInstance && <StatePopup instance={popupInstance} setInstance={setPopupInstance} />}
        </Container>
    );
};

export default StateList;

const Container = styled.div`
padding: 0 20px;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.head-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    .caption {
        font-weight: 500;
        text-transform: capitalize;
        color: ${({ theme }) => theme.color};
        font-size: 12px;
        line-height: 20px;
        display:flex;
        align-items:center;
        gap:4px;
        svg{
            font-size:1.2rem;
            cursor:pointer;
        }
    }
    .add-state {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        transition: all 0.3s linear;
        border-radius: 4px;
        svg{
            width: 16px;
            height: 16px;
        }
        &:hover{
            background: ${({ theme }) => theme.bg2};
            svg{
                path{
                    fill: ${({ theme }) => theme.color};
                }
            }
        }
    }
}
& > .states-wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
    & > .each {
        display:flex;
        align-items:center;
        justify-content:space-between;
        position:relative;
        margin: 0 -20px;
        padding: 0 20px;
        cursor: pointer;
        transition: all 0.5s ease;
        &:last-of-type{
            margin-bottom: 15px;
        }
        &:first-of-type{
            margin-top: 5px;
        }
        & > .delete {
            position: absolute;
            pointer-events: auto;
            left: -30px;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: left 0.3s ease;
            width: 18px;
            height: 18px;
        }
        &:hover, &[open]{
            & > .delete{
                left: 20px;
            }
            .name {
                margin-left:40px;
            }
        }
        .name {
            font-weight: 500;
            text-transform: uppercase;
            color: ${({ theme }) => theme.color2};
            font-size: 10px;
            line-height: 20px;
            transition:0.3s ease;
        }
        .input{
            width: 160px;
            & > div{
                width: 100%;
                display: flex;
                justify-content: end;
            }
            & > input{
                width: 100%;
            }
        }
    }
}
`;
