import { getBuilderContext } from 'contexts/builder';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { TbRowInsertBottom, TbArrowsMoveVertical } from 'react-icons/tb';
import { VscTrash, VscEdit, VscDatabase } from 'react-icons/vsc';
import { FaRegClone } from 'react-icons/fa';
import { getCrossDomainContext } from 'contexts/crossDomain';
import Button from 'elements/Button';
import { getReferenceContext } from 'contexts/reference';
import { recursiveEqual } from 'utils/recursive';

const History = () => {
    const { history = [] } = React.useContext(getBuilderContext);
    const { composition, setComposition } = React.useContext(getCrossDomainContext);
    const { selectedHistoryIndex, setSelectedHistoryIndex } = React.useContext(getReferenceContext);
    React.useEffect(() => {
        if (history.length && composition.length) {
            history.forEach((h, i) => {
                // const json = JSON.stringify(h.data);
                // const comp = JSON.stringify(composition);
                // if (json === comp) setSelectedHistoryIndex(i);
                if (recursiveEqual(h.data, composition)) setSelectedHistoryIndex(i);
            });
        }
    }, [history, composition]);
    const selectHistoryInstance = (data) => {
        setComposition([...data]);
    };
    return (
        <Container>
            <Div c="history-wrap">
                {history.map((instance, i) => {
                    let Icon;
                    if (instance.type === 'add') Icon = TbRowInsertBottom;
                    if (instance.type === 'delete') Icon = VscTrash;
                    if (instance.type === 'move') Icon = TbArrowsMoveVertical;
                    if (instance.type === 'clone') Icon = FaRegClone;
                    if (instance.type === 'edit') Icon = VscEdit;
                    if (instance.type === 'initial') Icon = VscDatabase;
                    return (
                        <Button onClick={() => selectHistoryInstance(instance.data)} key={i} c="instance" open={i === selectedHistoryIndex}>
                            <Icon />
                            <Div c="title">{instance.title}</Div>
                        </Button>
                    );
                })}
            </Div>
        </Container>
    );
};

export default History;

const Container = styled.div`
    padding: 15px;
    & > .history-wrap {
        & > .instance {
            width: 100%;
            border-radius: unset;
            padding: 8px;
            gap: 6px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
            color: ${({ theme }) => theme.color2};
            &:first-child {
                border-top: 1px solid ${({ theme }) => theme.sectionBorderColor};
            }
            &:hover {
                cursor: pointer;
                background: ${({ theme }) => theme.bg2};
                transition: 0.3s ease;
            }
            &[open] {
                background: ${({ theme }) => theme.mainColor};
                color: white;
            }
        }
    }
`;
