import React from "react";
import request from 'utils/request';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import Div from "elements/Div";
import { IoIosArrowDown } from "react-icons/io";
import { toast } from 'react-hot-toast';
import { Close, Help, Trash } from "utils/svg";
import Tooltip from "components/Tooltip";

const Types = ({ instance, setInstance }) => {
    const [types, setTypes] = React.useState(null)
    const [newType, setNewType] = React.useState(null)
    const [loading, setLoading] = React.useState(null)
    const [active, setActive] = React.useState(false)
    const { id: webId } = useParams();

    React.useEffect(() => {
        fetchTypes()
    }, [])

    const fetchTypes = async () => {
        try {
            setLoading(true)
            const result = await request({ url: window.urls.web + `/template_type`, headers: { webId } })
            setTypes(result.data)
        } catch (e) {
            console.log(e, 'err template type')
        } finally { setLoading(false) }
    }

    const handleType = (val) => {
        setInstance(val)
        setActive(false)
    }

    const handleSubmit = async (e) => {
        e?.preventDefault();
        try {
            setLoading(true);
            const result = await request({
                url: newType.id ? `${window.urls.web}/template_type/${newType.id}` : `${window.urls.web}/template_type`,
                body: { name: newType.name },
                method: newType.id ? 'put' : 'post',
                headers: { webId }
            });
            toast.success(result.message);
        } catch (e) {
            toast.error(e.message || 'An error occurred');
            setNewType(null)
            setActive(false)
        } finally {
            close();
            setLoading(false);
            fetchTypes();
            setActive(false)
            setNewType(null)
        }
    };
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                handleSubmit()
            }, 150);
        }

    };
    const deleteType = async (id) => {
        try {
            if (!window.confirm('You sure you want to delete type?')) return null
            setLoading(true)
            await request({ url: window.urls.web + `/template_type/${id}`, method: 'delete' });
        } catch (e) {
            console.log(e, 'err delete template type')
        } finally {
            setLoading(false)
            fetchTypes()
        }
    };

    return (
        <Container>
            {loading ? "loading" : <Div c='wrapper'>
                <Div c="caption">Component type</Div>
                <Div c="dropdown-wrap">
                    <Div c={`dropdown-head ${active && 'active'}`} onClick={() => setActive(!active)}>
                        <span>{instance?.name ? instance.name : 'Choose one'}</span>
                        {instance?.name && (
                            <Div c="close" onClick={() => handleType(null)}><Close /></Div>
                        )}
                        <Div c="arrow"><IoIosArrowDown /></Div>
                    </Div>
                    {active && (
                        <Div c="dropdown">
                            <Div c="add"><input type='text' value={newType?.name} onChange={(e) => setNewType({ ...newType, name: e.target.value })} placeholder="Add type..." onKeyDown={handleKeyDown} /></Div>
                            {types?.map((val, i) => (
                                <Div c="item" key={i} onClick={() => handleType(val)}>{val.name} <Trash onClick={(e) => { e.stopPropagation(); deleteType(val.id) }} /></Div>
                            ))}
                        </Div>
                    )}
                </Div>
            </Div>}
        </Container>
    )
}

export default Types

const Container = styled.div`
width: 100%;
.wrapper{
    width: 100%;
    .caption{
        font-weight: 600;
        text-transform: uppercase;
        color: ${({ theme }) => theme.color2};
        font-size: 10px;
        line-height: 20px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .dropdown-wrap{
        width: 100%;
        position: relative;
        .dropdown-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: ${({ theme }) => theme.bg2};
            border-radius: 4px;
            padding: 8px 12px;
            cursor: pointer;
            position: relative;
            span{
                font-size: ${({ theme }) => theme.fs};
                color: ${({ theme }) => theme.color};
                font-weight:500;
            }
            input{
                font-size: ${({ theme }) => theme.fs};
                color: ${({ theme }) => theme.color};
                font-weight:500;
                background: transparent;
                width: 90%;
                border: none;
                &::placeholder{
                    font-size: 10px;
                    color: ${({ theme }) => theme.color2};
                    font-weight:400;
                }
                &:focus{
                    outline: none;
                }
            }
            .arrow{
                background: ${({ theme }) => theme.bg2};
                transition: all 0.3s linear;
                z-index: 0;
                display: flex;
                align-items: center;
                svg{
                    width: 14px;
                    height: 14px;
                }
            }
            .close{
                position: absolute;
                right: 14px;
                transition: right 0.3s ease;
                display: flex;
                align-items: center;
                bottom: 0;
                top: 0;
                svg{
                    width: 14px;
                    height: 14px;
                    path{
                        fill: ${({ theme }) => theme.color2};
                    }
                }
            }
            &:hover{
                .close{
                    right: 28px;
                }
            }
            &.active{
                .arrow{
                    svg{
                        rotate: 180deg;
                    }
                }
            }
        }
        .dropdown{
            top: 120%;
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            top: 120%;
            border-radius: 4px;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
            min-width: 100px;
            background: ${({ theme }) => theme.bg2};
            max-height: 160px;
            overflow-y: auto;
            .item{
                padding: 10px 15px;
                font-size: ${({ theme }) => theme.fs};
                font-weight: 600;
                color: ${({ theme }) => theme.color};
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                svg{
                    width: 14px;
                    height: 14px;
                    z-index: 1;
                }
                &:hover {
                    background: ${({ theme }) => theme.lightMainColor};
                    color: ${({ theme }) => theme.mainColor};
                    cursor: pointer;
                }
            }
            .add{
                padding: 0 15px;
                font-size: ${({ theme }) => theme.fs};
                font-weight: 600;
                color: ${({ theme }) => theme.color};
                border-radius: 4px;
                input{
                    padding: 10px 0;
                    font-size: ${({ theme }) => theme.fs};
                    color: ${({ theme }) => theme.color};
                    font-weight:500;
                    background: transparent;
                    width: 100%;
                    border: none;
                    &::placeholder{
                        font-size: ${({ theme }) => theme.fs};
                        color: ${({ theme }) => theme.color};
                        font-weight:500;
                    }
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
    }
}
`