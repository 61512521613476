import { getCrossDomainContext } from 'contexts/crossDomain';
import React from 'react';
import DisablePointerEvents from './DisablePointerEvents';
import Indicator from './Indicator';
import InitDragDrop from './InitDragDrop';
import HandleEmptyRoot from './HandlyEmptyRoot';
import HighlightElement from './HighlightElement';
import HandleEmptyAppendables from './HandleEmptyAppendables';
import SelectElement from './SelectElement';
import HighlightAppendables from './HighlightAppendables';
// import InitCopyPasteDelete from './InitCopyPasteDelete';
import InitRichtext from './InitRichtext';
import DrawParallelComponents from './drawParallelComponents';
import InitRightClick from './InitRightClick';
import HandleLoadFonts from './HandleLoadFonts';
import InitListenContentClass from './InitListenContentClass';
import InitWatchRepeatable from './InitWatchRepeatable';
import InitRightClickParent from './InitRightClickParent';
import HandleGrid from './HandleGrid';
import InitClickEvent from './initClickEvent';
import HandleSectionMenu from './HandleSectionMenu';
import HandleGridBlock from './HandleGridBlock';
import HandleResizeComponent from './HandleResizeComponent';
import InitKeyDowns from './InitKeyDowns';

const InitGadgets = () => {
    const { frame } = React.useContext(getCrossDomainContext);
    const [initialized, setInitialized] = React.useState(false);
    React.useEffect(() => {
        if (frame) if (!initialized) initialize();
    }, [frame]);
    const initialize = () => {
        setInitialized(true);
    };

    return (
        initialized && (
            <React.Fragment>
                <HandleGrid/>
                <HandleGridBlock/>
                <InitClickEvent/>
                <InitKeyDowns/>
                <HandleSectionMenu/>
                <HandleResizeComponent/>
                <DrawParallelComponents/>

                <HandleLoadFonts/>
                <DisablePointerEvents />
                <HighlightElement />
                <HighlightAppendables/>
                <Indicator />
                <InitDragDrop />
                <HandleEmptyRoot />
                <HandleEmptyAppendables />
                <SelectElement/>
                {/* <InitCopyPasteDelete/> */}
                <InitRichtext/>
                <InitRightClick/>
                <InitRightClickParent/>
                <InitListenContentClass/>
                <InitWatchRepeatable/>
                
            </React.Fragment>
        )
    );
};

export default InitGadgets;
