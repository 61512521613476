import React from 'react';
import Library from 'siro_lib';
import DragBox from '../../components/DragBox';
import { reduceStructure } from '../../helpers';
import FlexDivider from 'components/FlexDivider';
const Layout = Library.Layout;
const Basics = Library.Basics

const Layouts = () => {
    const cloned = {...Layout}
    const instanceClone = {Repeatinstance: {...cloned.Repeatinstance}}
    const boxClone = {Box: {...Basics.Box}}
    delete cloned.Repeatinstance
    const elements = reduceStructure(cloned, 'Layout');
    const instance = reduceStructure(instanceClone, 'Layout')[0]
    const box = reduceStructure(boxClone, 'Basics')[0]
    return (
        <FlexDivider grid={3}>
            {elements.map((element) => {
                if(element.component === 'Repeatgrid') {
                    const arr = ['']
                    element.children = []
                    arr.forEach(x => element.children.push(JSON.parse(JSON.stringify(instance))))
                } else if (element.component === 'Carousel') {
                    const emptyBox = JSON.parse(JSON.stringify(box))
                    element.children = [emptyBox]
                }
                return (
                    <DragBox component={element} key={Math.random()} />
                )
            })}
        </FlexDivider>
    );
};

export default React.memo(Layouts);
