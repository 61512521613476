import React, { createContext } from 'react';
import { isEqual, makeTrackable } from './miscs';
import PropTypes, { array } from 'prop-types';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { recursiveCopy } from 'utils/recursive';

export const getBuilderContext = createContext();
export default class BuilderContextProvider extends React.Component {
    static contextType = getCrossDomainContext;
    state = {
        selectedComponent: null,
        selectedParallels: [],
        elements: [],
        composition: this.context.composition,
        history: [],
        contentclass: null,
        repeatable: null
    };

    neighborLayers = (selected) => {
        const stackLayers = (name, indices, children, depth = 0, maxDepth = 1) => {
            if (depth > maxDepth) {
                return null
            }
            let split_indices = indices.split('-')
            let layer = { name: name, indices: indices }
            if (children) {
                let childLayers = []
                children.map((child, i) => {
                    const copiedSplitIndices = [...split_indices, i]
                    let track = copiedSplitIndices.join('-')
                    let nestedChildren = child.children ? stackLayers(child.name, track, child.children, depth + 1, maxDepth) : null
                    childLayers.push({
                        name: child.name,
                        indices: track,
                        children: nestedChildren
                    })
                })
                layer = { ...layer, children: childLayers }

            }
            return layer
        }

        let split_indices = selected.indices.split('-')
        if (split_indices.length == 2) {
            split_indices.pop()
        } else if (split_indices.length > 2) {
            split_indices.pop()
            // split_indices.pop()
        }
        let indices = split_indices.join('-')
        const copiedElement = []
        copiedElement.push(this.getComponent(indices))

        let layers = []
        copiedElement.map(instance => {
            layers = stackLayers(instance.name, instance.indices, instance.children)
        })

        return layers
    }

    findLayers = (selected, indices) => {
        let split_indices = indices.split('-')
        split_indices.pop()

        let layers = []

        // upperlayers
        let i = 0
        while (split_indices.length > 0 && i < 3) {
            const copiedIndices = [...split_indices]
            const join = split_indices.join('-')
            this.extractComponentInfo(this.context.composition, copiedIndices, ({ trackReference }) => {
                const layer = Object.getComponentByReference(this.context.composition, trackReference)
                layers.unshift({ name: layer.name, indice: join })
            })
            split_indices.pop()
            i++
        }

        // selected layer
        layers.push({ name: selected.name, indice: indices })

        // childLayers
        if (selected.children) {
            let children = [...selected.children];
            if (children.length > 3)
                children = children.slice(0, 3);

            for (let i = 0; i < children.length; i++) {
                let split_indices = indices.split('-')
                let copiedIndices = [...split_indices]
                copiedIndices.push(`${i}`)
                const join = copiedIndices.join('-')
                layers.push({ name: children[i].name, indice: join });
            }
        }

        return layers
    }

    selectComponent = (indices) => {
        if (!indices) return this.setState({ selectedComponent: null });
        let targetComponent;
        this.extractComponentInfo(this.context.composition, indices.split('-'), ({ trackReference }) => {
            targetComponent = Object.getComponentByReference(this.context.composition, trackReference);
        });
        this.setState({ selectedComponent: { ...targetComponent, indices } });
    }

    getComponent = (indices) => {
        if (!indices) return null;
        let targetComponent;
        this.extractComponentInfo(this.context.composition, indices.split('-'), ({ trackReference }) => {
            targetComponent = Object.getComponentByReference(this.context.composition, trackReference);
        });
        return { ...targetComponent, indices };
    };
    extractComponentInfo = (givenComponentList, indices, makeTransfer, round, selectorArray) => {
        const thisIndex = indices[0];
        if (typeof round === 'undefined') round = -1;
        round++;

        if (thisIndex) {
            indices.shift();

            if (selectorArray) selectorArray.push(`.children[${thisIndex}]`);
            else selectorArray = [`[${thisIndex}]`];

            this.extractComponentInfo(givenComponentList, indices, makeTransfer, round, selectorArray);
        } else {
            makeTransfer({ trackReference: selectorArray.join('') });
        }
    };
    handleMoveComponent = (clonedCompositionData, sourceIndex, targetIndex, lastPointer) => {
        let storedComponent;
        const trackableClone = makeTrackable(clonedCompositionData, true);
        const removedClone = this.componentRemoveReducer(trackableClone, sourceIndex, (arg) => {
            storedComponent = { ...arg };
        });
        const insertedClone = this.componentInsertReducer(removedClone, targetIndex, storedComponent, lastPointer);
        // this.setState({ composition: insertedClone })

        // ADD HISTORY LINE
        const existingHistory = [...this.state.history];
        existingHistory.push({ title: `Moved ${storedComponent.name}`, data: insertedClone, type: 'move', id: Date.now() });
        this.setState({ history: existingHistory });

        this.context.setComposition(insertedClone);
    };
    handleCloneComponent = (clonedCompositionData, targetIndex, lastPointer = 'd') => {
        let storedComponent;
        const copiedTargetIndex = [...targetIndex]; //Copied index for InsertReducer but I dont know why targetIndex parameter is being disappeared down there
        this.extractComponentInfo(this.context.composition, targetIndex, ({ trackReference }) => {
            storedComponent = Object.getComponentByReference(this.context.composition, trackReference);
        });
        const trackableClone = makeTrackable(clonedCompositionData, true);
        const insertedClone = this.componentInsertReducer(trackableClone, copiedTargetIndex, storedComponent, lastPointer);
        // this.setState({ composition: insertedClone })

        // ADD HISTORY LINE
        const existingHistory = [...this.state.history];
        existingHistory.push({ title: `Cloned ${storedComponent.name}`, data: insertedClone, type: 'clone', id: Date.now() });
        this.setState({ history: existingHistory });

        this.context.setComposition(insertedClone);
    };
    componentRemoveReducer = (array, sourceIndex, skipAndStore) => {
        return array.reduce((acc, item) => {
            const newItem = item;
            if (item.children) newItem.children = this.componentRemoveReducer(item.children, sourceIndex, skipAndStore);
            if (!isEqual(newItem.track, sourceIndex)) acc.push({ ...newItem });
            else skipAndStore(newItem);
            return acc;
        }, []);
    };
    componentInsertReducer = (array, targetIndex, instanceCopy, lastPointer) => {
        return array.reduce((acc, item) => {
            const newItem = item;
            if (item.children) newItem.children = this.componentInsertReducer(item.children, targetIndex, instanceCopy, lastPointer);
            if (isEqual(newItem.track, targetIndex)) {
                if (lastPointer === 'm') acc.push({ ...newItem, track: null, children: [{ ...instanceCopy }] });
                else if (lastPointer === 'a') {
                    if (newItem.children) {
                        acc.push({ ...newItem, track: null, children: [...newItem.children, { ...instanceCopy }] })
                        setTimeout(() => {
                            // To get this operation out of the current cycle end
                            // Used timeout to sit this function back
                            this.selectComponent(newItem.track.join('-') + '-' + newItem.children.length)
                        }, 0);
                    } else {
                        acc.push({ ...newItem, track: null, children: [{ ...instanceCopy }] })
                        setTimeout(() => {
                            // To get this operation out of the current cycle end
                            // Used timeout to sit this function back
                            this.selectComponent(newItem.track.join('-') + '-' + '0')
                        }, 0);
                    }
                }
                else {
                    if (lastPointer === 'u') acc.push({ ...instanceCopy, track: null });
                    acc.push({ ...newItem, track: null });
                    if (lastPointer === 'd') acc.push({ ...instanceCopy, track: null });
                }
            } else acc.push({ ...newItem, track: null });
            return acc;
        }, []);
    };
    componentReplaceReducer = (array, targetIndex, instanceCopy) => {
        return array.reduce((acc, item) => {
            const newItem = item;
            if (item.children) newItem.children = this.componentReplaceReducer(item.children, targetIndex, instanceCopy);
            if (isEqual(newItem.track, targetIndex)) {
                acc.push({ ...instanceCopy, track: null })
            } else acc.push({ ...newItem, track: null });
            return acc;
        }, []);
    };
    componentEditReducer = (array, targetIndex, instanceCopy, transferCb) => {
        return array.reduce((acc, item) => {
            const newItem = item;
            if (item.children) newItem.children = this.componentEditReducer(item.children, targetIndex, instanceCopy, transferCb);
            if (isEqual(newItem.track, targetIndex)) {
                const spreadComponent = { ...newItem, name: instanceCopy.name, style: instanceCopy.style, scroll: instanceCopy.scroll, options: instanceCopy.options, track: null };
                if (instanceCopy.state) spreadComponent.state = { ...instanceCopy.state }
                if (instanceCopy.events) spreadComponent.events = { ...instanceCopy.events }
                if (instanceCopy.cases) spreadComponent.cases = { ...instanceCopy.cases }
                if (instanceCopy.propBind) spreadComponent.propBind = { ...instanceCopy.propBind }
                if (instanceCopy.stateBind) spreadComponent.stateBind = { ...instanceCopy.stateBind }
                // this.setState({ selectedComponent: { ...spreadComponent, indices: targetIndex.join('-') } })
                transferCb && transferCb({ ...spreadComponent, indices: targetIndex.join('-') });
                acc.push(spreadComponent);
            } else acc.push({ ...newItem, track: null });
            return acc;
        }, []);
    };
    componentEditMutationReducer = (target, mutationArg, fieldName, value) => {
        const targetComponent = { ...this.getComponent(target) };
        const multiType = Array.isArray(mutationArg);
        if (multiType) {
            mutationArg.forEach((mutationObject) => {
                const extractedObject = { ...targetComponent[mutationObject.type] };
                // const copiedObject = JSON.parse(JSON.stringify(extractedObject)); //CAREFUL ITS BAD UNFREEZE METHOD FOR NOW
                const copiedObject = recursiveCopy(extractedObject, true);
                mutationObject.changes.forEach((change) => {
                    if (mutationObject.type === 'options') {
                        copiedObject[change.key].value = change.value;
                    } else if (mutationObject.type === 'style') {
                        let redirectMap = false
                        if (this.state.contentclass) {
                            const [indices] = this.state.contentclass.split('||')
                            if (indices === targetComponent.indices) redirectMap = true
                        }
                        if (redirectMap) {
                            const [, className] = this.state.contentclass.split('||')
                            copiedObject.class[className][this.context.device][change.key] = change.value
                        } else {
                            copiedObject[this.context.device][change.key] = change.value;
                        }
                    }
                });
                targetComponent[mutationObject.type] = copiedObject;
            });
        } else {
            const extractedObject = { ...targetComponent[mutationArg] };
            const copiedObject = recursiveCopy(extractedObject, true);
            if (mutationArg === 'style') {
                let redirectMap = false
                if (this.state.contentclass) {
                    const [indices] = this.state.contentclass.split('||')
                    if (indices === targetComponent.indices) redirectMap = true
                }
                if (redirectMap) {
                    const [, className] = this.state.contentclass.split('||')
                    copiedObject.class[className][this.context.device][fieldName] = value;
                } else {
                    copiedObject[this.context.device][fieldName] = value;
                }
                targetComponent[mutationArg] = copiedObject;
            } else if (mutationArg === 'name') {
                targetComponent.name = value
            } else if (mutationArg === 'locked') {
                targetComponent.locked = value
            } else if (mutationArg === 'state') {
                copiedObject[fieldName] = value
                targetComponent[mutationArg] = copiedObject
            } else if (mutationArg === 'delete state') {
                const copiedState = { ...targetComponent.state }
                delete copiedState[fieldName]
                targetComponent.state = copiedState
            }
            else if (mutationArg === "events") {
                copiedObject[fieldName] = value
                targetComponent[mutationArg] = copiedObject
            }
            else if (mutationArg === "delete event") {
                const copiedEvents = { ...targetComponent.events }
                delete copiedEvents[fieldName]
                targetComponent.events = copiedEvents
            } else if (mutationArg === "cases") {
                copiedObject[fieldName] = value
                targetComponent[mutationArg] = copiedObject
            } else if (mutationArg === "delete case") {
                const copiedCases = { ...targetComponent.cases }
                delete copiedCases[fieldName]
                targetComponent.cases = copiedCases
            } else if (mutationArg === "prop binding") {
                const copiedPropBind = { ...targetComponent.propBind }
                const [type, field] = fieldName.split('.')
                if (!copiedPropBind[type]) copiedPropBind[type] = {}
                copiedPropBind[type][field] = value
                targetComponent.propBind = copiedPropBind
            } else if (mutationArg === "state binding") {
                const copiedPropBind = { ...targetComponent.stateBind }
                const [type, field] = fieldName.split('.')
                if (!copiedPropBind[type]) copiedPropBind[type] = {}
                copiedPropBind[type][field] = value
                targetComponent.stateBind = copiedPropBind
            }
            else if (mutationArg === "addClass") {
                if (!targetComponent.style.class) targetComponent.style.class = {}
                targetComponent.style.class[fieldName] = {
                    desktop: {},
                    tablet: {},
                    mobile: {}
                }
            }
            else if (mutationArg === "add option sync") {
                const extractedObject = { ...targetComponent.options }
                const copiedObject = recursiveCopy(extractedObject, true);
                if (!copiedObject[fieldName].sync) copiedObject[fieldName].sync = {}
                copiedObject[fieldName].sync = value;
                targetComponent.options = copiedObject;
            }
            else if (mutationArg === "remove option sync") {
                const extractedObject = { ...targetComponent.options }
                const copiedObject = recursiveCopy(extractedObject, true);
                delete copiedObject[fieldName].sync
                targetComponent.options = copiedObject;
            }
            else if (mutationArg === 'activate option') {
                const extractedObject = { ...targetComponent.options }
                const copiedObject = recursiveCopy(extractedObject, true);
                copiedObject[fieldName].active = value
                targetComponent.options = copiedObject
            }
            else if (mutationArg === 'scroll') {
                const extractedObject = { ...targetComponent.scroll }
                const copiedObject = recursiveCopy(extractedObject, true);
                copiedObject[fieldName] = value
                targetComponent.scroll = copiedObject
            }
            else if (mutationArg === 'add scroll animation') {
                const extractedObject = { ...targetComponent.scroll }
                const copiedObject = recursiveCopy(extractedObject, true);
                if (!copiedObject.animations) copiedObject.animations = {}
                copiedObject.animations[fieldName] = value
                targetComponent.scroll = copiedObject
            }
            else if (mutationArg === 'remove scroll animation') {
                const extractedObject = { ...targetComponent.scroll }
                const copiedObject = recursiveCopy(extractedObject, true);
                if (!copiedObject.animations) copiedObject.animations = {}
                delete copiedObject.animations[fieldName]
                targetComponent.scroll = copiedObject
            }
            else {
                if (copiedObject[fieldName]['translations']) {
                    copiedObject[fieldName].value = value;
                    const translations = copiedObject[fieldName]['translations']
                    translations[this.context.language] = value
                } else {
                    copiedObject[fieldName].value = value;
                }
                targetComponent[mutationArg] = copiedObject;
            }
            // if (mutationArg === 'options') copiedObject[fieldName].value = value;
            // else if (mutationArg === 'style') copiedObject[this.context.device][fieldName] = value;
        }
        return targetComponent;
    };
    componentDeleteReducer = (array, targetIndex) => {
        return array.reduce((acc, item) => {
            const newItem = item;
            if (item.children) newItem.children = this.componentDeleteReducer(item.children, targetIndex);
            if (!isEqual(newItem.track, targetIndex)) acc.push({ ...newItem, track: null });
            return acc;
        }, []);
    };
    cloneComponent = (target) => {
        const clonedCompositionData = [...this.context.composition];
        const targetIndex = target.split('-');
        this.handleCloneComponent(clonedCompositionData, targetIndex);
    };
    moveComponent = (source, target, lastPointer) => {
        this.setState({ selectedComponent: null });
        const clonedCompositionData = [...this.context.composition];
        const sourceIndex = source.split('-');
        const targetIndex = target.split('-');
        if (!isEqual(sourceIndex, targetIndex)) this.handleMoveComponent(clonedCompositionData, sourceIndex, targetIndex, lastPointer);
    };
    replaceComponent = (element, target) => {
        const targetIndex = target.split('-');
        const trackableClone = makeTrackable([...this.context.composition], true);
        const replacedClone = this.componentReplaceReducer(trackableClone, targetIndex, element);
        // this.setState({ composition: replacedClone })

        // ADD HISTORY LINE
        // const existingHistory = [...this.state.history];
        // existingHistory.push({ title: `Inserted ${element.name}`, data: replacedClone, type: 'add' });
        // this.setState({ history: existingHistory });

        this.context.setComposition(replacedClone);
    };
    addComponent = (element, target, lastPointer) => {
        this.setState({ selectedComponent: null });
        const targetIndex = target.split('-');
        const trackableClone = makeTrackable([...this.context.composition], true);
        const insertedClone = this.componentInsertReducer(trackableClone, targetIndex, element, lastPointer);
        // this.setState({ composition: insertedClone })

        // ADD HISTORY LINE
        const existingHistory = [...this.state.history];
        existingHistory.push({ title: `Inserted ${element.name}`, data: insertedClone, type: 'add', id: Date.now() });
        this.setState({ history: existingHistory });

        this.context.setComposition(insertedClone);
    };
    editComponent = (target, mutationArg, fieldName, value, ignoreKeepSelect) => {
        const clonedParallels = [...this.state.selectedParallels];
        const mutatedComponent = this.componentEditMutationReducer(target, mutationArg, fieldName, value);
        const targetIndex = target.split('-');
        const trackableClone = makeTrackable([...this.context.composition], true);
        let keepSelected;
        let editedClone = this.componentEditReducer(trackableClone, targetIndex, mutatedComponent, (ref) => (keepSelected = ref));

        clonedParallels.forEach((indices) => {
            const mutatedParallel = this.componentEditMutationReducer(indices, mutationArg, fieldName, value);
            const trackableParallelClone = makeTrackable([...editedClone], true);
            const processedClone = this.componentEditReducer(trackableParallelClone, indices.split('-'), mutatedParallel);
            editedClone = processedClone;
        });

        // ADD HISTORY LINE
        const existingHistory = [...this.state.history];
        let historyTitle;
        if (Array.isArray(mutationArg)) {
            let operations = '';
            mutationArg.forEach((mutation) => {
                let values = '';
                mutation.changes.forEach((change) => (values = values + change.key + ', '));
                values = values.slice(0, values.length - 2);
                operations = operations + `${mutation.type} ${values} and `;
            });
            operations = operations.slice(0, operations.length - 5);
            historyTitle = `Edited ` + operations;
        } else historyTitle = `Edited ${mutationArg} ${fieldName}`;
        existingHistory.push({ title: historyTitle, data: editedClone, type: 'edit', id: Date.now() });
        //Keep in mind here, the ignoreKeepSelect argument is used only for action state change
        //to not to select its source node
        if (ignoreKeepSelect) this.setState({ history: existingHistory });
        else this.setState({ selectedComponent: keepSelected, history: existingHistory });
        this.context.setComposition(editedClone);
    };
    deleteComponent = (target) => {
        this.setState({ selectedComponent: null });
        const targetIndex = target.split('-');
        const trackableClone = makeTrackable([...this.context.composition], true);
        const deletedClone = this.componentDeleteReducer(trackableClone, targetIndex);
        // this.setState({ composition: deletedClone })

        // ADD HISTORY LINE
        const existingHistory = [...this.state.history];
        existingHistory.push({ title: `Deleted an element`, data: deletedClone, type: 'delete', id: Date.now() });
        this.setState({ history: existingHistory });

        this.context.setComposition(deletedClone);
    };
    mutateParallelIndex = (index) => {
        if (this.state.selectedComponent.indices === index) return null;
        const clonedParallel = [...this.state.selectedParallels];
        const alreadyExisted = clonedParallel.find((p) => p === index);
        if (alreadyExisted) {
            const i = clonedParallel.indexOf(index);
            clonedParallel.splice(i, 1);
        } else {
            clonedParallel.push(index);
        }
        this.setState({ selectedParallels: clonedParallel });
    };
    emptyParallelIndex = () => {
        this.setState({ selectedParallels: [] });
    };

    handleSetState = (arg) => this.setState(arg);

    componentDidMount() {
        this.setState({ history: [{ title: 'Current', type: 'initial', data: [...this.context.composition], id: Date.now() }] })
    }

    async saveTemplate(templateComposition) {
        // eslint-disable-next-line no-async-promise-executor
        console.log('saveTemplate try');
    }
    render() {
        return (
            <getBuilderContext.Provider
                value={{
                    ...this.state,
                    setContext: this.handleSetState,
                    cloneComponent: this.cloneComponent,
                    moveComponent: this.moveComponent,
                    addComponent: this.addComponent,
                    selectComponent: this.selectComponent,
                    getComponent: this.getComponent,
                    editComponent: this.editComponent,
                    deleteComponent: this.deleteComponent,
                    saveTemplate: this.saveTemplate,
                    mutateParallelIndex: this.mutateParallelIndex,
                    emptyParallelIndex: this.emptyParallelIndex,
                    replaceComponent: this.replaceComponent,
                    findLayers: this.findLayers,
                    neighborLayers: this.neighborLayers,
                }}
            >
                {this.props.children}
            </getBuilderContext.Provider>
        );
    }
}

BuilderContextProvider.propTypes = {
    children: PropTypes.any,
};

Object.getComponentByReference = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1');
    s = s.replace(/^\./, '');
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (!o) return;
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};
