import React from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { Close } from "utils/svg";
import request from 'utils/request';
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import Div from 'elements/Div';
import ToggleButton from 'components/ToggleButton';
import { getBuilderContext } from 'contexts/builder';
import { recursiveCopy } from 'utils/recursive';

const collectionDto = {
    webId: null,
    ctId: null,
    name: null,
    categoryId: null,
}

const useCollection = (object, selectedType) => {
    const { id } = useParams();
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext)
    const sourceDetail = object.sourceDetail.value ?? collectionDto
    const [categoryValue, setCategoryValue] = React.useState(null)
    const [categoryToggle, setCategoryToggle] = React.useState(false)

    const [collections, setCollections] = React.useState(null);
    const [categoryDropdown, setCategoryDropdown] = React.useState(false)
    const [categories, setCategories] = React.useState(null)
    const [active, setActive] = React.useState(false)

    const handleMutation = (val, isCategory) => {
        const mutateObject = isCategory ? { ctId: sourceDetail.ctId, categoryName: val !== null ? val.label : null, name: sourceDetail.name, webId: id, categoryId: val !== null ? val.key : null } :
            val !== null ? { ctId: val._id, name: val._source.name, webId: id } : null;
        const copiedObject = recursiveCopy(object, true);
        copiedObject.sourceDetail.value = mutateObject;

        editComponent(selectedComponent.indices, 'options', 'dataSource', copiedObject);
        isCategory ? setCategoryDropdown(!categoryDropdown) : setActive(!active);
    };

    const handleCategory = (val) => {
        handleMutation(val, true);
        setCategoryValue(val)
    };

    const handleDropdownItem = (val) => {
        handleMutation(val, false);
    };


    const fetchColTypes = async () => {
        try {
            const result = await request({
                url: window.urls.content + `/collection/ct/listCt`,
                method: 'post',
                headers: { webid: id },
                body: {},
            });
            setCollections(result.data);
        } catch (e) {
            console.log(e, 'err fetching colTypes');
        }
    };

    React.useEffect(() => {
        if (selectedType === 'Collection') {
            mainFunction()
        }
    }, [selectedType])

    React.useEffect(() => {
        fetchColTypes()
    }, [])

    React.useEffect(() => {
        const foundOne = collections?.find((c) => c._id === sourceDetail.ctId)
        if (foundOne) {
            const filterCategories = foundOne._source.fields.filter(field => field.type === 'category')
            if (filterCategories.length) setCategories(filterCategories)
            else setCategories(null)
        } else {
            setCategories(null)
        }
    }, [collections, sourceDetail.ctId])

    const mainFunction = () => {
        return (
            <Collection>
                <Div c='wrapper'>
                    <Div c="caption">Collection</Div>
                    <Div c="dropdown-wrap">
                        <Div c={`dropdown-head ${active && 'active'}`} onClick={() => setActive(!active)}>
                            <span>{sourceDetail.name !== null ? sourceDetail.name : 'Choose one'}</span>
                            {sourceDetail.name !== null && (
                                <Div c="close" onClick={() => handleDropdownItem(null)}><Close /></Div>
                            )}
                            <Div c="arrow"><IoIosArrowDown /></Div>
                        </Div>
                        {active && (
                            <Div c="dropdown">
                                {collections?.map((val, i) => (
                                    <Div c="item" key={i} onClick={() => handleDropdownItem(val)}>
                                        {val._source.name}
                                    </Div>
                                ))}
                            </Div>
                        )}
                    </Div>
                </Div>
                {sourceDetail.name && <>
                    <Div c="toggle-wrap" onClick={() => setCategoryToggle(!categoryToggle)}>
                        <span>Category</span>
                        <ToggleButton isToggled={categoryToggle} setIsToggled={setCategoryToggle} />
                    </Div>
                    {categoryToggle &&
                        <Div c='wrapper'>
                            <Div c='caption'>Available categories</Div>
                            <Div c="dropdown-wrap">
                                <Div c={`dropdown-head ${categoryDropdown && 'active'}`} onClick={() => setCategoryDropdown(!categoryDropdown)}>
                                    <span>{categoryValue !== null ? categoryValue?.label : 'Choose one'}</span>
                                    {categoryValue !== null && <Div c='close' onClick={() => handleCategory(null)}><Close /></Div>}
                                    <Div c='arrow'><IoIosArrowDown /></Div>
                                </Div>
                                {categoryDropdown && <Div c='dropdown'>
                                    {categories?.map((val, i) => (
                                        <Div c="item" key={i} onClick={() => handleCategory(val)}>{val.label}</Div>
                                    ))}
                                </Div>}
                            </Div>
                        </Div>
                    }
                </>}
            </Collection>
        );
    }
    return selectedType === 'Collection' ? mainFunction() : null;
}

export default useCollection

const Collection = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
.toggle-wrap{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    padding-top: 10px;
    span{
        font-size: ${({ theme }) => theme.fs};
        color: ${({ theme }) => theme.color};
        font-weight:500;
    }
    & > div{
        pointer-events: none;
    }
}
.wrapper{
    .dropdown-wrap{
        width: 100%;
        position: relative;
        .dropdown-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: ${({ theme }) => theme.bg2};
            border-radius: 4px;
            padding: 8px 12px;
            cursor: pointer;
            position: relative;
            span{
                font-size: ${({ theme }) => theme.fs};
                color: ${({ theme }) => theme.color};
                font-weight:500;
            }
            .arrow{
                background: ${({ theme }) => theme.bg2};
                transition: all 0.3s linear;
                z-index: 0;
                display: flex;
                align-items: center;
                svg{
                    width: 14px;
                    height: 14px;
                }
            }
            .close{
                position: absolute;
                right: 14px;
                transition: right 0.3s ease;
                display: flex;
                align-items: center;
                bottom: 0;
                top: 0;
                svg{
                    width: 14px;
                    height: 14px;
                    path{
                        fill: ${({ theme }) => theme.color2};
                    }
                }
            }
            &:hover{
                .close{
                    right: 28px;
                }
            }
            &.active{
                .arrow{
                    svg{
                        rotate: 180deg;
                    }
                }
            }
        }
        .dropdown{
            top: 120%;
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            top: 120%;
            border-radius: 4px;
            background: ${({ theme }) => theme.bg};
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
            min-width: 100px;
            background: ${({ theme }) => theme.bg2};
            .item{
                padding: 10px 15px;
                font-size: ${({ theme }) => theme.fs};
                font-weight: 600;
                color: ${({ theme }) => theme.color};
                border-radius: 4px;
                &:hover {
                    background: ${({ theme }) => theme.lightMainColor};
                    color: ${({ theme }) => theme.mainColor};
                    cursor: pointer;
                }
            }
        }
    }
}
`