import { getCrossDomainContext } from 'contexts/crossDomain';
import { getWebglobalContext } from 'contexts/webglobal';
import React from 'react';

const HandleLoadFonts = () => {
    const { settings } = React.useContext(getWebglobalContext);
    const { frame } = React.useContext(getCrossDomainContext);
    const doc = frame.document;
    React.useEffect(() => {
        const { userDefined } = settings;
        // let styleString = '';
        // styleString = styleString + `@import url();`
        // userDefined.font?.map((font) => {
        //     styleString = styleString + `@import url(${font.url});`;
        //     styleString = styleString + `font-family:${font.value};`;
        // });
        // const styleTag = doc.createElement('style');
        // styleTag.innerText = styleString;
        // doc.head.appendChild(styleTag);

        const bootstrap = doc.createElement('link')
        bootstrap.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap-v4-grid-only@1.0.0/dist/bootstrap-grid.css')
        bootstrap.setAttribute('rel', 'stylesheet')
        doc.head.appendChild(bootstrap)
        if(userDefined) {
            userDefined.font?.map((font, i) => {
                if(i === 0) {
                    const defaultFont = window.fonts[i]
                    if(defaultFont) {
                        const root = doc.querySelector('#root')
                        root.style.fontFamily = defaultFont.value
                    }
                }
                const link = doc.createElement('link')
                link.setAttribute('href', font.url)
                link.setAttribute('rel', 'stylesheet')
                doc.head.appendChild(link)
            })
        }
    }, []);
    return null;
};

export default HandleLoadFonts;
