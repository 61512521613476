import React from "react";
import styled from "styled-components";
import Div from 'elements/Div'; import { Close } from "utils/svg";
import { useParams } from "react-router-dom";

const LocalStylesSettings = ({ close }) => {
    const { id } = useParams()
    return (
        <Container>
            <Div c="main-content">
                <Div c="title">
                    <h5>Settings</h5>
                    <Div c="close" onClick={() => close()}><Close /></Div>
                </Div>
                <Div c="content">
                    <iframe src={`https://www.dev.admin.siro.mn/webspaces/${id}/mainsetting`}></iframe>
                </Div>
            </Div>
        </Container>
    )
}

export default LocalStylesSettings

const Container = styled.div`
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
position: fixed;
left: 0;
top: 0;
background: rgba(0, 0, 0, 0.5);
z-index: 1;
animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
& > .main-content{
    width: calc(100vw - 132px);
    height: calc((100vw - 132px) * 0.715655);
    max-height: calc(100vh - 132px);
    max-width: calc((100vh - 132px) * 1.39732);
    background: ${({ theme }) => theme.popupBg};
    & > .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px;
        & > h5{
            font-size: ${({ theme }) => theme.fs4};
            color: ${({ theme }) => theme.color};
        }
        & > .close{
            cursor: pointer;
            width: 24px;
            height: 24px;
            & > svg{
                width: 24px;
                height: 24px;
                path{
                    fill : ${({ theme }) => theme.color2};
                }
            }
        }
    }
    & > .content{
        display: flex;
        gap: 30px;
        height: calc(100% - 87px);
        width: 100%;
        padding: 0 30px 30px 30px;
        iframe{
            width: 100%;
            height: 100%;
            border: none;
        }
    }
}
`