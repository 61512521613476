import React from "react";
import styled from "styled-components";

const TextTransformController = ({ value: firstValue, forwardChange }) => {
    const handleItemClick = (transformValue) => {
        forwardChange(transformValue);
    };
    return (
        <Container>
            <div className='box'>
                <div className={`item ${firstValue == 'none' && "active"}`} onClick={() => handleItemClick('none')}>-</div>
                <div className={`item ${firstValue == 'uppercase' && "active"}`} onClick={() => handleItemClick('uppercase')}>AG</div>
                <div className={`item ${firstValue == 'capitalize' && "active"}`} onClick={() => handleItemClick('capitalize')}>Ag</div>
                <div className={`item ${firstValue == 'lowercase' && "active"}`} onClick={() => handleItemClick('lowercase')}>ag</div>
            </div>
        </Container>
    )
}

export default TextTransformController

const Container = styled.div`
width: 100%;
.box{
    width: 100%;
    display: flex;
    gap: 6px;
    padding: 4px;
    background: ${({ theme }) => theme.bg2};
    border-radius: 4px;
    .item{
        width: 25%;
        height: 28px;
        background: transparent;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: ${({theme}) => theme.fs};
        color: ${({theme}) => theme.color};
        font-weight: 500;
        border: 1px solid ${({ theme }) => theme.bg2};
        &.active, &:hover {
            background:${({ theme }) => theme.bg3};
        }
    }
}
`