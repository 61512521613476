import React from 'react';
import Library from 'siro_lib';
import DragBox from '../../components/DragBox';
import { reduceStructure } from '../../helpers';
import FlexDivider from 'components/FlexDivider';
import styled from 'styled-components';
import Div from 'elements/Div';
import request from 'utils/request';
import { useParams } from 'react-router-dom';
const form = Library.Form;
const basics = Library.Basics

const Form = () => {
    const elements = reduceStructure({ ...form }, 'Form');
    const basicElements = reduceStructure({...basics }, 'Basics')
    const { id: webId } = useParams();
    const [formList, setFormList] = React.useState([]);
    const fetchFormList = async () => {
        const result = await request({
            method: 'post',
            url: window.urls.content + `/form`,
            body: { status: 'active' },
            headers: { webId },
        });
        setFormList(result.data);
        // console.log(result.data.data[0], 'yomafa');
        console.log(elements, 'elements');
        // processFormComponent(result.data.data[0]);
    };
    React.useEffect(() => {
        fetchFormList();
    }, []);
    const processFormComponent = (instance) => {
        const children = instance._source.fields.reduce((acc, cur) => {

            //Generate label
            const label = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Label')))
            label.options.text.value = cur.label

            //Generate base input
            let base = null
            if(cur.type === 'stringInput') base = elements.find(e => e.name === 'Text')
            else if(cur.type === 'textArea') base = elements.find(e => e.name === 'Text')
            else if(cur.type === 'dropdownSelect') base = elements.find(e => e.name === 'Select')
            else if(cur.type === 'checkbox') {
                base = elements.find(e => e.name === 'CheckGroup')
                base.children = cur.choice.reduce((cAcc, cCur) => {
                    const child = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Checkbox')))
                    child.options.choiceKey.value = cCur.choiceKey
                    child.options.label.value = cCur.label
                    child.options.value.value = cCur.value
                    child.options.name.value = cur.label
                    cAcc.push(child)
                    return cAcc
                }, [])
            }
            else if(cur.type === 'radio') {
                base = elements.find(e => e.name === 'RadioGroup')
                base.children = cur.choice.reduce((cAcc, cCur) => {
                    const child = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Radio')))
                    child.options.choiceKey.value = cCur.choiceKey
                    child.options.label.value = cCur.label
                    child.options.value.value = cCur.value
                    child.options.name.value = cur.label
                    child.options.radioHook.value = cur.key
                    cAcc.push(child)
                    return cAcc
                }, [])
            }
            //Clone to unfreeze nested objects
            const copiedBase = JSON.parse(JSON.stringify(base))
            //If no base component found, skip and continue generation
            if(!copiedBase) return acc
            //Fill up the contextual options
            copiedBase.options.formHook.value = cur.key
            if(copiedBase.options.name) copiedBase.options.name.value = cur.label
            copiedBase.options.required.value = cur.required
            // copiedBase.options.required.value = true
            //If the current iteration is select input, fill up the options
            if(cur.type === 'dropdownSelect') {
                copiedBase.options.options = cur.choice.reduce((cAcc, cCur) => {
                    cAcc.push({
                        disabled: { type: 'boolean', value: false },
                        selected: { type: 'boolean', value: false },
                        label: { type: 'text', value: cCur.label },
                        value: { type: 'text', value: cCur.choiceKey },
                    })
                    return cAcc
                }, [])
                //Insert selected null value
                // copiedBase.options.options.unshift({
                //     disabled: { type: 'boolean', value: true },
                //     selected: { type: 'boolean', value: true },
                //     label: { type: 'text', value: 'Choose one'},
                //     value: { type: 'text', value: ''},
                // })
            }

            const box = JSON.parse(JSON.stringify(basicElements.find(e => e.name === 'Box')))
            box.style.desktop.paddingLeft = "0px"
            box.style.desktop.paddingRight = "0px"
            box.style.desktop.paddingTop = "0px"
            box.style.desktop.paddingBottom = "0px"
            box.children = []
            box.children.push(label)
            box.children.push(copiedBase)
            acc.push(box)

            //Return accumulator and continue
            return acc
        }, []);

        //After all elements are setup, insert submit button
        const submit = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Submit')))
        children.push(submit)

        //Set up the main form component
        const component = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Form')))
        component.options.webId.value = instance._source.webId
        component.options.formId.value = instance._id
        component.name = instance._source.title
        component.children = children
        console.log(component, 'whaat?')
        return component
    };

    return (
        <Container>
            <FlexDivider grid={3}>
                {elements.map((element) => (
                    <DragBox component={element} key={Math.random()} />
                ))}
            </FlexDivider>
            <Div c="form-wrap">
                <Div c="main-title">Prebuilt forms</Div>
                <FlexDivider grid={3}>
                    {formList.map((instance) => (
                        <DragBox component={processFormComponent(instance)} key={Math.random()} />
                    ))}
                </FlexDivider>
            </Div>
        </Container>
    );
};

export default React.memo(Form);

const Container = styled.div`
    & > .form-wrap {
        padding-top: 15px;
        border-top: 1px solid ${({ theme }) => theme.sectionBorderColor};
        margin-top: 15px;
        .main-title {
            font-weight: 500;
            color: ${({ theme }) => theme.color2};
        }
    }
`;
