import Div from 'elements/Div';
import React, { useState } from 'react';
import styled from 'styled-components';

const Tab = ({ label, selectedTab, onSelectTab }) => {
  const isActive = selectedTab === label;
  return (
    <Div
      className={`tab ${isActive ? 'active' : ''}`}
      onClick={() => onSelectTab(label)}
    >
      {label}
    </Div>
  );
};

const Tabs = ({ children, popupMinWidth, onMouseDown }) => {
  const [selectedTab, setSelectedTab] = useState(children[0].props.label);
  const onSelectTab = (tabLabel) => {
    setSelectedTab(tabLabel);
  };

  return (
    <Container popupMinWidth={popupMinWidth - 2}>
      <Div className="tabs">
        <Div className="tab-list" onMouseDown={onMouseDown}>
          {children.map((child) => (
            <Tab
              key={child.props.label}
              label={child.props.label}
              selectedTab={selectedTab}
              onSelectTab={onSelectTab}
            />
          ))}
        </Div>
        <Div className="tab-content">
          {children.map((child) =>
            child.props.label === selectedTab ? child.props.children : null
          )}
        </Div>
      </Div>
    </Container>
  );
};
export default Tabs

const Container = styled.div`
.tabs{
  width: 100%;
  .tab-list{
    position: fixed;
    z-index: 10;
    width: ${({ popupMinWidth }) => popupMinWidth}px;
    display: flex;
    height: 55px;
    padding: 0 20px;
    gap: 15px;
    background: ${({ theme }) => theme.bg};
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    border-radius: 8px 8px 0 0;
    .tab{
      display: flex;
      align-items: center;
      font-weight: 500;
      line-height: 22px;
      user-select:none;
      font-size: ${({ theme }) => theme.fs2};
      color: ${({ theme }) => theme.color2};
      border-bottom: 2px solid transparent;
      transition: border-bottom-color 0.3s ease;
      cursor: pointer;
      &:hover{
        color: ${({ theme }) => theme.color};
      }
      &.active{
        color: ${({ theme }) => theme.color};
        font-weight: 600;
        border-bottom-color: ${({ theme }) => theme.color};
      }
    }
  }
  .tab-content{
    padding-top: 55px;
  }
}
`
