import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getReferenceContext } from 'contexts/reference';
import { getCustomThemeContext } from 'contexts/theme';
import { dispatchChange } from 'utils/styleHelpers';
import React from 'react';

const HandleGridBlock = () => {
    const { frame, frameUpdate, composition } = React.useContext(getCrossDomainContext)
    const { theme } = React.useContext(getCustomThemeContext)
    const { setEditComponentPopupShow, setAddBlockPopupShow, copiedElement, setCopiedElement, setLocalComponentInstance } = React.useContext(getReferenceContext)
    const doc = frame.document
    const { selectedComponent, cloneComponent, deleteComponent, addComponent, moveComponent, selectComponent, editComponent } = React.useContext(getBuilderContext)
    React.useEffect(() => {
        checkDepth()
    }, [selectedComponent, frameUpdate, copiedElement, composition])

    const checkDepth = () => {
        if (!selectedComponent) return clearMenu()
        const targetNode = doc.querySelector(`[kind][data-track="${selectedComponent.indices}"]`)
        if (!targetNode) return clearMenu()
        const kind = targetNode.getAttribute('kind')
        const depth = targetNode.getAttribute('data-depth')
        if (kind === 'grid' && depth === '1') return clearMenu()
        if (kind === 'component' && depth === '1') return clearMenu()
        drawMenu()
    }
    const clearMenu = () => {
        // Cleanup node
        doc.querySelector('#bl-block-menu-style')?.remove()
        const menuElements = [...doc.querySelectorAll('[bl-block-menu]')]
        menuElements.forEach(el => el?.remove())
    }
    const drawMenu = () => {
        // Cleanup node
        doc.querySelector('#bl-block-menu-style')?.remove()
        const menuElements = [...doc.querySelectorAll('[bl-block-menu]')]
        menuElements.forEach(el => el?.remove())

        // Append style node
        const style = doc.createElement('style')
        style.setAttribute('id', 'bl-block-menu-style')
        style.innerText = blockMenuStyle
        doc.head.appendChild(style)

        const targetNode = doc.querySelector(`[kind][data-track="${selectedComponent.indices}"]`)
        const targetRect = targetNode.getBoundingClientRect()
        const wrapper = doc.createElement('div')
        wrapper.setAttribute('bl-block-menu', 'wrapper')

        const menuBarWidth = 350
        const menubarHeight = 44
        if (targetRect.top > 100) wrapper.style.top = `${targetRect.top + frame.scrollY - 54}px`
        else {
            if (targetRect.top + targetRect.height + menubarHeight > frame.innerHeight) {
                wrapper.style.bottom = `${100}px`
            } else {
                wrapper.style.top = `${targetRect.top + targetRect.height + frame.scrollY + 25}px`
            }
        }

        if (targetRect.left + frame.scrollX > 0) {
            if (targetRect.left + targetRect.width + menuBarWidth > frame.innerWidth) {
                wrapper.style.right = `30px`
            } else {
                wrapper.style.left = `${targetRect.left + frame.scrollX}px`
            }
        }
        else {
            if (100 + targetRect.width + menuBarWidth > frame.innerWidth) {
                wrapper.style.left = `30px`
            } else {
                wrapper.style.left = `100px`
            }
        }

        doc.body.appendChild(wrapper)

        let isDragging = false
        let offsetX, offsetY

        const startDrag = (e) => {
            isDragging = true
            offsetX = e.clientX - wrapper.getBoundingClientRect().left
            offsetY = e.clientY - wrapper.getBoundingClientRect().top
        }

        const endDrag = () => {
            isDragging = false
        }

        const drag = (e) => {
            if (isDragging) {
                wrapper.style.left = `${e.clientX - offsetX}px`
                wrapper.style.top = `${(e.clientY - offsetY) + frame.scrollY}px`
            }
        }

        doc.addEventListener('mousedown', startDrag)
        doc.addEventListener('mousemove', drag)
        doc.addEventListener('mouseup', endDrag)

        // const buttons = ['insert', 'edit', 'clone', 'save', 'remove']
        const buttons = distinguishOptions()
        buttons.forEach(b => {
            const btnNode = doc.createElement('button');
            btnNode.setAttribute('caption', b.caption)
            b.disabled && btnNode.setAttribute('disabled', true)
            const imgNode = doc.createElement('img');
            imgNode.src = b.imgPath
            btnNode.appendChild(imgNode)
            const txtNode = doc.createElement('p');
            txtNode.innerText = b.caption
            btnNode.appendChild(txtNode)
            wrapper.appendChild(btnNode)

            const altWrapper = doc.createElement('div')
            if (b.alternatives) {
                altWrapper.setAttribute('btn-alt', '')
                b.alternatives.map(altBut => {
                    const subBtn = doc.createElement('button')
                    subBtn.innerHTML = `<img src="${altBut.imgPath}"/>`
                    const subCallback = ev => {
                        ev.stopPropagation()
                        altBut.callback(altBut.caption)
                        clearMenu()
                    }
                    subBtn.addEventListener('click', subCallback)
                    altWrapper.appendChild(subBtn)
                })
                wrapper.appendChild(altWrapper)
            }

            //Event binding
            const callbackFunction = (e) => {
                e.stopPropagation()
                if (b.alternatives) {
                    altWrapper.setAttribute('open', '')
                } else {
                    b.callback(b.caption === 'Save' ? targetNode : null)
                    clearMenu()
                }
            }

            btnNode.addEventListener('click', callbackFunction)
        })
    }
    const identifyAlignment = (selected, direction) => {
        const targetNode = doc.querySelector(`[data-track="${selected.indices}"]`)
        const { flexDirection, justifyContent, alignItems } = getComputedStyle(targetNode);
        if (flexDirection === "row" || flexDirection === "row-reverse") {
            const yDirection = { name: 'alignItems', value: alignItems, coordinate: 'y' }
            const xDirection = { name: 'justifyContent', value: justifyContent, coordinate: 'x' }
            if (direction === 'y') {
                return yDirection
            }
            else {
                return xDirection
            }
        } else if (flexDirection === 'column' || flexDirection === 'column-reverse') {
            const yDirection = { name: 'justifyContent', value: justifyContent, coordinate: 'y' }
            const xDirection = { name: 'alignItems', value: alignItems, coordinate: 'x' }
            if (direction === 'y') {
                return yDirection
            }
            else {
                return xDirection
            }
        }
    }

    const reduceIcon = (attributeValue) => {
        let imgPath
        if (attributeValue.coordinate == 'y') {
            attributeValue = attributeValue.value.toLowerCase()
            if (attributeValue === 'center') imgPath = '/img/section-icons/y-center.svg'
            else if (attributeValue === 'end') imgPath = '/img/section-icons/y-bottom.svg'
            else imgPath = '/img/section-icons/y-top.svg'
        } else {
            attributeValue = attributeValue.value.toLowerCase()
            if (attributeValue === 'center') imgPath = '/img/section-icons/x-center.svg'
            else if (attributeValue === 'end') imgPath = '/img/section-icons/x-end.svg'
            else imgPath = '/img/section-icons/x-start.svg'
        }
        return imgPath
    }

    const iconChange = (selected, style) => {
        const targetNode = doc.querySelector(`[data-track="${selected.indices}"]`)
        const { textAlign, fontWeight, fontSize, objectFit } = getComputedStyle(targetNode);
        let imgPath

        if (style == 'textAlign') {
            if (textAlign == 'left' || textAlign == 'start') imgPath = '/img/section-icons/text_start.svg'
            else if (textAlign == 'center') imgPath = '/img/section-icons/text_center.svg'
            else if (textAlign == 'end' || textAlign == 'right') imgPath = '/img/section-icons/text_end.svg'
            else imgPath = '/img/section-icons/text_justify.svg'
        }
        if (style == 'fontWeight') {
            if (fontWeight == '400') imgPath = '/img/section-icons/s.svg'
            else if (fontWeight == '500') imgPath = '/img/section-icons/m.svg'
            else if (fontWeight == '600') imgPath = '/img/section-icons/l.svg'
            else if (fontWeight == '700') imgPath = '/img/section-icons/xl.svg'
            else imgPath = '/img/section-icons/fontweight.svg'
        }
        if (style == 'fontSize') {
            if (fontSize == '14px') imgPath = '/img/section-icons/14.svg'
            else if (fontSize == '16px') imgPath = '/img/section-icons/16.svg'
            else if (fontSize == '18px') imgPath = '/img/section-icons/18.svg'
            else if (fontSize == '24px') imgPath = '/img/section-icons/24.svg'
            else imgPath = '/img/section-icons/fontsize.svg'
        }
        if (style == 'objectFit') {
            if (objectFit == 'none') imgPath = '/img/section-icons/none.svg'
            else if (objectFit == 'cover') imgPath = '/img/section-icons/cover.svg'
            else imgPath = '/img/section-icons/objectFit.svg'
        }

        return imgPath
    }

    const handleStyle = (selected, style, caption) => {
        const name = style.name ? style.name : style
        dispatchChange(editComponent, selected, name, caption)
    }

    const distinguishOptions = () => {
        const [sectionIndex, currentIndex] = getFirstAndLastNumbers(selectedComponent.indices)
        const optionButtons = []

        if (selectedComponent.appendable) optionButtons.push({
            caption: 'Insert',
            imgPath: '/img/section-icons/insert.svg',
            callback: () => setAddBlockPopupShow(true)
        })
        optionButtons.push({
            caption: 'Edit',
            imgPath: '/img/section-icons/edit.svg',
            callback: () => setEditComponentPopupShow(true)
        })
        optionButtons.push({
            caption: 'Clone',
            imgPath: '/img/section-icons/clone.svg',
            callback: () => cloneComponent(selectedComponent.indices)
        })
        if (selectedComponent.component == 'Text' || selectedComponent.component == 'Button') {
            optionButtons.push({
                type: 'multiple',
                caption: 'FontSize',
                imgPath: iconChange(selectedComponent, 'fontSize'),
                alternatives: [
                    {
                        caption: '14px',
                        imgPath: '/img/section-icons/14.svg',
                        callback: () => handleStyle(selectedComponent, 'fontSize', '14px')
                    },
                    {
                        caption: '16px',
                        imgPath: '/img/section-icons/16.svg',
                        callback: () => handleStyle(selectedComponent, 'fontSize', '16px')
                    },
                    {
                        caption: '18px',
                        imgPath: '/img/section-icons/18.svg',
                        callback: () => handleStyle(selectedComponent, 'fontSize', '18px')
                    },
                    {
                        caption: '24px',
                        imgPath: '/img/section-icons/24.svg',
                        callback: () => handleStyle(selectedComponent, 'fontSize', '24px')
                    }
                ],
                callback: () => null
            })
            optionButtons.push({
                type: 'multiple',
                caption: 'FontWeight',
                imgPath: iconChange(selectedComponent, 'fontWeight'),
                alternatives: [
                    {
                        caption: 'normal',
                        imgPath: '/img/section-icons/s.svg',
                        callback: () => handleStyle(selectedComponent, 'fontWeight', '400')
                    },
                    {
                        caption: 'medium',
                        imgPath: '/img/section-icons/m.svg',
                        callback: () => handleStyle(selectedComponent, 'fontWeight', '500')
                    },
                    {
                        caption: 'semibold',
                        imgPath: '/img/section-icons/l.svg',
                        callback: () => handleStyle(selectedComponent, 'fontWeight', '600')
                    },
                    {
                        caption: 'bold',
                        imgPath: '/img/section-icons/xl.svg',
                        callback: () => handleStyle(selectedComponent, 'fontWeight', '700')
                    }
                ],
                callback: () => null
            })
            optionButtons.push({
                type: 'multiple',
                caption: 'Align',
                imgPath: iconChange(selectedComponent, 'textAlign'),
                alternatives: [
                    {
                        caption: 'Left',
                        imgPath: '/img/section-icons/text_start.svg',
                        callback: () => handleStyle(selectedComponent, 'textAlign', 'start')
                    },
                    {
                        caption: 'Center',
                        imgPath: '/img/section-icons/text_center.svg',
                        callback: () => handleStyle(selectedComponent, 'textAlign', 'center')
                    },
                    {
                        caption: 'Right',
                        imgPath: '/img/section-icons/text_end.svg',
                        callback: () => handleStyle(selectedComponent, 'textAlign', 'end')
                    },
                    {
                        caption: 'Justify',
                        imgPath: '/img/section-icons/text_justify.svg',
                        callback: () => handleStyle(selectedComponent, 'textAlign', 'justify')
                    }
                ],
                callback: () => null
            })
        }
        if (selectedComponent.component == 'Image' || selectedComponent.component == 'Video') {
            optionButtons.push({
                type: 'multiple',
                caption: 'objectFit',
                imgPath: iconChange(selectedComponent, 'objectFit'),
                alternatives: [
                    {
                        caption: 'none',
                        imgPath: '/img/section-icons/none.svg',
                        callback: () => handleStyle(selectedComponent, 'objectFit', 'none')
                    },
                    {
                        caption: 'fit',
                        imgPath: '/img/section-icons/objectFit.svg',
                        callback: () => handleStyle(selectedComponent, 'objectFit', 'contain')
                    },
                    {
                        caption: 'fill',
                        imgPath: '/img/section-icons/cover.svg',
                        callback: () => handleStyle(selectedComponent, 'objectFit', 'cover')
                    },
                ],
                callback: () => null
            })
        }
        if (selectedComponent.component === 'Box' || selectedComponent.component === 'List' || selectedComponent.component === 'Datagrid') {
            optionButtons.push({
                type: 'multiple',
                caption: 'Align y',
                imgPath: reduceIcon(identifyAlignment(selectedComponent, 'y')),
                alternatives: [
                    {
                        caption: 'start',
                        imgPath: '/img/section-icons/y-top.svg',
                        callback: (caption) => handleStyle(selectedComponent, identifyAlignment(selectedComponent, 'y'), caption)
                    },
                    {
                        caption: 'center',
                        imgPath: '/img/section-icons/y-center.svg',
                        callback: (caption) => handleStyle(selectedComponent, identifyAlignment(selectedComponent, 'y'), caption)
                    },
                    {
                        caption: 'end',
                        imgPath: '/img/section-icons/y-bottom.svg',
                        callback: (caption) => handleStyle(selectedComponent, identifyAlignment(selectedComponent, 'y'), caption)
                    }
                ],
                callback: () => null
            })
            optionButtons.push({
                type: 'multiple',
                caption: 'Align x',
                imgPath: reduceIcon(identifyAlignment(selectedComponent, 'x')),
                alternatives: [
                    {
                        caption: 'start',
                        imgPath: '/img/section-icons/x-start.svg',
                        callback: (caption) => handleStyle(selectedComponent, identifyAlignment(selectedComponent, 'x'), caption)
                    },
                    {
                        caption: 'center',
                        imgPath: '/img/section-icons/x-center.svg',
                        callback: (caption) => handleStyle(selectedComponent, identifyAlignment(selectedComponent, 'x'), caption)
                    },
                    {
                        caption: 'end',
                        imgPath: '/img/section-icons/x-end.svg',
                        callback: (caption) => handleStyle(selectedComponent, identifyAlignment(selectedComponent, 'x'), caption)
                    }
                ],
                callback: () => null
            })
        }
        optionButtons.push({
            caption: 'Save',
            imgPath: '/img/section-icons/heart.svg',
            callback: (target) => setLocalComponentInstance({ index: target.dataset.track, updateData: true })
        })
        optionButtons.push({
            caption: 'Copy',
            imgPath: '/img/section-icons/copy.svg',
            callback: () => setCopiedElement(selectedComponent)
        })
        optionButtons.push({
            caption: 'Paste',
            imgPath: '/img/section-icons/paste.svg',
            callback: () => addComponent(copiedElement, selectedComponent.indices, 'a'),
            disabled: copiedElement ? false : true
        })
        const otherObject = {
            caption: 'Other',
            imgPath: '/img/section-icons/other.svg',
            alternatives: [
                {
                    caption: 'Remove',
                    imgPath: '/img/section-icons/remove.svg',
                    callback: () => deleteComponent(selectedComponent.indices)
                }
            ],
            callback: () => null
        }
        if (selectedComponent.kind === 'grid') {
            otherObject.alternatives.push({
                caption: 'Move backward',
                imgPath: '/img/section-icons/backward.svg',
                callback: () => {
                    moveComponent(selectedComponent.indices, `${sectionIndex}-${currentIndex - 1}`, 'u')
                    setTimeout(() => {
                        selectComponent(`${sectionIndex}-${currentIndex - 1}`)
                    }, 0);
                },
                disabled: composition[sectionIndex]?.children[currentIndex - 1] ? false : true
            })
            otherObject.alternatives.push({
                caption: 'Move forward',
                imgPath: '/img/section-icons/forward.svg',
                callback: () => {
                    moveComponent(selectedComponent.indices, `${sectionIndex}-${currentIndex + 1}`, 'd')
                    setTimeout(() => {
                        selectComponent(`${sectionIndex}-${currentIndex + 1}`)
                    }, 0);
                },
                disabled: composition[sectionIndex]?.children[currentIndex + 1] ? false : true
            })
        }
        optionButtons.push(otherObject)
        return optionButtons
    }

    const blockMenuStyle = `
        [bl-block-menu="wrapper"]{
            box-shadow:0 4px 16px rgba(0,0,0,.12), 0 0 0 1px rgba(0,0,0,.02);
            border-radius:8px;
            padding:4px;
            background:${theme.bg2};
            position:absolute;
            z-index:1002;
            pointer-events:auto;
            display:flex;
            gap:0px;
            align-items:center;
            animation: fade-in-down 0.5s ease both;
            font-family:sans-serif;
            font-size:12px;
        }
        [bl-block-menu="wrapper"] button {
            border:none;
            width:36px;
            height:36px;
            padding:8px;
            display:flex;
            align-items:center;
            justify-content:center;
            background:transparent;
            border-radius:8px;
            position:relative;
        }
        [bl-block-menu="wrapper"] button[caption="remove"] {
            color:red;
        }
        [bl-block-menu="wrapper"] button:hover {
            background:${theme.bg3};
            cursor:pointer;
        }
        [bl-block-menu="wrapper"] button[disabled="true"] {
            opacity:0.5;
            cursor:not-allowed;
        }
        [bl-block-menu="wrapper"] button:hover p {
            display:block !important;
        }
        [bl-block-menu="wrapper"] img {
            width:100%;
            height:100%;
        }
        [bl-block-menu="wrapper"] p {
            position:absolute;
            top:calc(100% + 5px);
            background:${theme.color};
            color:${theme.bg};
            padding:10px 12px;
            text-transform:capitalize;
            display:none;
            animation: fade-in-down 0.5s ease both;
            border-radius:8px;
        }
        [bl-block-menu="wrapper"] p:before {
            content:"";
            display:block;
            position:absolute;
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid ${theme.color};
            top:-5px;
            left:calc(50% - 5px);
        }
        [bl-block-menu="wrapper"] [btn-alt] {
            border-radius:8px;
            padding:4px;
            gap:10px;
            display:none;
            position:absolute;
            bottom:110%;
            right: 0;
            left: 0;
            margin:auto;
            width: fit-content;
            background:${theme.bg2};
            box-shadow:0 4px 16px rgba(0,0,0,.12), 0 0 0 1px rgba(0,0,0,.02);
        }
        [bl-block-menu="wrapper"] [btn-alt][open] {
            display:flex;
        }
        @keyframes fade-in-down {
            0% {
              opacity: 0;
              transform: translateY(-10px);
            }
            100% {
              opacity: 1;
              transform: translateY(0px);
            }
        }
    `

    return null
};

export default HandleGridBlock;

function getFirstAndLastNumbers(inputString) {
    // Split the input string by the '-' character
    const parts = inputString.split('-');

    // Convert the first and last parts to numbers
    const firstNumber = Number(parts[0]);
    const lastNumber = Number(parts[parts.length - 1]);

    // Return an array with the first and last numbers
    return [firstNumber, lastNumber];
}