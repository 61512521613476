import React from 'react'
import styled from 'styled-components'
import { MdOutlineLock, MdOutlineLockOpen } from "react-icons/md";
import Div from 'elements/Div'
import { RadiusBottomLeft, RadiusBottomRight, RadiusTopLeft, RadiusTopRight } from 'utils/svg'
import ToggleButton from 'components/ToggleButton'

const RadiusController = ({ value, forwardChange }) => {
    const [data, setData] = React.useState({
        top_left: "",
        top_right: "",
        bottom_right: "",
        bottom_left: ""
    })
    const [isToggled, setIsToggled] = React.useState(false)
    const [all, setAll] = React.useState(false)

    React.useEffect(() => {
        if (value != undefined && typeof value === 'string') {
            setIsToggled(true)
            const values = value.split(' ');
            if (values.length == 1) {
                setAll(true)
                setData({
                    top_left: values[0],
                    top_right: values[0],
                    bottom_right: values[0],
                    bottom_left: values[0],
                })
            } else if (values.length == 2) {
                setAll(false)
                setData({
                    top_left: values[0],
                    top_right: values[1],
                    bottom_right: values[0],
                    bottom_left: values[1],
                })
            } else if (values.length == 3) {
                setAll(false)
                setData({
                    top_left: values[0],
                    top_right: values[1],
                    bottom_right: values[2],
                    bottom_left: values[1],
                })
            } else {
                const areAllValuesEqual =
                    values[0] === values[1] &&
                    values[0] === values[2] &&
                    values[0] === values[3];

                if (areAllValuesEqual)
                    setAll(true);
                else
                    setAll(false);

                setData({
                    top_left: values[0],
                    top_right: values[1],
                    bottom_right: values[2],
                    bottom_left: values[3],
                })
            }
        }
        else {
            setIsToggled(false)
            setAll(true);
            setData({
                top_left: "0px",
                top_right: "0px",
                bottom_left: "0px",
                bottom_right: "0px"
            })
        }
    }, [value])

    const handleInputChange = (e, field) => {
        const { value } = e.target;
        if (all == true)
            setData({
                top_left: value,
                top_right: value,
                bottom_left: value,
                bottom_right: value,
            });
        else
            setData(prevData => ({
                ...prevData,
                [field]: value
            }));
    };

    const handleBlur = () => {
        setTimeout(() => {
            const combinedString = `${data.top_left} ${data.top_right} ${data.bottom_right} ${data.bottom_left}`
            forwardChange(combinedString)
        }, 150);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                const combinedString = `${data.top_left} ${data.top_right} ${data.bottom_right} ${data.bottom_left}`;
                forwardChange(combinedString)
            }, 150);
        }
    };

    const handleToggle = (isToggled) => {
        if (isToggled)
            setIsToggled(isToggled)
        else {
            setIsToggled(isToggled)
            forwardChange(0)
        }
    }

    return (
        <Container>
            <Div c='radius-wrap'>
                <Div className='toggle-wrap'>
                    <Div c='groupname' onClick={() => handleToggle(!isToggled)}>
                        <span>Radius</span>
                    </Div>
                    <ToggleButton isToggled={isToggled} setIsToggled={handleToggle} />
                </Div>
                {isToggled && <Div c="radius">
                    <Div c="group-wrap">
                        <Div className='group'>
                            <input type="text" value={data.top_left} onChange={(e) => handleInputChange(e, 'top_left')} onKeyDown={handleKeyDown} onBlur={handleBlur} />
                            <Div className='icon'><RadiusTopLeft /></Div>
                        </Div>
                        <Div className='group'>
                            <input type="text" value={data.bottom_left} onChange={(e) => handleInputChange(e, 'bottom_left')} onKeyDown={handleKeyDown} onBlur={handleBlur} />
                            <Div className='icon'><RadiusBottomLeft /></Div>
                        </Div>
                    </Div>
                    <Div c='toggle'>
                        <Div className="icon" onClick={() => setAll(!all)}>{all ? <MdOutlineLock /> : <MdOutlineLockOpen />}</Div>
                    </Div>
                    <Div c="group-wrap">
                        <Div className='group'>
                            <Div className='icon'><RadiusTopRight /></Div>
                            <input type="text" value={data.top_right} onChange={(e) => handleInputChange(e, 'top_right')} onKeyDown={handleKeyDown} onBlur={handleBlur} />
                        </Div>
                        <Div className='group'>
                            <Div className='icon'><RadiusBottomRight /></Div>
                            <input type="text" value={data.bottom_right} onChange={(e) => handleInputChange(e, 'bottom_right')} onKeyDown={handleKeyDown} onBlur={handleBlur} />
                        </Div>
                    </Div>
                </Div>}
            </Div>
        </Container>
    )
}

export default RadiusController

const Container = styled.div`
.radius-wrap{
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    width: 100%;
    .toggle-wrap{
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 44px;
        cursor: pointer;
        align-items: center;
        .groupname{
            display: flex;
            align-items: center;
            gap: 5px;
            width: 100%;
            span{
                font-weight: 500;
                color: ${({ theme }) => theme.color};
            }
        }
    }
    .radius{
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 15px;
        .group-wrap{
            width: calc((100% - 24px) / 2);
            display: flex;
            flex-direction: column;
            gap: 10px;
            .group{
                display: flex;
                border-radius: 4px;
                padding: 8px;
                background: ${({ theme }) => theme.bg2};
                align-items: center;
                gap: 2px;
                width: 100%;
                input{
                    width: 100%;
                    background: transparent;
                    border: none;
                    color: ${({ theme }) => theme.color};
                    &:focus{
                        outline: none;
                    }
                }
                .icon{
                    svg{
                        path{
                            fill: ${({ theme }) => theme.color2};
                        }
                    }
                }
            }
        }
        .toggle{
            width:fit-content;
            .icon{
                border-radius: 4px;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: ${({ theme }) => theme.bg};
                svg{
                    width: 20px;
                    height: 20px;
                    color: ${({ theme }) => theme.color2};
                }
                &.active{
                    background: ${({ theme }) => theme.bg3};
                }
            }
        }
    }
}
`