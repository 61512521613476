import React from 'react';
import styledComponents from 'styled-components';
import { getBuilderContext } from '../../contexts/builder';
import { BsFillCaretDownFill, BsFillCaretRightFill, BsDownload, BsTrash, BsLock, BsUnlock } from 'react-icons/bs';
import { getReferenceContext } from '../../contexts/reference';
import { getCrossDomainContext } from 'contexts/crossDomain';
import styled from 'styled-components';
import Div from 'elements/Div';
import { Box, Down, Icon, Image, Right, Text } from 'utils/svg';

const InpsectLayers = () => {
    const itself = React.useRef();
    const indicator = React.useRef();
    const { composition, frame } = React.useContext(getCrossDomainContext);
    const doc = frame.document;
    const dragImg = React.useRef();
    const { selectComponent, deleteComponent, selectedComponent, emptyParallelIndex, mutateParallelIndex, editComponent, moveComponent } =
        React.useContext(getBuilderContext);
    const { Highlight, setTemplateInstance } = React.useContext(getReferenceContext);
    const internalPointer = React.useRef();
    const handleInspectClick = (e, parent) => {
        const { track } = parent.dataset;
        if (!selectedComponent) return selectComponent(track);
        if (e.shiftKey) {
            mutateParallelIndex(track);
        } else {
            emptyParallelIndex();
            selectComponent(track);
        }
    };
    const handleDelete = (e) => {
        const { track } = e.currentTarget.parentElement.parentElement.dataset;
        emptyParallelIndex([]);
        selectComponent(null);
        deleteComponent(track);
    };
    const handleSaveTemplate = async (e) => {
        const { track } = e.currentTarget.parentElement.parentElement.dataset;
        setTemplateInstance({ index: track, updateData: true });
    };
    const toggleHandler = (e) => {
        e.stopPropagation();
        const parent = e.currentTarget.parentElement.parentElement;
        if (parent.classList.contains('collapsed')) {
            parent.classList.remove('collapsed');
        } else {
            parent.classList.add('collapsed');
        }
    };
    const handleMouseOver = (e) => {
        const target = e.target.nodeName === 'SPAN' ? e.target.parentElement : e.target;
        const { track } = target.dataset;
        const forwardingElement = doc.querySelector('[data-track="' + track + '"]');
        if (forwardingElement) {
            Highlight.current.dispatchEvent(
                new CustomEvent('draw', {
                    detail: {
                        target: forwardingElement,
                        // title: forwardingElement.title
                    },
                })
            );
        }
    };
    const handleMouseOut = () => {
        Highlight.current.dispatchEvent(new CustomEvent('clear', { bubbles: true }));
    };
    const findCollapsedParentElement = (node) => {
        let parentElement = node.parentElement;

        while (parentElement !== null && parentElement.classList.contains('collapsed') === false) {
            parentElement = parentElement.parentElement;
        }

        return parentElement;
    };
    const repositionScroll = () => {
        const container = itself.current;
        try {
            // Visible top
            const paddingTop = Number(getComputedStyle(container).paddingTop.match(/(\d+)/)[0]);
            const offsetTop = container.parentElement.offsetTop;
            const visibleTop = offsetTop + paddingTop;

            // Visible height
            const offsetHeight = container.parentElement.offsetHeight;
            const paddingBottom = Number(getComputedStyle(container).paddingBottom.match(/(\d+)/)[0]);
            const visibleHeight = offsetHeight - paddingTop - paddingBottom;

            // Visible scroll area
            const visibleScrollStart = container.parentElement.scrollTop;
            const visibleScrollEnd = visibleScrollStart + visibleHeight - 30;

            const layerElement = container.querySelector(`[kind="inspect"][data-track="${selectedComponent.indices}"]`);
            if (layerElement.offsetTop - visibleTop < 1) {
                const collapsedParent = findCollapsedParentElement(layerElement);
                collapsedParent.classList.remove('collapsed');
            }
            const elementTop = layerElement.offsetTop - visibleTop;
            if (elementTop < 1) if (elementTop > visibleScrollStart && elementTop < visibleScrollEnd) return null;
            container.parentElement.scrollTo(0, elementTop - visibleHeight / 2 + 50);
        } catch (e) {
            console.warn('repositionScroll', e);
        }
    };

    const handleEditable = (e) => {
        e.preventDefault();
        const target = e.target;
        target.setAttribute('contentEditable', true);
        target.focus();
    };
    const onEnter = (e) => {
        if(e.keyCode !== 13) return null
        e.preventDefault();
        e.stopPropagation();
        const parent = e.target.parentElement.parentElement
        if(parent?.getAttribute('kind') !== 'inspect') return null
        editComponent(parent.dataset.track, 'name', e.target.innerText, e.target.innerText);
        e.target.setAttribute('contentEditable', false)
    }
    const handleNameChange = (e, track) => {
        e.preventDefault();
        const target = e.target;
        editComponent(track, 'name', target.innerText, target.innerText);
        target.setAttribute('contentEditable', false);
    };
    React.useEffect(() => {
        const img = document.createElement('img');
        img.setAttribute('src', '/img/dragImage.jpg');
        dragImg.current = img;
    }, []);
    React.useEffect(() => {
        if (selectedComponent) repositionScroll();
    }, [selectedComponent]);
    const handleLock = () => {};
    const dragStart = (e) => {
        e.stopPropagation();
        const target = e.target;
        if (target.getAttribute('kind') !== 'inspect') return null;
        target.classList.add('inspect-dragging');
        itself.current.classList.add('block-spans');
        e.dataTransfer.setDragImage(dragImg.current, 0, 0);

        //EXPERIMENTAL OUTLINE
        const frameNode = doc.querySelector(`[data-track="${e.target.dataset.track}"]`);
        doc.querySelector('#root').setAttribute('data-highlight', '');
        frameNode.classList.add('dragging-source');
        indicator.current.style.opacity = '1';

        const { track } = e.target.dataset;
        e.dataTransfer.setData('text/track', track);
        e.dataTransfer.effectAllowed = 'move';
    };
    const cleanupDragDrop = () => {
        itself.current.classList.remove('block-spans');
        const dragging = [...document.querySelectorAll('.inspect-dragging')];
        dragging.forEach((d) => d.classList.remove('inspect-dragging'));
        indicator.current.style.opacity = '0';

        const draggingSource = [...doc.querySelectorAll('.dragging-source')];
        draggingSource.forEach((d) => d.classList.remove('dragging-source'));
        doc.querySelector('#root').removeAttribute('data-highlight');
    }
    const dragEnd = () => {
        cleanupDragDrop()
    };
    const handleDrop = (e) => {
        const kind = e.target?.getAttribute('kind');
        if (kind === 'inspect') {
            const allowedOperation = e.dataTransfer.effectAllowed;
            const source = e.dataTransfer.getData('text/track');
            const { track } = e.target.dataset;
            if (allowedOperation === 'move') moveComponent(source, track, internalPointer.current);
            cleanupDragDrop()
        }
    };
    const dragOver = (e) => {
        e.stopPropagation();
        const kind = e.target.getAttribute('kind');
        if (kind !== 'inspect') return null;
        const span = e.target.querySelector(':scope > span');
        // console.log(itself.current.scrollTop, '?')
        const spanStylePaddingLeft = frame.getComputedStyle(span).paddingLeft;
        const spanStylePaddingRight = frame.getComputedStyle(span).paddingRight;
        const spanPos = span.getBoundingClientRect();
        const spanTop = spanPos.top;
        const spanMiddle = spanPos.top + spanPos.height / 2;
        const spanBottom = spanPos.top + spanPos.height;
        let pointing = null;
        if (e.target.getAttribute('data-appendable') === 'true') {
            const hasChild = e.target.querySelector(':scope > [kind]');
            if (!hasChild) {
                const margin = 5
                if (e.clientY > spanTop && e.clientY < spanMiddle - margin) pointing = 'u';
                if (e.clientY > spanMiddle - margin && e.clientY < spanMiddle + margin) pointing = 'm';
                if (e.clientY > spanMiddle + margin && e.clientY < spanBottom) pointing = 'd'
            }
            else {
                if (e.clientY > spanTop && e.clientY < spanMiddle) pointing = 'u';
                if (e.clientY > spanMiddle && e.clientY < spanBottom) pointing = 'd';
            }
        } else {
            if (e.clientY > spanTop && e.clientY < spanMiddle) pointing = 'u';
            if (e.clientY > spanMiddle && e.clientY < spanBottom) pointing = 'd';
        }
        if (pointing) {
            const frameNode = doc.querySelector(`[data-track="${e.target.dataset.track}"]`);
            indicator.current.setAttribute('data-direction', pointing);
            internalPointer.current = pointing;
            e.preventDefault()
            if (pointing === 'm') {
                const p = span.querySelector(':scope > p');
                const pPos = p.getBoundingClientRect();
                indicator.current.style.left = `${pPos.left + pPos.width + 15}px`;
                indicator.current.style.top = `${spanMiddle}px`;
                indicator.current.style.width = `50px`;
                
            } else {
                indicator.current.style.left = `calc(${spanPos.left}px + ${spanStylePaddingLeft})`;
                indicator.current.style.width = `calc(${spanPos.width}px - ${spanStylePaddingRight} - ${spanStylePaddingLeft})`;
                if (pointing === 'u') indicator.current.style.top = `${spanTop}px`;
                else if (pointing === 'd') indicator.current.style.top = `${spanBottom}px`;
            }
        }

        // console.log(spanTop, e.clientY, pointing)
    };
    const StackLayers = ({ index, name, component, depth, track, children, appendable, ...props }) => {
        const indexToAssign = index;
        if (!track) track = '' + index + '';
        else track = track + '-' + index;
        depth = typeof depth === 'number' ? depth + 1 : 0;
        const hasChildren = children && children.length;

        return (
            <LayerWrap
                key={track + -'inspect'}
                data-index={indexToAssign}
                data-track={track}
                data-depth={depth}
                data-appendable={appendable ? true : ''}
                title={name ?? component}
                depthMeasure={depth}
                kind="inspect"
                onDragEnd={dragEnd}
                onDragStart={dragStart}
                onDragOver={dragOver}
                onDrop={handleDrop}
                draggable={true}
            >
                <span onClick={(e) => handleInspectClick(e, e.currentTarget.parentElement)}>
                    {hasChildren ? (
                        <>
                            <Right onClick={toggleHandler} className="__icon_caret collapsed" />
                            <Down onClick={toggleHandler} className="__icon_caret expanded" />
                        </>
                    ) : null}
                    {component == 'Text' ? (
                        <Text className="__icon_box" />
                    ) : name == 'Image' ? (
                        <Image className="__icon_box" />
                    ) : name == 'Icon' ? (
                        <Icon className="__icon_box" />
                    ) : (
                        <Box className="__icon_box" />
                    )}
                    <p
                        onClick={(e) => handleInspectClick(e, e.currentTarget.parentElement.parentElement)}
                        onBlur={(e) => handleNameChange(e, track)}
                        onDoubleClick={handleEditable}
                        onKeyDown={onEnter}
                    >
                        {name ?? component}
                    </p>
                    <BsDownload onClick={handleSaveTemplate} title="Save as template" className="__icon_save" />
                    <BsTrash onClick={handleDelete} title="Delete component" className="__icon_delete" />
                    {props.locked ? (
                        <BsUnlock onClick={() => handleLock(props.locked)} />
                    ) : (
                        <BsLock onClick={() => handleLock(props.locked)} />
                    )}
                </span>
                {hasChildren ? children.map((child, index) => StackLayers({ ...child, track, depth, index })) : ''}
            </LayerWrap>
        );
    };
    return (
        <Container
            id="layers-container"
            ref={itself}
            selected={selectedComponent?.indices}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <Div c="wrap">{composition.map((instance, index) => StackLayers({ ...instance, index, depth: 0 }))}</Div>
            <Indicator ref={indicator} />
        </Container>
    );
};

export default InpsectLayers;

const Indicator = styled.div`
    display: flex;
    position: fixed;
    height: 2px;
    background: ${({ theme }) => theme.mainColor};
    justify-content: center;
    z-index: 1;
    pointer-events: none;
    transition: 0.2s ease;
    opacity: 0;
    &[data-direction='d'] {
        &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid ${({ theme }) => theme.mainColor};
            margin-top: -5px;
        }
    }
    &[data-direction='u'] {
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid ${({ theme }) => theme.mainColor};
        }
    }
    &[data-direction='m'] {
        justify-content: flex-start;
        align-items: center;
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 5px solid ${({ theme }) => theme.mainColor};
            margin-left: -2px;
        }
    }
`;

const Container = styled.div`
    height: calc(100vh - 100px);
    overflow-y: auto;
    width: 100%;
    &.block-spans {
        .wrap {
            div {
                span {
                    pointer-events: none !important;
                }
            }
        }
    }
    .wrap {
        [data-track='${({ selected }) => selected}'] {
            & > span {
                background: ${({ theme }) => theme.lightMainColor} !important;
            }
        }
        div {
            padding-left: 10px;
            width: 100%;
            &[data-depth='1'] {
                padding-left: 0px;
                width: 100%;
            }
            span {
                border-bottom: 1px solid ${({ theme }) => theme.bg2};
                padding-top: 10px;
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                padding: 8px 24px;
                position: relative;
                &:hover {
                    cursor: pointer;
                    background: ${({ theme }) => theme.bg2};
                    .__icon_save {
                        opacity: 1;
                        transform: translateX(0px);
                    }
                    .__icon_delete {
                        opacity: 1;
                        transform: translateX(0px);
                    }
                }
                .__icon_box {
                    color: ${({ theme }) => theme.mainColor};
                    font-size: 20px;
                    pointer-events: none;
                    margin-left: 5px;
                }
                .__icon_save {
                    margin-left: auto;
                    transform: translateX(10px);
                    opacity: 0;
                    transition: 0.3s ease;
                    color: ${({ theme }) => theme.mainColor};
                }
                .__icon_delete {
                    margin-left: 5px;
                    margin-right: 5px;
                    transform: translateX(10px);
                    opacity: 0;
                    transition: 0.3s ease;
                    color: red;
                }
                .__icon_caret {
                    margin-left: -15px;
                    width: 10px;
                    height: 10px;
                    color: ${({ theme }) => theme.color};
                    position: absolute;
                    &.collapsed {
                        display: none;
                    }
                }
                p {
                    margin: 0px;
                    margin-left: 10px;
                    font-weight: 700;
                    // pointer-events:none;
                }
            }
        }
        div.collapsed {
            span {
                .__icon_caret {
                    &.collapsed {
                        display: block;
                    }
                    &.expanded {
                        display: none;
                    }
                }
            }
            div {
                display: none;
            }
        }
        div.inspect-dragging {
            position: relative;
            outline: 1px solid ${({ theme }) => theme.mainColor};
            & > * {
                pointer-events: none;
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                inset: 0;
                background: ${({ theme }) => theme.mainColor};
                opacity: 0.3;
            }
        }
    }
`;
const LayerWrap = styledComponents.div`
    // margin-left:${({ depthMeasure }) => depthMeasure && `${depthMeasure + '' + 0}px`};
`;
