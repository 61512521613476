import Div from 'elements/Div';
import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import styled from 'styled-components';

const SearchBar = () => {
    return (
        <Container>
            <Div c="wrap">
                <AiOutlineSearch/>
                <input placeholder='Search' />
            </Div>
        </Container>
    );
};

export default SearchBar;

const Container = styled.div `
    margin:15px 0px;
    padding:0px 15px;
    & > .wrap {
        background:${({theme})=>theme.bg2};
        color:${({theme})=>theme.color};
        padding:8px 10px;
        display:flex;
        gap:10px;
        align-items:center;
        border-radius:4px;
        border-bottom:1px solid ${({theme})=>theme.bg3};
        svg {
            font-size:22px;
            color:${({theme})=>theme.color2};
        }
        input {
            background:transparent;
            border:none;
            outline:none;
            color:inherit;
            width:100%;
        }
    }
`