import React from 'react'
import styled from 'styled-components'
import { SketchPicker } from 'react-color'
import Div from 'elements/Div'
import ToggleButton from 'components/ToggleButton'

const ShadowController = ({ value, forwardChange }) => {
    const [isToggled, setIsToggled] = React.useState(false)
    const [data, setData] = React.useState({
        x: "",
        y: "",
        blur: "",
        color: ""
    })

    React.useEffect(() => {
        if (value !== undefined && value !== "none") {
            setIsToggled(true)
            const values = value.split(' ');
            if (values.length === 4) {
                setData({
                    x: values[0],
                    y: values[1],
                    blur: values[2],
                    color: values[3]
                })
            }
        }
        else {
            setIsToggled(false)
            setData({
                x: "0px",
                y: "0px",
                blur: "0px",
                color: "#eee"
            })
        }
    }, [value])

    const [showPicker, setShowPicker] = React.useState(false)
    const itself = React.useRef()

    const handleColorChange = (color) => {
        setData({ ...data, color: color.hex })
        const combinedString = `${data.x} ${data.y} ${data.blur} ${data.color}`;
        forwardChange(combinedString)
    };

    const handleInputChange = (e, field) => {
        const { value } = e.target;
        const newValue = value;
        setData(prevData => ({
            ...prevData,
            [field]: newValue
        }));
    };
    const handleBlur = () => {
        setTimeout(() => {
            const combinedString = `${data.x} ${data.y} ${data.blur} ${data.color}`;
            forwardChange(combinedString)
        }, 150);
    };
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                const combinedString = `${data.x} ${data.y} ${data.blur} ${data.color}`;
                forwardChange(combinedString)
            }, 150);
        }
    };

    React.useEffect(() => {
        if (showPicker) {
            const picker = itself.current?.querySelector('.sketch-picker')
            const appending_bar = document.createElement('div')
            appending_bar.classList.add('appending_bar')
            appending_bar.addEventListener('click', () => setShowPicker(false))
            appending_bar.innerHTML = "✓"
            picker?.appendChild(appending_bar)

            const sketchNode = document.querySelector('.sketch-picker')
            if (sketchNode) {
                sketchNode.addEventListener('mouseleave', e => {
                    setShowPicker(false)
                })
            }
        }
    }, [showPicker])

    const handleToggle = (isToggled) => {
        if (isToggled) {
            setIsToggled(isToggled)
        } else {
            forwardChange('none')
            setIsToggled(isToggled)
        }
    }

    return (
        <Container>
            <Div c="shadow-wrap">
                <Div className='toggle-wrap' onClick={() => handleToggle(!isToggled)}>
                    <Div c='groupname'>
                        <span>Text Shadow</span>
                    </Div>
                    <ToggleButton isToggled={isToggled} setIsToggled={handleToggle} />
                </Div>
                {isToggled && <Div c='group'>
                    <Div c='item'>
                        <span>X</span>
                        <input type="text" value={data.x} onChange={(e) => handleInputChange(e, 'x')} onKeyDown={handleKeyDown} onBlur={handleBlur} />
                    </Div>
                    <Div c='item'>
                        <span>Y</span>
                        <input type="text" value={data.y} onChange={(e) => handleInputChange(e, 'y')} onKeyDown={handleKeyDown} onBlur={handleBlur} />
                    </Div>
                    <Div c='item'>
                        <span>Blur</span>
                        <input type="text" value={data.blur} onKeyDown={handleKeyDown} onBlur={handleBlur} onChange={(e) => handleInputChange(e, 'blur')} />
                    </Div>
                    <Div c='color' ref={itself}>
                        <Div c='show-color' onClick={() => setShowPicker(!showPicker)} style={{ background: `${data.color}` }}></Div>
                        <input value={data.color} type="text" onChange={(e) => handleInputChange(e, 'color')} onKeyDown={handleKeyDown} onBlur={handleBlur} />
                        {showPicker && <SketchPicker color={data.color} onChange={handleColorChange} />}
                    </Div>
                </Div>}
            </Div>
        </Container>
    )
}

export default ShadowController

const Container = styled.div`
.shadow-wrap{
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    width: 100%;
    .toggle-wrap{
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 44px;
        cursor: pointer;
        align-items: center;
        & > div {
            pointer-events: none;
        }
        .groupname{
            display: flex;
            align-items: center;
            gap: 5px;
            width: 100%;
            span{
                font-weight: 500;
                color: ${({ theme }) => theme.color};
            }
        }
    }
    .group{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 15px;
        .item{
            width: 134px;
            position: relative;
            height: 28px;
            input{
                height: 100%;
                width: 100%;
                transition:0.3s ease;
                border: none;
                padding: 0 0 0 60px;
                background: transparent;
                color: ${({ theme }) => theme.color};
                font-size: 11px;
                &:focus{
                    outline: none;
                }
            }
            span{
                color: ${({ theme }) => theme.color2};
                font-size: 11px;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                height: fit-content;
            }
        }
        .color{
            width: 134px;
            height: 28px;
            position: relative;
            .show-color{
                position:absolute;
                width: 22px;
                height: 22px;
                left:0px;
                top:0;
                bottom:0;
                margin: auto;
                border-radius: 4px;
                cursor:pointer;
                min-width:18px;
                border:1px solid ${({ theme }) => theme.sectionBorderColor};
                box-sizing:border-box;
                cursor:pointer;
            }
            input{
                width: 100%;
                height: 100%;
                border: none;
                color: ${({ theme }) => theme.color};
                padding: 0 30px;
                border-radius: 8px;
                font-size: 12px;
                background: transparent;
                &:focus{
                    outline: none;
                }
            }
            .sketch-picker{
                position:absolute;
                z-index:1;
                right:0px;
                top:calc(100% + 15px);
                padding-bottom:10px !important;
            }
            .appending_bar{
                width:25px;
                height:25px;
                border-radius:100%;
                background:#15AA0C;
                color:white;
                display:flex;
                justify-content:center;
                align-items:center;
                border:1px solid ${({ theme }) => theme.sectionBorderColor};
                cursor:pointer;
                position:absolute;
                right:20px;
                top:20px;
                font-size:10px;
                -webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
                animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
            }
        }
    }
}
`