import React from 'react';
import styled from 'styled-components';
import { getBuilderContext } from 'contexts/builder';
import Div from 'elements/Div';
import Instance from './array/instance';
import { recursiveCopy } from 'utils/recursive';
import ToggleButton from 'components/ToggleButton';
import { Back } from 'utils/svg';

const object = ({ object, forwardKey, forwardChange }) => {
    const [active, setActive] = React.useState(object.active);
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext);
    const [detail, setDetail] = React.useState(false)

    const handleActive = (val) => {
        setActive(val)
        editComponent(selectedComponent.indices, 'activate option', forwardKey, val)
    };
    const handleChange = (key, value, passedIndex, alternativeOption) => {
        const copiedObject = recursiveCopy(object.value, true)
        if (alternativeOption === 'add option sync') {
            if (!copiedObject[key].sync) copiedObject[key].sync = {}
            copiedObject[key].sync = value;
        } else if (alternativeOption === 'remove option sync') {
            delete copiedObject[key].sync
        } else {
            copiedObject[key].value = value
        }
        forwardChange(forwardKey, copiedObject)
    }

    return (
        <Container isActive={active}>
            <Div c='object-head'>
                <p onClick={() => setDetail(!detail)}>{forwardKey}</p>
                <ToggleButton isToggled={active} setIsToggled={handleActive} />
            </Div>
            {detail &&
                <Div c='object-body'>
                    <Instance forwardKey={forwardKey} index={0} object={object.value} forwardChange={handleChange} />
                </Div>
            }
        </Container>
    );
};

export default object;

const Container = styled.div`
width: 100%;
.object-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    padding: 0 20px;
    width: 100%;
    p{
        font-weight: 500;
        text-transform: capitalize;
        color: ${({ theme }) => theme.color};
        font-size: 12px;
        line-height: 20px;
        padding: 10px 0;
        width: 100%;
        cursor: pointer;
    }
}
.object-body{
    padding: 0 10px;
    & > div{
        & > .option_instance{
            & > div{
                border: none;
                & > .object-head{
                    border: none;
                }
            }
        }
    }
}
`
