import React from 'react';
import styled from 'styled-components';
import { MdLightMode, MdDarkMode } from 'react-icons/md';
import { getCustomThemeContext } from 'contexts/theme';
import Button from 'elements/Button';

const DarkModeToggle = () => {
    const { darkMode, setDarkMode } = React.useContext(getCustomThemeContext);
    return (
        <Container>
            <Button onClick={() => {
                localStorage.setItem('darkmode', !darkMode)
                setDarkMode(!darkMode)
            }}>
                {darkMode ? (
                    <MdLightMode />
                ) : (
                    <MdDarkMode />
                )}
            </Button>
        </Container>
    );
};

export default DarkModeToggle;

const Container = styled.div`
    position: fixed;
    bottom: 15px;
    right: 15px;
    svg {
        font-size: 30px;
    }
`;
