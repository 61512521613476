import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { cssUnits, getAttributeValues } from 'modules/Styles/constants';
import { containsNumbers } from 'utils/styleHelpers';
import Div from '../../elements/Div';
import { FaChevronDown } from 'react-icons/fa';

const InputNumberUnit = ({ value: initialValue, onChange: dispatchChange, attribute }) => {
    const [timeoutId2, setTimeoutId2] = React.useState();
    const debounceDuration = 100;
    const debounceDuration2 = 200;
    const [value, setValue] = React.useState('');
    const [mounted, setMounted] = React.useState(false);
    const [suggestions, setSuggestions] = React.useState(null);
    const [selectedUnit, setSelectedUnit] = React.useState(null);
    const [warningForSelectUnit, setWarningForSelectUnit] = React.useState(false);
    const [valueContainsNumber, setValueContainsNumber] = React.useState(false);
    const inputRef = React.useRef();

    React.useEffect(() => {
        if (initialValue) processValue();
    }, [initialValue]);

    const processValue = () => {
        const initialValueStr = initialValue.toString();
        if (containsNumbers(initialValueStr)) {
            const unit = initialValueStr.replace(/[0-9]/g, '');
            setWarningForSelectUnit(true);
            if (unit) {
                const foundValidUnit = cssUnits.find((u) => u === unit.toLowerCase());
                if (foundValidUnit) {
                    setSelectedUnit(unit);
                    setWarningForSelectUnit(false);
                }
            }
            setValue(initialValueStr);
            setValueContainsNumber(true);
        } else {
            setWarningForSelectUnit(false);
            setValue(initialValueStr);
            setValueContainsNumber(false);
        }
        setMounted(true);
    };
    // const [selectedUnit, setSelectedUnit] = React.useState(cssUnits[0])
    const displaySuggestions = (skipMount) => {
        if (skipMount) {
            const suggestionValues = getAttributeValues(attribute);
            setSuggestions(suggestionValues);
        } else {
            if (mounted) {
                const suggestionValues = getAttributeValues(attribute);
                setSuggestions(suggestionValues);
            }
        }
    };
    const handleFocus = () => {
        // if (!value) displaySuggestions(true);
        if (!value) debounce(() => displaySuggestions(true), debounceDuration2, timeoutId2, setTimeoutId2)();
    };
    const handleBlur = () => {
        // if(!value) dispatchChange(initialValue)
        // dispatchChange(value)
        initialValue !== value && dispatchChange(value);
        setTimeout(() => {
            // clearSuggestions()
            debounce(clearSuggestions, debounceDuration, timeoutId2, setTimeoutId2)();
        }, 150);
    };
    const handleSelectSuggestion = (argument) => {
        dispatchChange(argument);
    };
    const changeUnit = (e) => {
        const changingUnit = e.target.value;
        const restOfString = value.replace(/[0-9]/g, '');
        const numberValue = value.replace(restOfString, '');
        dispatchChange(numberValue + changingUnit);
    };
    const handleKeyDown = (e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            const restOfString = value.replace(/[0-9]/g, '');
            const numberValue = value.replace(restOfString, '');
            if (numberValue) {
                const incrementor = e.keyCode === 38 ? 1 : -1;
                dispatchChange(Number(numberValue) + incrementor + restOfString);
            }
        } else if (e.keyCode === 13) {
            dispatchChange(value)
        }
    };
    const clearSuggestions = () => {
        setSuggestions(null);
    };
    const debounce = (fn, delay, timeout, settimeout) => {
        return function (...args) {
            clearTimeout(timeout);
            settimeout(
                setTimeout(() => {
                    fn.apply(this, args);
                }, delay)
            );
        };
    };
    React.useEffect(() => {
        if (!value) {
            setValueContainsNumber(false);
            setWarningForSelectUnit(false);
            setSelectedUnit(null);
            // displaySuggestions();
            debounce(displaySuggestions, debounceDuration2, timeoutId2, setTimeoutId2)();
        } else {
            debounce(clearSuggestions, debounceDuration, timeoutId2, setTimeoutId2)();
            // initialValue !== value && dispatchChange(value);
        }
    }, [value]);
    const lookForMaskedValue = () => {
        const suggestionValues = getAttributeValues(attribute);
        let foundMasked = null
        suggestionValues.forEach((suggestion) => {
            const masked = Array.isArray(suggestion);
            if (masked) {
                if (suggestion[1] === value) foundMasked = suggestion[0];
            }
        });
        return foundMasked ?? value;
    };
    return (
        <Container data-attribute>
            <input onKeyDown={handleKeyDown} ref={inputRef} value={lookForMaskedValue()} onFocus={handleFocus} onBlur={handleBlur} onChange={(e) => setValue(e.target.value)} />
            {valueContainsNumber ? (
                <select value={warningForSelectUnit ? '' : selectedUnit} data-warning={warningForSelectUnit} onChange={changeUnit}>
                    {warningForSelectUnit ? <option>?</option> : ''}
                    {cssUnits.map((unit) => (
                        <option value={unit} key={attribute + unit}>
                            {unit}
                        </option>
                    ))}
                </select>
            ) : (
                ''
            )}
            {warningForSelectUnit ? (
                ''
            ) : (
                <FaChevronDown
                    onClick={() => {
                        inputRef.current.focus();
                        // displaySuggestions();
                        debounce(displaySuggestions, debounceDuration2, timeoutId2, setTimeoutId2)();
                    }}
                    className="chevron"
                />
            )}
            {suggestions &&
                <Div c="suggestion_wrap">
                    {suggestions.map((sug, index) => {
                        const masked = Array.isArray(sug);
                        const key = `${attribute}-${masked ? sug[1] : sug}-${index}`
                        return (
                            <Div key={key} onClick={() => handleSelectSuggestion(masked ? sug[1] : sug)}>
                                {masked ? sug[0] : sug}
                            </Div>
                        );
                    })}
                </Div>}
        </Container>
    );
};

InputNumberUnit.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    attribute: PropTypes.string,
};

export default InputNumberUnit;

const Container = styled.div`
    position: relative;
    .suggestion_wrap {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 120%;
        border-radius: 4px;
        background: ${({ theme }) => theme.bg};
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
        min-width: 100px;
        background: ${({theme}) => theme.bg2};
        & > div {
            padding: 10px 15px;
            font-size: ${({ theme }) => theme.fs};
            font-weight: 600;
            color: ${({theme}) => theme.color};
            border-radius: 4px;
            &:hover {
                background: ${({ theme }) => theme.lightMainColor};
                color: ${({theme}) => theme.mainColor};
                cursor: pointer;
            }
        }
    }
    input {
        outline: none;
        background: ${({ theme }) => theme.bg2};
        color: ${({ theme }) => theme.color};
        font-weight: 600;
        border: none;
        padding: 10px 12px;
        padding-right: 25px;
        border-radius: 4px;
        width: 100%;
    }
    .chevron {
        position: absolute;
        font-size: 10px;
        margin-top: -5px;
        right: 10px;
        top: 50%;
        cursor: pointer;
    }
    select {
        position: absolute;
        top: 50%;
        right: 10px;
        outline: none;
        background: transparent;
        border: none;
        color: ${({ theme }) => theme.color};
        margin-top: -8px;
        opacity: 0;
        font-weight: bold;
        pointer-events: none;
        transition: 0.3s ease;
        font-size: 14px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        width: 10px;
        &[data-warning='true'] {
            color: #f78022;
            opacity: 1;
            pointer-events: auto;
        }
        &::-ms-expand {
            display: none;
        }
    }
`;
