import LoaderGif from 'components/LoaderGif';
import React from 'react';
import styled from 'styled-components';

const LoadingFallback = ({height}) => {
    return (
        <Container height={height}>
            <LoaderGif/>
        </Container>
    );
};

export default LoadingFallback;

const Container = styled.div `
    font-family: sans-serif;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100vh;
    max-height: 100%;
    ${({height}) => height && `
        height:${height}px;
        max-height: ${height}px;
    `};
    flex-direction:column;
    svg{
        width: 35px;
    }
`