import Div from "elements/Div";
import React from "react";
import styled from "styled-components";
import * as ImportedModules from 'modules';
import { Close, Drag, Help } from "utils/svg";

const StyleController = ({ close, onMouseDown, onMouseMove, onMouseUp }) => {
    const [opacity, setOpacity] = React.useState(0.6);

    return (
        <Container onMouseOver={() => setOpacity(1)}
            onMouseOut={() => setOpacity(0.6)}
            style={{ opacity: opacity }}>
            <Div c="fixed" onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
                <Div c="head-wrap">
                    <Div c="icon"><Help /></Div>
                    <Div c="icon"><Drag /></Div>
                    <Div c="icon" onClick={() => close(false)}><Close /></Div>
                </Div>
            </Div>
            <Div c="main">
                <ImportedModules.Styles />
            </Div>
        </Container>
    )
}

export default StyleController

const Container = styled.div`
height: calc(100vh - 100px);
overflow-y: auto;
width: 160px;
box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.10);
background: ${({ theme }) => theme.bg};
position: absolute;
right: 40px;
top: 76px;
bottom: 24px;
z-index: 1;
border-radius: 4px;
::-webkit-scrollbar {
    display: none;
}
.fixed{
    position: fixed;
    width: 158px;
    background: ${({ theme }) => theme.bg};
    z-index: 100;
    border-radius: 4px 4px 0 0;
    cursor: move;
    .head-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        height: 40px;
        border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
        .icon{
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 4px;
            &:hover{
                background: ${({ theme }) => theme.bg2};
            }
            svg{
                width: 16px;
                height: 16px;
                path{
                    fill: ${({ theme }) => theme.color2};
                }
            }
        }
    }
}
.main{
    margin-top: 28px;
    padding: 12px 0;
    position: relative;
    height: calc(100% - 28px);
}
`