import PropTypes from 'prop-types';
import React from 'react';

export const getWebglobalContext = React.createContext();

export const WebglobalContextProvider = (props) => {
    const [settings, setSettings] = React.useState({})
    return (
        <getWebglobalContext.Provider
            value={{
                settings,
                setSettings
            }}
        >
            {props.children}
        </getWebglobalContext.Provider>
    );
};

WebglobalContextProvider.propTypes = {
    children: PropTypes.any,
};
