import React from "react";
import styled from "styled-components";

const Controller = ({ value, forwardChange }) => {
    return (
        <Container>
            <div className='box'>
                <div className={`item ${value == 'initial' && "active"}`} onClick={() => forwardChange('initial')}>none</div>
                <div className={`item ${value == 'row-reverse' && "active"}`} onClick={() => forwardChange('row-reverse')}>Row</div>
                <div className={`item ${value == 'column-reverse' && "active"}`} onClick={() => forwardChange('column-reverse')}>Column</div>
            </div>
        </Container>
    )
}

export default Controller

const Container = styled.div`
width: 100%;
.box{
    width: 100%;
    display: flex;
    padding: 2px;
    background: ${({ theme }) => theme.bg2};
    border-radius: 4px;
    .item{
        width: 50%;
        background: transparent;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: ${({ theme }) => theme.fs};
        color: ${({ theme }) => theme.color2};
        font-weight: 500;
        border: 1px solid ${({ theme }) => theme.bg2};
        line-height: 24px;
        &.active, &:hover {
            background:${({ theme }) => theme.bg3};
            color: ${({ theme }) => theme.color};
        }
    }
}
`