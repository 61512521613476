import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { Settings } from '../svg';

const IterateStates = ({ handleOnSelect, statesData: states }) => {
    console.log(states,'/?')
    return (
        <Container>
            {states && Object.keys(states).map((stateKey) => {
                const state = states[stateKey];
                return (
                    <Div c="sphere" key={stateKey}>
                        <img src={`/img/icons/${state.type}.svg`}/>
                        <Div onClick={() => handleOnSelect({ syncType: 'state', stateName: stateKey, ...state })} c="property">{stateKey} ({state.value?.toString()})</Div>
                        {/* <Div c="pointer">{pointer}</Div> */}
                    </Div>
                );
            })}
        </Container>
    );
};

export default IterateStates;

const Container = styled.div `
    .sphere{
        background:white;
        padding:4px 6px;
        width:fit-content;
        box-shadow:0px 0px 2px 1px rgba(0,0,0,0.1);
        border-radius:4px;
        margin-bottom:8px;
        display:flex;
        align-items:center;
        gap:4px;
        & > .property {
            font-weight:500;
            opacity:0.7;
            cursor:pointer;
            &:hover{
                opacity:1;
            }
        }
        & > img {
            width:20px;
            height:20px;
        }
    }
`