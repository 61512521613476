import DeviceSelection from 'components/DeviceSelection';
import React from 'react';
import styled from 'styled-components';

const HeadingMiddle = () => {
    return (
        <Container>
            <DeviceSelection />
        </Container>
    );
};

export default HeadingMiddle;

const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: fit-content;
`
