import { CrossDomainContextProvider } from 'contexts/crossDomain';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import LoadingFallback from './LoadingFallback';
import request from 'utils/request';
import { getWebglobalContext } from 'contexts/webglobal';
import { useSearchParams } from 'react-router-dom';
import { tranformSingleSource, transformSource } from 'siro_lib';

const PageEntry = () => {
    const [compositionData, setCompositionData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const { settings, setSettings } = React.useContext(getWebglobalContext);
    const { id: webId } = useParams();
    const [searchParams] = useSearchParams();
    const slug = searchParams.get('slug');
    const id = searchParams.get('id');
    const type = searchParams.get('type');
    const sourceId = searchParams.get('sourceId');
    const sourceType = searchParams.get('sourceType');

    React.useEffect(() => {
        fetchComposition();
    }, [slug, id, type, sourceId, sourceType]);

    const fetchComposition = async () => {
        try {
            setLoading(true)
            let query = type === 'page' ? `page_slug=${slug}` : `id=${id}`
            const base = type === 'inapp' ? window.urls.notification : window.urls.web
            const result = await request({ url: base + `/${type}?${query}`, headers: { 'webId': `${webId}` } });
            const composition = result.data?.data ?? []

            const pageData = {}
            if (result.data.isProtected) {
                const processedUser = transformSource({ sourceType: 'User', sourceDetail: { dummy: true } })
                pageData.user = processedUser
                const processedOrder = transformSource({ sourceType: 'My orders', sourceDetail: { dummy: true } })
                pageData.order = processedOrder
            }
            if (sourceType == 'collection') {
                const url = window.urls.content + `/collection/cd/getCd`
                const collectionFetch = await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({ _id: sourceId }),
                    headers: {
                        'Content-Type': 'application/json',
                        'webId': webId,
                    },
                })

                const collectionData = await collectionFetch.json()
                const processedCollection = tranformSingleSource({ sourceType: 'Collection', data: collectionData.data })
                pageData.collection = { type: 'object', value: processedCollection }
            }
            if (sourceType == 'product' && sourceId) {
                const url = window.urls.product + `/product` + `/${sourceId}`;
                const productFetch = await fetch(url, {
                    method: 'GET',
                    headers: { 'webId': webId },
                })
                const productData = await productFetch.json()
                const processedProduct = tranformSingleSource({ sourceType: 'Product', data: productData.data })
                pageData.product = { type: 'object', value: processedProduct }
            }
            if (sourceType == 'order') {
                const processedOrder = transformSource({ sourceType: 'Order', fetchedData: {}, sourceDetail: { dummy: true } })
                pageData.OrderDetail = processedOrder
            }

            setSettings({ ...settings, [type]: { ...result.data }, compositionReady: true, detailedData: pageData })
            setCompositionData(Array.isArray(composition) ? composition : [])
        } catch (e) {
            console.log(e, 'fetchComposition')
            setCompositionData([])
            setSettings({})
        } finally {
            setLoading(false)
        }
    };

    return loading ? (
        <LoadingFallback />
    ) : (
        <CrossDomainContextProvider initialData={compositionData}>
            <Outlet />
        </CrossDomainContextProvider>
    );
};

export default PageEntry;
