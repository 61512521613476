import React from 'react';
import styled from 'styled-components';
import useSync from 'utils/useSync';

const Text = ({ object, forwardKey, forwardChange }) => {
    const [value, setValue] = React.useState(object.value)
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: object.sync, operation: { type: 'option', forwardKey, forwardChange } })
    const handleChange = (e) => {
        setValue(Number(e.target.value))
        forwardChange(forwardKey, Number(e.target.value))
    }
    return (
        <Container>
            <div className='caption'>{forwardKey}</div>
            {object.sync ? syncElement() : (
                <div className='flex'>
                    <input type='number' value={value} onChange={handleChange} />
                    {syncTrigger()}
                </div>
            )}
            {syncSuggestion()}
        </Container>
    );
};

export default Text;

const Container = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items:center;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.flex{
    display: flex;
    input{
        border: none;
        background: ${({ theme }) => theme.bg2};
        padding: 8px 12px;
        border-radius: 4px;
        color: ${({ theme }) => theme.color};
        font-weight: 500;
        max-width: 180px;
        &:focus{
            outline: none;
        }
    }
}
`