import Button from "elements/Button";
import Div from "elements/Div";
import React from "react";
import styled from "styled-components";

const InappEditor = ({ closePopup }) => {
    const containerRef = React.useRef()

    return (
        <Container onClick={(e) => e.target === containerRef.current && closePopup()} ref={containerRef}>
            <Div c="main-content">
                <Div c="title">Inapp</Div>
                <Div c='content-wrap'>
                    xD
                </Div>
                <Div c="footer">
                    <Button c="cancel" onClick={() => closePopup()}>Close</Button>
                    <Button c="save">Save</Button>
                </Div>
            </Div>
        </Container>
    )
}

export default InappEditor

const Container = styled.div`
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
position: fixed;
left: 0;
top: 0;
background: rgba(0, 0, 0, 0.5);
z-index: 1000;
animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
& > .main-content{
    background: ${({ theme }) => theme.popupBg};
    width: calc(100vw - 100px);
    height: calc((100vw - 100px) * 0.715655);
    max-height: calc(100vh - 100px);
    max-width: calc((100vh - 100px) * 1.39732);
    padding: 50px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-between;
    .title {
        color: ${({ theme }) => theme.color};
        font-weight: 600;
        font-size: ${({ theme }) => theme.fs4};
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;
    }
    .content-wrap{
        height: calc(100% - 140px);
    }
    .footer{
        display: flex;
        gap: 10px;
        justify-content: end;
        width: 100%;
        button {
            text-transform: uppercase;
            background-color: transparent;
            transition: background-color 0.3s;
            padding: 11px 22px;
            border-radius: 0;
            color: ${({ theme }) => theme.color};
            &.save{
                background-color: ${({ theme }) => theme.color};
                color: ${({ theme }) => theme.bg};
            }
            &.cancel{
                &:hover{
                    background-color: ${({ theme }) => theme.bg2};
                }
            }
        }
    }
}
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
`