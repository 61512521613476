import React from 'react';
import InpsectLayers from './InspectLayers';
import { getCrossDomainContext } from 'contexts/crossDomain';

const Layers = () => {
    const {frame} = React.useContext(getCrossDomainContext)
    return frame ? <InpsectLayers /> : '';
};

export default Layers;
