import AttributeGroup from 'modules/Styles/AttributeGroup';
import { getBuilderContext } from 'contexts/builder';
import React from 'react';
import ComponentNotSelected from './NotSelected';
import SearchModule from 'components/SearchModule';
import ClassList from './ClassList';

const Styles = ({ modules }) => {
    const { selectedComponent } = React.useContext(getBuilderContext);
    const [searchTerm, setSearchTerm] = React.useState('');
    const attributeGroup = [
        {
            name: "Appearance",
            attributes: [
                { component: 'BackgroundColor', name: 'Background Color' },
                { component: 'ObjectFit', name: 'Object Fit' },
                { component: 'Zindex', name: 'Z Index' },
                { component: 'Display', name: 'Element' },
            ]
        },
        {
            name: "Layout",
            attributes: [
                { component: 'Spacing', name: 'Alignment' },
                { component: 'Direction', name: 'Direction' },
                { component: 'Gap', name: 'Gap' },
                { component: 'Align', name: 'Padding' },
                { component: 'Wrap', name: 'Wrap' },
                { component: 'FlexDirectionReverse', name: 'Direction Reverse' }
            ]
        },
        {
            name: "Size",
            attributes: [
                { component: 'Width', name: 'Width' },
                { component: 'Height', name: 'Height' },
            ]
        },
        {
            name: "Typography",
            attributes: [
                { component: 'FontAlign', name: 'Text Alignment' },
                { component: 'TextTransform', name: 'Capitalize' },
                { component: 'FontColor', name: 'Font color' },
                { component: 'FontSize', name: 'Font size' },
                { component: 'FontFamily', name: 'Font name' },
                { component: 'FontWeight', name: 'Font weight' },
                { component: 'LineHeight', name: 'Line height' },
                { component: 'LetterSpacing', name: 'Letter Spacing' },
                { component: 'TextDecoration', name: 'Text Decoration' },
                { component: 'WhiteSpace', name: 'White Space' },
            ]
        },
        {
            name: "Styles",
            attributes: [
                { component: 'Overflow', name: 'Overflow' },
                { component: 'Opacity', name: 'Opacity' },
                { component: 'BoxShadow', name: '' },
                { component: 'TextShadow', name: '' },
                { component: 'Rotate', name: 'Rotation' },
                { component: 'Scale', name: 'Scale' },
                { component: 'Transform', name: 'Transform' },
                { component: 'Cursor', name: 'Cursor' },
                { component: 'Blur', name: 'Blur' },
                { component: 'PointerEvents', name: 'Pointer Events' },
            ]
        },
        {
            name: "Background",
            attributes: [
                { component: 'BackgroundImage', name: 'Background Image' },
                { component: 'BackgroundRepeat', name: 'Background Repeat' },
                { component: 'BackgroundSize', name: 'Background Size' },
                { component: 'BackgroundPosition', name: 'Background Position' }
            ]
        },
        {
            name: "Border",
            attributes: [
                { component: 'Border', name: '' },
                { component: 'BorderRadius', name: '' }
            ]
        },
        {
            name: "Transition",
            attributes: [
                { component: 'TransitionProperty', name: 'Property' },
                { component: 'TransitionTimingFunction', name: 'Timing function' },
                { component: 'TransitionDelay', name: 'Delay' },
                { component: 'TransitionDuration', name: 'Duration' },
            ]
        },
        {
            name: "Type",
            attributes: [
                { component: 'Position', name: 'Position' },
                { component: 'Top', name: 'Top' },
                { component: 'Bottom', name: 'Bottom' },
                { component: 'Left', name: 'Left' },
                { component: 'Right', name: 'Right' }
            ]
        },
        {
            name: "Max-*",
            attributes: [
                { component: 'MaxWidth', name: 'Max Width' },
                { component: 'MinWidth', name: 'Min Width' },
                { component: 'MaxHeight', name: 'Max Height' },
                { component: 'MinHeight', name: 'Min Height' },
            ]
        },
        {
            name: "Others",
            attributes: [
                { component: 'CustomCellCount', name: 'Grid Cell Count' },
                { component: 'CustomGap', name: 'Grid Horizontal Gap' },
                { component: 'CustomGapVertical', name: 'Grid Vertical Gap' },
                { component: 'CustomMaxWidth', name: 'Grid Max Width' },
                { component: 'AutoScale', name: 'Auto Scale' },
                { component: 'CountPerRow', name: 'Row Count' },
                { component: 'RepeatGap', name: 'Repeat Gap' },
            ]
        },
        {
            name: "Padding",
            attributes: [
                { component: 'PaddingTop', name: 'Padding Top' },
                { component: 'PaddingBottom', name: 'Padding Bottom' },
                { component: 'PaddingLeft', name: 'Padding Left' },
                { component: 'PaddingRight', name: 'Padding Right' }
            ],
        },
    ]
    const filteredAttributes = attributeGroup.filter((group) =>
        group.attributes.some(
            (attribute) =>
                attribute.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                attribute.component.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    const attributeGroupFiltered = modules
        ? modules.selectedComponentValue.reduce((filtered, componentName) => {
            const foundGroup = attributeGroup.find(group =>
                group.attributes.some(attribute => attribute.component === componentName)
            );
            if (foundGroup) {
                const filteredAttributes = foundGroup.attributes.filter(({ component }) =>
                    componentName === component
                );
                if (filteredAttributes.length > 0) {
                    filtered.push({
                        ...foundGroup,
                        attributes: filteredAttributes,
                    });
                }
            }
            return filtered;
        }, [])
        : attributeGroup;

    return selectedComponent ? (
        <React.Fragment>
            <SearchModule width={modules ? '318px' : '158px'} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <div style={{ paddingTop: '43px' }}>
                <ClassList />
                {searchTerm ? filteredAttributes.map((group, i) => (
                    <AttributeGroup key={i} name={modules ? '' : group.name} attributes={group.attributes.filter(
                        (attribute) =>
                            attribute.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            attribute.component.toLowerCase().includes(searchTerm.toLowerCase())
                    )} />
                )) : (modules ? attributeGroupFiltered : attributeGroup).map((group, i) => (
                    <AttributeGroup key={i} name={modules ? '' : group.name} attributes={group.attributes} />
                ))}
            </div>
        </React.Fragment>
    ) : (
        <ComponentNotSelected />
    );
};

export default Styles;