import { getBuilderContext } from 'contexts/builder';
import React from 'react';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getCustomThemeContext } from 'contexts/theme';
import { getReferenceContext } from 'contexts/reference';

const SelectElement = () => {
    const { selectedComponent, setContext } = React.useContext(getBuilderContext)
    const { Selected: itself } = React.useContext(getReferenceContext)
    const { frame, frameUpdate, frameScreen } = React.useContext(getCrossDomainContext)
    const {theme} = React.useContext(getCustomThemeContext)
    const [isGrid, setIsGrid] = React.useState(false)
    const doc = frame.document

    const drawShape = () => {
        clearShape()
        if(!selectedComponent) return null
        const target = doc.querySelector(`[data-track="${selectedComponent.indices}"]`)
        if(!target) return console.warn('Target not selected!')
        const { width, height, top, left } = target.getBoundingClientRect();
        itself.current.style.width = `${width}px`
        itself.current.style.height = `${height}px`
        itself.current.style.top = `${top + frame.scrollY}px`
        itself.current.style.left = `${left + frame.scrollX}px`

        const selectHelperWrap = doc.createElement('div')
        selectHelperWrap.setAttribute('id', 'selectHelperWrap')
        if(height < 50) selectHelperWrap.style.top = `${top + frame.scrollY + height}px`
        else selectHelperWrap.style.top = `${top + frame.scrollY + height - 22}px`
        selectHelperWrap.style.left = `${left + frame.scrollX}px`
        const titleNode = doc.createElement('div')
        titleNode.setAttribute('id', 'titleNode')
        if(selectedComponent.name === selectedComponent.component) {
            titleNode.innerText = selectedComponent.name
        } else {
            titleNode.innerText = `${selectedComponent.name} (${selectedComponent.component})`
        }
        selectHelperWrap.appendChild(titleNode)
        const repeatable = target.getAttribute('data-repeatable')
        if(repeatable === "true") {
            const repeatableWrap = doc.createElement('div')
            repeatableWrap.setAttribute('id', 'repeatableWrap')
            repeatableWrap.innerText = `↻`;
            repeatableWrap.addEventListener('click', handleRepeatClick)
            selectHelperWrap.appendChild(repeatableWrap)
        }
        doc.body.appendChild(selectHelperWrap)
    }
    const handleRepeatClick = () => {
        const target = doc.querySelector(`[data-track="${selectedComponent.indices}"]`)
        setContext({repeatable: selectedComponent.indices})
    }
    const clearShape = () => {
        itself.current.style.width = `unset`
        itself.current.style.height = `unset`
        itself.current.style.top = `unset`
        itself.current.style.left = `unset`

        itself.current.innerHTML = ''

        doc.querySelector('#selectHelperWrap')?.remove()
    }

    const appendNodeTags = () => {
        doc.querySelector('#bl-select')?.remove()
        doc.querySelector('#bl-select-style')?.remove()
        const shape = doc.createElement('div')
        const id = 'bl-select'
        shape.setAttribute('id', id)
        const styleTag = doc.createElement('style')
        styleTag.setAttribute('id', `${id}-style`)
        styleTag.innerText = `
            #${id} {
                position:absolute;
                pointer-events:none;
                box-sizing:border-box;
                border:2px solid ${isGrid ? theme.mainColor2 : theme.mainColor};
                opacity:1;
                z-index:1000;
            }
            #${id}:after {
                content:"";
                display:block;
                width:100%;
                height:100%;
                // background: ${theme.mainColor};
                opacity:0.1;
                position:absolute;
                top:0;
                left:0;
                z-index:1000;
            }
            #titleNode {
                display:block;
                padding:4px 8px;
            }
            #repeatableWrap {
                padding:4px 8px;
                border-left:1px solid white;
                cursor:pointer;
            }
            #selectHelperWrap {
                display:block;
                background:${isGrid ? theme.mainColor2 : theme.mainColor};
                width:fit-content;
                font-size:12px;
                color:white;
                font-family: sans-serif;
                position:absolute;
                pointer-events:auto;
                display:flex;
                align-items:center;
                z-index:1000;
            }
        `
        doc.body.appendChild(shape)
        doc.head.appendChild(styleTag)
        itself.current = shape
    }

    React.useEffect(() => {
        appendNodeTags()
    }, [isGrid, selectedComponent])

    React.useEffect(() => {
        if(selectedComponent) {
            drawShape()
            const target = doc.querySelector(`[data-track="${selectedComponent.indices}"]`)
            const depth = target?.getAttribute('data-depth');
            const targetParent = target?.parentElement
            const parentKind = targetParent?.getAttribute('kind');
            if(depth === "1" || parentKind === 'grid') setIsGrid(true)
            else setIsGrid(false)
        }
        else clearShape()
    }, [selectedComponent, frameUpdate, frameScreen, isGrid])
    return null
};

export default SelectElement;