import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';

const ClassList = () => {
    const { selectedComponent, editComponent, selectComponent, setContext } = React.useContext(getBuilderContext);
    const { frame } = React.useContext(getCrossDomainContext);
    const [addNew, setAddNew] = React.useState(false);
    const [newClassName, setNewClassName] = React.useState('');
    const input = React.useRef();
    const spreadClass = { ...(selectedComponent.style?.class ?? {}) };
    React.useEffect(() => {
        setNewClassName('');
        input.current?.focus();
    }, [addNew]);
    const handleAddClass = () => {
        if (!newClassName) return console.warn('Cannot add empty class');
        if (newClassName === 'hover') console.warn('Cannot name a class hover');
        editComponent(selectedComponent.indices, 'addClass', newClassName);
        setAddNew(false);
        setNewClassName('');
    };
    const handleEmitClass = (className) => {
        const alreadyExists = checkClassActivated(className);
        const root = frame.document.querySelector('#root');
        if (alreadyExists) {
            //Cleanup content class if exists
            root.removeAttribute('contentclass');
            //Set context contentclass to reflect its change accordingly
            setContext({ contentclass: null });
            //To force reload, mannually emit select function
            selectComponent(selectedComponent.indices);
            return null;
        }
        root.setAttribute('contentclass', `${selectedComponent.indices}||${className}`);
        //Set context contentclass to reflect its change accordingly
        setContext({ contentclass: `${selectedComponent.indices}||${className}` });
        //To force reload, mannually emit select function
        selectComponent(selectedComponent.indices);
    };
    const checkClassActivated = (argClass) => {
        const root = frame.document.querySelector('#root');
        const contentclass = root.getAttribute('contentclass');
        if (!contentclass) return null;
        const [indices, className] = contentclass.split('||');
        if (!indices || !className) return null;
        if (selectedComponent.indices === indices && className === argClass) {
            return true;
        }
    };
    const handleHoverInit = () => {
        if (!selectedComponent.style.class) {
            editComponent(selectedComponent.indices, 'addClass', 'hover');
        }
        if (!selectedComponent.style.class.hover) {
            editComponent(selectedComponent.indices, 'addClass', 'hover');
        }
        handleEmitClass('hover');
    };
    return (
        <Container>
            <Button open={checkClassActivated('hover')} onClick={handleHoverInit} c="hoverToggle">
                :hover
            </Button>
            <Div c="classList">
                {Object.keys(spreadClass).map((className) => {
                    if (className !== 'hover')
                        return (
                            <Button
                                open={checkClassActivated(className)}
                                onClick={() => handleEmitClass(className)}
                                c="instance"
                                key={selectedComponent.indices + className}
                            >
                                {className} <AiOutlineClose />
                            </Button>
                        );
                })}
                {addNew && (
                    <input
                        onBlur={() => !newClassName && setAddNew(false)}
                        ref={input}
                        value={newClassName}
                        onChange={(e) => setNewClassName(e.target.value)}
                    />
                )}
                {addNew ? (
                    <Button c="confirm" onClick={() => handleAddClass()}>
                        <AiOutlineCheck />
                    </Button>
                ) : (
                    <Button c="add" onClick={() => setAddNew(true)}>
                        +
                    </Button>
                )}
            </Div>
        </Container>
    );
};

export default ClassList;

const Container = styled.div`
    padding: 10px 15px;
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    display: flex;
    align-items: center;
    & > .hoverToggle {
        margin-right: 10px;
        padding-right: 10px;
        border-radius: 0px;
        border-right: 1px solid ${({ theme }) => theme.sectionBorderColor};
        min-width: 55px;
        &[open] {
            color: ${({ theme }) => theme.mainColor};
            font-weight: 600;
        }
    }
    & > .classList {
        border: 1px solid ${({ theme }) => theme.sectionBorderColor};
        border-radius: 4px;
        padding: 4px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        & > .instance {
            padding: 0px 4px;
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.sectionBorderColor};
            opacity: 0.7;
            display: flex;
            align-items: center;
            gap: 1px;
            svg {
                color: red;
            }
            &[open] {
                background: ${({ theme }) => theme.mainColor};
                color: white;
                opacity: 1;
            }
        }
        & > .add {
            font-size: 12px;
        }
        & > .confirm {
            color: green;
            font-size: 12px;
            padding: 0px;
        }
        input {
            border: none;
            outline: none;
            width: 50px;
        }
    }
`;
