import { getBuilderContext } from 'contexts/builder';
import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import EventPopup from './EventPopup';
import ActionPopup from '../ActionTypes/ActionPopup';
import { FiPlus } from "react-icons/fi";
import { Close, Mouse, Trash } from 'utils/svg';
import { FiMousePointer } from "react-icons/fi";

const index = () => {
    const { selectedComponent, editComponent } = React.useContext(getBuilderContext)
    const [popupInstance, setPopupInstance] = React.useState(null)
    const [actionInstance, setActionInstance] = React.useState(null)
    const spreadEvent = selectedComponent?.events ?? {}
    const handleActionChange = (actionObject) => {
        const { _eventName, _indices } = actionObject
        delete actionObject._eventName
        delete actionObject._indices
        const existingEvents = [...spreadEvent[_eventName]]
        if (actionObject.update) {
            const matchingEventIndex = existingEvents.findIndex(event =>
                event.stateName === actionObject.stateName && event.type === actionObject.type
            )
            if (matchingEventIndex !== - 1) {
                existingEvents[matchingEventIndex] = actionObject;
            } else {
                existingEvents[actionObject.position] = actionObject;
            }
        } else {
            existingEvents.push({ ...actionObject })
        }
        editComponent(_indices, 'events', _eventName, existingEvents)
    }
    const handleDelete = (eventName) => {
        if (!window.confirm('Are you sure to delete it?')) return null
        editComponent(selectedComponent.indices, 'delete event', eventName)
    }
    const handleActionDelete = (eventName, index) => {
        const existingEvents = [...spreadEvent[eventName]]

        if (existingEvents) {
            if (!window.confirm('Are you sure to delete it?')) return null
            existingEvents.splice(index, 1)
            editComponent(selectedComponent.indices, 'events', eventName, existingEvents)
        }
    }

    return (
        <Container>
            <Div c='head-wrap'>
                <Div c="caption">Events settings</Div>
                <Button c="add-event" onClick={() => setPopupInstance({})}>
                    <FiPlus />
                </Button>
            </Div>
            <Div c="events-wrap">
                {Object.keys(spreadEvent).length > 0 ? Object.keys(spreadEvent).map((eventName) => {
                    const indices = selectedComponent.indices
                    const actions = spreadEvent[eventName]
                    return (
                        <Div c="each" key={indices + eventName}>
                            <Div c='top'>
                                <Div c='left'>
                                    <FiMousePointer />
                                    <span>{eventName}</span>
                                </Div>
                                <Div c='trash'>
                                    <Trash className='delete' onClick={() => handleDelete(eventName)} />
                                </Div>
                            </Div>
                            <Div c="action-wrap">
                                <Div c='actions'>
                                    {actions.map((action, i) => <Div c='box' key={i}>
                                        <span onClick={() => setActionInstance({ position: i, update: true, _eventName: eventName, _indices: indices, ...action })}>{action.type}</span>
                                        <Close onClick={() => handleActionDelete(eventName, i)} />
                                    </Div>)}
                                </Div>
                                <Button c="add-action" onClick={() => setActionInstance({ _eventName: eventName, _indices: indices })}>
                                    Add action +
                                </Button>
                            </Div>
                        </Div>
                    )
                }) : <Div c='text-wrap'>
                    <Mouse />
                    <span>Select an element on the builder, then click + above to animate the selected element when a user interacts with it (such as on hover or click)</span>
                </Div>}
            </Div>
            {actionInstance && (
                <ActionPopup instance={actionInstance} setInstance={setActionInstance} dispatchChange={handleActionChange} />
            )}
            {popupInstance && <EventPopup instance={popupInstance} setInstance={setPopupInstance} />}
        </Container>
    );
};

export default index;

const Container = styled.div`
padding: 0 20px;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.head-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    .caption {
        font-weight: 500;
        text-transform: capitalize;
        color: ${({ theme }) => theme.color};
        font-size: 12px;
        line-height: 20px;
    }
    .add-event {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        transition: all 0.3s linear;
        border-radius: 4px;
        svg{
            width: 16px;
            height: 16px;
        }
        &:hover{
            background: ${({ theme }) => theme.bg2};
            svg{
                path{
                    fill: ${({ theme }) => theme.color2};
                }
            }
        }
    }
}
& > .events-wrap {
    .text-wrap{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
        border-radius: 4px;
        padding: 15px 0;
        & > svg{
            path{
                fill: ${({ theme }) => theme.color2};
            }
        }
        span{
            font-size: 12px;
            color: ${({ theme }) => theme.color2};
            text-align: center;
            width: 80%;
            line-height: 1.3;
        }
    }
    & > .each {
        border:1px solid ${({ theme }) => theme.sectionBorderColor};
        margin-bottom: 10px;
        & > .top {
            padding: 8px 12px;
            display:flex;
            align-items:center;
            justify-content:space-between;
            border-bottom: 1px solid ${({ theme }) => theme.bg2};
            .left{
                display:flex;
                align-items:center;
                gap:8px;
                .text{
                    font-weight: 500;
                    text-transform: uppercase;
                    color: ${({ theme }) => theme.color2};
                    font-size: 10px;
                    line-height: 20px;
                }
                svg {
                    width:16px;
                    height: 16px;
                }
            }
            .trash{
                width: 30px;
                height: 30px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;
                transition: all 0.3s linear;
                border-radius: 4px;
                svg{
                    width: 16px;
                    height: 16px;
                }
            }
            &:hover{
                .trash{
                    background: ${({ theme }) => theme.bg2};
                    svg{
                        path{
                            fill: ${({ theme }) => theme.color2};
                        }
                    }
                }
            }
        }
        .action-wrap{
            .add-action{
                width: 100%;
                padding: 15px;
                border-radius: 0;
                text-transform: uppercase;
                font-weight: 600;
                color: ${({ theme }) => theme.color};
                font-size: 10px;
            }
            .actions{
                display: flex;
                padding: 8px 12px;
                flex-wrap: wrap;
                gap: 10px;
                .box{
                    border-radius: 20px;
                    padding: 5px 20px;
                    background-color: ${({ theme }) => theme.bg2};
                    transition: background-color .2s,color .2s;
                    position: relative;
                    z-index:1;
                    overflow: hidden;
                    cursor: pointer;
                    span{
                        position: relative;
                        right: 0;
                        right .2s cubic-bezier(.32,.94,.6,1)
                    }
                    svg{
                        width: 14px;
                        height: 14px;
                        position: absolute;
                        right: 2px;
                        transition: all .2s cubic-bezier(.4,0,.68,.06);
                        transform: rotate(45deg);
                        opacity: 0;
                    }
                    &:hover{
                        span{
                            right: 7px;
                        }
                        svg{
                            right: 7px;
                            opacity: 1;
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
    }
}
`;
