import ComponentList from 'components/ComponentList';
import MySaved from 'components/MySaved';
import Tabs from 'components/Tabs';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import DictionaryElement from 'utils/dictionaryElement';
import { recursiveCopy } from 'utils/recursive';
import { Search } from 'utils/svg';
import { useParams } from 'react-router-dom';
import request from 'utils/request';
import { populateElements } from 'utils/vanillaFunctions';
import { reduceStructure } from 'modules/Insert/helpers';
import Library from 'siro_lib';
const form = Library.Form;
const basics = Library.Basics

const AddBlockPopup = ({ closePopup }) => {
    const elements = reduceStructure({ ...form }, 'Form');
    const basicElements = reduceStructure({ ...basics }, 'Basics')
    const popupRef = React.useRef()
    const { frame } = React.useContext(getCrossDomainContext)
    const { selectedComponent, addComponent } = React.useContext(getBuilderContext)
    const populatedElements = populateElements()
    const [searchTerm, setSearchTerm] = React.useState('')
    const [searchResults, setSearchResults] = React.useState([])
    const { id: webId } = useParams();
    const [formList, setFormList] = React.useState([]);
    const fetchFormList = async () => {
        const result = await request({
            method: 'post',
            url: window.urls.content + `/form`,
            body: { status: 'active' },
            headers: { webId },
        });
        setFormList(result.data);
    }

    React.useEffect(() => {
        repositionPopup()
        fetchFormList()
        document.addEventListener('click', watchClickParent)
        frame.document.addEventListener('click', watchClickFrame)
        return () => {
            document.removeEventListener('click', watchClickParent)
            frame.document.removeEventListener('click', watchClickFrame)
        }
    }, [])

    const repositionPopup = () => {
        // Get positioning info
        const mainFrame = document.querySelector('#main-frame')
        const doc = frame.document
        if (!doc || !selectedComponent) return null
        const targetSection = doc.querySelector(`[kind][data-track="${selectedComponent.indices}"]`)
        if (!targetSection) return null
        const targetRect = targetSection.getBoundingClientRect();
        if (targetRect.top * -1 >= 0) {
            popupRef.current.style.top = `${100 + mainFrame.offsetTop}px`
        }
        else {
            const popupBottom = targetRect.top + 100 + mainFrame.offsetTop + popupRef.current.offsetHeight
            if(popupBottom > doc.documentElement.offsetHeight) {
                const remainderHeight = popupBottom - doc.documentElement.offsetHeight
                popupRef.current.style.top = `${targetRect.top + 100 + mainFrame.offsetTop - remainderHeight}px`
            } else {
                popupRef.current.style.top = `${targetRect.top + 100 + mainFrame.offsetTop}px`
            }
        }
    }
    const watchClickParent = e => {
        const elements = [...document.elementsFromPoint(e.clientX, e.clientY)]
        const clickInPopup = elements.find(el => el === popupRef.current)
        if (!clickInPopup) return closePopup()
    }
    const watchClickFrame = (e) => {
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        const blSectionMenu = e.target.getAttribute('bl-section-menu')
        if (!blSectionMenu) return closePopup()
        if (blSectionMenu !== 'add-block-button') return closePopup()
    }
    const handleAddBlock = (component) => {
        const copiedElement = recursiveCopy(component, true)
        if (copiedElement.component === 'Button') copiedElement.style.desktop.gridArea = `1 / 2 / 3 / 7`
        if (copiedElement.component === 'Image') copiedElement.style.desktop.gridArea = `1 / 2 / 7 / 8`
        if (copiedElement.component === 'Icon') copiedElement.style.desktop.gridArea = `1 / 2 / 2 / 3`
        if (copiedElement.component === 'Video') copiedElement.style.desktop.gridArea = `1 / 2 / 7 / 9`
        if (copiedElement.component === 'Link') copiedElement.style.desktop.gridArea = `1 / 2 / 2 / 4`
        if (copiedElement.component === 'Text') copiedElement.style.desktop.gridArea = `1 / 2 / 3 / 8`
        if (copiedElement.component === 'Box') copiedElement.style.desktop.gridArea = `1 / 2 / 5 / 6`
        addComponent(copiedElement, selectedComponent.indices, 'a')
        closePopup()
    }
    const search = () => {
        const results = populatedElements.reduce((acc, curr) => {
            const filteredComponents = curr.components.filter((component) => {
                const componentName = component.name ? component.name.toLowerCase() : '';
                const searchTermLowerCase = searchTerm ? searchTerm.toLowerCase() : '';
                return componentName.includes(searchTermLowerCase);
            });

            if (filteredComponents.length > 0) {
                acc.push({ ...curr, components: filteredComponents });
            }
            return acc;
        }, []);
        setSearchResults(results);
    }
    const handleInputChange = (e) => {
        const { value } = e.target;
        setSearchTerm(value);
        if (value.trim() !== '') {
            search();
        } else {
            setSearchResults([]);
        }
    }

    const processFormComponent = (instance) => {
        const children = instance._source.fields.reduce((acc, cur) => {
            //Generate label
            const label = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Label')))
            label.options.text.value = cur.label

            //Generate base input
            let base = null
            if (cur.type === 'stringInput') base = elements.find(e => e.name === 'Text')
            else if (cur.type === 'textArea') base = elements.find(e => e.name === 'TextArea')
            else if (cur.type === 'dropdownSelect') base = elements.find(e => e.name === 'Select')
            else if (cur.type === 'number') base = elements.find(e => e.name === 'Text')
            else if (cur.type === 'date') base = elements.find(e => e.name === 'DateInput')
            else if (cur.type === 'checkbox') {
                base = elements.find(e => e.name === 'CheckGroup')
                base.children = cur.choice.reduce((cAcc, cCur) => {
                    const child = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Checkbox')))
                    child.options.choiceKey.value = cCur.choiceKey
                    child.options.label.value = cCur.label
                    child.options.value.value = cCur.value
                    child.options.name.value = cur.label
                    cAcc.push(child)
                    return cAcc
                }, [])
            }
            else if (cur.type === 'radio') {
                base = elements.find(e => e.name === 'RadioGroup')
                base.children = cur.choice.reduce((cAcc, cCur) => {
                    const child = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Radio')))
                    child.options.choiceKey.value = cCur.choiceKey
                    child.options.label.value = cCur.label
                    child.options.value.value = cCur.value
                    child.options.name.value = cur.label
                    child.options.radioHook.value = cur.key
                    cAcc.push(child)
                    return cAcc
                }, [])
            }
            //Clone to unfreeze nested objects
            const copiedBase = JSON.parse(JSON.stringify(base))
            //If no base component found, skip and continue generation
            if (!copiedBase) return acc
            //Fill up the contextual options
            copiedBase.options.formHook.value = cur.key
            if (copiedBase.options.name) copiedBase.options.name.value = cur.label
            copiedBase.options.required.value = cur.required
            // copiedBase.options.required.value = true
            //If the current iteration is select input, fill up the options
            if (cur.type === 'dropdownSelect') {
                copiedBase.options.options = cur.choice.reduce((cAcc, cCur) => {
                    cAcc.push({
                        disabled: { type: 'boolean', value: false },
                        selected: { type: 'boolean', value: false },
                        label: { type: 'text', value: cCur.label },
                        value: { type: 'text', value: cCur.choiceKey },
                    })
                    return cAcc
                }, [])
                //Insert selected null value
                // copiedBase.options.options.unshift({
                //     disabled: { type: 'boolean', value: true },
                //     selected: { type: 'boolean', value: true },
                //     label: { type: 'text', value: 'Choose one'},
                //     value: { type: 'text', value: ''},
                // })
            }

            const box = JSON.parse(JSON.stringify(basicElements.find(e => e.name === 'Box')))
            box.style.desktop.paddingLeft = "0px"
            box.style.desktop.paddingRight = "0px"
            box.style.desktop.paddingTop = "0px"
            box.style.desktop.paddingBottom = "0px"
            box.children = []
            box.children.push(label)
            box.children.push(copiedBase)
            acc.push(box)

            //Return accumulator and continue
            return acc
        }, []);

        //After all elements are setup, insert submit button
        const submit = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Submit')))
        children.push(submit)

        //Set up the main form component
        const component = JSON.parse(JSON.stringify(elements.find(e => e.name === 'Form')))
        component.options.webId.value = instance._source.webId
        component.options.formId.value = instance._id
        component.name = instance._source.title
        component.children = children
        addComponent(component, selectedComponent.indices, 'a')
        closePopup()
    }

    return (
        <Container ref={popupRef}>
            <Tabs>
                <Div label="Basic">
                    <Div c="search-wrap">
                        <Div c="search-bar">
                            <Search />
                            <input type="text" placeholder="Search" value={searchTerm} onChange={handleInputChange} />
                        </Div>
                    </Div>
                    <Div c="insert-element-wrap">
                        {(searchTerm.length != 0 ? searchResults : populatedElements).map(elementType => (
                            <Div c="element-type-wrap" key={elementType.type}>
                                <Div c="type-caption">{elementType.type}</Div>
                                <Div c="elements-wrap">
                                    {elementType.components.map(component => (
                                        <Button onClick={() => handleAddBlock(component)} key={component.component}>
                                            <DictionaryElement data={component.name} />
                                            {component.name}
                                        </Button>
                                    ))}
                                </Div>
                            </Div>
                        ))}
                        <Div c="element-type-wrap">
                            <Div c="type-caption">Prebuilt forms</Div>
                            <Div c="elements-wrap">
                                {formList.map(form => (
                                    <Button key={form._id} onClick={() => processFormComponent(form)}>
                                        <DictionaryElement data='Form' />
                                        {form._source.title}
                                    </Button>
                                ))}
                            </Div>
                        </Div>
                    </Div>
                </Div>
                <Div label="My Saved">
                    <MySaved closePopup={closePopup} />
                </Div>
                <Div label="Components">
                    <ComponentList closePopup={closePopup} />
                </Div>
            </Tabs>
        </Container>
    );
};

export default AddBlockPopup;

const Container = styled.div`
    position:absolute;
    left:200px;
    border-radius:8px;
    display:flex;
    flex-direction:column;
    gap:25px;
    box-shadow: 0px 0px 1px 0px ${({ theme }) => theme.color2};
    z-index:9;
    background:${({ theme }) => theme.bg};
    animation: fade-in-down 0.5s ease both;
    width: 400px;
    max-height: 500px;
    overflow-y: auto;
    .tabs{
        .tab-list{
            width: 400px;
        }
    }
    .search-wrap{
        padding: 25px;
        position: fixed;
        background:${({ theme }) => theme.bg};
        z-index: 1;
        width: 400px;
        border-radius:8px 8px 0 0;
        .search-bar{
            padding:10px;
            display:flex;
            align-items:center;
            gap:10px;
            background:${({ theme }) => theme.bg2};
            border-radius:4px;
            svg{
                width: 22px;
                height: 22px;
            }
            input{
                width: 100%;
                border: none;
                background: transparent;
                color: ${({ theme }) => theme.color};
                &:focus{
                    outline: none;
                }
            }
        }
    }
    .components-wrap{
        padding: 25px;
    }
    .insert-element-wrap{
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 100px 25px 25px 25px;
        .element-type-wrap{
            display: flex;
            flex-direction: column;
            gap: 15px;
            .type-caption{
                font-size: ${({ theme }) => theme.fs2};
                font-weight: 600;
                color: ${({ theme }) => theme.color};
            }
            .elements-wrap{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                gap: 15px;
                button{
                    padding: 11px 8px;
                    width: calc((100% - 15px) / 2);
                    text-align: start;
                    background-color: transparent;
                    transition: background-color .3s ease;
                    border-radius: 4px;
                    font-size: ${({ theme }) => theme.fs2};
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    &:hover{
                        background-color: ${({ theme }) => theme.bg2};
                    }
                }
            }
        }
    }
    @keyframes fade-in-down {
        0% {
            opacity: 0;
            margin-top:-10px;
        }
        100% {
            opacity: 1;
            margin-top:0px;
        }
    }
`