import { getCrossDomainContext } from 'contexts/crossDomain';
import Div from 'elements/Div';
import React from 'react';
import Select from '../../InputTypes/Select';
import { populateDataProps } from 'utils/vanillaFunctions';
import { MdOutlineToggleOff, MdOutlineToggleOn } from "react-icons/md";
import styled from 'styled-components';
import * as InputTypes from '../../InputTypes'
import useSync from 'utils/useSync';
import IterateProps from '../../../../utils/useSync/IterateProps';

const dataChange = ({ instance, setInstance }) => {
    const { frame } = React.useContext(getCrossDomainContext)
    const [availableStates, setAvailableStates] = React.useState([])
    const [currentInstance, setCurrentInstance] = React.useState(null)
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: instance.sync, operation: { type: 'setState', instance, setInstance } })
    React.useEffect(() => {
        setInstance({ ...instance, conditionValue: false })
        const target = frame.document.querySelector(`[data-track="${instance._indices}"]`)
        const populatedStates = populateDataProps(target)
        setAvailableStates(populatedStates)
    }, [])

    const handleOnSelect = (v) => {
        setCurrentInstance(v.type)
        setInstance({ ...instance, dataProps: v })
    }

    const SelectedType = InputTypes[currentInstance]
    return (
        <>
            <Container>
                <Div c="input-wrap">
                    <label>Data props</label>
                    <IterateProps handleOnSelect={handleOnSelect} propsData={availableStates} />
                </Div>
                <Div c="input-wrap">
                    <label>Condition</label>
                    <Select options={['equal', 'not equal']} value={instance.condition} onChange={(v) => setInstance({ ...instance, condition: v })} />
                </Div>
                <Div c='input-wrap'>
                    <label>To</label>
                    <Div c='sync'>
                        {instance.sync ? syncElement() :
                            SelectedType && <SelectedType value={instance.conditionValue} onChange={val => setInstance({ ...instance, conditionValue: val })} />
                        }
                        {syncTrigger()}
                    </Div>
                </Div>
            </Container>
            {syncSuggestion()}
        </>
    );
};

export default dataChange;

const presence = ({ instance }) => {
    return (
        <Presence>
            {instance.conditionValue ? <MdOutlineToggleOn /> : <MdOutlineToggleOff />}
            <Div c='text'>{instance.dataProps.key} {instance.condition} {instance.sync ? instance.sync.name : JSON.stringify(instance.conditionValue)}</Div>
        </Presence>
    )
}
const Presence = styled.div`
    display:flex;
    align-items:center;
    gap:8px;
    .text{
        font-weight: 500;
        text-transform: uppercase;
        color: ${({ theme }) => theme.color2};
        font-size: 10px;
        line-height: 20px;
    }
    svg {
        font-size:16px;
    }
`

export { presence }

const Container = styled.div`
width: 100%;
.input-wrap{
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}
.sync{
    display: flex;
    align-items: center;
    width: 100%;
    & > div{
        width: 100%;
    }
    & > input{
        max-width: 100%;
        width: 100%;
    }
}
`
