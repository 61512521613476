export const containsNumbers = (str) => {
    return /\d/.test(str);
};
export const getStyleValue = (selectedComponent, attributeName, doc) => {
    const device = localStorage.getItem('bl-device')
    const copiedComponent = {...selectedComponent}
    // const targetNode = doc.querySelector(`[data-track="${copiedComponent.indices}"]`)
    let copiedStyles = { ...copiedComponent.style }
    // Check for any classes or hovers activated
    const contentclass = doc.querySelector('#root').getAttribute('contentclass')
    if(contentclass) {
        const [indices, className] = contentclass.split('||')
        if(indices === copiedComponent.indices) {
            copiedStyles = {...copiedComponent.style.class[className]}
        }
    }

    let cssAttribute
    if (device === 'desktop') cssAttribute = copiedStyles[device][attributeName];
    else if (device === 'tablet') {
        if (copiedStyles['tablet'][attributeName]) {
            cssAttribute = copiedStyles['tablet'][attributeName];
        } else {
            cssAttribute = copiedStyles['desktop'][attributeName];
        }
    } else if (device === 'mobile') {
        if (copiedStyles['mobile'][attributeName]) {
            cssAttribute = copiedStyles['mobile'][attributeName];
        } else if (copiedStyles['tablet'][attributeName]) {
            cssAttribute = copiedStyles['tablet'][attributeName];
        } else {
            cssAttribute = copiedStyles['desktop'][attributeName];
        }
    }

    return cssAttribute
}
export const dispatchChange = (editComponent, selectedComponent, attribute, value) => {
    const copiedComponent = { ...selectedComponent };
    const copiedStyles = { ...copiedComponent.style };
    copiedStyles[attribute] = value;
    copiedComponent.style = copiedStyles;
    // editComponent(copiedComponent, selectedComponent.indices);
    editComponent(selectedComponent.indices, 'style', attribute, value);
};