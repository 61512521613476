const watchFrameMount = (setFrame) => {
    window.addEventListener('frameMounted', ({ detail }) => {
        if (detail.mounted) {
            setFrame(detail.frame);
        }
    });
};

const watchCompositionChange = (setFrameUpdate) => {
    window.addEventListener('compositionChange', () => {
        setFrameUpdate(Math.random())
    })
}

const watchFrameScreenChange = (setFrameScreen) => {
    window.addEventListener('screenChange', ({ detail }) => {
        setFrameScreen(detail.screenWidth)
    })
}

const syncWithFrame = (frame, composition) => {
    const event = new CustomEvent('dataChange', { detail: composition })
    frame.dispatchEvent(event)
}

const syncDevmode = (frame, devmode) => {
    const event = new CustomEvent('devmode', { detail: devmode })
    frame.dispatchEvent(event)
}

const syncDetailPage = (frame, detailedData) => {
    const event = new CustomEvent('detailChange', { detail: detailedData })
    frame.dispatchEvent(event)
}

const syncFrameLanguage = (frame, language) => {
    const event = new CustomEvent('languageChange', { detail: language })
    frame.dispatchEvent(event)
}

export {
    watchFrameMount,
    syncWithFrame,
    syncFrameLanguage,
    watchCompositionChange,
    watchFrameScreenChange,
    syncDetailPage,
    syncDevmode
}
