import PropTypes from 'prop-types';
import React from 'react';
import { getBuilderContext } from './builder';
import { getCrossDomainContext } from './crossDomain';
import { recursiveEqual } from 'utils/recursive';
import AddBlockPopup from 'components/AddBlockPopup';
import SectionPopup from 'components/SectionPopup';
import InappEditor from 'components/InappEditor';
import LocalStylesSettings from 'components/LocalStylesSettings';
import EditComponentPopup from 'components/EditComponentPopup';
import AddComponent from 'components/ComponentList/AddComponent';
import AddSectionPopup from 'components/SectionPopup/AddSectionPopup';
import LocalComponentPopup from 'components/MySaved/LocalComponentPopup';

export const getReferenceContext = React.createContext();

export const ReferenceContextProvider = (props) => {
    const [templateInstance, setTemplateInstance] = React.useState(null)
    const [localComponentInstance, setLocalComponentInstance] = React.useState(null)
    const [sectionInstance, setSectionInstance] = React.useState(null)
    const [addBlockPopupShow, setAddBlockPopupShow] = React.useState(false)
    const [editComponentPopupShow, setEditComponentPopupShow] = React.useState(false)
    const Highlight = React.useRef();
    const Indicator = React.useRef();
    const InsertingElement = React.useRef();
    const PointingAt = React.useRef();
    const Appending = React.useRef();
    const Selected = React.useRef();
    const InsertDialog = React.useRef();
    const InsertTemplateDialog = React.useRef()
    const [copiedElement, setCopiedElement] = React.useState(null);
    const [sectionPopupShow, setSectionPopupShow] = React.useState(false);
    const [draggableModule, setDraggableModule] = React.useState(false);
    const [localStylesSettings, setLocalStylesSettings] = React.useState(false);
    const [dataSourcePopup, setDataSourcePopup] = React.useState(false);
    const [languageSwitching, setLanguageSwitching] = React.useState(false);
    const [dynamicPagesSection, setDynamicPagesSection] = React.useState(null);
    const [InappPopup, setInappPopup] = React.useState(false)

    //HISTORY IMPLEMENTATION - START
    const [selectedHistoryIndex, setSelectedHistoryIndex] = React.useState(null);
    const { history = [] } = React.useContext(getBuilderContext);
    const { composition, setComposition } = React.useContext(getCrossDomainContext);
    const historyUndo = () => {
        if (history[selectedHistoryIndex - 1]) {
            const comp = history[selectedHistoryIndex - 1].data;
            setComposition([...comp]);
        }
    };
    const historyRedo = () => {
        if (history[selectedHistoryIndex + 1]) {
            const comp = history[selectedHistoryIndex + 1].data;
            setComposition([...comp]);
        }
    };
    React.useEffect(() => {
        if (history.length && composition.length) {
            history.forEach(({ data }, i) => {
                // const json = JSON.stringify(data);
                // const comp = JSON.stringify(composition);
                // if (json === comp) setSelectedHistoryIndex(i);
                if (recursiveEqual(data, composition)) setSelectedHistoryIndex(i);
            });
        }
    }, [composition, history]);
    //HISTORY IMPLEMENTATION - END

    return (
        <getReferenceContext.Provider
            value={{
                Highlight,
                Indicator,
                InsertingElement,
                PointingAt,
                Appending,
                Selected,
                InsertDialog,
                InsertTemplateDialog,
                copiedElement,
                setCopiedElement,
                selectedHistoryIndex,
                setSelectedHistoryIndex,
                historyUndo,
                historyRedo,
                templateInstance,
                setTemplateInstance,
                localComponentInstance,
                setLocalComponentInstance,
                addBlockPopupShow,
                setAddBlockPopupShow,
                sectionPopupShow,
                setSectionPopupShow,
                draggableModule,
                setDraggableModule,
                localStylesSettings,
                setLocalStylesSettings,
                editComponentPopupShow,
                setEditComponentPopupShow,
                dataSourcePopup,
                setDataSourcePopup,
                dynamicPagesSection,
                setDynamicPagesSection,
                sectionInstance,
                setSectionInstance,
                languageSwitching,
                setLanguageSwitching,
                InappPopup,
                setInappPopup,
            }}
        >
            {props.children}
            {templateInstance && <AddComponent templateInstance={templateInstance} close={() => setTemplateInstance(null)} />}
            {localComponentInstance && <LocalComponentPopup localComponentInstance={localComponentInstance} close={() => setLocalComponentInstance(null)} />}
            {sectionInstance && <AddSectionPopup templateInstance={sectionInstance} close={() => setSectionInstance(null)} />}
            {addBlockPopupShow && <AddBlockPopup closePopup={() => setAddBlockPopupShow(false)} />}
            {sectionPopupShow && <SectionPopup PointingAt={PointingAt} close={() => setSectionPopupShow(false)} />}
            {localStylesSettings && <LocalStylesSettings close={() => setLocalStylesSettings(false)} />}
            {editComponentPopupShow && <EditComponentPopup closePopup={() => setEditComponentPopupShow(false)} />}
            {InappPopup && <InappEditor closePopup={() => setInappPopup(false)} />}
        </getReferenceContext.Provider>
    );
};

ReferenceContextProvider.propTypes = {
    children: PropTypes.any,
};
