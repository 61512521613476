export default `
.video-react .video-react-control:before, .video-react .video-react-big-play-button:before {
    text-align: center;
}
.video-react-icon, .video-react .video-react-closed-caption, .video-react .video-react-bezel .video-react-bezel-icon, .video-react .video-react-volume-level, .video-react .video-react-mute-control,
.video-react .video-react-volume-menu-button, .video-react .video-react-play-control, .video-react .video-react-play-progress, .video-react .video-react-big-play-button {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "video-react" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.video-react-icon-play-arrow:before, .video-react .video-react-bezel .video-react-bezel-icon-play:before, .video-react .video-react-play-control:before, .video-react .video-react-big-play-button:before {
  content: "";
}

.video-react-icon-play-circle-filled:before {
  content: "";
}

.video-react-icon-play-circle-outline:before {
  content: "";
}

.video-react-icon-pause:before, .video-react .video-react-bezel .video-react-bezel-icon-pause:before, .video-react .video-react-play-control.video-react-playing:before {
  content: "";
}

.video-react-icon-pause-circle-filled:before {
  content: "";
}

.video-react-icon-pause-circle-outline:before {
  content: "";
}

.video-react-icon-stop:before {
  content: "";
}

.video-react-icon-fast-rewind:before, .video-react .video-react-bezel .video-react-bezel-icon-fast-rewind:before {
  content: "";
}

.video-react-icon-fast-forward:before, .video-react .video-react-bezel .video-react-bezel-icon-fast-forward:before {
  content: "";
}

.video-react-icon-skip-previous:before {
  content: "";
}

.video-react-icon-skip-next:before {
  content: "";
}

.video-react-icon-replay-5:before, .video-react .video-react-bezel .video-react-bezel-icon-replay-5:before {
  content: "";
}

.video-react-icon-replay-10:before, .video-react .video-react-bezel .video-react-bezel-icon-replay-10:before {
  content: "";
}

.video-react-icon-replay-30:before, .video-react .video-react-bezel .video-react-bezel-icon-replay-30:before {
  content: "";
}

.video-react-icon-forward-5:before, .video-react .video-react-bezel .video-react-bezel-icon-forward-5:before {
  content: "";
}

.video-react-icon-forward-10:before, .video-react .video-react-bezel .video-react-bezel-icon-forward-10:before {
  content: "";
}

.video-react-icon-forward-30:before, .video-react .video-react-bezel .video-react-bezel-icon-forward-30:before {
  content: "";
}

.video-react-icon-volume-off:before, .video-react .video-react-bezel .video-react-bezel-icon-volume-off:before, .video-react .video-react-mute-control.video-react-vol-muted:before,
.video-react .video-react-volume-menu-button.video-react-vol-muted:before {
  content: "";
}

.video-react-icon-volume-mute:before, .video-react .video-react-mute-control.video-react-vol-0:before,
.video-react .video-react-volume-menu-button.video-react-vol-0:before {
  content: "";
}

.video-react-icon-volume-down:before, .video-react .video-react-bezel .video-react-bezel-icon-volume-down:before, .video-react .video-react-mute-control.video-react-vol-2:before,
.video-react .video-react-volume-menu-button.video-react-vol-2:before, .video-react .video-react-mute-control.video-react-vol-1:before,
.video-react .video-react-volume-menu-button.video-react-vol-1:before {
  content: "";
}

.video-react-icon-volume-up:before, .video-react .video-react-bezel .video-react-bezel-icon-volume-up:before, .video-react .video-react-mute-control:before,
.video-react .video-react-volume-menu-button:before {
  content: "";
}

.video-react-icon-fullscreen:before {
  content: "";
}

.video-react-icon-fullscreen-exit:before {
  content: "";
}

.video-react-icon-closed-caption:before, .video-react .video-react-closed-caption:before {
  content: "";
}

.video-react-icon-hd:before {
  content: "";
}

.video-react-icon-settings:before {
  content: "";
}

.video-react-icon-share:before {
  content: "";
}

.video-react-icon-info:before {
  content: "";
}

.video-react-icon-info-outline:before {
  content: "";
}

.video-react-icon-close:before {
  content: "";
}

.video-react-icon-circle:before, .video-react .video-react-volume-level:before, .video-react .video-react-play-progress:before {
  content: "";
}

.video-react-icon-circle-outline:before {
  content: "";
}

.video-react-icon-circle-inner-circle:before {
  content: "";
}

.video-react {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  font-size: 10px;
  line-height: 1;
  font-family: serif, Times, "Times New Roman";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.video-react:-moz-full-screen {
  position: absolute;
}
.video-react:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}
.video-react *,
.video-react *:before,
.video-react *:after {
  box-sizing: inherit;
}
.video-react ul {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  list-style-position: outside;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
  width: 100%;
  max-width: 100%;
  height: 0;
}
.video-react.video-react-16-9 {
  padding-top: 56.25%;
}
.video-react.video-react-4-3 {
  padding-top: 75%;
}
.video-react.video-react-fill {
  width: 100%;
  height: 100%;
}
.video-react .video-react-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-react.video-react-fullscreen {
  width: 100% !important;
  height: 100% !important;
  padding-top: 0 !important;
}
.video-react.video-react-fullscreen.video-react-user-inactive {
  cursor: none;
}

body.video-react-full-window {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: auto;
}
body.video-react-full-window .video-react-fullscreen {
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.video-react .video-react-button {
  background: none;
  border: none;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  overflow: visible;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  text-decoration: none;
  transition: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.video-react .video-react-loading-spinner {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;
  text-align: left;
  border: 6px solid rgba(43, 51, 63, 0.7);
  box-sizing: border-box;
  background-clip: padding-box;
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.video-react .video-react-loading-spinner:before, .video-react .video-react-loading-spinner:after {
  content: "";
  position: absolute;
  margin: -6px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 1;
  border: inherit;
  border-color: transparent;
  border-top-color: white;
  -webkit-animation: video-react-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, video-react-spinner-fade 1.1s linear infinite;
  animation: video-react-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, video-react-spinner-fade 1.1s linear infinite;
}

.video-react-seeking .video-react-loading-spinner,
.video-react-waiting .video-react-loading-spinner {
  display: block;
}

.video-react-seeking .video-react-loading-spinner:before,
.video-react-waiting .video-react-loading-spinner:before {
  border-top-color: white;
}

.video-react-seeking .video-react-loading-spinner:after,
.video-react-waiting .video-react-loading-spinner:after {
  border-top-color: white;
  -webkit-animation-delay: 0.44s;
  animation-delay: 0.44s;
}

@keyframes video-react-spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes video-react-spinner-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes video-react-spinner-fade {
  0% {
    border-top-color: #73859f;
  }
  20% {
    border-top-color: #73859f;
  }
  35% {
    border-top-color: white;
  }
  60% {
    border-top-color: #73859f;
  }
  100% {
    border-top-color: #73859f;
  }
}
@-webkit-keyframes video-react-spinner-fade {
  0% {
    border-top-color: #73859f;
  }
  20% {
    border-top-color: #73859f;
  }
  35% {
    border-top-color: white;
  }
  60% {
    border-top-color: #73859f;
  }
  100% {
    border-top-color: #73859f;
  }
}
.video-react .video-react-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.7);
  -webkit-border-radius: 0.3em;
  -moz-border-radius: 0.3em;
  border-radius: 0.3em;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.video-react .video-react-big-play-button.video-react-big-play-button-center {
  top: 50%;
  left: 50%;
  margin-top: -0.75em;
  margin-left: -1.5em;
}
.video-react .video-react-big-play-button.big-play-button-hide {
  display: none;
}
.video-react:hover .video-react-big-play-button,
.video-react .video-react-big-play-button:focus {
  outline: 0;
  border-color: #fff;
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}

.video-react-menu-button {
  cursor: pointer;
}
.video-react-menu-button.video-react-disabled {
  cursor: default;
}

.video-react-menu .video-react-menu-content {
  display: block;
  padding: 0;
  margin: 0;
  overflow: auto;
  font-family: serif, Times, "Times New Roman";
}
.video-react-menu li {
  list-style: none;
  margin: 0;
  padding: 0.2em 0;
  line-height: 1.4em;
  font-size: 1.2em;
  text-align: center;
}
.video-react-menu li:focus, .video-react-menu li:hover {
  outline: 0;
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
}
.video-react-menu li.video-react-selected, .video-react-menu li.video-react-selected:focus, .video-react-menu li.video-react-selected:hover {
  background-color: #fff;
  color: #2B333F;
}
.video-react-menu li.vjs-menu-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1em;
  line-height: 2em;
  padding: 0;
  margin: 0 0 0.3em 0;
  font-weight: bold;
  cursor: default;
}

.video-react-scrubbing .vjs-menu-button:hover .video-react-menu {
  display: none;
}

.video-react .video-react-menu-button-popup .video-react-menu {
  display: none;
  position: absolute;
  bottom: 0;
  width: 10em;
  left: -3em;
  height: 0em;
  margin-bottom: 1.5em;
  border-top-color: rgba(43, 51, 63, 0.7);
}
.video-react .video-react-menu-button-popup .video-react-menu .video-react-menu-content {
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.7);
  position: absolute;
  width: 100%;
  bottom: 1.5em;
  max-height: 15em;
}

.video-react-menu-button-popup .video-react-menu.video-react-lock-showing {
  display: block;
}

.video-react .video-react-menu-button-inline {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  overflow: hidden;
}
.video-react .video-react-menu-button-inline:before {
  width: 2.222222222em;
}
.video-react .video-react-menu-button-inline:hover, .video-react .video-react-menu-button-inline:focus, .video-react .video-react-menu-button-inline.video-react-slider-active {
  width: 12em;
}
.video-react .video-react-menu-button-inline:hover .video-react-menu, .video-react .video-react-menu-button-inline:focus .video-react-menu, .video-react .video-react-menu-button-inline.video-react-slider-active .video-react-menu {
  display: block;
  opacity: 1;
}
.video-react .video-react-menu-button-inline.video-react-slider-active {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.video-react .video-react-menu-button-inline .video-react-menu {
  opacity: 0;
  height: 100%;
  width: auto;
  position: absolute;
  left: 4em;
  top: 0;
  padding: 0;
  margin: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.video-react .video-react-menu-button-inline .video-react-menu-content {
  width: auto;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.video-react-no-flex .video-react-menu-button-inline .video-react-menu {
  display: block;
  opacity: 1;
  position: relative;
  width: auto;
}
.video-react-no-flex .video-react-menu-button-inline:hover, .video-react-no-flex .video-react-menu-button-inline:focus, .video-react-no-flex .video-react-menu-button-inline.video-react-slider-active {
  width: auto;
}

.video-react .video-react-poster {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-color: #000000;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
}
.video-react .video-react-poster img {
  display: block;
  vertical-align: middle;
  margin: 0 auto;
  max-height: 100%;
  padding: 0;
  width: 100%;
}

.video-react .video-react-slider {
  outline: 0;
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0 0.45em 0 0.45em;
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
}
.video-react .video-react-slider:focus {
  -webkit-box-shadow: 0 0 1em #fff;
  -moz-box-shadow: 0 0 1em #fff;
  box-shadow: 0 0 1em #fff;
}

.video-react .video-react-control {
  outline: none;
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 4em;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}
.video-react .video-react-control:before {
  font-size: 1.8em;
  line-height: 1.67;
}
.video-react .video-react-control:focus:before, .video-react .video-react-control:hover:before, .video-react .video-react-control:focus {
  text-shadow: 0em 0em 1em #fff, 0em 0em 0.5em #fff;
}

.video-react .video-react-control-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.video-react-no-flex .video-react-control {
  display: table-cell;
  vertical-align: middle;
}

.video-react .video-react-control-bar {
  display: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3em;
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.7);
}

.video-react-has-started .video-react-control-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.1s, opacity 0.1s;
  -moz-transition: visibility 0.1s, opacity 0.1s;
  -o-transition: visibility 0.1s, opacity 0.1s;
  transition: visibility 0.1s, opacity 0.1s;
}

.video-react-has-started.video-react-user-inactive.video-react-playing .video-react-control-bar.video-react-control-bar-auto-hide {
  visibility: visible;
  opacity: 0;
  -webkit-transition: visibility 1s, opacity 1s;
  -moz-transition: visibility 1s, opacity 1s;
  -o-transition: visibility 1s, opacity 1s;
  transition: visibility 1s, opacity 1s;
}

.video-react-controls-disabled .video-react-control-bar,
.video-react-using-native-controls .video-react-control-bar,
.video-react-error .video-react-control-bar {
  display: none !important;
}

.video-react-audio.video-react-has-started.video-react-user-inactive.video-react-playing .video-react-control-bar {
  opacity: 1;
  visibility: visible;
}

.video-react-has-started.video-react-no-flex .video-react-control-bar {
  display: table;
}

.video-react .video-react-progress-control {
  -webkit-box-flex: auto;
  -moz-box-flex: auto;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 4em;
}

.video-react-live .video-react-progress-control {
  display: none;
}

.video-react .video-react-progress-holder {
  -webkit-box-flex: auto;
  -moz-box-flex: auto;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  height: 0.3em;
}

.video-react .video-react-progress-control:hover .video-react-progress-holder {
  font-size: 1.6666666667em;
}

/* If we let the font size grow as much as everything else, the current time tooltip ends up
 ginormous. If you'd like to enable the current time tooltip all the time, this should be disabled
 to avoid a weird hitch when you roll off the hover. */
.video-react .video-react-progress-control:hover .video-react-time-tooltip,
.video-react .video-react-progress-control:hover .video-react-mouse-display:after,
.video-react .video-react-progress-control:hover .video-react-play-progress:after {
  visibility: visible;
  font-size: 0.6em;
}

.video-react .video-react-progress-holder .video-react-play-progress,
.video-react .video-react-progress-holder .video-react-load-progress,
.video-react .video-react-progress-holder .video-react-tooltip-progress-bar,
.video-react .video-react-progress-holder .video-react-load-progress div {
  position: absolute;
  display: block;
  height: 0.3em;
  margin: 0;
  padding: 0;
  width: 0;
  left: 0;
  top: 0;
}

.video-react .video-react-play-progress {
  background-color: #fff;
}
.video-react .video-react-play-progress:before {
  position: absolute;
  top: -0.3333333333em;
  right: -0.5em;
  font-size: 0.9em;
}

.video-react .video-react-time-tooltip,
.video-react .video-react-mouse-display:after,
.video-react .video-react-play-progress:after {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: -3.4em;
  right: -1.9em;
  font-size: 0.9em;
  color: #000;
  content: attr(data-current-time);
  padding: 6px 8px 8px 8px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 0.3em;
  -moz-border-radius: 0.3em;
  border-radius: 0.3em;
}

.video-react .video-react-time-tooltip,
.video-react .video-react-play-progress:before,
.video-react .video-react-play-progress:after {
  z-index: 1;
}

.video-react .video-react-progress-control .video-react-keep-tooltips-inside:after {
  display: none;
}

.video-react .video-react-load-progress {
  background: #bfc7d3;
  background: rgba(115, 133, 159, 0.5);
}

.video-react .video-react-load-progress div {
  background: white;
  background: rgba(115, 133, 159, 0.75);
}

.video-react.video-react-no-flex .video-react-progress-control {
  width: auto;
}

.video-react .video-react-time-tooltip {
  display: inline-block;
  height: 2.4em;
  position: relative;
  float: right;
  right: -1.9em;
}

.video-react .video-react-tooltip-progress-bar {
  visibility: hidden;
}

.video-react .video-react-progress-control .video-react-mouse-display {
  display: none;
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #000;
  z-index: 1;
}

.video-react-no-flex .video-react-progress-control .video-react-mouse-display {
  z-index: 0;
}

.video-react .video-react-progress-control:hover .video-react-mouse-display {
  display: block;
}

.video-react.video-react-user-inactive .video-react-progress-control .video-react-mouse-display,
.video-react.video-react-user-inactive .video-react-progress-control .video-react-mouse-display:after {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 1s, opacity 1s;
  -moz-transition: visibility 1s, opacity 1s;
  -o-transition: visibility 1s, opacity 1s;
  transition: visibility 1s, opacity 1s;
}

.video-react.video-react-user-inactive.video-react-no-flex .video-react-progress-control .video-react-mouse-display,
.video-react.video-react-user-inactive.video-react-no-flex .video-react-progress-control .video-react-mouse-display:after {
  display: none;
}

.video-react .video-react-mouse-display .video-react-time-tooltip,
.video-react .video-react-progress-control .video-react-mouse-display:after {
  color: #fff;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
}

.video-react .video-react-play-control {
  cursor: pointer;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}
.video-react .video-react-fullscreen-control {
  cursor: pointer;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.video-react.video-react-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.video-react .video-react-time-control {
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  font-size: 1em;
  line-height: 3em;
  min-width: 2em;
  width: auto;
  padding-left: 1em;
  padding-right: 1em;
}
.video-react .video-react-time-divider {
  line-height: 3em;
  min-width: initial;
  padding: 0;
}

.video-react .video-react-mute-control,
.video-react .video-react-volume-menu-button {
  cursor: pointer;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}
.video-react .video-react-volume-control {
  width: 5em;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.video-react .video-react-volume-bar {
  margin: 1.35em 0.45em;
}
.video-react .video-react-volume-bar.video-react-slider-horizontal {
  width: 5em;
  height: 0.3em;
}
.video-react .video-react-volume-bar.video-react-slider-horizontal .video-react-volume-level {
  width: 100%;
}
.video-react .video-react-volume-bar.video-react-slider-vertical {
  width: 0.3em;
  height: 5em;
  margin: 1.35em auto;
}
.video-react .video-react-volume-bar.video-react-slider-vertical .video-react-volume-level {
  height: 100%;
}
.video-react .video-react-volume-level {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.video-react .video-react-volume-level:before {
  position: absolute;
  font-size: 0.9em;
}
.video-react .video-react-slider-vertical .video-react-volume-level {
  width: 0.3em;
}
.video-react .video-react-slider-vertical .video-react-volume-level:before {
  top: -0.5em;
  left: -0.3em;
}
.video-react .video-react-slider-horizontal .video-react-volume-level {
  height: 0.3em;
}
.video-react .video-react-slider-horizontal .video-react-volume-level:before {
  top: -0.3em;
  right: -0.5em;
}
.video-react .video-react-menu-button-popup.video-react-volume-menu-button .video-react-menu {
  display: block;
  width: 0;
  height: 0;
  border-top-color: transparent;
}
.video-react .video-react-menu-button-popup.video-react-volume-menu-button-vertical .video-react-menu {
  left: 0.5em;
  height: 8em;
}
.video-react .video-react-menu-button-popup.video-react-volume-menu-button-horizontal .video-react-menu {
  left: -2em;
}
.video-react .video-react-menu-button-popup.video-react-volume-menu-button .video-react-menu-content {
  height: 0;
  width: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
.video-react .video-react-volume-menu-button-vertical:hover .video-react-menu-content,
.video-react .video-react-volume-menu-button-vertical:focus .video-react-menu-content,
.video-react .video-react-volume-menu-button-vertical.video-react-slider-active .video-react-menu-content,
.video-react .video-react-volume-menu-button-vertical .video-react-lock-showing .video-react-menu-content {
  height: 8em;
  width: 2.9em;
}
.video-react .video-react-volume-menu-button-horizontal:hover .video-react-menu-content,
.video-react .video-react-volume-menu-button-horizontal:focus .video-react-menu-content,
.video-react .video-react-volume-menu-button-horizontal .video-react-slider-active .video-react-menu-content,
.video-react .video-react-volume-menu-button-horizontal .video-react-lock-showing .video-react-menu-content {
  height: 2.9em;
  width: 8em;
}
.video-react .video-react-volume-menu-button.video-react-menu-button-inline .video-react-menu-content {
  background-color: transparent !important;
}

.video-react .video-react-playback-rate .video-react-playback-rate-value {
  line-height: 3em;
  text-align: center;
}
.video-react .video-react-playback-rate .video-react-menu {
  width: 4em;
  left: 0em;
}

.video-react .video-react-bezel {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 52px;
  height: 52px;
  z-index: 17;
  margin-left: -26px;
  margin-top: -26px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 26px;
}
.video-react .video-react-bezel.video-react-bezel-animation {
  -moz-animation: video-react-bezel-fadeout 0.5s linear 1 normal forwards;
  -webkit-animation: video-react-bezel-fadeout 0.5s linear 1 normal forwards;
  animation: video-react-bezel-fadeout 0.5s linear 1 normal forwards;
  pointer-events: none;
}
.video-react .video-react-bezel.video-react-bezel-animation-alt {
  -moz-animation: video-react-bezel-fadeout-alt 0.5s linear 1 normal forwards;
  -webkit-animation: video-react-bezel-fadeout-alt 0.5s linear 1 normal forwards;
  animation: video-react-bezel-fadeout-alt 0.5s linear 1 normal forwards;
  pointer-events: none;
}
.video-react .video-react-bezel .video-react-bezel-icon {
  width: 36px;
  height: 36px;
  margin: 8px;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
}
@keyframes video-react-bezel-fadeout {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes video-react-bezel-fadeout-alt {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}
.video-react .video-react-closed-caption {
  cursor: pointer;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}
.video-react video::-webkit-media-text-track-container {
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}
/* CUSTOM FIXES HERE */
.video-react-button.video-react-big-play-button{
    opacity:0 !important;
}
.video-react-control-bar{
    display:none !important;
}
`