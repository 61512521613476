import React from "react";
import styled from "styled-components";

const Object = ({ object, value, onChange }) => {
    const objectJson = JSON.stringify(object, null, 2);
    return (
        <Container>
           <pre>{objectJson}</pre>
        </Container>
    )
}

export default Object

const Container = styled.div`
    background: ${({ theme }) => theme.bg2};
    border-radius: 4px;
    color: ${({ theme }) => theme.color};
    font-weight: 500;
    max-width: 160px;
`