import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { getBuilderContext } from 'contexts/builder';
import ToggleButton from 'components/ToggleButton';
import useSync from 'utils/useSync';
import Select from '../InputTypes/Select'
import Options from 'components/Inputs/Options';
import Object from 'components/Inputs/Object';

const StatePopup = ({ instance: firstInstance, setInstance: setFirstInstance }) => {
    const stateTypes = ["boolean", "text", "number", "select"]
    const [instance, setInstance] = React.useState(firstInstance)
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext)
    const outerWrap = React.useRef()
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: instance.sync, operation: { type: 'setState', instance, setInstance } })

    const handleSave = () => {
        //BOOLEAN DEFAULT LINT
        if (instance.type === "boolean") if (!instance.value) instance.value = false
        const fixedName = instance.name.replace(/ /g, "").toLowerCase()

        //EMIT EDIT FUNCTION
        if (instance.type == 'select' && instance.options) {
            editComponent(selectedComponent.indices, 'state', fixedName, {
                type: instance.type,
                value: instance.value,
                options: instance.options,
                sync: instance.sync
            })
        } else if (instance.type == 'object' && instance.object) {
            editComponent(selectedComponent.indices, 'state', fixedName, {
                type: instance.type,
                value: null,
                object: instance.object,
                sync: instance.sync
            })
        } else {
            editComponent(selectedComponent.indices, 'state', fixedName, {
                type: instance.type,
                value: instance.value,
                sync: instance.sync
            })
        }
        setFirstInstance(null)
    }

    return (
        <Container ref={outerWrap} onClick={(e) => e.target === outerWrap.current && setFirstInstance(null)}>
            <Div c="wrap">
                <Div c="title">Edit content state</Div>
                <Div c="content-wrap">
                    <Div c="input-wrap">
                        <label>Name</label>
                        <input value={instance.name} onChange={e => setInstance({ ...instance, name: e.target.value })} />
                    </Div>
                    <Div c="input-wrap">
                        <label>Type</label>
                        <Select options={stateTypes} value={instance.type} onChange={(val) => setInstance({ ...instance, type: val })} />
                    </Div>
                    {instance.type === 'boolean' ? (
                        <Div c="toggle-wrap" onClick={() => setInstance({ ...instance, value: !instance.value })}>
                            <label>Value</label>
                            <ToggleButton isToggled={instance.value} setIsToggled={(v) => setInstance({ ...instance, value: v })} />
                        </Div>
                    ) : instance.type === 'select' ?
                        <Div c='input-wrap'>
                            <label>Options</label>
                            <Div c='sync-wrap'>
                                {instance.sync ? syncElement() :
                                    <Div c='options-wrapper'>
                                        <Options
                                            options={instance?.options}
                                            change={(val) => setInstance((prevInstance) => ({
                                                ...prevInstance,
                                                options: Array.isArray(prevInstance.options) ? [...prevInstance.options, val] : [val],
                                            }))}
                                            remove={(val) => setInstance({ ...instance, options: val })}
                                        />
                                        {instance?.options && <Div c="input-wrap">
                                            <label>Default Value</label>
                                            <Select options={instance?.options} value={instance.value} onChange={(val) => setInstance({ ...instance, value: val })} height="100px"/>
                                        </Div>}
                                    </Div>
                                }
                                {syncTrigger()}
                            </Div>
                        </Div>
                        : instance.type === 'object' ?
                            <Div c='input-wrap'>
                                {/* <label>Object</label> */}
                                <Div c='sync-wrap'>
                                    {instance.sync ? syncElement() :
                                        <Object change={(val) => setInstance({ ...instance, object: val })} />
                                    }
                                    {syncTrigger()}
                                </Div>
                            </Div>
                            : <Div c="input-wrap">
                                <label>Value</label>
                                <Div c='sync-wrap'>
                                    {instance.sync ? syncElement() : <input type={instance.type} value={instance.value} onChange={e => setInstance({ ...instance, value: instance.type === 'number' ? Number(e.target.value) : e.target.value })} />}
                                    {syncTrigger()}
                                </Div>
                            </Div>
                    }
                    {syncSuggestion()}
                </Div>
                <Div c='button'><Button onClick={handleSave}>Save</Button></Div>
            </Div>
        </Container>
    );
};

export default StatePopup;

const Container = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    & > .wrap {
        background: ${({ theme }) => theme.popupBg};
        width: 600px;
        height: fit-content;
        max-height: 80vh;
        overflow-y: auto;
        padding: 40px;
        & > .title {
            color: ${({ theme }) => theme.color};
            font-weight: 600;
            font-size: ${({ theme }) => theme.fs4};
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-transform: capitalize;
            margin-bottom: 30px;
            & > svg {
                cursor: pointer;
            }
        }
        & > .content-wrap {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: flex-end;
            .input-wrap {
                width: 100%;
                label {
                    font-weight: 600;
                    text-transform: uppercase;
                    color: ${({ theme }) => theme.color2};
                    font-size: 10px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }
                .sync-wrap{
                    display: flex;
                    padding: 8px 0;
                    width: 100%;
                    & > .options-wrapper{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                    }
                    & > div{
                        width: 100%;
                    }
                    & > input {
                        width:100%;
                        border: none;
                        border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
                        padding: 8px 0;
                        transition: all 0.6s linear;
                        background: transparent;
                        color: ${({ theme }) => theme.color};
                        &:focus{
                            outline: none;
                            border-bottom: 1px solid ${({ theme }) => theme.color};
                        }
                    }
                }
                & > input {
                    width:100%;
                    border: none;
                    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
                    padding: 8px 0;
                    transition: all 0.6s linear;
                    background: transparent;
                    color: ${({ theme }) => theme.color};
                    &:focus{
                        outline: none;
                        border-bottom: 1px solid ${({ theme }) => theme.color};
                    }
                }
            }
            .toggle-wrap{
                display: flex;
                width: 100%;
                justify-content: space-between;
                height: 44px;
                cursor: pointer;
                align-items: center;
                border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
                span{
                    font-weight: 600;
                    text-transform: uppercase;
                    color: ${({ theme }) => theme.color2};
                    font-size: 10px;
                    line-height: 20px;
                }
                & > div {
                    padding: 8px 0;
                    & > .toggle{
                        pointer-events: none;
                    }
                }
            }
        }
        & > .button{
            width: 100%;
            display: flex;
            justify-content: end;
            margin-top: 30px;
            button {
                text-transform: uppercase;
                padding: 11px 22px;
                border-radius: 0;
                background-color: ${({ theme }) => theme.color};
                color: ${({ theme }) => theme.bg};
            }
        }
    }
`;
