import React from 'react';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { dispatchChange, getStyleValue } from 'utils/styleHelpers';
import styled from 'styled-components';
import BlurController from './BlurController';

const Blur = () => {
    const [value, setValue] = React.useState({})
    const { selectedComponent, editComponent } =
        React.useContext(getBuilderContext)
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext)
    const doc = frame.document

    React.useEffect(() => {
        setInitialValue()
    }, [selectedComponent])

    React.useEffect(() => {
        setInitialValue();
    }, [frameUpdate]);

    const setInitialValue = () => {
        const styleValue = getStyleValue(selectedComponent, 'filter', doc)
        setValue(styleValue)
    }

    const forwardChange = (changingValue) => {
        dispatchChange(editComponent, selectedComponent, 'filter', `blur(${changingValue}px)`)
    }

    return (
        <Container>
            <BlurController value={value} forwardChange={forwardChange} />
        </Container>
    );
};

export default Blur;

const Container = styled.div`
width: 100%;
`