import ToggleButton from 'components/ToggleButton';
import { getBuilderContext } from 'contexts/builder';
import { getReferenceContext } from 'contexts/reference';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import DataSourcePopup from './popup';

const dataSource = ({ object, forwardKey, forwardChange }) => {
    const { dataSourcePopup, setDataSourcePopup } = React.useContext(getReferenceContext)
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext)
    const [active, setActive] = React.useState(object.active)
    const handleActive = (val) => {
        setActive(val)
        editComponent(selectedComponent.indices, 'activate option', forwardKey, val)
    }
    return (
        <Container>
            <div className="caption">
                <span>{forwardKey}</span>
            </div>
            <Div c="box">
                <span onClick={() => setDataSourcePopup(true)}>EDIT</span>
                <ToggleButton isToggled={active} setIsToggled={handleActive} />
            </Div>
            {dataSourcePopup && <DataSourcePopup object={object} forwardKey={forwardKey} forwardChange={forwardChange} close={() => setDataSourcePopup(false)} />}
        </Container>
    )
}

export default dataSource;


const Container = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items:center;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.box{
    display: flex;
    align-items: center;
    gap: 10px;
    span{
        background: ${({ theme }) => theme.bg2};
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        color: ${({ theme }) => theme.color};
        font-size: 10px;
        font-weight: 600;
    }
}
`