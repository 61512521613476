import { getBuilderContext } from 'contexts/builder'
import React from 'react'
import { useParams } from 'react-router-dom';
import { recursiveCopy } from 'utils/recursive'

const useUser = (object, selectedType) => {
    const { id } = useParams();
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext)

    React.useEffect(() => {
        if (selectedType === 'Cart') {
            mainCartFunction()
        }
    }, [selectedType])

    const mainCartFunction = () => {
    }
    return null
}

export default useUser