import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import React from 'react';
import { recursiveCopy, recursiveEqual } from 'utils/recursive';

const InitWatchRepeatable = () => {
    const { composition } = React.useContext(getCrossDomainContext);
    const { repeatable: repeatableIndices, getComponent, replaceComponent, setContext } = React.useContext(getBuilderContext);
    const [deepCloneSource, setDeepCloneSource] = React.useState(null);
    const prevChange = React.useRef();

    React.useEffect(() => {
        if (repeatableIndices) {
            const comp = getComponent(repeatableIndices + '-0');
            prevChange.current = comp;
            setDeepCloneSource(repeatableIndices + '-0');
        }
    }, [repeatableIndices]);
    React.useEffect(() => {
        if (repeatableIndices) {
            attemptRepeat();
        }
    }, [composition]);
    const attemptRepeat = () => {
        const parentComponent = getComponent(repeatableIndices);
        if (parentComponent.repeatable) {
            if (deepCloneSource) {
                const perPage = parentComponent.options.perPage?.value
                const hasComponent = getComponent(deepCloneSource);
                if (hasComponent) makeComparison(perPage);
            }
        } else {
            setContext({ repeatable: null });
        }
    };
    const makeComparison = (providedPerPage) => {
        const comp = getComponent(deepCloneSource);
        const noDifference = recursiveEqual(comp, prevChange.current);
        if (noDifference) return null;
        const parentComponent = getComponent(repeatableIndices);
        const copiedChildren = [...parentComponent.children];
        delete parentComponent.children;
        const reducedParent = { ...parentComponent };
        const mutatedChildren = [];
        if(providedPerPage) {
            for(let i = 0; i < Number(providedPerPage); i ++) {
                mutatedChildren.push(recursiveCopy(comp, true))
            }
        } else {
            copiedChildren.forEach(() => mutatedChildren.push(recursiveCopy(comp, true)));
        }
        
        reducedParent.children = mutatedChildren;
        prevChange.current = comp;
        replaceComponent(reducedParent, repeatableIndices);
    };
    return null;
};

export default InitWatchRepeatable;
