/* eslint-disable no-undef */
export const AwsConfigAuth = {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
        domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
        path: "/",
        expires: 365,
        sameSite: "lax",
        secure: process.env.REACT_APP_AUTH_COOKIE_STORAGE_SECURE,
    },
    authenticationFlowType: "USER_PASSWORD_AUTH",
};
export const basePaths = {
    web: process.env.REACT_APP_WEB,
    content: process.env.REACT_APP_CONTENT,
    notification: process.env.REACT_APP_NOTIFICATION,
    product: process.env.REACT_APP_PRODUCT,
    order: process.env.REACT_APP_ORDER,
    webId: process.env.REACT_APP_WEBID
}