import { getCrossDomainContext } from 'contexts/crossDomain';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const HighlightAppendables = () => {
    const { frameUpdate, frameScreen, frame } = React.useContext(getCrossDomainContext);
    const { theme } = React.useContext(getCustomThemeContext);
    const doc = frame.document;
    const selectorClass = 'appendableHighlights';

    const drawHighlights = () => {
        const existingHighlights = [...doc.querySelectorAll(`.${selectorClass}`)]
        existingHighlights.forEach(div => div.remove())
        const appendables = [...doc.querySelectorAll('[data-appendable="true"]')];
        appendables.forEach((appendable) => {
            const { width, height, top, left } = appendable.getBoundingClientRect();

            const shape = doc.createElement('div');
            shape.classList.add(selectorClass);
            doc.body.appendChild(shape);

            shape.style.pointerEvents = 'none';
            shape.style.position = 'absolute';
            shape.style.border = `1px dashed ${theme.color}`;
            shape.style.boxSizing = 'border-box';
            shape.style.opacity = '0.1';
            shape.style.width = `${width}px`;
            shape.style.height = `${height}px`;
            shape.style.top = `${top + frame.scrollY}px`;
            shape.style.left = `${left + frame.scrollX}px`;
        });
    };

    React.useEffect(() => {
        // drawHighlights();
        // setTimeout(() => {
        //     drawHighlights();
        // }, 500);
    }, [frameUpdate, frameScreen]);

    React.useEffect(() => {
        // setInterval(() => {
        //     drawHighlights()
        // }, 1000);
    }, [])

    return null;
};

export default HighlightAppendables;
