import Tabs from 'components/Tabs';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import Div from 'elements/Div';
import { Actions, Options, Styles } from 'modules';
import Animations from 'modules/Animations';
import React from 'react';
import styled from 'styled-components';
import { selectedComponentValues } from 'utils/vanillaFunctions';

const EditComponentPopup = ({ closePopup }) => {
    const popupRef = React.useRef()
    const { selectedComponent } = React.useContext(getBuilderContext)
    const { frame } = React.useContext(getCrossDomainContext)
    const [cursorInfo, setCursorInfo] = React.useState(null)
    const popupMinWidth = 320
    const popupMinHeight = 500
    React.useEffect(() => {
        repositionPopup()
        frame.document.addEventListener('click', watchClickFrame)
        frame.document.addEventListener('click', watchClickParent)
        return () => {
            frame.document.removeEventListener('click', watchClickFrame)
            frame.document.removeEventListener('click', watchClickParent)
        }
    }, [])
    React.useEffect(() => {
        if(cursorInfo) {
            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', mouseUp)
            frame.addEventListener('mouseup', mouseUp)
        }
        else {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', mouseUp)
            frame.removeEventListener('mouseup', mouseUp)
        }
        return () => {
            document.removeEventListener('mousemove',handleMouseMove)
            document.removeEventListener('mouseup', mouseUp)
            frame.removeEventListener('mouseup', mouseUp)
        }
    }, [cursorInfo])
    const watchClickFrame = (e) => {
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        return closePopup()
    }
    const watchClickParent = e => {
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        const elements = [...document.elementsFromPoint(e.clientX, e.clientY)]
        const clickInPopup = elements.find(el => el === popupRef.current)
        if (!clickInPopup) return closePopup()
    }
    const repositionPopup = () => {
        const mainFrame = document.querySelector('#main-frame')
        const doc = frame.document
        if (!doc || !selectedComponent) return null
        const targetSection = doc.querySelector(`[kind][data-track="${selectedComponent.indices}"]`)
        if (!targetSection) return null
        const frameRect = mainFrame.getBoundingClientRect();
        const targetRect = targetSection.getBoundingClientRect();
        const clientWidth = document.documentElement.clientWidth
        const clientHeight = document.documentElement.clientHeight
        const remLeft = targetRect.left
        const remRight = (clientWidth - frameRect.left) - targetRect.right
        const remBottom = (clientHeight - frameRect.top) - targetRect.bottom
        if (remLeft > remRight) {
            if (remLeft > popupMinWidth) {
                popupRef.current.style.right = `${remRight + targetRect.width + 30}px`
            } else {
                popupRef.current.style.left = `${frameRect.left + 30}px`
            }
        } else {
            if (remRight > popupMinWidth) {
                popupRef.current.style.left = `${remLeft + targetRect.width + frameRect.left + 30}px`
            } else {
                popupRef.current.style.left = `${frameRect.left + 30}px`
            }
        }
        if (remBottom > popupMinHeight) {
            popupRef.current.style.top = `${targetRect.top + frameRect.top}px`
        } else {
            popupRef.current.style.bottom = `20vh`
        }
    }
    const handleMovePopup = (e) => {
        const popupPos = popupRef.current.getBoundingClientRect()
        setCursorInfo({ leftoverX: e.clientX - popupPos.left, leftoverY: e.clientY - popupPos.top })
    }
    const mouseUp = () => cursorInfo && setCursorInfo(null)
    const handleMouseMove = (e) => {
        popupRef.current.style.top = `${e.clientY - cursorInfo.leftoverY}px`
        popupRef.current.style.left = `${e.clientX - cursorInfo.leftoverX}px`
    }
    return (
        <Container data-scrollable popupMinWidth={popupMinWidth} popupMinHeight={popupMinHeight} ref={popupRef}>
            <Tabs onMouseDown={handleMovePopup} popupMinWidth={popupMinWidth}>
                <Div label="Design">
                    <Styles modules={selectedComponentValues(selectedComponent?.component)} />
                </Div>
                <Div label="Options">
                    <Options />
                </Div>
                <Div label="Actions">
                    <Actions />
                </Div>
                <Div label="Animations">
                    <Animations />
                </Div>
            </Tabs>
        </Container>
    );
};

export default EditComponentPopup;

const Container = styled.div`
    width: ${({ popupMinWidth }) => popupMinWidth}px;
    height: ${({ popupMinHeight }) => popupMinHeight}px;
    background:${({ theme }) => theme.bg};
    border-radius:8px;
    box-shadow:0 4px 16px rgba(0,0,0,.12), 0 0 0 1px rgba(0,0,0,.02);
    position:absolute;
    z-index:1000;
    overflow-y:scroll;
    animation: fade-in-down 0.5s ease both;
    @keyframes fade-in-down {
        0% {
          opacity: 0;
          margin-top:-10px;
        }
        100% {
          opacity: 1;
          margin-top:0px;
        }
    }
`