import React from 'react';
import styled from 'styled-components';
import useSync from 'utils/useSync';

const ListSource = ({ object, forwardKey, forwardChange, selectedComponent }) => {
    const [value, setValue] = React.useState(object.value)
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: object.sync, operation: { type: 'option', forwardKey, forwardChange } })
    const handleChange = (e) => {
        setValue(e.target.value)
        forwardChange(forwardKey, e.target.value)
    }
    return (
        <Container>
            <div className="caption">
                <span>{forwardKey}</span>
            </div>
            <div className='flex'>
                {object.sync ? syncElement() : (<>
                    <input />
                    {syncTrigger()}
                </>)}
                {syncSuggestion()}
            </div>
        </Container>
    )
}

export default ListSource;

const Container = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items:center;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.flex{
    display: flex;
    input{
        border: none;
        background: ${({ theme }) => theme.bg2};
        padding: 8px 12px;
        border-radius: 4px;
        color: ${({ theme }) => theme.color};
        font-weight: 500;
        max-width: 120px;
        &:focus{
            outline: none;
        }
    }
}
`