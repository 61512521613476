import Button from 'elements/Button';
import React from 'react';
import Animation from 'siro_animations'
import styled from 'styled-components';
import { TrashRed } from 'utils/svg';

const show = ({ instance, setInstance }) => {
    const [modalOpen, setModalOpen] = React.useState(false)
    return (
        <Container>
            <div className="show-wrap">
                <div className='caption'>
                    <span>Animation</span>
                    <div className='icon' onClick={() => setInstance({ ...instance, value: null })}><TrashRed /></div>
                </div>
                {instance.value ?
                    <div className='value'>
                        <span>{instance.value.css}</span>
                        <span>animation-duration: {instance.value.duration};</span>
                        {instance.value.infinite && <span>animation-iteration-count: infinite</span>}
                        <span>{instance.value.keyframe}</span>
                    </div>
                    :
                    <button onClick={() => setModalOpen(true)}>Select Animation</button>
                }
                {modalOpen !== false &&
                    <Animation
                        setModalOpen={setModalOpen}
                        object={instance.value}
                        setObject={(val) => setInstance({ ...instance, value: val })}
                        mode="Entrances"
                    />
                }
            </div>
        </Container>
    );
};

export default show;

const Container = styled.div`
width: 100%;
.show-wrap{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    .caption{
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        span{
            text-transform: uppercase;
            color: ${({ theme }) => theme.color2};
            font-size: 10px;
            line-height: 20px;
            font-weight: 600;
        }
        .icon{
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color:transparent;
            border-radius: 4px;
            transition: background-color .3s ease;
            cursor: pointer;
            &:hover{
                background-color: ${({ theme }) => theme.bg2};
            }
            & > svg{
                width: 16px;
                height: 16px;
            }
        }
    }
    button{
        padding: 11px 8px;
        width: 100%;
        background-color: transparent;
        transition: background-color .3s ease;
        border-radius: 4px;
        font-size: ${({ theme }) => theme.fs2};
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        &:hover{
            background-color: ${({ theme }) => theme.bg2};
        }
    }
    .value{
        padding: 8px 12px;
        background: ${({ theme }) => theme.bg2};
        border-radius: 4px;
        line-height: 22px;
        color: ${({ theme }) => theme.color2};
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}
`
