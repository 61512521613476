import Div from "elements/Div";
import React from "react";
import { FiPlus } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Search, Settings, Trash } from 'utils/svg';
import styled from "styled-components";

const MainPages = ({ setPopupInstance, pages, handleDelete, slug, webId }) => {
    const [isActive, setActive] = React.useState(false)
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = React.useState('')

    const filteredPages = pages.filter(page => {
        return page?.name && page.name.toLowerCase().includes(searchTerm.toLowerCase())
    });

    return (
        <Container>
            <Div c={`title-wrap ${isActive && "active"}`} onClick={() => setActive(!isActive)}>
                <Div c="title">Main Pages</Div>
                <Div c="icons">
                    <Div c="add" onClick={() => setPopupInstance({})}>
                        <FiPlus />
                    </Div>
                    <Div c="arrow">
                        <IoIosArrowDown />
                    </Div>
                </Div>
            </Div>
            {isActive &&
                <Div c="main-wrap">
                    <Div c="search-wrap">
                        <Div c="search-bar">
                            <Search />
                            <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </Div>
                    </Div>
                    <Div c="pages-wrap">
                        {filteredPages.length > 0 ? filteredPages.map((page) => (
                            <Div open={slug === page.slug} c="page" key={page.id}>
                                {slug !== page.slug ? <Trash onClick={(e) => handleDelete(e, page.id)} className="delete" /> : ''}
                                <Div c="name-wrap" onClick={() => navigate(`/${webId}?slug=${page.slug}&type=page`)}>
                                    <span>{page.name}</span>
                                    <span className='slug'>{page.slug}</span>
                                </Div>
                                <Settings onClick={() => setPopupInstance({ ...page })} className="edit" />
                            </Div>
                        )) : <Div c='empty'>Searched result not found</Div>}
                    </Div>
                </Div>
            }
        </Container>
    )
}

export default MainPages;

const Container = styled.div`
& > .title-wrap{
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .title{
        font-weight: 600;
        line-height: 24px;
    }
    .icons{
        display: flex;
        align-items: center;
        gap: 5px;
        .add, .arrow{
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            transition: all 0.3s linear;
            border-radius: 4px;
            svg{
                width: 16px;
                height: 16px;
            }
            &:hover{
                background: ${({ theme }) => theme.bg2};
                svg{
                    path{
                        fill: ${({ theme }) => theme.color2};
                    }
                }
            }
        }
    }
    &.active{
        & > .icons{
            & > .arrow{
                rotate: 180deg;
            }
        }
    }
}
& > .main-wrap{
    width: 100%;
    overflow-x: hidden;
    .search-wrap{
        border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
        .search-bar{
            padding:10px;
            display:flex;
            align-items:center;
            gap:10px;
            border-radius:4px;
            svg{
                width: 22px;
                height: 22px;
            }
            input{
                width: 100%;
                border: none;
                background: transparent;
                color: ${({ theme }) => theme.color};
                &:focus{
                    outline: none;
                }
            }
        }
    }
    & > .pages-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        .empty{
            padding: 10px;
        }
        & > .page {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 6px;
            color: ${({ theme }) => theme.color2};
            text-transform: capitalize;
            background-color: ${({ theme }) => theme.bg};
            transition: all 0.5s ease;
            border-radius: 0;
            position: relative;
            padding: 0;
            width: 100%;
            & > .name-wrap {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: flex;
                align-items: flex-end;
                gap: 5px;
                padding: 15px 35px;
                width: 220px;
                span{
                    font-size: ${({ theme }) => theme.fs};
                    font-weight: 500;
                    color: ${({ theme }) => theme.color};
                    &.slug{
                        color: ${({ theme }) => theme.color2};
                        font-weight: 400;
                        font-size: 10px;
                        display: none;
                    }
                }
            }
            & > svg{
                width: 16px;
                height: 16px;
                cursor: pointer;
                path: {
                    fill: ${({ theme }) => theme.color};
                }
            }
            & > .edit {
                margin: auto;
                pointer-events: auto;
                position: absolute;
                right: -30px;
                top: 0;
                bottom: 0;
                transition: right 0.3s ease;
            }
            & > .delete {
                position: absolute;
                pointer-events: auto;
                left: -30px;
                top: 0;
                bottom: 0;
                margin: auto;
                transition: left 0.3s ease;
            }
            &:hover, &[open]{
                background-color: ${({ theme }) => theme.bg2};
                & > .name-wrap{
                    .slug{
                        display: block;
                    }
                }
                & > .delete{
                    left: 10px;
                }
                & > .edit{
                    right: 10px;
                }
            }
        }
    }
}
`