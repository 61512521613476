import FlexDivider from 'components/FlexDivider';
import { getReferenceContext } from 'contexts/reference';
import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import request from 'utils/request';
import {FcDatabase} from 'react-icons/fc'
import Library from 'siro_lib';
import { recursiveCopy } from 'utils/recursive';
const Basics = Library.Basics;
const Datalink = Basics.Datalink

const DataTemplates = () => {
    const [dataTemplates, setDataTemplates] = React.useState([]);
    const {id: webId} = useParams()
    const {setDataTemplateInstance, InsertingElement} = React.useContext(getReferenceContext)
    const fetchData = async () => {
        const result = await request({url: window.urls.web + `/data_template`, headers: {'web-id': webId}})
        setDataTemplates(result.data)
    };
    React.useEffect(() => {
        fetchData();
    }, []);
    const handleDrag = (e, template) => {
        e.stopPropagation();
        e.dataTransfer.effectAllowed = 'copy'; //Operation instruction
        const propData = {}
        Object.keys(Datalink.propData).forEach(key => {
            const value = Datalink.propData[key].defaultValue
            propData[key] = value
        })
        // const unfrozenProps = JSON.parse(JSON.stringify(propData))
        const unfrozenProps = recursiveCopy(propData)
        const component = {
            ...unfrozenProps,
            type: 'Basics',
            component: 'Datalink',
            name: template.name
        }
        component.options.dataTemplateId = { value: template.id, type: 'hidden' }
        component.options.webId = {value: webId, type: 'hidden'}
        InsertingElement.current = component; //Storing element to insert
        console.log(component, 'inserting what?')
    }
    return (
        <FlexDivider grid={3}>
            {dataTemplates.map(dataTemplate =>(
                <Cardbox draggable onDragStart={e=>handleDrag(e, dataTemplate)} className='cardbox' key={dataTemplate.id}>
                    <FcDatabase/>
                    <span>{dataTemplate.name}</span>
                </Cardbox>
            ))}
            <Cardbox onClick={() => setDataTemplateInstance({})} className="cardbox addnew">
                <AiOutlinePlus />
                <span>Add new</span>
            </Cardbox>
        </FlexDivider>
    );
};

export default DataTemplates;

const Cardbox = styled.div`
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({theme})=>theme.sectionBorderColor};
    border-radius: 4px;
    background: ${({ theme }) => theme.bg};
    flex-direction: column;
    gap: 6px;
    cursor: pointer;
    & > svg {
        font-size:20px;
    }
    &.addnew {
        color: ${({ theme }) => theme.mainColor};
        svg {
            font-size: 20px;
        }
    }
`;
