import Div from "elements/Div";
import React from "react";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { Close } from "utils/svg";

const select = ({ options, value, onChange, height }) => {
    const [active, setActive] = React.useState(false)
    const handleDropdownItem = (val) => {
        onChange(val)
        setActive(!active)
    }
    return (
        <Container>
            <Div c="dropdown-wrap">
                <Div c={`dropdown-head ${active && 'active'}`} onClick={() => setActive(!active)}>
                    <span>{value == null ? 'Choose one' : value}</span>
                    {value && <Div c='close' onClick={() => onChange(null)}><Close /></Div>}
                    <Div c='arrow'><IoIosArrowDown /></Div>
                </Div>
                {active && <Div c='dropdown' style={{maxHeight: `${height ? height : '200px'}`}}>
                    {options?.map((val, i) => (
                        <Div c="item" key={i} onClick={() => handleDropdownItem(val)}>{val}</Div>
                    ))}
                </Div>}
            </Div>
        </Container>
    );
};

export default select;

const Container = styled.div`
margin-top: 5px;
.dropdown-wrap{
    width: 100%;
    position: relative;
    .dropdown-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: ${({ theme }) => theme.bg2};
        border-radius: 4px;
        padding: 8px 12px;
        cursor: pointer;
        position: relative;
        span{
            font-size: ${({ theme }) => theme.fs};
            color: ${({ theme }) => theme.color};
            font-weight:500;
        }
        .arrow{
            background: ${({ theme }) => theme.bg2};
            transition: all 0.3s linear;
            z-index: 1;
            display: flex;
            align-items: center;
            svg{
                width: 14px;
                height: 14px;
            }
        }
        .close{
            position: absolute;
            right: 14px;
            transition: right 0.3s ease;
            display: flex;
            align-items: center;
            svg{
                width: 14px;
                height: 14px;
                path{
                    fill: ${({ theme }) => theme.color2};
                }
            }
        }
        &:hover{
            .close{
                right: 28px;
            }
        }
        &.active{
            .arrow{
                svg{
                    rotate: 180deg;
                }
            }
        }
    }
    .dropdown{
        top: 120%;
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 120%;
        border-radius: 4px;
        background: ${({ theme }) => theme.bg};
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
        min-width: 100px;
        background: ${({ theme }) => theme.bg2};
        overflow-y: auto;
        .item{
            padding: 10px 15px;
            font-size: ${({ theme }) => theme.fs};
            font-weight: 600;
            color: ${({ theme }) => theme.color};
            border-radius: 4px;
            &:hover {
                background: ${({ theme }) => theme.lightMainColor};
                color: ${({ theme }) => theme.mainColor};
                cursor: pointer;
            }
        }
    }
}
`
