import LeftTabs from 'components/LeftTabs'
import React from 'react'
import styled from 'styled-components'

const LeftSection = () => {
    return (
        <Container>
            <LeftTabs />
        </Container>
    )
}

export default LeftSection

const Container = styled.div`
    transition: 0.5s ease;
    width: 56px;
    border-right: 1px solid ${({ theme }) => theme.sectionBorderColor};
    height: 100%;
`
