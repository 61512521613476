import React from 'react'
import styled from 'styled-components'

const ScaleController = ({ value, forwardChange }) => {
    const [data, setData] = React.useState(1)

    React.useEffect(() => {
        if (value !== undefined) {
            setData(data)
        }
        else {
            setData(1)
        }
    }, [value])

    const handleBlur = () => {
        setTimeout(() => {
            forwardChange(data)
        }, 150);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                forwardChange(data)
            }, 150);
        }
    };
    return (
        <Container>
            <div className='wrap'>
                <input type="number" value={data} onChange={(e) => setData(e.target.value)} onKeyDown={handleKeyDown} onBlur={handleBlur} />
            </div>
        </Container>
    )
}

export default ScaleController

const Container = styled.div`
width: 100%;
.wrap{
    display: flex;
    gap: 10px;
    input{
        outline: none;
        background: ${({theme})=> theme.bg2};
        color: ${({theme}) => theme.color};
        border: none;
        padding: 10px 25px 10px 12px;
        border-radius: 4px;
        width: 100%;
    }
}
`