import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const HandleResizeComponent = () => {

    const { frame, frameUpdate, frameScreen } = React.useContext(getCrossDomainContext)
    const { theme } = React.useContext(getCustomThemeContext)
    const doc = frame.document
    const { selectedComponent, editComponent } = React.useContext(getBuilderContext)
    const [pointerLock, setPointerLock] = React.useState(false)
    const [startingPoint, setStartingPoint] = React.useState(null)

    React.useEffect(() => {
        checkComponent()
    }, [selectedComponent, frameUpdate, frameScreen])

    React.useEffect(() => {
        doc.addEventListener('mousedown', handleMouseDown)
        if(pointerLock) {
            doc.addEventListener('mouseup', handleMouseUp)
            doc.addEventListener('mousemove', handleMouseMove)
        } else {
            doc.removeEventListener('mouseup', handleMouseUp)
            doc.removeEventListener('mousemove', handleMouseMove)
        }
        return () => {
            doc.removeEventListener('mousedown', handleMouseDown)
            doc.removeEventListener('mouseup', handleMouseUp)
            doc.removeEventListener('mousemove', handleMouseMove)
        }
    }, [selectedComponent, pointerLock, startingPoint])

    const handleMouseMove = (e) => {
        if(!startingPoint) return null
        const { startX, startY, parentSpaceX, parentSpaceY, nodeWidth, nodeHeight } = startingPoint
        const stretchX =  e.clientX - startX
        const stretchY =  e.clientY - startY
        const stretchedWidth = nodeWidth + stretchX
        const stretchedHeight = nodeHeight + stretchY
        let tmpStyleText = resizeTmpStyleInit

        // Draw cursor info
        const cursorWrapperX = doc.querySelector(`[bl-resize-cursor-wrap] [x]`)
        cursorWrapperX.innerText = `Width: ${Math.round(stretchedWidth)}px`
        const cursorWrapperY = doc.querySelector(`[bl-resize-cursor-wrap] [y]`)
        cursorWrapperY.innerText = `Height: ${Math.round(stretchedHeight)}px`
        const cursorWrapper = doc.querySelector(`[bl-resize-cursor-wrap]`)
        cursorWrapper.style.left = `${e.clientX + 15}px`
        cursorWrapper.style.top = `${e.clientY + 15}px`

        // Limit x direction
        if(stretchedWidth > parentSpaceX) {
            tmpStyleText += `[data-track="${selectedComponent.indices}"] {
                width: 100%;
            }
            #bl-select{
                border-left-color: red !important;
                border-right-color: red !important;
            }`
            cursorWrapperX.innerText = `Width: 100%`
        } else {
            tmpStyleText += `[data-track="${selectedComponent.indices}"] {
                width:${stretchedWidth}px;
            }`
        }
        // Limit y direction
        if(stretchedHeight > parentSpaceY) {
            tmpStyleText += `[data-track="${selectedComponent.indices}"] {
                height: 100%;
            }
            #bl-select{
                border-top-color: red !important;
                border-bottom-color: red !important;
            }`
            cursorWrapperY.innerText = `Height: 100%`
        } else {
            tmpStyleText += `[data-track="${selectedComponent.indices}"] {
                height:${stretchedHeight}px;
            }`
        }

        // Assign resize tmp style value
        const resizeTmp = doc.querySelector('#resize-tmp-style')
        resizeTmp.innerText = tmpStyleText

        editComponent(selectedComponent.indices, [
            {
                type: 'style',
                changes: [
                    { key: 'width', value: stretchedWidth > parentSpaceX ? '100%' : `${Math.round(stretchedWidth)}px` },
                    { key: 'height', value: stretchedHeight > parentSpaceY ? '100%' : `${Math.round(stretchedHeight)}px` },
                ],
            },
        ]);

    }

    const handleMouseDown = (e) => {
        // Cleanup existing node and event
        doc.querySelector('#pointer-lock-helper')?.remove()
        doc.querySelector('#resize-tmp-style')?.remove()
        
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        const isResizeElement = e.target.hasAttribute('bl-resize-node');
        if (!isResizeElement) return null;

        // Append pointer lock helper style
        const pointerLockHelper = doc.createElement('style')
        pointerLockHelper.setAttribute('id', 'pointer-lock-helper')
        pointerLockHelper.innerText = pointerLockStyle
        doc.head.appendChild(pointerLockHelper)

        // Append contextual helper style
        const resizeTmpHelper = doc.createElement('style')
        resizeTmpHelper.setAttribute('id', 'resize-tmp-style')
        doc.head.appendChild(resizeTmpHelper)

        // Append cursor info wrapper
        const cursorWrapper = doc.createElement('div')
        cursorWrapper.setAttribute('bl-resize-cursor-wrap', '')
        const cursorX = doc.createElement('div')
        cursorX.setAttribute('x', '')
        const cursorY = doc.createElement('div')
        cursorY.setAttribute('y', '')
        cursorWrapper.appendChild(cursorX)
        cursorWrapper.appendChild(cursorY)
        doc.body.appendChild(cursorWrapper)

        //Get parent space
        const targetNode = doc.querySelector(`[kind][data-track="${selectedComponent.indices}"]`)
        const targetComputed = frame.window.getComputedStyle(targetNode)
        const parentComputed = frame.window.getComputedStyle(targetNode.parentElement);
        const parentWidth = parseFloat(parentComputed.width, 10)
        const parentLeft = parseFloat(parentComputed.paddingLeft, 10)
        const parentRight = parseFloat(parentComputed.paddingRight, 10)
        const parentSpaceX = parentWidth - (parentLeft + parentRight)
        const parentHeight = parseFloat(parentComputed.height, 10)
        const parentTop = parseFloat(parentComputed.paddingTop, 10)
        const parentBottom = parseFloat(parentComputed.paddingBottom, 10)
        const parentSpaceY = parentHeight - (parentTop + parentBottom)
        const nodeWidth = parseFloat(targetComputed.width, 10)
        const nodeHeight = parseFloat(targetComputed.height, 10)

        // Assign values
        setStartingPoint({ startX: e.clientX, startY: e.clientY, parentSpaceX, parentSpaceY, nodeWidth, nodeHeight })
        setPointerLock(true)
    }

    const handleMouseUp = (e) => {
        if(!pointerLock) return null
        // Cleanup existing node and event
        doc.querySelector('#pointer-lock-helper')?.remove()
        doc.querySelector('#resize-tmp-style')?.remove()
        setPointerLock(false)
    }

    const checkComponent = () => {
        if(!selectedComponent) return clearController()
        const targetNode = doc.querySelector(`[kind][data-track="${selectedComponent.indices}"]`)
        if(!targetNode) return clearController()
        const parentNode = targetNode.parentElement
        if(!parentNode) return clearController()
        const targetKind = targetNode.getAttribute('kind')
        if(targetKind === 'grid') return clearController()
        const parentKind = parentNode.getAttribute('kind')
        if(parentKind === 'grid') return clearController()
        const targetRect = targetNode.getBoundingClientRect()
        if(targetRect.width < 20 || targetRect.height < 20) return clearController()
        drawController(targetNode, parentNode)
    }
    const clearController = () => {
        // Cleanup dom node
        doc.querySelector('[bl-resize-node]')?.remove()
        doc.querySelector('#bl-resize-node-style')?.remove()
    }
    const drawController = (targetNode) => {

        // Cleanup dom node
        doc.querySelector('[bl-resize-node]')?.remove()
        doc.querySelector('#bl-resize-node-style')?.remove()

        const nodeRect = targetNode.getBoundingClientRect()
        const resizeNode = doc.createElement('div')
        resizeNode.setAttribute('bl-resize-node', '')
        resizeNode.innerHTML = `<img src="/img/section-icons/resize-node.svg"/>`
        resizeNode.style.top = `${nodeRect.top + nodeRect.height + frame.scrollY - 15}px`
        resizeNode.style.left = `${nodeRect.left + nodeRect.width + frame.scrollX - 15}px`
        doc.body.appendChild(resizeNode)

        // Append style node
        const styleNode = doc.createElement('style')
        styleNode.setAttribute('id', 'bl-resize-node-style')
        styleNode.innerText = resizeNodeStyle
        doc.head.appendChild(styleNode)

    }

    const resizeTmpStyleInit = `
        [bl-resize-cursor-wrap] {
            position:fixed;
            background:black;
            color:white;
            display:flex;
            flex-direction:column;
            padding:8px;
            gap:6px;
            font-family: sans-serif;
            font-size:12px;
            font-weight:300;
        }
        [bl-resize-cursor-wrap] [x],[y] {

        }
    `

    const resizeNodeStyle = `
        [bl-resize-node] {
            width:15px;
            height:15px;
            display:flex;
            justify-content:center;
            align-items:center;
            position:absolute;
            z-index:1000;
            pointer-events:auto;
            cursor:nwse-resize;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
        [bl-resize-node] img {
            width:100%;
            height:100%;
            pointer-events:none;
        }
    `

    const pointerLockStyle = `
        [kind] {
            pointer-events:none !important;
            user-drag: none;
            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
        [bl-resize-node] {
            opacity: 0 !important;
        }
        [bl-block-menu="wrapper"] {
            opacity: 0 !important;
        }
        #selectHelperWrap {
            opacity: 0 !important;
        }
    `

    return null
};

export default HandleResizeComponent;