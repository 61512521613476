import React from 'react'
import request from 'utils/request';
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import Div from 'elements/Div'
import { FaCheck } from "react-icons/fa";
import { getBuilderContext } from 'contexts/builder';
import { recursiveCopy } from 'utils/recursive';

const useCategory = (object, selectedType) => {
    const { id } = useParams()
    const [category, setCategory] = React.useState(null)
    const [depth1, setDepth1] = React.useState(null)
    const [depth2, setDepth2] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext)
    const sourceDetail = object.sourceDetail.value ?? { webId: id }

    const fetchCategory = async () => {
        try {
            const result = await request({
                url: window.urls.product + `/category/search`,
                method: 'post',
                headers: { webid: id },
                body: {},
            })
            setCategory(result.data.data)
            setLoading(true)
        } catch (e) {
            console.log(e, 'err fetching category')
        }
    }

    const fetchDepthCategory = async (category) => {
        try {
            const result = await request({
                url: window.urls.product + `/category/${category.categoryId}`,
                method: 'get',
                body: {},
            })

            const hitsList = result['data']['_source']['children']['categories_by_depth']['buckets'].length > 0
                ?
                result['data']['_source']['children']['categories_by_depth']['buckets'][0]['categories_by_parent']['buckets'][0]['categories']['hits']['hits']
                :
                null
            if (category.depth == 0)
                setDepth1(hitsList)
            else
                setDepth2(hitsList)

            setLoading(true)
        } catch (e) {
            console.log(e, 'err fetching depth 1 category')
        }
    }

    const handleCategory = (category) => {
        if (sourceDetail?.categoryId == category?._id) {
            const copiedObject = recursiveCopy(object, true);
            copiedObject.sourceDetail.value = { webId: id };
            editComponent(selectedComponent.indices, 'options', 'dataSource', copiedObject);
            return null
        }
        const updatedSourceDetail = category !== null ? { webId: id, depth: category?._source.depth, categoryId: category?._id, name: category?._source.name } : { webId: id };
        const copiedObject = recursiveCopy(object, true);
        copiedObject.sourceDetail.value = updatedSourceDetail;
        editComponent(selectedComponent.indices, 'options', 'dataSource', copiedObject);
    }

    React.useEffect(() => {
        if (selectedType === 'Categories') {
            mainFunction()
        }
    }, [selectedType])

    React.useEffect(() => {
        fetchCategory()
    }, [])

    React.useEffect(() => {
        if (sourceDetail?.categoryId) {
            fetchDepthCategory(sourceDetail)
        } else {
            setDepth1(null)
            setDepth2(null)
        }

        if (sourceDetail?.depth == 0) {
            setDepth2(null)
        }
    }, [sourceDetail])

    React.useEffect(() => {
        if (object.sourceDetail.value == null){
            handleCategory(null)
        }
    }, [object])

    const CategoryRenderer = ({ data, caption, check }) => (
        <Div c='categories'>
            <Div c='caption'>{caption}</Div>
            {data && data.map((item, i) => (
                <Div c={`category-wrap ${item._id === sourceDetail?.categoryId && 'active'}`} key={i} onClick={() => check && handleCategory(item)}>
                    {check ? <Div c='check'><FaCheck /></Div> : ''}
                    <Div c='category' key={i}>
                        <span>{item._source.name}</span>
                    </Div>
                </Div>
            ))}
        </Div>
    )
    const mainFunction = () => {
        return (
            <Category>
                {loading ? <Div c='wrapper'>
                    <Div c="caption">Categories</Div>
                    <Div c="categories">
                        {category?.map((data, i) => (
                            <Div c={`category-wrap ${data._id == sourceDetail?.categoryId && 'active'}`} key={i} onClick={() => handleCategory(data)}>
                                <Div c='check'><FaCheck /></Div>
                                <Div c='category' key={i}>
                                    <span>{data._source.name}</span>
                                </Div>
                            </Div>
                        ))}
                    </Div>
                    {depth1 && <CategoryRenderer data={depth1} caption={`Categories in Depth 1`} check={true} />}
                    {depth2 && <CategoryRenderer data={depth2} caption={`Categories in Depth 2`} check={false} />}
                </Div> : "loading"}
            </Category>
        )
    }
    return selectedType === 'Categories' ? mainFunction() : null
}

export default useCategory

const Category = styled.div`
.wrapper{
    .categories{
        display: flex;
        flex-direction: column;
        gap: 5px;
        .category-wrap{
            display: flex;
            gap: 5px;
            height: 30px;
            .check{
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                background: ${({ theme }) => theme.bg2};
                & > svg{
                    display: none;
                }
            }
            .category{
                width: calc(100% - 30px);
                height: 100%;
                padding: 8px 12px;
                background: ${({ theme }) => theme.bg2};
                border-radius: 4px;
                cursor: pointer;
            }
            &.active{
                .check{
                    & > svg{
                        display: flex;
                    }
                }
            }
        }
    }
}
`