import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { RxDotFilled } from 'react-icons/rx'
import { getBuilderContext } from 'contexts/builder';
import PropTypes from 'prop-types';
import GenericNumber from 'modules/Styles/GenericNumber';

const AlignBox = ({ value: propDimensions, forwardChange }) => {
    const { selectedComponent: { indices } } = React.useContext(getBuilderContext)

    const positions = [
        { name: 'Top left', x: 'flex-start', y: 'flex-start' },
        { name: 'Top center', x: 'center', y: 'flex-start' },
        { name: 'Top right', x: 'flex-end', y: 'flex-start' },
        { name: 'Middle left', x: 'flex-start', y: 'center' },
        { name: 'Middle center', x: 'center', y: 'center' },
        { name: 'Middle right', x: 'flex-end', y: 'center' },
        { name: 'Bottom left', x: 'flex-start', y: 'flex-end' },
        { name: 'Bottom center', x: 'center', y: 'flex-end' },
        { name: 'Bottom right', x: 'flex-end', y: 'flex-end' },
    ]
    return (
        <Container>
            <Div c="mainBox">
                {positions.map((p, i) => (
                    <Div
                        key={indices + '-' + i}
                        open={p.x === propDimensions.x && p.y === propDimensions.y}
                        onClick={() => {
                            if (p.x === propDimensions.x && p.y === propDimensions.y) {
                                forwardChange({ x: '', y: '' })
                            } else {
                                forwardChange({ x: p.x, y: p.y })
                            }
                        }}
                    >
                        <RxDotFilled />
                    </Div>
                ))}
            </Div>
            <Div c="absoluteBoxes top"><GenericNumber attributeName='paddingTop' /></Div>
            <Div c="absoluteBoxes bottom"><GenericNumber attributeName='paddingBottom' /></Div>
            <Div c="absoluteBoxes left"><GenericNumber attributeName='paddingLeft' /></Div>
            <Div c="absoluteBoxes right"><GenericNumber attributeName='paddingRight' /></Div>
        </Container>
    );
};

export default AlignBox;

AlignBox.propTypes = {
    value: PropTypes.any,
    forwardChange: PropTypes.func
}

const Container = styled.div`
    width:100%;
    max-width:134px;
    display: grid;
    place-items: center;
    gap: 5px;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    & > .mainBox{
        background:${({ theme }) => theme.bg2};
        border-radius:2px;
        border:1px solid ${({ theme }) => theme.sectionBorderColor};
        display:flex;
        flex-wrap:wrap;
        grid-column-start: 2;
        grid-row-start: 2;
        & > div {
            width:calc(${100 / 3}% - 8px);
            margin:4px;
            background:${({ theme }) => theme.bg2};
            border-radius:6px;
            display:flex;
            align-items:center;
            justify-content:center;
            transition:0.2s ease;
            cursor:pointer;
            position:relative;
            &:hover{
                background:${({ theme }) => theme.bg3};
            }
            &[open]{
                &:after{
                    content:"";
                    display:block;
                    position:absolute;
                    width:13px;
                    height:13px;
                    background:${({ theme }) => theme.mainColor};
                    top:calc(50% - 6.5px);
                    left:calc(50% - 6.5px);
                    border-radius:2.5px;
                }
                &:hover{
                    background:${({ theme }) => theme.bg2};
                    cursor:alias;
                }
            }
        }
    }
    & > .absoluteBoxes {
        & > div{
            & > input{
                padding: 0;
                text-align:center;
                width: 30px;
                height: 30px;
            }
        }
        &.top{
            grid-column-start: 2;
            grid-row-start: 1;
        }
        &.right{
            grid-column-start: 3;
            grid-row-start: 2;
        }
        &.bottom{
            grid-column-start: 2;
            grid-row-start: 3;
        }
        &.left{
            grid-column-start: 1;
            grid-row-start: 2;
        }
    }
`;
