import React from 'react';
import { getBuilderContext } from '../../../contexts/builder';
import * as OptionComponents from '.';
import styled from 'styled-components';

const Options = () => {
    const { selectedComponent, editComponent } = React.useContext(getBuilderContext)
    const { options } = selectedComponent

    const handleChange = (key, value, alternativeOption) => {
        if (alternativeOption === 'add option sync' || alternativeOption === 'remove option sync') {
            editComponent(selectedComponent.indices, alternativeOption, key, value);
        } else {
            editComponent(selectedComponent.indices, 'options', key, value)
        }
    }

    return (
        <Container>
            {Object.keys(options).map((optionKey) => {
                if (options[optionKey].type === 'hidden') return null
                const OptionType = optionKey != 'link' ? OptionComponents[options[optionKey].type] : OptionComponents['link']
                return (
                    <div className="options_instance" key={selectedComponent.indices + optionKey}>
                        <OptionType
                            object={options[optionKey]}
                            forwardKey={optionKey}
                            forwardChange={handleChange}
                            selectedComponent={selectedComponent}
                        />
                    </div>
                )
            })}
        </Container>
    );
};

export default Options;

const Container = styled.div`
.options_instance{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .caption{
        font-weight: 500;
        text-transform: capitalize;
        color: ${({ theme }) => theme.color};
        font-size: 12px;
        line-height: 20px;
        padding: 10px 0;
    }
}
`;
