import React from 'react';
import Library from 'siro_lib';
import DragBox from '../components/DragBox';
import { reduceStructure } from '../helpers';
import FlexDivider from 'components/FlexDivider';
const Basics = Library.Basics;

const Elements = () => {
    const cloned = {...Basics}
    const elements = reduceStructure(cloned, 'Basics');
    return (
        <FlexDivider grid={3}>
            {elements.map((element) => (
                <DragBox component={element} key={Math.random()} />
            ))}
        </FlexDivider>
    );
};

export default React.memo(Elements);
