import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { IoIosArrowDown } from "react-icons/io";
import { useParams } from 'react-router-dom';
import request from 'utils/request';
import { Close } from "utils/svg";
import useSync from 'utils/useSync';


const ProductBrand = ({ object, forwardKey, forwardChange }) => {
    const [brand, setBrand] = React.useState(null)
    const { id } = useParams()
    const [searchQuery, setSearchQuery] = React.useState(object.value || '')
    const [active, setActive] = React.useState(false)
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: object.sync, operation: { type: 'option', forwardKey, forwardChange } })
    const [selectedBrand, setSelectedBrand] = React.useState('')

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const fetchBrand = async () => {
        try {
            const result = await request({
                url: window.urls.product + `/brand`,
                method: 'get',
                headers: { webid: id },
                body: {},
            })
            setBrand(result.data)
        } catch (e) {
            console.log(e, 'err fetching brand')
        }
    }
    React.useEffect(() => {
        fetchBrand()
    }, [])

    const filteredCategories = brand
        ? brand.filter((item) =>
            item._source.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : []

    const handleDropdownItem = (val) => {
        if (val !== null)
            forwardChange(forwardKey, val._source.slug)
        else
            forwardChange(forwardKey, null)

        setActive(false)
        if (val == null){
            setSelectedBrand(null)
            setSearchQuery('')
        }
        else{
            setSelectedBrand(val._source.name)
            setSearchQuery(val._source.name)
        }
    }

    React.useEffect(() => {
        if (selectedBrand) {
            setSearchQuery(selectedBrand);
        }
    }, [selectedBrand]);

    return (
        <Container>
            <div className="caption">
                <span>{forwardKey}</span>
            </div>
            {object.sync ? syncElement() : (
                <div className='flex'>
                    <div className="dropdown-wrap">
                        <Div c={`dropdown-head ${active && 'active'}`} onClick={() => setActive(!active)}>
                            <input type='text' placeholder='Choose one' value={searchQuery} onChange={handleSearch} />
                            {selectedBrand !== null && (
                                <Div c="close" onClick={() => handleDropdownItem(null)}><Close /></Div>
                            )}
                            <Div c="arrow"><IoIosArrowDown /></Div>
                        </Div>
                        {active && (
                            <Div c="dropdown">
                                {filteredCategories.map((val, i) => (
                                    <Div c="item" key={i} onClick={() => handleDropdownItem(val)}>{val._source.name}</Div>
                                ))}
                            </Div>
                        )}
                    </div>
                    {syncTrigger()}
                </div>
            )}
            {syncSuggestion()}
        </Container>
    )
}

export default ProductBrand;

const Container = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items:center;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.flex{
    display: flex;
    .dropdown-wrap{
        position: relative;
        width: 100%;
        .dropdown-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: ${({ theme }) => theme.bg2};
            border-radius: 4px;
            padding: 8px 12px;
            cursor: pointer;
            position: relative;
            span{
                font-size: ${({ theme }) => theme.fs};
                color: ${({ theme }) => theme.color};
                font-weight:500;
            }
            input{
                font-size: ${({ theme }) => theme.fs};
                color: ${({ theme }) => theme.color};
                font-weight:500;
                background: transparent;
                width: 90%;
                border: none;
                &::placeholder{
                    font-size: ${({ theme }) => theme.fs};
                    color: ${({ theme }) => theme.color};
                    font-weight:500;
                }
                &:focus{
                    outline: none;
                }
            }
            .arrow{
                background: ${({ theme }) => theme.bg2};
                transition: all 0.3s linear;
                z-index: 0;
                display: flex;
                align-items: center;
                svg{
                    width: 14px;
                    height: 14px;
                }
            }
            .close{
                position: absolute;
                right: 14px;
                transition: right 0.3s ease;
                display: flex;
                align-items: center;
                bottom: 0;
                top: 0;
                svg{
                    width: 14px;
                    height: 14px;
                    path{
                        fill: ${({ theme }) => theme.color2};
                    }
                }
            }
            &:hover{
                .close{
                    right: 28px;
                }
            }
            &.active{
                .arrow{
                    svg{
                        rotate: 180deg;
                    }
                }
            }
        }
        .dropdown{
            top: 120%;
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            top: 120%;
            border-radius: 4px;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
            min-width: 100px;
            background: ${({ theme }) => theme.bg2};
            .item{
                padding: 10px 15px;
                font-size: ${({ theme }) => theme.fs};
                font-weight: 600;
                color: ${({ theme }) => theme.color};
                border-radius: 4px;
                &:hover {
                    background: ${({ theme }) => theme.lightMainColor};
                    color: ${({ theme }) => theme.mainColor};
                    cursor: pointer;
                }
            }
        }
    }
}
`