import React from 'react';
import styled from 'styled-components';
import StateList from './StateList';
import { getBuilderContext } from 'contexts/builder';
import EventsList from './EventsList'
import CaseList from './CaseList/CaseList';

const Actions = () => {
    const { selectedComponent } = React.useContext(getBuilderContext);
    return selectedComponent ? (
        <Container>
            <StateList />
            <EventsList/>
            <CaseList/>
        </Container>
    ) : null;
};

export default Actions;

const Container = styled.div`
`;
