import React from "react";
import styled from "styled-components";
import * as Icons from 'utils/svg';
import * as ImportedModules from 'modules';
import Div from "elements/Div";
import { getReferenceContext } from "contexts/reference";
import LanguageSwitch from "./LanguageSwitch";
import { getCrossDomainContext } from "contexts/crossDomain";

const LeftTabs = () => {
    const { draggableModule, setDraggableModule, localStylesSettings, setLocalStylesSettings, languageSwitching, setLanguageSwitching, inappPopup, setInappPopup } = React.useContext(getReferenceContext)
    const { language, availableLanguages } = React.useContext(getCrossDomainContext)
    const [selectedTab, setSelectedTab] = React.useState(null)
    const modules = [
        { module: 'Layers', icon: Icons.Layers },
        { module: 'Pages', icon: Icons.Pages },
    ]
    const SelectedModule = ImportedModules[modules[selectedTab]?.module]

    const handleSelect = (i) => {
        setLanguageSwitching(false)
        if (selectedTab != i)
            setSelectedTab(i)
        else
            setSelectedTab(null)
    }

    return (
        <Container>
            <Div c="tabs">
                <Div c="wrap">
                    {modules.map((module, i) => (
                        <Div
                            key={i}
                            open={i === selectedTab}
                            disabled={i === selectedTab}
                            onClick={() => handleSelect(i)}
                            c="icon"
                        >
                            <module.icon />
                        </Div>
                    ))}
                    <Div
                        open={draggableModule == true}
                        disabled={draggableModule == true}
                        onClick={() => setDraggableModule(!draggableModule)}
                        c="icon"
                    >
                        <Icons.Styles />
                    </Div>
                    <Div
                        open={inappPopup == true}
                        disabled={inappPopup == true}
                        onClick={() => setInappPopup(!inappPopup)}
                        c="icon"
                    >
                        <Icons.Duplicate />
                    </Div>
                </Div>
                <Div c="wrap">
                    <Div
                        open={languageSwitching === true}
                        disabled={languageSwitching === true}
                        onClick={() => { setLanguageSwitching(!languageSwitching), setSelectedTab(null) }}
                        c="icon"
                    >
                        <Div c='flag'>
                            {availableLanguages.map((img) => { if (img.key == language) return <img src={img.img} key={img.key} alt='flag' /> })}
                        </Div>
                        <Icons.Lang />
                    </Div>
                    <Div
                        open={localStylesSettings === true}
                        disabled={localStylesSettings === true}
                        onClick={() => setLocalStylesSettings(!localStylesSettings)}
                        c="icon"
                    >
                        <Icons.Settings />
                    </Div>
                </Div>
            </Div>
            <Div c={`module-wrap ${selectedTab != null && "active"}`} onClick={() => setLanguageSwitching(false)}>
                {selectedTab != null && <Div c='caption-wrap'>
                    <Div c='title'>{modules[selectedTab].module}</Div>
                    <Div c="icon" onClick={() => setSelectedTab(null)}>
                        <Icons.Close />
                    </Div>
                </Div>}
                {selectedTab != null && <SelectedModule />}
            </Div>
            {languageSwitching &&
                <Div c='module-wrap active'>
                    <Div c='caption-wrap'>
                        <Div c='title'>Change Language</Div>
                        <Div c="icon" onClick={() => setLanguageSwitching(false)}>
                            <Icons.Close />
                        </Div>
                    </Div>
                    <LanguageSwitch setLanguageSwitching={setLanguageSwitching} />
                </Div>
            }
        </Container>
    )
}

export default LeftTabs

const Container = styled.div`
height: 100%;
display: flex;
width: 310px;
.tabs{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    .wrap{
        .icon{
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: background-color 0.5s ease;
            background-color: transparent;
            position: relative;
            img{
                position: absolute;
                width: 20px;
                height: 20px;
                top: 0;
                right: -5px;
                z-index: 9;
            }
            svg{
                width: 18px;
                height: 18px;
                path{
                    fill: ${({ theme }) => theme.color};
                }
            }
            &[open], &:hover{
                background-color: ${({ theme }) => theme.lightMainColor};
                svg{
                    path{
                        fill: ${({ theme }) => theme.mainColor};
                    }
                }
            }
        }
    }
}
.module-wrap{
    height: 100%;
    width: 0;
    z-index: 1;
    transition: width 0.2s;
    background: ${({ theme }) => theme.bg};
    border-right: 1px solid ${({ theme }) => theme.sectionBorderColor};
    .caption-wrap{
        padding: 12px 16px;
        border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            font-weight: 600;
            font-size: ${({ theme }) => theme.fs}
        }
        .icon{
            cursor: pointer;
        }
    }
    &.active{
        width: 260px;
    }
}
`