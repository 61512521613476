import axios from "axios";
import { Auth } from 'aws-amplify';

/* eslint-disable no-async-promise-executor */
const request = ({url, method = 'get', headers = {}, body}) => {
    return new Promise(async (resolve, reject) => {
        try{
            const {signInUserSession} = await Auth.currentAuthenticatedUser()
            const {accessToken} = signInUserSession
            let axiosRequest
            if(body) axiosRequest = axios[method](url, {...body}, {headers: {...headers, 'Authorization': `Bearer ${accessToken.jwtToken}`}})
            else axiosRequest = axios[method](url, {headers: {...headers, 'Authorization': `Bearer ${accessToken.jwtToken}`}})
            const result = await axiosRequest
            // if(result.statusText !== 200) throw new Error(result.data.message)
            resolve(result.data)
        } catch(e) {
            console.log(e, 'error in request.js')
            reject(e.message)
        }
    })
};

export default request;
