import ToggleButton from 'components/ToggleButton';
import { getBuilderContext } from 'contexts/builder';
import Div from 'elements/Div';
import React from 'react';
import { IoIosArrowDown } from "react-icons/io";
import styled from 'styled-components';
import { Close } from 'utils/svg';

const animationObject = {
    entry: 0,
    center: 1,
    exit: 0,
    unit: ''
}

const Vertical = () => {
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext)
    const existingObject = selectedComponent?.scroll?.animations.vertical
    const [animation, setAnimation] = React.useState(existingObject ?? null)
    const [enabled, setEnabled] = React.useState(existingObject ? true : false)
    const unit = ['%', 'px', 'vh', 'vw', 'deg']
    const [active, setActive] = React.useState(false)
    const debounceRef = React.useRef();
    const debounce = (func, delay) => {
        return (...args) => {
            clearTimeout(debounceRef.current)
            debounceRef.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    const handleChange = () => {
        if (animation) {
            editComponent(selectedComponent.indices, 'add scroll animation', 'vertical', animation)
        } else {
            editComponent(selectedComponent.indices, 'remove scroll animation', 'vertical')
        }
    }
    const debouncedFunction = debounce(handleChange, 200);
    React.useEffect(() => {
        if (enabled) {
            if (!animation) setAnimation(animationObject)
        } else {
            setAnimation(null)
        }
    }, [enabled])
    React.useEffect(() => {
        debouncedFunction()
    }, [animation])

    return (
        <Container>
            <div className='controller-wrap'>
                <div className='toggled-wrap' onClick={() => setEnabled(!enabled)}>
                    <span>Enable Vertical</span>
                    <ToggleButton isToggled={enabled} setIsToggled={setEnabled} />
                </div>
                {enabled && animation ?
                    <Div c='controller'>
                        <Div c='field'>
                            <Div c='text'>
                                <span>Entry</span>
                                <Div c='unit-wrap'>
                                    <input type='text' value={animation.entry} onChange={e => setAnimation({ ...animation, entry: Number(e.target.value) })} />
                                    <span>{animation.unit}</span>
                                </Div>
                            </Div>
                            <Div c='range'>
                                <Div c='line' style={{ width: `${((animation.entry - (-360)) / (360 - (-360))) * 100}%` }}></Div>
                                <input type="range" step={!animation.unit && 0.1} min={-360} max={360} value={animation.entry} onChange={e => setAnimation({ ...animation, entry: Number(e.target.value) })} />
                            </Div>
                        </Div>
                        <Div c='field'>
                            <Div c='text'>
                                <span>Center</span>
                                <Div c='unit-wrap'>
                                    <input type='text' value={animation.center} onChange={e => setAnimation({ ...animation, center: Number(e.target.value) })} />
                                    <span>{animation.unit}</span>
                                </Div>
                            </Div>
                            <Div c='range'>
                                <Div c='line' style={{ width: `${((animation.center - (-360)) / (360 - (-360))) * 100}%` }}></Div>
                                <input type="range" step={!animation.unit && 0.1} min={-360} max={360} value={animation.center} onChange={e => setAnimation({ ...animation, center: Number(e.target.value) })} />
                            </Div>
                        </Div>
                        <Div c='field'>
                            <Div c='text'>
                                <span>Exit</span>
                                <Div c='unit-wrap'>
                                    <input type='text' value={animation.exit} onChange={e => setAnimation({ ...animation, exit: Number(e.target.value) })} />
                                    <span>{animation.unit}</span>
                                </Div>
                            </Div>
                            <Div c='range'>
                                <Div c='line' style={{ width: `${((animation.exit - (-360)) / (360 - (-360))) * 100}%` }}></Div>
                                <input type="range" step={!animation.unit && 0.1} min={-360} max={360} value={animation.exit} onChange={e => setAnimation({ ...animation, exit: Number(e.target.value) })} />
                            </Div>
                        </Div>
                        <Div c='field'>
                            <Div c='text'>
                                <span>Unit</span>
                            </Div>
                            <Div c="dropdown-wrap">
                                <Div c={`dropdown-head ${active && 'active'}`} onClick={() => setActive(!active)}>
                                    <span>{animation?.unit ? animation.unit : 'Choose one'}</span>
                                    {animation?.unit && <Div c='close' onClick={() => { setAnimation({ ...animation, unit: '' }), setActive(!active) }}><Close /></Div>}
                                    <Div c='arrow'><IoIosArrowDown /></Div>
                                </Div>
                                {active && <Div c='dropdown'>
                                    {unit?.map((val, i) => (
                                        <Div c="item" key={i} onClick={() => { setAnimation({ ...animation, unit: val }), setActive(!active) }}>{val}</Div>
                                    ))}
                                </Div>}
                            </Div>
                        </Div>
                    </Div>
                    : null}
            </div>
        </Container>

    );
};

export default Vertical;

const Container = styled.div`
margin-top: 20px;
 .controller-wrap{
    .toggled-wrap{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        cursor: pointer;
        span{
            line-height: 20px;
            font-size: ${({ theme }) => theme.fs2};
            font-weight: 500;
        }
        & > div{
            pointer-events: none;
        }
    }
    .controller{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .field{
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;
            .text{
                display: flex;
                align-items: center;
                justify-content: space-between;
                span{
                    color: ${({ theme }) => theme.color};
                    font-weight: 500;
                    line-height: 16px;
                }
                .unit-wrap{
                    background-color: ${({ theme }) => theme.bg2};
                    border-radius: 4px;
                    transition: background-color 0.5s ease;
                    width: fit-content;
                    input{
                        padding-top:8px;
                        padding-bottom:8px;
                        padding-left: 12px;
                        background: transparent;
                        border: none;
                        width: 40px;
                        font-weight: 500;
                        text-align: end;
                        cursor: pointer;
                        &:focus{
                            outline: none;
                        }
                    }
                    span{
                        font-weight: 500;
                        line-height: 16px;
                        padding-top:8px;
                        padding-bottom:8px;
                        padding-right: 12px;
                    }
                    &:hover{
                        background-color: ${({ theme }) => theme.bg2};
                    }
                }
            }
            .range{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .line{
                    position: absolute;
                    left: 0;
                    height: 3px;
                    z-index: 1;
                    background-color: ${({ theme }) => theme.mainColor};
                    pointer-events: none;
                }
                & > input[type='number']{
                    width: 34px;
                    height: 34px;
                    z-index: 0;
                    text-align: center;
                    background: ${({ theme }) => theme.bg2};
                    border: none;
                    border-radius: 4px;
                    color: ${({ theme }) => theme.color};
                    &:focus{
                        outline: none;
                    }
                }
                input[type='range'] {
                    width: 100%;
                    -webkit-appearance: none;
                    position: relative;
                }
                input[type='range']::-webkit-slider-runnable-track {
                    height: 3px;
                    background: ${({ theme }) => theme.bg3};
                }
                input[type='range']::-webkit-slider-thumb {
                    width: 10px;
                    -webkit-appearance: none;
                    height: 10px;
                    border-radius: 50%;
                    cursor: pointer;
                    background: ${({ theme }) => theme.mainColor};
                    transition: 0.5s all ease;
                    margin-top: -3.5px;
                }
            }
        }
        .dropdown-wrap{
            width: 100%;
            position: relative;
            .dropdown-head{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: ${({ theme }) => theme.bg2};
                border-radius: 4px;
                padding: 8px 12px;
                cursor: pointer;
                position: relative;
                span{
                    font-size: ${({ theme }) => theme.fs};
                    color: ${({ theme }) => theme.color};
                    font-weight:500;
                }
                .arrow{
                    background: ${({ theme }) => theme.bg2};
                    transition: all 0.3s linear;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    svg{
                        width: 14px;
                        height: 14px;
                    }
                }
                .close{
                    position: absolute;
                    right: 14px;
                    transition: right 0.3s ease;
                    display: flex;
                    align-items: center;
                    svg{
                        width: 14px;
                        height: 14px;
                        path{
                            fill: ${({ theme }) => theme.color2};
                        }
                    }
                }
                &:hover{
                    .close{
                        right: 28px;
                    }
                }
                &.active{
                    .arrow{
                        svg{
                            rotate: 180deg;
                        }
                    }
                }
            }
            .dropdown{
                top: 120%;
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                top: 120%;
                border-radius: 4px;
                background: ${({ theme }) => theme.bg};
                box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
                min-width: 100px;
                background: ${({ theme }) => theme.bg2};
                .item{
                    padding: 10px 15px;
                    font-size: ${({ theme }) => theme.fs};
                    font-weight: 600;
                    color: ${({ theme }) => theme.color};
                    border-radius: 4px;
                    &:hover {
                        background: ${({ theme }) => theme.lightMainColor};
                        color: ${({ theme }) => theme.mainColor};
                        cursor: pointer;
                    }
                }
            }
        }
    }
 }
`