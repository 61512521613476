import Div from "elements/Div";
import React from "react";
import styled from "styled-components";

const Phone = ({ setLinkData, linkData }) => {
    return (
        <Container>
            <Div c='link-wrap'>
                <label>Phone</label>
                <input type="number" placeholder="7500-7777" value={linkData.slug} onChange={(e) => setLinkData({ ...linkData, slug: e.target.value })} />
            </Div>
        </Container>
    )
}

export default Phone

const Container = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
& > .title{
    font-weight: 600;
    font-size: ${({ theme }) => theme.fs4};
}
.link-wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    label{
        letter-spacing: 0.75px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.color2};
        font-weight: 500;
        font-size: 10px;
    }
    input{
        border: none;
        background: ${({ theme }) => theme.bg2};
        padding: 12px 18px;
        border-radius: 4px;
        color: ${({ theme }) => theme.color};
        font-weight: 500;
        width: 100%;
        &:focus{
            outline: none;
        }
    }
}
`