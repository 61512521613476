import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

function checkNum(value, fallback) {
    const result = Number(value);
    return !isNaN(result) ? result : fallback;
}

const useDragOverThrottle = (callback, delay) => {
    const throttledCallback = React.useCallback(
        throttleWithPreventDefault(callback, delay),
        [callback, delay]
    );

    return throttledCallback;
};

const throttleWithPreventDefault = (func, delay) => {
    let lastExecTime = 0;

    return (...args) => {
        args[0]?.preventDefault()
        const now = Date.now();

        if (now - lastExecTime >= delay) {
            lastExecTime = now;
            func(...args);
        }
    };
};

const HandleGrid = () => {
    const { frame, frameUpdate, frameScreen } = React.useContext(getCrossDomainContext);
    const { theme } = React.useContext(getCustomThemeContext);
    const { editComponent, selectedComponent, selectComponent } = React.useContext(getBuilderContext);
    const doc = frame.document;
    const [gridSystem, setGridSystem] = React.useState({
        leftoverX: 0, leftoverY: 0, colStart: 0, rowStart: 0, colSpan: 0, rowSpan: 0, colEnd: 0, rowEnd: 0,
        calcWidth: 0, calcHeight: 0, cursorX: 0, cursorY: 0, offsetX: 0, offsetY: 0
    })
    const [resizeInAction, setResizeInAction] = React.useState(false)
    const [resizingPosition, setResizingPosition] = React.useState(null)
    const [pointerLock, setPointerLock] = React.useState(false)
    const [rowCount, setRowCount] = React.useState(0)
    const [rowMutated, setRowMutated] = React.useState(false)
    const [currentGridTrack, setCurrentGridTrack] = React.useState(null)
    const [adjustRowInAction, setAdjustRowInAction] = React.useState(false)
    const [dragInAction, setDragInAction] = React.useState(false)
    const handleDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!e.target || e.target.nodeType !== 1) return null;
        const gridWrap = e.target.hasAttribute('grid-system-wrap');
        const gridNode = e.target.hasAttribute('grid-system-nodes');
        if (gridNode || gridWrap) {
            const detectorX = e.clientX + frame.scrollX - gridSystem.leftoverX;
            const detectorY = e.clientY + frame.scrollY - gridSystem.leftoverY;
            const elements = [...doc.elementsFromPoint(detectorX, detectorY)];
            elements.forEach((element) => {
                if (!element || element.nodeType !== 1) return null;
                const gridNode = element.getAttribute('grid-system-nodes');
                if (gridNode) {
                    const startY = Number(element.getAttribute('starty'))
                    const startX = Number(element.getAttribute('startx'))
                    setGridSystem({ ...gridSystem, rowStart: startY, colStart: startX })
                }
            });
        }
    };
    const throttledDragOver = React.useCallback(useDragOverThrottle(handleDragOver, 500), [gridSystem])

    React.useEffect(() => {
        doc.addEventListener('dragover', throttledDragOver);
        // doc.addEventListener('dragover', handleDragOver);
        // doc.addEventListener('dragover', throttledHandleDragOver);
        doc.addEventListener('drop', handleDrop);
        doc.addEventListener('dragstart', handleDragStart);
        doc.addEventListener('dragend', handleDragEnd);
        return () => {
            doc.removeEventListener('dragover', throttledDragOver);
            // doc.removeEventListener('dragover', handleDragOver);
            // doc.removeEventListener('dragover', throttledHandleDragOver);
            doc.removeEventListener('drop', handleDrop);
            doc.removeEventListener('dragstart', handleDragStart);
            doc.removeEventListener('dragend', handleDragEnd);
        }
    }, [gridSystem, selectedComponent, frameUpdate, frameScreen, rowCount, rowMutated, currentGridTrack]);

    React.useEffect(() => {
        doc.addEventListener('mousedown', handleMouseDownResize);
        doc.addEventListener('mouseup', handleMouseUpResize);

        doc.addEventListener('mousedown', handleMouseDownAdjustRow);
        doc.addEventListener('mouseup', handleMouseUpAdjustRow);

        return () => {
            doc.removeEventListener('mousedown', handleMouseDownResize)
            doc.removeEventListener('mouseup', handleMouseUpResize)

            doc.removeEventListener('mousedown', handleMouseDownAdjustRow);
            doc.removeEventListener('mouseup', handleMouseUpAdjustRow);
        }
    }, [selectedComponent, resizeInAction, resizingPosition, gridSystem, rowCount, rowMutated, currentGridTrack, adjustRowInAction]);

    React.useEffect(() => {
        document.addEventListener('mouseup', clearAnyOtherNodes)
        return () => {
            document.removeEventListener('mouseup', clearAnyOtherNodes)
        }
    }, [])

    React.useEffect(() => {
        if (selectedComponent && !resizeInAction) drawResizers();
        else clearResizers();
    }, [selectedComponent, frameUpdate, frameScreen, resizeInAction]);

    React.useEffect(() => {
        // Resize mousemove event
        if (resizeInAction) doc.addEventListener('mousemove', handleMouseMoveResize);
        else doc.removeEventListener('mousemove', handleMouseMoveResize)

        // Adjust row mousemove event
        if (adjustRowInAction) doc.addEventListener('mousemove', handleMouseMoveAdjustRow)
        else doc.removeEventListener('mousemove', handleMouseMoveAdjustRow)

        return () => {
            doc.removeEventListener('mousemove', handleMouseMoveResize)
            doc.removeEventListener('mousemove', handleMouseMoveAdjustRow)
        }
    }, [resizeInAction, gridSystem, adjustRowInAction, rowCount])

    React.useEffect(() => {
        doc.addEventListener('mouseover', handlePointerLock);
        return () => {
            doc.removeEventListener('mouseover', handlePointerLock);
        }
    }, [pointerLock, resizeInAction])

    React.useEffect(() => {
        if (rowCount) handleDrawGridSystem()
        else clearGridSystem()
    }, [rowCount, currentGridTrack, rowMutated])

    // Due to performance reason, dragover calls drawCurrentElementDuplicate method
    React.useEffect(() => {
        if (dragInAction) drawCurrentElementDuplicate()
    }, [rowCount, gridSystem, dragInAction])

    const handleMouseDownAdjustRow = (e) => {
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        const blSectionMenu = e.target.getAttribute('bl-section-menu');
        if (blSectionMenu !== 'adjust-row') return null;
        if (!selectedComponent) return null
        const targetNode = doc.querySelector(`[kind][data-track="${selectedComponent.indices}"]`)
        const computedStyles = frame.window.getComputedStyle(targetNode);
        const computedRowCount = Number(computedStyles.getPropertyValue('--row-count'))
        setCurrentGridTrack(selectedComponent.indices)
        setRowCount(computedRowCount)
        setAdjustRowInAction(true)
    }
    const handleMouseUpAdjustRow = (e) => {
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        if (!adjustRowInAction) return null
        if (rowMutated) editComponent(currentGridTrack, 'style', '--row-count', `${rowCount}`, true);
        clearGridSystem()
        setAdjustRowInAction(false)
        setRowMutated(false)
        setRowCount(0)
        setCurrentGridTrack(null)
    }
    const handleMouseMoveAdjustRow = (e) => {
        e.stopPropagation();
        if (!e.target || e.target.nodeType !== 1) return null;
        const targetNode = doc.querySelector(`[kind][data-track="${currentGridTrack}"]`)
        const collidingNodes = [...doc.elementsFromPoint(e.clientX, e.clientY)]
        const insideTarget = collidingNodes.find(p => p === targetNode)
        if (!insideTarget) return null

        const gridSystemWrap = targetNode.querySelector('[grid-system-wrap]')
        if (!gridSystemWrap) return null

        const gridSystemRect = gridSystemWrap.getBoundingClientRect()
        const gridSystemBottom = gridSystemRect.top + gridSystemRect.height

        const adjustButton = doc.querySelector('[bl-section-menu="adjust-row"]')
        if (adjustButton) adjustButton.style.top = `${e.clientY + frame.scrollY - 15}px`

        if (e.clientY >= gridSystemBottom) return adjustRowCount(1)

        const gridNode = e.target.hasAttribute('grid-system-nodes');
        if (!gridNode) return null
        const startY = Number(e.target.getAttribute('starty'))

        if (rowCount > startY) return adjustRowCount(-1)
    }
    const handlePointerLock = (e) => {
        if (!e.target || e.target.nodeType !== 1) return null;
        const resizeNode = e.target.hasAttribute('bl-resize');
        if (!resizeNode) {
            if (pointerLock) {
                if (resizeInAction) return null
                doc.querySelector('#pointer-lock-helper')?.remove()
                setPointerLock(false)
            } else return null
        } else {
            if (pointerLock) return null
            const pointerLockHelper = doc.createElement('style')
            pointerLockHelper.setAttribute('id', 'pointer-lock-helper')
            pointerLockHelper.innerText = `
                [kind] {
                    pointer-events:none !important;
                    user-drag: none;
                    -webkit-user-drag: none;
                    user-select: none;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                }
            `
            doc.head.appendChild(pointerLockHelper)
            setPointerLock(true)
        }
    }

    const handleDragEnd = (e) => {
        if (!e.target || e.target.nodeType !== 1) return null;
        const kind = e.target.parentElement.getAttribute('kind');
        if (kind !== 'grid') return null;

        // Helper style removal and cleanup
        e.target.classList.remove('invisible');
        e.target.classList.remove('drop-bg-effect');
        const dragging = [...doc.querySelectorAll('.grid-dragging')];
        dragging.forEach((draggingElement) => {
            draggingElement.classList.remove('grid-dragging');
        });

        // Cleanup the drawn grid information
        clearGridSystem();
        setGridSystem({ ...gridSystem, colSpan: 0, rowSpan: 0, colStart: 0, rowStart: 0, leftoverX: 0, leftoverY: 0 })

        setRowMutated(false)
        setRowCount(0)
        setCurrentGridTrack(null)
        setDragInAction(false)
        if (rowMutated) {
            editComponent(currentGridTrack, 'style', '--row-count', `${rowCount}`, true);
        }
    }

    const handleDragStart = (e) => {
        e.stopPropagation();
        const kind = e.target.getAttribute('kind');
        // if (kind === 'block') {
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        const parentElement = e.target.parentElement;
        if (parentElement.getAttribute('kind') !== 'grid') return null;
        const targetStyle = frame.getComputedStyle(e.target)
        console.log(targetStyle.position, 'pos?')
        if (targetStyle.position === 'fixed') return null
        proceedDragStart(e)
        // }
    };
    const proceedDragStart = (e) => {
        const computedStyles = frame.window.getComputedStyle(e.target);
        const computedRowCount = Number(computedStyles.getPropertyValue('--row-count'))

        setCurrentGridTrack(e.target.parentElement.dataset.track)
        setRowCount(computedRowCount)
        setDragInAction(true)

        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/type', 'grid');
        e.dataTransfer.setData('text/track', e.target.dataset.track);

        // ENCAPSULATE OUTER MOST ELEMENT OF DRAG EVENT TO PREVENT MOVING INSIDE ITS CHILDREN
        e.target.classList.add('grid-dragging');
        const descendants = [...e.target.querySelectorAll('*')];
        descendants.map((descendant) => descendant.classList.add('grid-dragging'));

        const background = computedStyles.getPropertyValue('background-color');
        if (background === 'rgba(0, 0, 0, 0)') e.target.classList.add('drop-bg-effect');
        setTimeout(() => e.target.classList.add('invisible'), 0);

        //Initialize runningGridInfo
        const colStart = checkNum(computedStyles.getPropertyValue('grid-column-start'), 1);
        const colEnd = checkNum(computedStyles.getPropertyValue('grid-column-end'), 2);

        const rowStart = checkNum(computedStyles.getPropertyValue('grid-row-start'), 1);
        const rowEnd = checkNum(computedStyles.getPropertyValue('grid-row-end'), 2);

        let { left, top } = e.target.getBoundingClientRect();
        const leftoverX = e.clientX - left + frame.scrollX;
        const leftoverY = e.clientY - top + frame.scrollY;

        setGridSystem({
            colStart, rowStart, leftoverX, leftoverY,
            colSpan: colEnd - colStart,
            rowSpan: rowEnd - rowStart,
            colEnd, rowEnd
        })
    }
    const handleDrop = (e) => {
        const type = e.dataTransfer.getData('text/type');
        if (type !== 'grid') return null;
        const track = e.dataTransfer.getData('text/track');
        const gridValue = `${gridSystem.rowStart} / ${gridSystem.colStart} / ${gridSystem.rowStart + gridSystem.rowSpan} / ${gridSystem.colStart + gridSystem.colSpan}`;
        editComponent(track, 'style', 'gridArea', gridValue, true);
    };
    const drawCurrentElementDuplicate = () => {
        doc.querySelector('[grid-system-current]')?.remove();

        const gridCurrent = doc.createElement('div');
        gridCurrent.setAttribute('grid-system-current', true);
        gridCurrent.style.gridColumn = `${gridSystem.colStart} / span ${gridSystem.colSpan}`;
        gridCurrent.style.gridRow = `${gridSystem.rowStart} / span ${gridSystem.rowSpan}`;

        const xInfo = doc.createElement('div')
        xInfo.setAttribute('info', 'x')
        xInfo.innerText = gridSystem.colSpan
        gridCurrent.appendChild(xInfo)
        const yInfo = doc.createElement('info', 'y')
        yInfo.setAttribute('info', 'y')
        yInfo.innerText = gridSystem.rowSpan
        gridCurrent.appendChild(yInfo)

        const gridWrap = doc.querySelector('[grid-system-wrap]');
        gridWrap.appendChild(gridCurrent);

        //Check if row needs to increase
        const currentBoundingRect = gridCurrent.getBoundingClientRect()
        const currentBottom = currentBoundingRect.top + currentBoundingRect.height
        const wrapBoundingRect = gridWrap.getBoundingClientRect()
        const wrapBottom = wrapBoundingRect.top + wrapBoundingRect.height
        currentBottom >= wrapBottom && adjustRowCount(1)
    }
    const drawCurrentElement = (e, detector, fnCallback) => {
        doc.querySelector('[grid-system-current]')?.remove();

        const gridCurrent = doc.createElement('div');
        gridCurrent.setAttribute('grid-system-current', true);
        gridCurrent.style.gridColumn = `${gridSystem.colStart} / span ${gridSystem.colSpan}`;
        gridCurrent.style.gridRow = `${gridSystem.rowStart} / span ${gridSystem.rowSpan}`;

        const xInfo = doc.createElement('div')
        xInfo.setAttribute('info', 'x')
        xInfo.innerText = gridSystem.colSpan
        gridCurrent.appendChild(xInfo)
        const yInfo = doc.createElement('info', 'y')
        yInfo.setAttribute('info', 'y')
        yInfo.innerText = gridSystem.rowSpan
        gridCurrent.appendChild(yInfo)

        const gridWrap = doc.querySelector('[grid-system-wrap]');
        gridWrap.appendChild(gridCurrent);

        //Check if row needs to increase
        const currentBoundingRect = gridCurrent.getBoundingClientRect()
        const currentBottom = currentBoundingRect.top + currentBoundingRect.height
        const wrapBoundingRect = gridWrap.getBoundingClientRect()
        const wrapBottom = wrapBoundingRect.top + wrapBoundingRect.height
        currentBottom >= wrapBottom && adjustRowCount(1)

        const elements = [...doc.elementsFromPoint(detector.x, detector.y)];
        elements.forEach((element) => {
            if (!element || element.nodeType !== 1) return null;
            const gridNode = element.getAttribute('grid-system-nodes');
            if (gridNode) fnCallback(element)
        });
    };

    const adjustRowCount = (incrementor) => {
        setRowCount(rowCount + incrementor)
        setRowMutated(true)
    }

    const clearGridSystem = () => {
        const gridWrap = doc.querySelector('[grid-system-wrap]');
        if (gridWrap) {
            gridWrap.classList.remove('active');
            setTimeout(() => {
                doc.querySelector('#grid-system-style')?.remove();
                doc.querySelector('[grid-system-wrap]')?.remove();
            }, 100);
        }
    };
    const handleDrawGridSystem = (e) => {
        const target = doc.querySelector(`[kind="grid"][data-track="${currentGridTrack}"]`);
        if (!target) return null
        // Cleanup
        doc.querySelector('#grid-system-style')?.remove();
        doc.querySelector('[grid-system-wrap]')?.remove();

        const computedStyle = frame.window.getComputedStyle(target);
        const cellCount = Number(computedStyle.getPropertyValue('--cell-count')) + 2;
        // const rowCount = Number(computedStyle.getPropertyValue('--row-count'));

        // Append style helper
        const styleTag = doc.createElement('style');
        styleTag.setAttribute('id', 'grid-system-style');
        styleTag.innerText = `
            #selectHelperWrap {
                opacity:0 !important;
            }
            [bl-resize] {
                opacity:0 !important;
            }
            #bl-select{
                opacity:0 !important;
            }
            [bl-block-menu="wrapper"] {
                opacity:0 !important;
            }
            [grid-system-wrap]{
                position:absolute;
                left:0px;
                right:0px;
                opacity:0;
            }
            [grid-system-wrap]:before{
                content: "";
                display:block;
                width:4px;
                height:100%;
                // background:${theme.mainColor};
                position:absolute;
                left:calc(50% - 2px);
                border-left:4px dotted ${theme.mainColor};
            }
            [grid-system-wrap].active{
                opacity:1;
            }
            [grid-system-nodes]{
                box-sizing:border-box;
                background:rgba(255,255,255,0.2);
                border-radius:3px;
                border:1px solid rgba(0,0,0,0.2);
                box-shadow:0px 0px 1px 1px rgba(255,255,255,0.4);
                z-index:8;
                position:relative;
            }
            [grid-system-nodes]:before{
                content: "";
                display:block;
                position:absolute;
                inset:-6.5px;
            }
            [grid-system-current]{
                border: 3px solid ${theme.mainColor2};
                box-sizing: border-box;
                pointer-events: none;
                z-index: 9;
                position:relative;
            }
            [info="x"],[info="y"]{
                border-radius:8px;
                display:flex;
                align-items:center;
                justify-content:center;
                font-family:sans-serif;
                // background:${theme.mainColor};
                // color:white;
                // font-size:10px;
                position:absolute;
                width:20px;
                height:20px;
                font-weight:100;
                opacity:0.0;
            }
            [info="x"]{
                left:calc(50% - 10px);
                top:-30px;
            }
            [info="y"]{
                left:-30px;
                top:calc(50% - 10px);

            }
            [kind="grid"][data-track="${target.dataset.track}"] {
                position: relative !important;
                ${`--row-count: ${rowCount} !important`};
                rotate:unset !important;
                scale:unset !important;
                translate: unset !important;
                transform:unset !important;
                filter:unset !important;
                perspective: unset !important;
            }
        `;
        doc.head.appendChild(styleTag);

        const gridColumnValue = computedStyle.getPropertyValue('grid-template-columns');
        const gridRowValue = computedStyle.getPropertyValue('grid-template-rows');
        const gridGap = computedStyle.getPropertyValue('gap');
        const topSpace = computedStyle.getPropertyValue('padding-top');
        const bottomSpace = computedStyle.getPropertyValue('padding-bottom');

        const gridWrapper = doc.createElement('div');
        gridWrapper.setAttribute('source-track', target.dataset.track)
        gridWrapper.setAttribute('grid-system-wrap', true);
        gridWrapper.style.gridTemplateColumns = gridColumnValue;
        gridWrapper.style.gridTemplateRows = gridRowValue;
        gridWrapper.style.gap = gridGap;
        gridWrapper.style.top = topSpace;
        gridWrapper.style.bottom = bottomSpace;
        gridWrapper.style.display = 'grid';
        gridWrapper.style.pointerEvents = 'auto';

        for (let i = 0; i < cellCount; i++) {
            for (let j = 0; j < rowCount; j++) {
                const div = doc.createElement('div');
                div.setAttribute('grid-system-nodes', true);
                div.setAttribute('starty', j + 1);
                div.setAttribute('startx', i + 1);
                div.style.gridArea = `${j + 1} / ${i + 1} / ${j + 2} / ${i + 2}`;
                if (i === 0 || i === cellCount - 1) div.style.opacity = '0';
                gridWrapper.appendChild(div);
            }
        }

        target.appendChild(gridWrapper);
        setTimeout(() => {
            gridWrapper.classList.add('active');
        }, 1);
    };
    const clearAnyOtherNodes = () => {
        const parents = [...doc.querySelectorAll('.resize-parent-draggable-removed-temp')]
        parents.forEach(p => {
            p.setAttribute('draggable', 'true')
            p.classList.remove('resize-parent-draggable-removed-temp')
        })

        const children = [...doc.querySelectorAll('.resize-children-draggable-removed-temp')]
        children.forEach(c => c.classList.remove('resize-children-draggable-removed-temp'))

        const sibling = [...doc.querySelectorAll('.resize-sibling-draggable-removed-temp')]
        sibling.forEach(c => c.classList.remove('resize-sibling-draggable-removed-temp'))

    }
    function disableAnyOtherNodes(node) {
        // Function to apply resize-in-action class to all ancestor nodes
        const draggables = [...doc.querySelectorAll('[data-track][draggable="true"]')]
        draggables.forEach(draggable => {
            draggable.removeAttribute('draggable')
            draggable.classList.add('resize-parent-draggable-removed-temp')
        })

        // Apply resize-in-action-child class to all children nodes
        Array.from(node.children).forEach(child => {
            if (child.nodeType !== 1) return null
            if (!child.classList) return null
            child.classList.add('resize-children-draggable-removed-temp');
        });

        // Apply disable-temporarily class to sibling nodes
        Array.from(node.parentNode.children).forEach(sibling => {
            if (sibling !== node) {
                if (sibling.nodeType !== 1) return null
                if (!sibling.classList) return null
                sibling.classList.add('resize-sibling-draggable-removed-temp');
            }
        });
    }
    const handleMouseDownResize = (e) => {
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        const isResizeElement = e.target.getAttribute('bl-resize');
        if (!isResizeElement) return null;
        if (!selectedComponent) return null

        const targetNode = doc.querySelector(`[kind="component"][data-track="${selectedComponent.indices}"]`)
        if (!targetNode) return null
        const parentElement = targetNode.parentElement;
        if (parentElement.getAttribute('kind') !== 'grid') return null;
        disableAnyOtherNodes(targetNode)
        console.log('gone')

        const computedStyles = frame.window.getComputedStyle(targetNode);
        const computedRowCount = Number(computedStyles.getPropertyValue('--row-count'))
        setCurrentGridTrack(parentElement.dataset.track)
        setRowCount(computedRowCount)

        const boundingRect = targetNode.getBoundingClientRect()
        //Initialize runningGridInfo
        const colStart = checkNum(computedStyles.getPropertyValue('grid-column-start'), 1);
        const colEnd = checkNum(computedStyles.getPropertyValue('grid-column-end'), 2);

        const rowStart = checkNum(computedStyles.getPropertyValue('grid-row-start'), 1);
        const rowEnd = checkNum(computedStyles.getPropertyValue('grid-row-end'), 1);

        const calcWidth = computedStyles.getPropertyValue('width');
        const calcHeight = computedStyles.getPropertyValue('height');

        setGridSystem({
            ...gridSystem,
            colStart, rowStart,
            colSpan: colEnd - colStart,
            rowSpan: rowEnd - rowStart,
            colEnd, rowEnd,
            calcWidth, calcHeight,
            cursorX: e.clientX + frame.scrollX,
            cursorY: e.clientY + frame.scrollY,
            offsetX: boundingRect.left + frame.scrollX,
            offsetY: boundingRect.top + frame.scrollY
        })

        const position = e.target.getAttribute('pos');
        setResizingPosition(position)
        setResizeInAction(true)

        doc.querySelector('#resize-style-helper')?.remove()
        const resizeStyleHelper = doc.createElement('style')
        resizeStyleHelper.setAttribute('id', 'resize-style-helper')
        doc.head.appendChild(resizeStyleHelper)

    }
    const handleMouseUpResize = (e) => {
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        if (!resizeInAction) return null

        doc.querySelector('#resize-style-helper')?.remove()

        const gridValue = `${gridSystem.rowStart} / ${gridSystem.colStart} / ${gridSystem.rowStart + gridSystem.rowSpan} / ${gridSystem.colStart + gridSystem.colSpan}`;
        if (rowMutated) {

            editComponent(selectedComponent.indices, 'style', 'gridArea', gridValue, true);
            setTimeout(() => {
                editComponent(currentGridTrack, 'style', '--row-count', `${rowCount}`, true);
            }, 0);
        } else {
            const targetNode = doc.querySelector(`[data-track="${selectedComponent.indices}"]`);
            const targetStyles = frame.getComputedStyle(targetNode).position
            editComponent(selectedComponent.indices, 'style', 'gridArea', gridValue, true);
        }

        setResizeInAction(false)
        clearGridSystem()
        clearResizers()

        // drawGrid helper states
        setRowMutated(false)
        setRowCount(0)
        setCurrentGridTrack(null)

        //
        clearAnyOtherNodes()
    }
    const handleMouseMoveResize = (e) => {
        e.stopPropagation();
        if (!e.target || e.target.nodeType !== 1) return null;
        const gridNode = e.target.hasAttribute('grid-system-nodes');
        const gridWrap = e.target.hasAttribute('grid-system-wrap');
        if (gridWrap || gridNode) {
            //Make the selected element look fit to cursor
            const resizeStyleHelper = doc.querySelector('#resize-style-helper')
            if (resizeStyleHelper) {
                resizeStyleHelper.innerText = `
                [kind="component"][data-track="${selectedComponent.indices}"] {
                    pointer-events: none !important;
                    position:fixed !important;
                    ${resizingPosition === 'bottom-right' ? `
                        top: ${gridSystem.offsetY - frame.scrollY}px !important;
                        left: ${gridSystem.offsetX - frame.scrollX}px !important;
                        width: calc(${gridSystem.calcWidth} + ${(e.clientX + frame.scrollX) - gridSystem.cursorX}px) !important;
                        height: calc(${gridSystem.calcHeight} + ${(e.clientY + frame.scrollY) - gridSystem.cursorY}px) !important;
                    ` : ''}
                    ${resizingPosition === 'top-right' ? `
                        bottom: calc(${frame.window.innerHeight - gridSystem.offsetY + frame.scrollY}px - ${gridSystem.calcHeight}) !important;
                        left: ${gridSystem.offsetX - frame.scrollX}px !important;
                        width: calc(${gridSystem.calcWidth} + ${(e.clientX + frame.scrollX) - gridSystem.cursorX}px) !important;
                        height: calc(${gridSystem.calcHeight} - ${(e.clientY + frame.scrollY) - gridSystem.cursorY}px) !important;
                    ` : ''}
                    ${resizingPosition === 'top-left' ? `
                        bottom: calc(${frame.window.innerHeight - gridSystem.offsetY + frame.scrollY}px - ${gridSystem.calcHeight}) !important;
                        right: calc(${frame.window.innerWidth - gridSystem.offsetX + frame.scrollX}px - ${gridSystem.calcWidth}) !important;
                        width: calc(${gridSystem.calcWidth} - ${(e.clientX + frame.scrollX) - gridSystem.cursorX}px) !important;
                        height: calc(${gridSystem.calcHeight} - ${(e.clientY + frame.scrollY) - gridSystem.cursorY}px) !important;
                    ` : ''}
                    ${resizingPosition === 'bottom-left' ? `
                        top: ${gridSystem.offsetY - frame.scrollY}px !important;
                        right: calc(${frame.window.innerWidth - gridSystem.offsetX + frame.scrollX}px - ${gridSystem.calcWidth}) !important;
                        width: calc(${gridSystem.calcWidth} - ${(e.clientX + frame.scrollX) - gridSystem.cursorX}px) !important;
                        height: calc(${gridSystem.calcHeight} + ${(e.clientY + frame.scrollY) - gridSystem.cursorY}px) !important;
                    ` : ''}
                }
                `
            }
            //Positioning manipulation
            drawCurrentElement(e, { x: e.clientX, y: e.clientY }, (node) => {
                const startY = Number(node.getAttribute('starty'))
                const startX = Number(node.getAttribute('startx'))
                if (resizingPosition === 'bottom-right') {
                    const colSpan = startX - gridSystem.colStart + 1
                    const rowSpan = startY - gridSystem.rowStart + 1
                    if (colSpan <= 0 || rowSpan <= 0) return null
                    setGridSystem({ ...gridSystem, rowSpan, colSpan })
                } else if (resizingPosition === 'top-right') {
                    const colSpan = startX - gridSystem.colStart + 1
                    const rowSpan = gridSystem.rowEnd - startY
                    if (colSpan <= 0 || rowSpan <= 0) return null
                    setGridSystem({ ...gridSystem, rowSpan, colSpan, rowStart: startY })
                } else if (resizingPosition === 'top-left') {
                    const colSpan = gridSystem.colEnd - startX
                    const rowSpan = gridSystem.rowEnd - startY
                    if (colSpan <= 0 || rowSpan <= 0) return null
                    setGridSystem({ ...gridSystem, rowSpan, colSpan, rowStart: startY, colStart: startX })
                } else if (resizingPosition === 'bottom-left') {
                    const colSpan = gridSystem.colEnd - startX
                    const rowSpan = startY - gridSystem.rowStart + 1
                    if (colSpan <= 0 || rowSpan <= 0) return null
                    setGridSystem({ ...gridSystem, rowSpan, colSpan, colStart: startX })
                }
            });
        }
    }
    const drawResizers = () => {
        clearResizers();
        const targetNode = doc.querySelector(`[data-track="${selectedComponent.indices}"]`);
        if (!targetNode) return null
        const parentElement = targetNode.parentElement;
        if (parentElement.getAttribute('kind') !== 'grid') return null
        const targetRect = targetNode.getBoundingClientRect();
        const rect = {
            top: targetRect.top + frame.scrollY,
            left: targetRect.left + frame.scrollX,
            width: targetRect.width,
            height: targetRect.height,
        }

        const blStyleHelper = doc.createElement('style')
        blStyleHelper.setAttribute('id', 'bl-style-helper')
        blStyleHelper.innerText = `
            [bl-resize]:before {
                content: "";
                background: white;
                display:block;
                width:100%;
                height:100%;
                border:1px solid ${theme.mainColor2};
            }
        `
        doc.head.appendChild(blStyleHelper)

        const positions = ['top-left', 'top-right', 'bottom-left', 'bottom-right']
        positions.forEach(position => {
            const div = doc.createElement('div')
            div.setAttribute('bl-resize', true)
            div.setAttribute('pos', position)
            div.style.width = '20px'
            div.style.height = '20px'
            div.style.border = `5px solid transparent`
            div.style.position = `absolute`
            div.style.zIndex = '1001'
            div.style.pointerEvents = 'auto'
            if (position === 'top-left') {
                div.style.left = `${rect.left - 10}px`
                div.style.top = `${rect.top - 10}px`
                div.style.cursor = `nw-resize`
            } else if (position === 'top-right') {
                div.style.left = `${rect.left + rect.width - 10}px`
                div.style.top = `${rect.top - 10}px`
                div.style.cursor = `ne-resize`
            } else if (position === 'bottom-left') {
                div.style.left = `${rect.left - 10}px`
                div.style.top = `${rect.top + rect.height - 10}px`
                div.style.cursor = `sw-resize`
            } else if (position === 'bottom-right') {
                div.style.left = `${rect.left + rect.width - 10}px`
                div.style.top = `${rect.top + rect.height - 10}px`
                div.style.cursor = `se-resize`
            }
            doc.body.appendChild(div)
        })
    }
    const clearResizers = () => {
        doc.querySelector('#bl-style-helper')?.remove()
        const existingNodes = [...doc.querySelectorAll('[bl-resize]')];
        existingNodes.forEach((existingNode) => existingNode?.remove());
    };
    return null;
};

export default HandleGrid;
