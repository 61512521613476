import Div from 'elements/Div';
import React from 'react'
import styled from 'styled-components';

const ComponentNotSelected = () => {
    
    return (
        <NotSelected>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 333 512.39"
            >
                <path
                    fillRule="nonzero"
                    d="M146.68 492.45h39.64c34.84 0 66.53-14.27 89.51-37.24 22.97-22.97 37.23-54.66 37.23-89.51V230.17H19.94V365.7c0 34.85 14.26 66.54 37.23 89.51s54.67 37.24 89.51 37.24zm166.38-282.22v-63.55c0-34.84-14.26-66.53-37.23-89.51-22.98-22.97-54.67-37.23-89.51-37.23h-9.85v55.21c11.19 4.11 19.24 14.92 19.24 27.43v42.27c0 12.51-8.07 23.31-19.24 27.43v37.95h136.59zm-156.53 0v-37.95c-11.16-4.1-19.24-14.87-19.24-27.43v-42.27c0-12.56 8.05-23.34 19.24-27.44v-55.2h-9.85c-34.84 0-66.54 14.26-89.51 37.23-22.97 22.98-37.23 54.67-37.23 89.51v63.55h136.59zM0 146.68c0-40.34 16.5-77.03 43.08-103.6C69.65 16.5 106.34 0 146.68 0h39.64c40.34 0 77.03 16.5 103.6 43.08C316.5 69.65 333 106.34 333 146.68V365.7c0 40.35-16.5 77.03-43.08 103.61-26.57 26.58-63.26 43.08-103.6 43.08h-39.64c-40.34 0-77.03-16.5-103.6-43.08C16.5 442.73 0 406.05 0 365.7V146.68z"
                />
            </svg>
            <Div>Select an element</Div>
        </NotSelected>
    );
};

export default ComponentNotSelected

const NotSelected = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    svg {
        fill: ${({ theme }) => theme.mainColor} !important;
        width: 30px;
    }
`;