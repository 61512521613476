import React from 'react';
import styled from 'styled-components';

const number = ({ value, onChange, ...props }) => {
    return <Input {...props} type="number" onChange={(e) => onChange(Number(e.target.value))} />;
};

export default number;

const Input = styled.input `
    border: none;
    background: ${({ theme }) => theme.bg2};
    padding: 8px 12px;
    border-radius: 4px;
    color: ${({theme}) => theme.color};
    font-weight: 500;
    max-width: 160px;
    &:focus{
        outline: none;
    }
`