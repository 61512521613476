import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getReferenceContext } from 'contexts/reference';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const HandleSectionMenu = () => {
    const { frame, frameUpdate, composition } = React.useContext(getCrossDomainContext);
    const { theme } = React.useContext(getCustomThemeContext)
    const { selectedComponent, deleteComponent, cloneComponent, moveComponent, addComponent } = React.useContext(getBuilderContext)
    const { addBlockPopupShow, setAddBlockPopupShow, PointingAt, setSectionPopupShow, setEditComponentPopupShow, copiedElement } = React.useContext(getReferenceContext)
    const doc = frame.document

    // React.useEffect(() => {
    //     doc.addEventListener('mouseover', handleMouseOver);
    //     return () => {
    //         doc.removeEventListener('mouseover', handleMouseOver);
    //     };
    // }, []);

    React.useEffect(() => {
        if(selectedComponent) {
            const targetNode = doc.querySelector(`[data-track="${selectedComponent.indices}"]`)
            const targetDepth = targetNode?.getAttribute('data-depth')
            if(targetDepth === '1' && !addBlockPopupShow) drawSectionMenu()
            else clearSectionMenu()
        } else {
            clearSectionMenu()
        }
    }, [frameUpdate, selectedComponent, addBlockPopupShow, composition])
    const clearSectionMenu = () => {
        // Cleanup node
        doc.querySelector('#bl-section-menu-style')?.remove()
        const menuElements = [...doc.querySelectorAll('[bl-section-menu]')]
        menuElements.forEach(el => el?.remove())
        doc.removeEventListener('scroll', stickNodePosition)
    }
    // const handleMouseOver = (e) => {
    //     // Basic builder component check
    //     if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
    //     if(!e.target.hasAttribute('kind')) return null

    //     //Get the outermost element with depth of 1
    //     const depth = e.target.getAttribute('data-depth');
    //     const track = e.target.getAttribute('data-track');
    //     if(depth === '1') return setCurrentSectionTrack(track);
    // };
    const stickNodePosition = (selector) => {
        const targetSection = doc.querySelector(`[kind][data-track="${selectedComponent.indices}"]`)
        if(!targetSection) return null
        const nodeToStick = doc.querySelector(selector)
        if(!nodeToStick) return null

        const targetRect = targetSection.getBoundingClientRect();
        if(targetRect.top * -1 >= 0) nodeToStick.style.top = `calc(100px + ${(targetRect.top * -1) + targetSection.offsetTop}px)`
        else nodeToStick.style.top = `calc(100px + ${targetSection.offsetTop}px)`
    }
    const drawSectionMenu = () => {
        // Cleanup node
        doc.querySelector('#bl-section-menu-style')?.remove()
        const menuElements = [...doc.querySelectorAll('[bl-section-menu]')]
        menuElements.forEach(el => el?.remove())
        doc.removeEventListener('scroll', stickNodePosition)

        // Append style node
        const style = doc.createElement('style')
        style.setAttribute('id', 'bl-section-menu-style')
        style.innerText = sectionMenuStyle
        doc.head.appendChild(style)

        // Get positioning info
        const targetSection = doc.querySelector(`[kind][data-track="${selectedComponent.indices}"]`)
        if(!targetSection) return null
        const targetRect = targetSection.getBoundingClientRect();

        // Append add section top
        if(Number(selectedComponent.indices) > 0) {
            const addSectionTop = doc.createElement('div')
            addSectionTop.setAttribute('bl-section-menu', 'add-top')
            const addSectionButtonTop = doc.createElement('button')
            addSectionButtonTop.innerText = '+'
            addSectionTop.appendChild(addSectionButtonTop)
            addSectionTop.style.top = `${targetRect.top + frame.scrollY}px`
            doc.body.appendChild(addSectionTop)
            addSectionTop.addEventListener('click', (e) => {
                e.stopPropagation()
                PointingAt.current = 'u'
                setSectionPopupShow(true)
            })
        }

        // Append add section bottom
        const addSectionBottom = doc.createElement('div')
        addSectionBottom.setAttribute('bl-section-menu', 'add-bottom')
        const addSectionButtonBottom = doc.createElement('button')
        addSectionButtonBottom.innerText = '+'
        addSectionBottom.appendChild(addSectionButtonBottom)
        addSectionBottom.style.top = `${targetRect.top + targetRect.height + frame.scrollY}px`
        doc.body.appendChild(addSectionBottom)
        addSectionBottom.addEventListener('click', (e) => {
            e.stopPropagation()
            PointingAt.current = 'd'
            setSectionPopupShow(true)
        })

        // Append add block button
        const addBlockButton = doc.createElement('button')
        addBlockButton.setAttribute('bl-section-menu', 'add-block-button')
        addBlockButton.innerText = `+ ADD BLOCK`
        if(targetRect.top * -1 >= 0) addBlockButton.style.top = `calc(100px + ${(targetRect.top * -1) + targetSection.offsetTop}px)`
        else addBlockButton.style.top = `calc(100px + ${targetSection.offsetTop}px)`
        addBlockButton.style.left = `200px`
        doc.body.appendChild(addBlockButton)
        doc.addEventListener('scroll', () => stickNodePosition('[bl-section-menu="add-block-button"]'))
        addBlockButton.addEventListener('click', () => setAddBlockPopupShow(true))

        // Append context wrapper
        const ctxWrapper = doc.createElement('div')
        ctxWrapper.setAttribute('bl-section-menu', 'ctx-wrapper')
        if(targetRect.top * -1 >= 0) ctxWrapper.style.top = `calc(100px + ${(targetRect.top * -1) + targetSection.offsetTop}px)`
        else ctxWrapper.style.top = `calc(100px + ${targetSection.offsetTop}px)`
        ctxWrapper.style.right = '200px'
        doc.body.appendChild(ctxWrapper)
        doc.addEventListener('scroll', () => stickNodePosition('[bl-section-menu="ctx-wrapper"]'))

        const buttons = distinguishOptions()
        buttons.forEach(b => {
            const btnNode = doc.createElement('button');
            btnNode.setAttribute('caption', b.caption)
            b.disabled && btnNode.setAttribute('disabled', true)
            const imgNode = doc.createElement('img');
            imgNode.src = b.imgPath
            btnNode.appendChild(imgNode)
            const txtNode = doc.createElement('p');
            txtNode.innerText = b.caption
            btnNode.appendChild(txtNode)
            ctxWrapper.appendChild(btnNode)

            //Event binding
            const callbackFunction = (e) => {
                e.stopPropagation()
                b.callback()
            }
            btnNode.addEventListener('click', callbackFunction)
        })

        // Append adjust row button
        const adjustRowButton = doc.createElement('button')
        adjustRowButton.setAttribute('bl-section-menu', 'adjust-row')
        adjustRowButton.innerHTML = `<img src="/img/section-icons/scroll.svg"/>`
        const computedStyles = frame.window.getComputedStyle(targetSection)
        const paddingBottom = computedStyles.getPropertyValue('padding-bottom')
        adjustRowButton.style.top = `calc(${targetRect.top + targetRect.height + frame.scrollY}px - ${paddingBottom} - 30px)`
        adjustRowButton.style.height = '30px'
        const kind = targetSection.getAttribute('kind')
        if(kind === 'grid') doc.body.appendChild(adjustRowButton)
    }

    const distinguishOptions = () => {
        const currentIndex = checkNum(selectedComponent.indices)
        const optionButtons = []
        optionButtons.push({
            caption: 'Edit',
            imgPath: '/img/section-icons/edit.svg',
            callback: () => setEditComponentPopupShow(true)
        })
        optionButtons.push({
            caption: 'Clone',
            imgPath: '/img/section-icons/clone.svg',
            callback: () => cloneComponent(selectedComponent.indices)
        })
        optionButtons.push({
            caption: 'Up',
            imgPath: '/img/section-icons/up.svg',
            callback: () => moveComponent(selectedComponent.indices,`${currentIndex-1}`, 'u'),
            disabled: composition[currentIndex-1] ? false : true
        })
        optionButtons.push({
            caption: 'Down',
            imgPath: '/img/section-icons/down.svg',
            callback: () => moveComponent(selectedComponent.indices, `${currentIndex+1}`, 'd'),
            disabled: composition[currentIndex+1] ? false : true
        })
        if(selectedComponent.appendable) optionButtons.push({
            caption: 'Paste',
            imgPath: '/img/section-icons/paste.svg',
            callback: () => addComponent(copiedElement, selectedComponent.indices, 'a'),
            disabled: copiedElement ? false : true
        })
        optionButtons.push({
            caption: 'Remove',
            imgPath: '/img/section-icons/remove.svg',
            callback: () => deleteComponent(selectedComponent.indices)
        })
        return optionButtons
    }

    const sectionMenuStyle = `
        [bl-section-menu] {
            font-family: sans-serif;
            font-size:12px;
        }
        [bl-section-menu="add-top"],[bl-section-menu="add-bottom"]{
            position:absolute;
            width:100%;
            height:1px;
            // background:${theme.mainColor};
            background: ${theme.mainColor2};
            display:flex;
            align-items:center;
            justify-content:center;
            pointer-events:auto !important;
            z-index:1001;
            animation: fade-in 0.5s ease both;
        }
        [bl-section-menu="add-top"] button,[bl-section-menu="add-bottom"] button{
            padding:6px 11px;
            border-radius:8px;
            // background:${theme.mainColor};
            background: ${theme.mainColor2};
            display:flex;
            align-items:center;
            justify-content:center;
            color:white;
            border:none;
            transition:0.3s ease;
            cursor:pointer;
        }
        [bl-section-menu="add-block-button"]{
            box-shadow:0 4px 16px rgba(0,0,0,.12), 0 0 0 1px rgba(0,0,0,.02);
            border:none;
            border-radius:8px;
            padding:12px 22px;
            background:white;
            position:absolute;
            z-index:1002;
            pointer-events:auto;
            cursor:pointer;
            animation: fade-in-down 0.5s ease both;
        }
        [bl-section-menu="ctx-wrapper"]{
            box-shadow:0 4px 16px rgba(0,0,0,.12), 0 0 0 1px rgba(0,0,0,.02);
            border-radius:8px;
            padding:4px;
            background:white;
            position:absolute;
            z-index:1002;
            pointer-events:auto;
            display:flex;
            gap:0px;
            align-items:center;
            animation: fade-in-down 0.5s ease both;
        }
        [bl-section-menu="ctx-wrapper"] button {
            border:none;
            width:36px;
            height:36px;
            padding:8px;
            display:flex;
            align-items:center;
            justify-content:center;
            background:white;
            border-radius:8px;
            position:relative;
        }
        [bl-section-menu="ctx-wrapper"] button[caption="remove"] {
            color:red;
        }
        [bl-section-menu="ctx-wrapper"] button:hover {
            background:${theme.bg2};
            cursor:pointer;
        }
        [bl-section-menu="ctx-wrapper"] button[disabled="true"] {
            opacity:0.5;
            cursor:not-allowed;
        }
        [bl-section-menu="ctx-wrapper"] button:hover p {
            display:block !important;
        }
        [bl-section-menu="ctx-wrapper"] img {
            width:100%;
            height:100%;
        }
        [bl-section-menu="ctx-wrapper"] p {
            position:absolute;
            top:calc(100% + 5px);
            background:${theme.color};
            color:${theme.bg};
            padding:10px 12px;
            text-transform:capitalize;
            display:none;
            animation: fade-in-down 0.5s ease both;
            border-radius:8px;
        }
        [bl-section-menu="ctx-wrapper"] p:before {
            content:"";
            display:block;
            position:absolute;
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid ${theme.color};
            top:-5px;
            left:calc(50% - 5px);
        }
        [bl-section-menu="adjust-row"] {
            width:35px;
            display:flex;
            justify-content:center;
            align-items:center;
            // background:${theme.mainColor};
            background: ${theme.mainColor2};
            font-size:14px;
            font-weight:bold;
            position:absolute;
            right:100px;
            pointer-events:auto;
            border:none;
            border-radius:8px;
            cursor:ns-resize;
            animation: fade-in-down 0.5s ease both;
        }
        [bl-section-menu="adjust-row"] img {
            width:100%;
            height:100%;
            pointer-events:none;
        }
        @keyframes fade-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
        }
        @keyframes fade-out {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
        }
        @keyframes fade-in-down {
            0% {
              opacity: 0;
              transform: translateY(-10px);
            }
            100% {
              opacity: 1;
              transform: translateY(0px);
            }
        }
        @media only screen and (max-width:768px) {
            [bl-section-menu="add-block-button"] {
                left: 30px !important;
                margin-top:-50px;
            }
            [bl-section-menu="ctx-wrapper"] {
                right:unset !important;
                left: 30px !important;
            }
        }
    `

    return null;
};


export default HandleSectionMenu;

function checkNum(value, fallback) {
    const result = Number(value);
    return !isNaN(result) ? result : fallback;
}