import React from "react";
import styled from "styled-components";
import Div from 'elements/Div';
import { Close } from "utils/svg";
import Button from "elements/Button";
import Pages from "./Pages";
import WebAddress from "./WebAddress";
import Email from "./Mail";
import Phone from "./Phone";
import File from "./File";

const LinkEditor = ({ closePopup, handleSubmit, linkData }) => {
    const containerRef = React.useRef()
    const [types, setTypes] = React.useState([
        {
            type: 'page',
            newWindow: false,
            slug: null
        },
        {
            type: 'webAddress',
            newWindow: false,
            slug: null
        },
        {
            type: 'file',
            newWindow: false,
            slug: null
        },
        {
            type: 'email',
            slug: null
        },
        {
            type: 'phone',
            slug: null
        }
    ])

    React.useEffect(() => {
        setTypes(prevTypes => {
            const indexToUpdate = prevTypes.findIndex(item => item.type === linkData.type);
            if (indexToUpdate !== -1) {
                return prevTypes.map((item, index) => (index === indexToUpdate ? { ...item, ...linkData } : item));
            }
            return prevTypes;
        })
    }, [linkData]);


    const [activeType, setActiveType] = React.useState(linkData.type ? linkData : types[0])

    return (
        <Container onClick={(e) => e.target === containerRef.current && closePopup()} ref={containerRef}>
            <Div c="main-content">
                <Div c="left">
                    <Div c="title">LinkEditor</Div>
                    <Div c="types">
                        {types.map((type, i) => (
                            <Div c={`type ${activeType.type == type.type && 'active'}`} key={i} onClick={() => setActiveType(type)}>
                                <span>{type.type}</span>
                            </Div>
                        ))}
                    </Div>
                </Div>
                <Div c='right'>
                    <Div c='title'>{activeType.type}</Div>
                    {activeType.type == 'page' && <Pages closePopup={closePopup} linkData={activeType} setLinkData={setActiveType} />}
                    {activeType.type == 'webAddress' && <WebAddress closePopup={closePopup} linkData={activeType} setLinkData={setActiveType} />}
                    {activeType.type == 'file' && <File closePopup={closePopup} linkData={activeType} setLinkData={setActiveType} />}
                    {activeType.type == 'email' && <Email closePopup={closePopup} linkData={activeType} setLinkData={setActiveType} />}
                    {activeType.type == 'phone' && <Phone closePopup={closePopup} linkData={activeType} setLinkData={setActiveType} />}
                </Div>
                <Div c='footer'>
                    <Div c='buttons'>
                        <Button c="cancel" onClick={() => closePopup()}>Close</Button>
                        {linkData != activeType && <Button c="save" onClick={() => handleSubmit(activeType)}>Save</Button>}
                    </Div>
                </Div>
            </Div>
        </Container>
    )
}

export default LinkEditor

const Container = styled.div`
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
position: fixed;
left: 0;
top: 0;
background: rgba(0, 0, 0, 0.5);
z-index: 1000;
animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
& > .main-content{
    width: calc(100vw - 100px);
    height: calc((100vw - 100px) * 0.715655);
    max-height: calc(100vh - 100px);
    max-width: calc((100vh - 100px) * 1.39732);
    background: ${({ theme }) => theme.popupBg};
    display: flex;
    position: relative;
    .footer{
        position: absolute;
        bottom: 22px;
        right: 18px;
        padding: 0;
        .buttons{
            display: flex;
            button {
                text-transform: uppercase;
                background-color: transparent;
                transition: background-color 0.3s;
                color: ${({ theme }) => theme.color};
                border-radius: 0;
                padding: 11px 22px;
                &.save{
                    &:hover{
                        background-color: ${({ theme }) => theme.color};
                        color: ${({ theme }) => theme.bg};
                    }
                }
                &.cancel{
                    &:hover{
                        background-color: ${({ theme }) => theme.bg2};
                    }
                }
            }
        }
    }
    & > .left{
        width: 35%;
        background: ${({ theme }) => theme.bg2};
        .title{
            color: ${({ theme }) => theme.color};
        }
        .types{
            display: flex;
            flex-direction: column;
            gap: 5px;
            .type{
                padding: 2px 0;
                width: fit-content;
                border-bottom: 2px solid transparent;
                cursor: pointer;
                span{
                    font-weight: 600;
                    font-size: ${({ theme }) => theme.fs2};
                    line-height: 24px;
                    color: ${({ theme }) => theme.color};
                    text-transform: capitalize;
                }
                &.active{
                    border-bottom: 2px solid ${({ theme }) => theme.color};
                }
            }
        }
    }
    & > .right{
        width: 65%;
        & > .title{
            font-weight: 600;
            font-size: ${({ theme }) => theme.fs4};
            text-transform: capitalize;
        }
    }
    & > div {
        padding: 70px 30px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        & > .title{
            font-weight: 600;
            font-size: ${({ theme }) => theme.fs4};
        }
    }
}
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
`