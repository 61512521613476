import { getBuilderContext } from 'contexts/builder';
import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import CasePopup from './CasePopup';
import { generateId } from 'utils/vanillaFunctions';
import * as caseListPresences from './CaseTypes'
import ActionPopup from '../ActionTypes/ActionPopup';
import { FiPlus } from "react-icons/fi";
import { Case, Close, Trash } from 'utils/svg';

const CaseList = () => {
    const { selectedComponent, editComponent } = React.useContext(getBuilderContext);
    const [popupInstance, setPopupInstance] = React.useState(null);
    const [actionInstance, setActionInstance] = React.useState(null);
    const spreadCase = selectedComponent?.cases ?? {};

    const handleCaseChange = (instance) => {
        const argObject = { ...instance }
        delete argObject._indices
        delete argObject._editType
        if (instance._editType === "add") {
            const id = generateId()
            argObject.actions = []
            editComponent(instance._indices, 'cases', id, argObject)
        }
    }
    const handleActionChange = (actionObject) => {
        const { _caseKey } = actionObject
        delete actionObject._caseKey
        const existingCases = { ...spreadCase }
        if (!existingCases[_caseKey]) return console.warn('no such case')
        const thisCase = { ...existingCases[_caseKey] }
        const thisActions = [...thisCase.actions]
        const matchingCaseIndex = thisActions.findIndex(val =>
            val.type === actionObject.type
        )
        if (actionObject.update || matchingCaseIndex !== -1) {
            if (matchingCaseIndex !== - 1) {
                thisActions[matchingCaseIndex] = actionObject;
            }
        } else {
            thisActions.push({ ...actionObject })
        }
        thisCase.actions = thisActions
        editComponent(selectedComponent.indices, 'cases', _caseKey, thisCase)
        // thisCase.actions.push({...actionObject})
        // console.log(thisCase, 'thisCase')
        // editComponent(selectedComponent.indices, 'cases', )
    }
    const handleDelete = (caseKey) => {
        if (!window.confirm('Are you sure to delete it?')) return null
        editComponent(selectedComponent.indices, 'delete case', caseKey)
    }
    const handleCaseDelete = (caseKey, index) => {
        const existingCases = { ...spreadCase }
        const thisCase = { ...existingCases[caseKey] }
        const thisActions = [...thisCase.actions]

        if (thisActions) {
            if (!window.confirm('Are you sure to delete it?')) return null
            thisActions.splice(index, 1)
            thisCase.actions = thisActions
            editComponent(selectedComponent.indices, 'cases', caseKey, thisCase)
        }
    }

    return (
        <Container>
            <Div c='head-wrap'>
                <Div c="caption">Case settings</Div>
                <Button c="add-case" onClick={() => setPopupInstance({ _indices: selectedComponent.indices, _editType: 'add' })}>
                    <FiPlus />
                </Button>
            </Div>
            <Div c="cases-wrap">
                {Object.keys(spreadCase).map(caseKey => {
                    const thisCase = spreadCase[caseKey]
                    const Presence = thisCase.type == 'dataChange' ? caseListPresences.dataChange.presence : caseListPresences.stateChange.presence
                    return (
                        <Div c="presence" key={caseKey}>
                            <Div c="top">
                                <Presence instance={thisCase} />
                                <Div c='trash'>
                                    <Trash onClick={() => handleDelete(caseKey)} />
                                </Div>
                            </Div>
                            <Div c="action-wrap">
                                <Div c='actions'>
                                    {thisCase.actions?.map((action, i) => <Div c='box' key={i}>
                                        <span onClick={() => setActionInstance({ update: true, _caseKey: caseKey, ...action })}>{action.type}</span>
                                        <Close onClick={() => handleCaseDelete(caseKey, i)} />
                                    </Div>)}
                                </Div>
                                <Button c="add-action" onClick={() => setActionInstance({ _caseKey: caseKey })}>
                                    Add action +
                                </Button>
                            </Div>
                        </Div>
                    )
                })}
            </Div>
            {actionInstance && (
                <ActionPopup instance={actionInstance} setInstance={setActionInstance} dispatchChange={handleActionChange} />
            )}
            {popupInstance && <CasePopup instance={popupInstance} setInstance={setPopupInstance} dispatchChange={handleCaseChange} />}
        </Container>
    );
};

export default CaseList;

const Container = styled.div`
padding: 0 20px;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.head-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    .caption {
        font-weight: 500;
        text-transform: capitalize;
        color: ${({ theme }) => theme.color};
        font-size: 12px;
        line-height: 20px;
    }
    .add-case {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        transition: all 0.3s linear;
        border-radius: 4px;
        svg{
            width: 16px;
            height: 16px;
        }
        &:hover{
            background: ${({ theme }) => theme.bg2};
            svg{
                path{
                    fill: ${({ theme }) => theme.color2};
                }
            }
        }
    }
}
& > .cases-wrap {
    .text-wrap{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
        border-radius: 4px;
        padding: 15px 0;
        & > svg{
            path{
                fill: ${({ theme }) => theme.color2};
            }
        }
        span{
            font-size: 12px;
            color: ${({ theme }) => theme.color2};
            text-align: center;
            width: 80%;
            line-height: 1.3;
        }
    }
        & > .presence {
            border:1px solid ${({ theme }) => theme.sectionBorderColor};
            margin-bottom: 10px;
            cursor: pointer;
            & > .top {
                padding: 8px 12px;
                display:flex;
                align-items:center;
                justify-content:space-between;
                border-bottom: 1px solid ${({ theme }) => theme.bg2};
                .trash{
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: transparent;
                    transition: all 0.3s linear;
                    border-radius: 4px;
                    svg{
                        width: 16px;
                        height: 16px;
                    }
                }
                &:hover{
                    .trash{
                        background: ${({ theme }) => theme.bg2};
                        svg{
                            path{
                                fill: ${({ theme }) => theme.color2};
                            }
                        }
                    }
                }
            }
            & > .action-wrap {
                .actions{
                    display: flex;
                    padding: 8px 12px;
                    flex-wrap: wrap;
                    gap: 10px;
                    .box{
                        border-radius: 20px;
                        padding: 5px 20px;
                        background-color: ${({ theme }) => theme.bg2};
                        transition: background-color .2s,color .2s;
                        position: relative;
                        z-index:1;
                        overflow: hidden;
                        cursor: pointer;
                        span{
                            position: relative;
                            right: 0;
                            right .2s cubic-bezier(.32,.94,.6,1)
                        }
                        svg{
                            width: 14px;
                            height: 14px;
                            position: absolute;
                            right: 2px;
                            transition: all .2s cubic-bezier(.4,0,.68,.06);
                            transform: rotate(45deg);
                            opacity: 0;
                        }
                        &:hover{
                            span{
                                right: 7px;
                            }
                            svg{
                                right: 7px;
                                opacity: 1;
                                transform: rotate(0deg);
                            }
                        }
                    }
                }
                .add-action{
                    width: 100%;
                    padding: 15px;
                    border-radius: 0;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: ${({ theme }) => theme.color};
                    font-size: 10px;
                }
            }
        }
    }
`