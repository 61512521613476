import PropTypes from 'prop-types';
import React from 'react';
import {
    syncWithFrame,
    watchFrameMount,
    watchCompositionChange,
    watchFrameScreenChange,
    syncFrameLanguage,
    syncDetailPage,
    syncDevmode
} from './events';
import { getCookie, setCookie } from 'utils/vanillaFunctions';
import { getWebglobalContext } from 'contexts/webglobal';
import request from 'utils/request';
import { useParams } from 'react-router-dom';

export const getCrossDomainContext = React.createContext();

export const CrossDomainContextProvider = ({ children, initialData }) => {
    const [frame, setFrame] = React.useState(null);
    const [composition, setComposition] = React.useState(initialData);
    const [frameUpdate, setFrameUpdate] = React.useState(0);
    const [frameScreen, setFrameScreen] = React.useState(1024);
    const [device, setFrameDevice] = React.useState('desktop');
    const [frameEditWidth, setFrameEditWidth] = React.useState('100%');
    const [frameContainerWidth, setFrameContainerWidth] = React.useState('100%');
    const [language, setLanguage] = React.useState(getCookie('defaultLang') ?? 'mn');
    const { settings } = React.useContext(getWebglobalContext);
    const { id: webId } = useParams();
    const [availableLanguages, setAvailableLanguages] = React.useState([])
    const [devmode, setDevmode] = React.useState(false)


    //WATCH FOR INCOMING NETWORK REQUEST
    React.useEffect(() => {
        setComposition(initialData);
    }, [initialData]);

    React.useEffect(() => {
        fetchAvailableLanguages();
    }, []);

    const changeLanguage = (langArgument) => {
        setLanguage(langArgument)
        setCookie('defaultLang', langArgument);
    };
    const fetchAvailableLanguages = async () => {
        const result = await request({ url: window.urls.web + `/web/${webId}/language`, headers: { webId } });
        setAvailableLanguages(result.data.languages)
    };

    //COMPONENT MOUNTED
    React.useEffect(() => {
        watchFrameMount(setFrame);
        watchCompositionChange(setFrameUpdate);
        watchFrameScreenChange(setFrameScreen);

        //Prevent user to reload the page
        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();
            e.returnValue = 'Are you sure you want to leave this page?';
        });
    }, []);

    //WATCH FOR LOCAL COMPOSITION CHANGES
    React.useEffect(() => {
        if (frame) syncWithFrame(frame, composition);
    }, [frame, composition]);

    //WATCH FOR DEVMODE CHANGE
    React.useEffect(() => {
        if (frame) syncDevmode(frame, devmode)
    }, [frame, devmode])

    //WATCH FOR LANGUAGE CHANGE
    React.useEffect(() => {
        if (frame) syncFrameLanguage(frame, language);
    }, [frame, language]);

    React.useEffect(() => {
        if (frame) {
            syncDetailPage(frame, settings.detailedData);
        }
    }, [frame, settings.detailedData]);

    React.useEffect(() => {
        if (frameScreen <= 480) setFrameDevice('mobile');
        else if (frameScreen > 480 && frameScreen <= 768) setFrameDevice('tablet');
        else setFrameDevice('desktop');
    }, [frameScreen]);

    React.useEffect(() => {
        //THIS IS KINDA DUMB, BUT ITS OKAY FOR styleHelpers.getStyleValue
        //FUNCTION SINCE IT DOESNT HAVE TO BE RERENDERED
        localStorage.setItem('bl-device', device);
    }, [device]);

    return (
        <getCrossDomainContext.Provider
            value={{
                frame,
                composition,
                setComposition,
                frameUpdate,
                frameScreen,
                device,
                frameEditWidth,
                setFrameEditWidth,
                frameContainerWidth,
                setFrameContainerWidth,
                language,
                changeLanguage,
                availableLanguages,
                devmode,
                setDevmode
            }}
        >
            {children}
        </getCrossDomainContext.Provider>
    );
};

CrossDomainContextProvider.propTypes = {
    children: PropTypes.any,
    initialData: PropTypes.any,
};
