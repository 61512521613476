import { getCrossDomainContext } from 'contexts/crossDomain';
import { getWebglobalContext } from 'contexts/webglobal';
import Div from 'elements/Div';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const MainSection = () => {
    const { frameEditWidth, device, setFrameContainerWidth } = React.useContext(getCrossDomainContext)
    const { settings } = React.useContext(getWebglobalContext)
    const iframe = React.useRef()
    const [searchParams] = useSearchParams()
    const { id: webId } = useParams()
    const collectionId = searchParams.get('collectionId')
    const slug = searchParams.get('slug')

    React.useEffect(() => {
        setFrameContainerWidth(iframe.current.offsetWidth)
        document.addEventListener('resize', () => {
            setFrameContainerWidth(iframe.current.offsetWidth)
        })
    }, [])

    return (
        <Container pageFound={settings.compositionReady ? true : false} frameEditWidth={frameEditWidth} device={device}>
            <Div id="frame-wrapper">
                {settings.compositionReady ? null : <Div c="page-not-found">Not found</Div>}
                <iframe ref={iframe} id="main-frame" src={`/frame?collectionId=${collectionId}&slug=${slug}&webId=${webId}`} />
            </Div>
        </Container>
    );
};

export default MainSection;

const Container = styled.div`
    flex: 1;
    background: ${({ theme }) => theme.bg2};
    display: flex;
    flex-direction: column;
    & > #frame-wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        iframe {
            border: 1px dashed ${({ theme }) => theme.mainColor};
            width: ${({ frameEditWidth }) => frameEditWidth};
            height: 100%;
            display:none;
            background:${({ theme }) => theme.bg};
            ${({ device }) =>
        device === 'mobile' &&
        `
                height: 640px;
            `};
            ${({ pageFound }) => pageFound && `
                display:block;
            `};
        }
        .page-not-found{
            width:100%;
            height:100%;
            background:${({ theme }) => theme.bg};
            border: 1px dashed ${({ theme }) => theme.mainColor};
            display:flex;
            justify-content:center;
            align-items:center;
            display: flex;
            ${({ pageFound }) => pageFound && `
                display:none;
            `};
        }
    }
`;
