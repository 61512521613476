import { getCrossDomainContext } from 'contexts/crossDomain';
import { getReferenceContext } from 'contexts/reference';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const HighlightElement = () => {
    const { theme } = React.useContext(getCustomThemeContext);
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext);
    const { Highlight: itself } = React.useContext(getReferenceContext);
    const doc = frame.document;
    React.useEffect(() => {
        createShapeNode();
        doc.addEventListener('mouseover', (e) => {
            if (!e.target.hasAttribute('kind')) return clearShape();
            const kind = e.target.getAttribute('kind');
            if (kind === 'component'){
                const depth = e.target.getAttribute('data-depth')
                drawShape({ detail: { target: e.target, depth } });
            }
        });
        doc.addEventListener('mouseleave', (e) => {
            e.preventDefault()
            return clearShape();
        })
        itself.current.addEventListener('draw', drawShape);
        itself.current.addEventListener('clear', clearShape);
    }, []);

    React.useEffect(() => {
        clearShape()
    }, [frameUpdate])
    
    const drawShape = (event) => {
        const { detail } = event;
        const { target, depth } = detail;
        const shape = itself.current;
        const { width, height, top, left } = target.getBoundingClientRect();
        shape.style.top = `${top + frame.scrollY}px`;
        shape.style.left = `${left + frame.scrollX}px`;
        shape.style.width = `${width}px`;
        shape.style.height = `${height}px`;
        if(depth === '2') shape.style.borderColor = theme.mainColor2;
        else shape.style.borderColor = theme.mainColor;
    };
    const clearShape = () => {
        const shape = itself.current;
        shape.style.top = `0px`;
        shape.style.left = `0px`;
        shape.style.width = `0px`;
        shape.style.height = `0px`;
        shape.style.borderColor = 'initial';
    };
    const createShapeNode = () => {
        const shape = doc.createElement('div');
        const background = doc.createElement('div');
        background.style.cssText = `
            // background:${theme.mainColor};
            height:100%;
            opacity:0.10;
        `;
        shape.style.cssText = `
            display:block;
            position:absolute;
            pointer-events:none;
            box-sizing:border-box;
            border:1px dashed ${theme.mainColor};
            z-index:1000;
        `;
        shape.append(background);
        doc.body.appendChild(shape);
        itself.current = shape;
    };
    return null;
};

export default React.memo(HighlightElement);
