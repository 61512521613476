import React from 'react';
import styled from 'styled-components';
import Button from 'elements/Button';
import { getCrossDomainContext } from 'contexts/crossDomain';
import request from 'utils/request';
import { getWebglobalContext } from 'contexts/webglobal';
import { toast } from 'react-hot-toast';
import { getReferenceContext } from 'contexts/reference';
import { useSearchParams } from 'react-router-dom';
import { getCustomThemeContext } from 'contexts/theme';
import Div from 'elements/Div';
import { Back, Forward, Eye, Dev } from 'utils/svg';

const HeadingRight = () => {
    const { composition, devmode, setDevmode } = React.useContext(getCrossDomainContext);
    const { historyUndo, historyRedo } = React.useContext(getReferenceContext);
    const { SETLOADING } = React.useContext(getCustomThemeContext);
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');
    const { settings } = React.useContext(getWebglobalContext);
    const base = type === 'inapp' ? window.urls.notification : window.urls.web;
    const handleSave = async () => {
        try {
            SETLOADING(true);
            const result = await request({ url: base + `/${type}/${settings[type].id}`, body: { data: composition }, method: 'put' });
            toast.success(result.message);
        } catch (e) {
            toast.error(e.message);
        } finally {
            SETLOADING(false);
        }
    };
    const handleView = () => {
        if (type === 'page') {
            const base = settings.web?.domain;
            const url = settings.page?.slug;
            if (base && url) window.open('https://' + base + url, '_blank');
        }
    };
    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [composition]);
    const handleKeyDown = async (e) => {
        if (e.metaKey) {
            if (e.key === 'i') {
                const userInput = prompt('Enter JSON:');
                try {
                    SETLOADING(true);
                    if (!userInput) throw new Error('Input cannot be empty');
                    const parsedJSON = JSON.parse(userInput);
                    const result = await request({
                        url: base + `/${type}/${settings[type].id}`,
                        body: { data: parsedJSON },
                        method: 'put',
                    });
                    toast.success(result.message);
                } catch (error) {
                    toast.error(error.message);
                    console.error('Error parsing JSON:', error.message);
                } finally {
                    SETLOADING(false);
                }
            } else if (e.key === 'u') {
                console.log(composition)
            }
        }
    }
    return (
        <Container>
            <Div c="wrap">
                <Div c={`mode-switcher ${devmode && 'active'}`} onClick={() => setDevmode(!devmode)}>
                    <Div c="svg-container">
                        <Dev />
                    </Div>
                </Div>
            </Div>
            <Div c="wrap">
                <Div onClick={historyUndo}>
                    <Back />
                </Div>
                <Div onClick={historyRedo}>
                    <Forward />
                </Div>
            </Div>
            {type == 'page' && (
                <Div c="wrap">
                    <Div onClick={handleView}>
                        <Eye />
                    </Div>
                </Div>
            )}
            <Div c="button-wrap">
                <Button onClick={handleSave} c="save">
                    <span>Publish</span>
                </Button>
            </Div>
        </Container>
    );
};

export default HeadingRight;

const Container = styled.div`
display: flex;
justify-content: end;
height: 100%;
& > .wrap{
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.sectionBorderColor};
    & > div {
        cursor: pointer;
        svg{
            path{
                fill: ${({ theme }) => theme.color};
            }
        }
    }
    .mode-switcher{
        width: 56px;
        height: 32px;
        padding: 2px;
        background: ${({ theme }) => theme.bg3};
        border-radius: 16px;
        display: flex;
        justify-content: start;
        .svg-container{
            width: 32px;
            height: 100%;
            background: ${({ theme }) => theme.bg};
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &.active{
            justify-content: end;
            .svg-container{
                background: ${({ theme }) => theme.mainColor};
                svg{
                    path{
                        fill: ${({ theme }) => theme.bg};
                    }
                }
            }
        }
    }
}
.button-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 11px;
    & > .save{
        background: ${({ theme }) => theme.bg5};
        border-radius: 0;
        padding: 14px 22px;
        border-radius: 8px;
        & > span{
            font-size: ${({ theme }) => theme.fs};
            font-weight: 500;
            line-height: 1;
            color: white;
            letter-spacing: .5px;
            text-transform: uppercase;
        }
    }
}
`;
