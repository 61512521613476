import { getCrossDomainContext } from 'contexts/crossDomain';
import Div from 'elements/Div';
import React from 'react';
import Select from '../InputTypes/Select'
import { populateState } from 'utils/vanillaFunctions';

const toggleState = ({instance, setInstance}) => {
    const {frame} = React.useContext(getCrossDomainContext)
    const [availableStates, setAvailableStates] = React.useState([])
    React.useEffect(() => {
        const target = frame.document.querySelector(`[data-track="${instance._indices}"]`)
        const populatedStates = populateState(target)
        setAvailableStates(Object.keys(populatedStates))
    }, [])
    return (
        <Div c="input-wrap">
            <label>State name</label>
            <Select options={availableStates} value={instance.stateName} onChange={(v)=>setInstance({...instance, stateName: v})}/>
        </Div>
    );
};

export default toggleState;
