import { getCrossDomainContext } from 'contexts/crossDomain';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const HandleEmptyAppendables = () => {
    const { frame } = React.useContext(getCrossDomainContext);
    const { theme } = React.useContext(getCustomThemeContext);
    const doc = frame.document;

    React.useEffect(() => {
        initWatchEmptyAppendables();
    }, []);

    const checkAppendableEmpty = () => {
        const components = [...doc.querySelectorAll('[kind="component"]')]
        components.forEach(component => {
            if(component.dataset.appendable === "true") {
                const children = [...component.childNodes]
                let foundComponent = false
                children.forEach(child => {
                    if(child.getAttribute('kind') === "component") {
                        foundComponent = true
                    }
                    const grandChildren = [...child.childNodes]
                    grandChildren?.forEach(grandchild => {
                        if(grandchild.tagName === undefined) return null
                        if(grandchild?.getAttribute('kind') === "component") {
                            foundComponent = true
                        }
                    })
                })
                if(!foundComponent) {
                    return component.setAttribute('data-emptyappendable', 'true')
                }
                component.setAttribute('data-emptyappendable', 'false')
            }
        })
    }

    const initWatchEmptyAppendables = () => {
        const root = doc.querySelector('#root');
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const checkNodes = [...mutation.addedNodes, ...mutation.removedNodes]
                checkAppendableEmpty()
                checkNodes.forEach((node) => {
                    // console.log(node, 'node')
                    // if (node.getAttribute('kind') === 'component') {
                    //     if (node.dataset.appendable === 'true') {
                    //         // watchAppendableEmpty(node)
                    //         checkAppendableEmpty()
                    //     }
                    // }
                });
            });
        });
        const config = { childList: true, subtree: true };
        observer.observe(root, config);
    };

    return null;
};

export default HandleEmptyAppendables;
