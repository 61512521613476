import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import LoadingFallback from 'router-entries/LoadingFallback';
import styled from 'styled-components';
import request from 'utils/request';
import { useAuth } from 'utils/useAuth';
import MediaIndex from 'siro-media-library';
import ToggleButton from 'components/ToggleButton';

const PagePopup = ({ instance: firstInstance, close, refetch, type }) => {
    const { id: webId } = useParams()
    const [instance, setInstance] = React.useState({ ...firstInstance })
    const containerRef = React.useRef()
    const [loading, setLoading] = React.useState(false)
    const [dynamicType, setDynamicType] = React.useState(instance.type == 'dynamic' ? true : false)
    const [protectedType, setProtectedType] = React.useState(instance.isProtected ? instance.isProtected : false)
    const [showMediaLibrary, setShowMediaLibrary] = React.useState(false)
    const { accessToken } = useAuth()
    const base = type === 'inapp' ? window.urls.notification : window.urls.web
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const body = { name: instance.name, slug: instance.slug, isProtected: protectedType }
            if (type === 'page') body.type = dynamicType ? 'dynamic' : 'default'
            if (type === 'inapp') body.status = 'inactive'
            if (instance.imageUrl) body.imageUrl = instance.imageUrl
            const result = await request({
                url: instance.id ? `${base}/${type}/${instance.id}` : `${base}/${type}`,
                body,
                method: instance.id ? 'put' : 'post',
                headers: { webId }
            });
            toast.success(result.message)
        } catch (e) {
            toast.error(e)
        } finally {
            close()
            setLoading(false)
            refetch()
        }
    }
    const onMediaSelect = (val) => {
        setInstance({ ...instance, imageUrl: val.url })
        setShowMediaLibrary(false)
    }
    return (
        <Container onClick={(e) => e.target === containerRef.current && close()} ref={containerRef}>
            {loading ? (
                <LoadingFallback />
            ) : (
                <Div c="wrap">
                    <Div c="title">{type}</Div>
                    <form onSubmit={handleSubmit}>
                        <Div c="input-wrap">
                            <label>Name</label>
                            <input
                                onChange={(e) => setInstance({ ...instance, name: e.target.value })}
                                value={instance.name}
                                required
                                name="name"
                            />
                        </Div>
                        {type === 'inapp' && (
                            <Div c="input-wrap">
                                <label>Thumbnail</label>
                                <Div c="media-preview">
                                    <img src={instance.imageUrl} />
                                    <Button type="button" onClick={() => setShowMediaLibrary(!showMediaLibrary)}>Choose a photo</Button>
                                </Div>
                                <MediaIndex setImage={onMediaSelect} onCancel={() => setShowMediaLibrary(!showMediaLibrary)} open={showMediaLibrary} accessToken={accessToken} page={false} webId={webId} />
                            </Div>
                        )}
                        {type === 'page' && <Div c="input-wrap">
                            <label>Slug</label>
                            <input
                                onChange={(e) => setInstance({ ...instance, slug: e.target.value })}
                                value={instance.slug}
                                required
                                name="slug"
                            />
                        </Div>}
                        {type === 'page' && <Div c="toggle-wrap" onClick={() => setDynamicType(!dynamicType)}>
                            <span>Dynamic page</span>
                            <ToggleButton isToggled={dynamicType} setIsToggled={setDynamicType} />
                        </Div>}
                        {type === 'page' && <Div c="toggle-wrap" onClick={() => setProtectedType(!protectedType)}>
                            <span>Protected page</span>
                            <ToggleButton isToggled={protectedType} setIsToggled={setProtectedType} />
                        </Div>}
                        <Div c='footer'>
                            <Div c='buttons'>
                                <Button c="cancel" onClick={() => close()}>Cancel</Button>
                                <Button c="save" type="submit">Submit</Button>
                            </Div>
                        </Div>
                    </form>
                </Div>
            )}
        </Container>
    );
};

export default PagePopup;

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    & > .wrap {
        background: ${({ theme }) => theme.popupBg};
        width: 500px;
        height: fit-content;
        padding: 40px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        & > .title {
            color: ${({ theme }) => theme.color};
            font-weight: 600;
            font-size: ${({ theme }) => theme.fs4};
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-transform: capitalize;
            margin-bottom: 30px;
            & > svg {
                cursor: pointer;
            }
        }
        & > form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: flex-end;
            .input-wrap {
                width: 100%;
                label {
                    font-weight: 600;
                    text-transform: uppercase;
                    color: ${({ theme }) => theme.color2};
                    font-size: 10px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }
                input {
                    width:100%;
                    border: none;
                    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
                    padding: 8px 0;
                    transition: all 0.6s linear;
                    background: transparent;
                    color: ${({ theme }) => theme.color};
                    &:focus{
                        outline: none;
                        border-bottom: 1px solid ${({ theme }) => theme.color};
                    }
                }
                .media-preview{
                    gap:15px;
                    display:flex;
                    align-items:center;
                    img{
                        min-width:120px;
                        height:60px;
                        border-radius:8px;
                        border:1px solid ${({ theme }) => theme.sectionBorderColor};
                        object-fit:cover;
                    }
                    button{
                        padding: 12px 6px;
                        background:${({ theme }) => theme.bg};
                        color: inherit;
                    }
                }
            }
            .toggle-wrap{
                display: flex;
                width: 100%;
                justify-content: space-between;
                height: 44px;
                cursor: pointer;
                align-items: center;
                border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
                & > div{
                    pointer-events: none;
                }
                span{
                    font-weight: 600;
                    text-transform: uppercase;
                    color: ${({ theme }) => theme.color2};
                    font-size: 10px;
                    line-height: 20px;
                }
            }
            .footer{
                padding: 0;
                margin-top: 30px;
                .buttons{
                    display: flex;
                    gap: 10px;
                    button{
                        text-transform: uppercase;
                        background-color: transparent;
                        transition: background-color 0.3s;
                        padding: 8px 12px;
                        border-radius: 0;
                        &.cancel{
                            &:hover{
                                background-color: ${({ theme }) => theme.bg2};
                            }
                        }
                        &.save{
                            &:hover{
                                background-color: ${({ theme }) => theme.mainColor};
                                color: ${({ theme }) => theme.bg};
                            }
                        }
                    }
                }
            }
        }
    }
    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
