/* eslint-disable react/prop-types */
import { getReferenceContext } from 'contexts/reference';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Library, { SiroProvider } from 'siro_lib';
import { generateId, getCookie } from 'utils/vanillaFunctions';
window.siroEnv = 'builder'
const Frame = () => {
    const [composition, setComposition] = React.useState([]);
    const stackCount = React.useRef(0);
    const [screen, setScreen] = React.useState(window.innerWidth);
    const [, setFrameDevice] = React.useState('desktop');
    const [searchParams] = useSearchParams();
    const collectionId = searchParams.get('collectionId');
    const slug = searchParams.get('slug');
    const webId = searchParams.get('webId');
    const [collectionData, setCollectionData] = React.useState(null)
    const [language, setLanguage] = React.useState(getCookie('defaultLang') ?? 'mn')
    const [detailedData, setDetailedData] = React.useState({})
    const [devmode, setDevmode] = React.useState(false)

    React.useEffect(() => {
        if (collectionId) {
            if (collectionId !== "null") {
                fetchCollection();
            }
        }
    }, [collectionId])

    React.useEffect(() => {
        // SEND COMPOSITION CHANGE SIGNAL
        const event = new CustomEvent('compositionChange', { detail: { composition: [...composition] } });
        window.parent.dispatchEvent(event);
    }, [composition]);

    React.useEffect(() => {
        const event = new CustomEvent('devmode', { detail: { devmode: devmode } });
        window.parent.dispatchEvent(event);
        //EXPERIMENTAL SETTING
        window.devmode = devmode
    }, [devmode]);

    React.useEffect(() => {
        // SEND MOUNTED SIGNAL
        const event = new CustomEvent('frameMounted', {
            detail: { mounted: true, frame: window },
        });
        window.parent.dispatchEvent(event);

        // LISTEN FOR DATA CHANGE
        window.addEventListener('dataChange', ({ detail }) => {
            setComposition(detail);
        });

        // LISTEN FOR DETAIL DATA CHANGE
        window.addEventListener('detailChange', ({ detail }) => {
            setDetailedData(detail);
        });

        // LISTEN FOR LANGUAGE CHANGE
        window.addEventListener('languageChange', ({ detail }) => {
            setLanguage(detail);
        });

        window.addEventListener('devmode', ({ detail }) => {
            setDevmode(detail);
        });

        identifyScreenWidth();
        window.addEventListener('resize', () => {
            identifyScreenWidth();
        });

        //EXPERIMENTAL SETTINGS
        window.webId = webId
    }, []);

    React.useEffect(() => {
        if (screen <= 480) setFrameDevice('mobile');
        else if (screen > 480 && screen <= 768) setFrameDevice('tablet');
        else setFrameDevice('desktop');
    }, [screen]);

    const identifyScreenWidth = () => {
        // SEND FRAME SCREEN SIGNAL
        const event = new CustomEvent('screenChange', { detail: { screenWidth: window.innerWidth } });
        window.parent.dispatchEvent(event);
        setScreen(window.innerWidth);
    };

    const fetchCollection = async () => {

        const url = 'https://content-service.siro.mn/collection/cd/getCd';
        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({ _id: collectionId }),
            headers: {
                'Content-Type': 'application/json',
                'webId': webId,
            },
        });
        const resultJson = await result.json();
        const getStructure = (arg) => {
            const arr = arg._source.data.map(instance => {
                const copied = { ...instance }
                delete copied.value
                return copied
            })
            return arr
        }
        const apiStructure = getStructure(resultJson.data)
        function capitalizeFirstLetter(string) {
            const firstLetter = string.charAt(0).toUpperCase();
            const restOfString = string.slice(1);

            return firstLetter + restOfString;
        }
        const capitalizedKey = capitalizeFirstLetter(slug.replace('/', ''))
        const dataObjects = {
            [capitalizedKey]: {
                fields: apiStructure,
                data: resultJson.data
            }
        }
        setCollectionData(dataObjects)
    }

    const StackLayers = ({ type, component, children, depth, index, track, appendable, accessible, kind, repeatable, ...props }) => {
        const indexToAssign = index;
        if (!track) track = '' + index + '';
        else track = track + '-' + index;
        depth = typeof depth === 'number' ? depth + 1 : 0;
        const hasChildren = children && children.length;
        const Component = Library[type][component].default;
        const propTypes = Library[type][component].propData.options.defaultValue;
        const richText = Object.keys(propTypes).find((p) => propTypes[p].type === 'richtext');
        stackCount.current += 1;
        const draggable = devmode ? true : depth === 2 ? true : false
        if (repeatable) {
            if (!props.options.pageNumber) props.options.pageNumber = { type: 'text', value: '1' }
            if (!props.options.perPage) props.options.perPage = { type: 'text', value: '' }
            if (!props.options.filterString) props.options.filterString = { type: 'text', value: '' }
            if (!props.options.searchString) props.options.searchString = { type: 'text', value: '' }
            // if(!props.options.dataFilter) props.options.dataFilter = [
            //     {
            //         category: {
            //             type: 'boolean',
            //             value: true,
            //         },
            //         field: {
            //             type: 'text',
            //             value: ''
            //         },
            //         value: {
            //             type: 'text',
            //             value: ''
            //         }
            //     }
            // ]
        }
        return (
            <Component
                contenthook={track}
                cases={props.cases}
                events={props.events}
                state={props.state}
                options={props.options}
                scroll={props.scroll}
                propBind={props.propBind}
                stateBind={props.stateBind}
                dataObjects={detailedData}
                styleObjects={props.style}
                key={track}
                assignedName={props.name}
                indexToAssign={indexToAssign}
                builderHelpers={{
                    'data-depth': depth,
                    'data-index': indexToAssign,
                    'data-track': track,
                    'data-appendable': appendable ? true : '',
                    'data-repeatable': repeatable ? true : '',
                    'data-richtext': richText ? true : false,
                    'data-richtextfield': richText,
                    'data-accessbile': accessible,
                    'data-locked': props.locked,
                    draggable: kind === 'grid' ? false : draggable,
                    // title: props?.name ?? component,
                    kind: kind ?? 'component',
                }}
            >
                {hasChildren && children.map((child, index) => StackLayers({ ...child, track, depth, index }))}
            </Component>
        );
    };

    return (
        <SiroProvider defaultLang={language}>
            {composition.map((instance, index) => {
                return StackLayers({ ...instance, index, depth: 0 });
            })}
        </SiroProvider>
    );
};

export default Frame;
