import { getCrossDomainContext } from 'contexts/crossDomain';
import React from 'react';

const InitListenContentClass = () => {
    const { frame, composition } = React.useContext(getCrossDomainContext);
    const doc = frame.document;
    React.useEffect(() => {
        const root = doc.querySelector('#root');
        const observer = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                if (mutation.attributeName === 'contentclass') {
                    const existingClasses = [...doc.querySelectorAll(`[hascontentclass]`)];
                    existingClasses.forEach(node => {
                        const givenClass = node.getAttribute('hascontentclass')
                        node.classList.remove(givenClass)
                        node.removeAttribute('hascontentclass')
                    })
                    const contentclass = root.getAttribute('contentclass');
                    if (!contentclass) return null;
                    const [indices, className] = contentclass.split('||');
                    const target = doc.querySelector(`[data-track="${indices}"]`);
                    target.classList.add(className);
                    target.setAttribute('hascontentclass', className)
                }
            });
        });
        observer.observe(root, { attributes: {contentclass: true} });
        const syncWithContentClass = (target) => {
            const contentclass = root.getAttribute('contentclass');
            if (!contentclass) return null;
            const [indices, className] = contentclass.split('||');
            if (indices !== target.dataset.track) return null;
            const exists = target.classList.contains(className);
            if (exists) return null;
            target.classList.add(className);
            target.setAttribute('hascontentclass', className);
        };
        const classObserver = new MutationObserver((mutationList) => {
            const contentclass = root.getAttribute('contentclass');
            if (!contentclass) return null;
            mutationList.forEach((mutation) => {
                if (mutation.type) syncWithContentClass(mutation.target);
            });
        });
        const components = [...doc.querySelectorAll('[kind="component"]')];
        components.forEach((component) => classObserver.observe(component, { attributes: {class: true} }));
    }, [composition]);
    return null;
};

export default InitListenContentClass;
