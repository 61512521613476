import Div from "elements/Div";
import React from "react";
import { Close } from 'utils/svg';
import styled from "styled-components";

const Options = ({ options, change, remove }) => {
    const [addOption, setAddOption] = React.useState('')

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            if (e.target.value.trim() !== '') {
                change(addOption)
                setAddOption('')
            }
        } else if (typeof e === 'string') {
            if (e.trim() !== '') {
                change(addOption)
                setAddOption('')
            }
        }
    }

    const handleRemoveOption = (index) => {
        const newValue = options 
        newValue.splice(index, 1)
        remove(newValue)
    }

    return (
        <Container>
            <Div c='options-wrap'>
                {options?.map((option, i) => (
                    <div className="option" key={i}>
                        <span>{option}</span>
                        <Close onClick={() => handleRemoveOption(i)} />
                    </div>
                ))}
                <input value={addOption} placeholder='Add...' onChange={(e) => setAddOption(e.target.value)} onKeyDown={handleKeyDown} />
                {addOption && <Div c='add' onClick={() => handleKeyDown(addOption)}>{addOption}</Div>}
            </Div>
        </Container>
    )
}

export default Options

const Container = styled.div`
width: 100%;
.options-wrap{
    display: flex;
    width: calc(100% - 10px);
    padding-bottom: 5px;
    gap: 5px;
    flex-wrap: wrap;
    transition: all 0.6s linear;
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    position: relative;
    &:hover{
        border-bottom: 1px solid ${({ theme }) => theme.color};
    }
    .option{
        border-radius: 20px;
        padding: 5px 20px;
        background-color: ${({ theme }) => theme.bg2};
        transition: background-color .2s,color .2s;
        position: relative;
        z-index:1;
        overflow: hidden;
        cursor: pointer;
        span{
            position: relative;
            right: 0;
            right .2s cubic-bezier(.32,.94,.6,1)
        }
        svg{
            width: 14px;
            height: 14px;
            position: absolute;
            right: 2px;
            transition: all .2s cubic-bezier(.4,0,.68,.06);
            transform: rotate(45deg);
            opacity: 0;
        }
        &:hover{
            span{
                right: 7px;
            }
            svg{
                right: 7px;
                opacity: 1;
                transform: rotate(0deg);
            }
        }
    }
    input{
        flex-basis: 37px;
        padding: 4px 0;
        word-break: break-word;
        color: ${({ theme }) => theme.color};
        border: none;
        flex: 1;
        &:focus{
            outline: none;
        }
        &::placeholder{
            color: ${({ theme }) => theme.color};
        }
    }
    .add{
        position: absolute;
        top: 120%;
        width: 100%;
        border-radius: 4px;
        box-shadow:0 4px 16px rgba(0,0,0,.08);
        padding: 8px 12px;
        cursor: pointer;
        background-color: ${({ theme }) => theme.bg};
        transition: background-color .2s,color .2s;
        z-index: 1;
        color: ${({ theme }) => theme.color};
        font-weight: 500;
        &:hover{
            background-color: ${({ theme }) => theme.bg2};
        }
    }
}
`