import LoaderGif from 'components/LoaderGif';
import React from 'react';
import styled from 'styled-components';

const Authenticating = () => {
    const [showRedirect, setShowRedirect] = React.useState(false)
    React.useEffect(() => {
        setTimeout(() => {
            setShowRedirect(true)
        }, 5000);
    }, [])
    return (
        <Container showRedirect={showRedirect}>
            <LoaderGif/>
            <p>You are being authenticated, please wait...</p>
            <p>If you havent been redirected in 30 seconds, please <a href='https://www.admin.siro.mn'>click here</a></p>
        </Container>
    );
};

export default Authenticating;

const Container = styled.div `
    font-family: sans-serif;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100vh;
    flex-direction:column;
    svg{
        width: 35px;
        margin-bottom:15px;
    }
`