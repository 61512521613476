import React from 'react';
import styled from 'styled-components';

const Icon = ({ icon }) => {
    return <Container>{icon()}</Container>;
};

export default Icon;

const Container = styled.div`
    padding: 4px;
    background: ${({ theme }) => theme.bg2};
    border: 1px solid ${({ theme }) => theme.bg3};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
        font-size: 24px;
    }
`;
