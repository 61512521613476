import { IoIosMore } from "react-icons/io";
import React from 'react';
import styled from 'styled-components';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { Desktop, Tablet, Phone } from 'utils/svg';
import Div from 'elements/Div';

const DeviceSelection = () => {
    const { device, frameEditWidth, setFrameEditWidth, frameContainerWidth } = React.useContext(getCrossDomainContext);
    const devices = [
        { device: 'desktop', icon: Desktop },
        { device: 'tablet', icon: Tablet },
        { device: 'mobile', icon: Phone },
    ];
    const handleSelectDevice = (arg) => {
        if (arg === 'mobile') {
            if (frameContainerWidth > 480) setFrameEditWidth('420px');
        }
        if (arg === 'tablet') {
            if (frameContainerWidth > 768) setFrameEditWidth('768px');
        }
        if (arg === 'desktop') setFrameEditWidth('100%');
    };

    return (
        <Container>
            <Div c="wrap">
                <Div c='device-wrap'>
                    {devices.map((d) => (
                        <Div c="device" onClick={() => handleSelectDevice(d.device)} open={d.device === device} key={d.device}>
                            {<d.icon />}
                        </Div>
                    ))}
                </Div>
                {/* <Div c="device"><IoIosMore /></Div> */}
                <Div c="px">{frameEditWidth}</Div>
            </Div>
        </Container>
    );
};

export default DeviceSelection;

const Container = styled.div`
.wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .device-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.bg2};
        padding: 3px;
        border-radius: 4px;
        .device{
            width: 46px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            cursor: pointer;
            background: transparent;
            transition: all 0.3s linear;
            svg{
                path{
                    fill: ${({ theme }) => theme.color};
                }
            }
            &[open]{
                background: ${({ theme }) => theme.bg};
            }
        }
    }
    .px{
        height: 28px;
        padding: 2px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
    }
}
`;
