import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import useSync from 'utils/useSync';

const addToCart = ({ instance, setInstance }) => {
    const quantitySync = useSync({ value: instance.quantitySync, operation: { type: 'setState', instance, setInstance, syncKey: 'quantitySync' } })
    const productValSync = useSync({ value: instance.sync, operation: { type: 'setState', instance, setInstance } })
    return (
        <Container>
            <Div c="input-wrap">
                <label>Quantity</label>
                <Div c='sync'>
                    {instance.quantitySync ? quantitySync.syncElement() :
                        <input type="number" value={instance.quantity} onChange={(e) => setInstance({ ...instance, quantity: e.target.value })} />
                    }
                    {quantitySync.syncTrigger()}
                </Div>
                {quantitySync.syncSuggestion()}
            </Div>
            <Div c="input-wrap">
                <label>Choose product</label>
                <Div c='sync'>
                    {instance.sync ? productValSync.syncElement() :
                        <input value={instance.productVal} onChange={(e) => setInstance({ ...instance, productVal: e.target.value })} />
                    }
                    {productValSync.syncTrigger()}
                </Div>
                {productValSync.syncSuggestion()}
            </Div>
        </Container>
    );
};

export default addToCart;

const Container = styled.div`
width: 100%;
.input-wrap{
    margin-bottom: 15px;
}
.sync{
    display: flex;
    align-items: center;
    .type-wrap{
        width: 100%;
    }
}
`