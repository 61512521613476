import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import Button from 'elements/Button';
import Div from 'elements/Div';
import React, { useRef } from 'react';
import styled from 'styled-components';

const defaultTimeline = {
    entry: [0, 45],
    center: [],
    exit: [55, 100]
}
const generateThresholds = ({ entry, exit }) => {
    const entryThresholds = Array.from({ length: entry[1] + 1 }, (_, index) => (entry[0] + index) / 100);
    const exitThresholds = Array.from({ length: 101 - exit[0] }, (_, index) => (exit[0] + index) / 100);
    return [...entryThresholds, ...exitThresholds];
};

const ScrollAnimation = () => {
    const itself = React.useRef();
    const { selectedComponent, editComponent } = React.useContext(getBuilderContext);
    // const [timeline, setTimeline] = React.useState(null);
    const [timeline, setTimeline] = React.useState(selectedComponent?.scroll?.timeline ?? null);
    // const [margins, setMargins] = React.useState({ top: -100, bottom: -100, left: 0, right: 0 })
    const { frame } = React.useContext(getCrossDomainContext)
    const ranges = { entry_start: useRef(), entry_end: useRef(), exit_start: useRef(), exit_end: useRef(), };
    const debounceRef = useRef();
    const currentMark = useRef()
    const debounce = (func, delay) => {
        return (...args) => {
            clearTimeout(debounceRef.current);
            debounceRef.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    const handleChange = () => {
        if(timeline) {
            editComponent(selectedComponent.indices, 'scroll', 'timeline', timeline)
        }

        const targetNode = frame.document.querySelector(`[data-track="${selectedComponent.indices}"]`)
        if(!targetNode) return null
        const targetRect = targetNode.getBoundingClientRect()
        const scrollTop = frame.document.documentElement.scrollTop;
        targetNode.setAttribute('data-scrolltop', targetRect.top + scrollTop)
        
    };
    const debouncedFunction = debounce(handleChange, 500);
    React.useEffect(() => { debouncedFunction(); }, [timeline]);
    React.useEffect(() => {

        updatePosition()
        frame.addEventListener('scroll', updatePosition)
        return () => {
            frame.removeEventListener('scroll', updatePosition)
        }
    }, [])
    const enable = (arg, arg2) => {
        const selected = [...itself.current.querySelectorAll('.points.selected')]
        selected.forEach(s => s.classList.remove('selected'))
        const currentPoint = itself.current.querySelector(`.points.${arg}.${arg2}`);
        if (currentPoint) currentPoint.classList.add('selected')
        Object.keys(ranges).forEach(r => ranges[r].current.style.pointerEvents = "none");
        const currentRange = ranges[`${arg}_${arg2}`]
        if (currentRange) currentRange.current.style.pointerEvents = "auto";
    }
    // const intersectionCallback = (entries) => {
    //     entries.forEach(e => {
    //         const top = e.boundingClientRect.y + margins.top + margins.bottom
    //         if (top > 0) {
    //             currentMark.current.style.left = (e.intersectionRatio * 100) / 2 + '%'
    //         } else {
    //             currentMark.current.style.left = 100 - (e.intersectionRatio * 100) / 2 + '%'
    //         }
    //     })
    // }
    // const reinitializeMovement = () => {
    //     if (observerRef.current) observerRef.current.disconnect()
    //     const targetNode = frame.document.querySelector(`[data-track="${selectedComponent.indices}"]`)
    //     // const threshold = generateThresholds({ entry: timeline.entry, exit: timeline.exit })
    //     const threshold = Array.from({ length: 100 }, (_, index) => index / 100)
    //     const rootMargin = `${margins.top + 'px'} ${margins.right + 'px'} ${margins.bottom + 'px'} ${margins.left + 'px'}`
    //     const options = { root: frame.document, threshold, rootMargin }
    //     const observer = new IntersectionObserver(intersectionCallback, options)
    //     observerRef.current = observer
    //     observer.observe(targetNode)
    // }
    const updatePosition = () => {
        if(!currentMark.current) return null
        const targetNode = frame.document.querySelector(`[data-track="${selectedComponent.indices}"]`)
        const topAttribute = Number(targetNode.getAttribute('data-scrolltop'))
        const nodeTop = topAttribute - frame.window.document.documentElement.scrollTop
        const percentage = (nodeTop - frame.window.innerHeight) / frame.window.innerHeight * -100
        if (percentage <= 0) currentMark.current.style.left = 0 + '%'
        else if (percentage > 0 && percentage <= 100) currentMark.current.style.left = percentage + '%'
        else currentMark.current.style.left = 100 + '%'
    }
    return (
        <Container ref={itself} timeline={timeline}>
            <Div c="info-container">
                <p className='caption'>Scroll Effects <span>Premium</span></p>
                <p className='description'>Create animations that interact as you scroll up and down the page. Multiple effects can be enabled on the same block combining into a more complex animation. Enable with a single click or fine tune the effects using the custom timeline.</p>
            </Div>

            {/* <Div c="margins-container">
                <input value={margins.top} onChange={(e) => setMargins({ ...margins, top: e.target.value })} placeholder='top' />
                <input value={margins.bottom} onChange={(e) => setMargins({ ...margins, bottom: e.target.value })} placeholder='bottom' />
                <input value={margins.left} onChange={(e) => setMargins({ ...margins, left: e.target.value })} placeholder='left' />
                <input value={margins.right} onChange={(e) => setMargins({ ...margins, right: e.target.value })} placeholder='right' />
            </Div> */}
            {timeline ?
                <Div c="slide-container">
                    <Div c="marks-wrap">
                        {Array.from({ length: 9 }, (_, index) => index + 1).map(m => <Div key={m} c="marks"></Div>)}
                    </Div>
                    <Div c="lines entry" min={timeline.entry[0]} max={timeline.entry[1]}></Div>
                    <Div c="lines exit" min={timeline.exit[0]} max={timeline.exit[1]}></Div>
                    <input ref={ranges.entry_start} type="range" value={timeline.entry[0]} onChange={e => {
                        if (0 <= Number(e.target.value) && timeline.entry[1] > Number(e.target.value)) {
                            setTimeline({ ...timeline, entry: [Number(e.target.value), timeline.entry[1]] })
                        }
                    }} />
                    <input ref={ranges.entry_end} type="range" value={timeline.entry[1]} onChange={e => {
                        if (Number(e.target.value) > timeline.entry[0] && timeline.exit[0] > Number(e.target.value)) {
                            setTimeline({ ...timeline, entry: [timeline.entry[0], Number(e.target.value)] })
                        }
                    }} />
                    <input ref={ranges.exit_start} type="range" value={timeline.exit[0]} onChange={e => {
                        if (Number(e.target.value) > timeline.entry[1] && timeline.exit[1] > Number(e.target.value)) {
                            setTimeline({ ...timeline, exit: [Number(e.target.value), timeline.exit[1]] })
                        }
                    }} />
                    <input ref={ranges.exit_end} type="range" value={timeline.exit[1]} onChange={e => {
                        if (timeline.exit[0] < Number(e.target.value) && Number(e.target.value) <= 100) {
                            setTimeline({ ...timeline, exit: [timeline.exit[0], Number(e.target.value)] })
                        }
                    }} />
                    <Div onClick={() => enable('entry', 'start')} c="entry start points"><span>{timeline.entry[0]}%</span></Div>
                    <Div onClick={() => enable('entry', 'end')} c="entry end points"><span>{timeline.entry[1]}%</span></Div>
                    <Div onClick={() => enable('exit', 'start')} c="exit start points"><span>{timeline.exit[0]}%</span></Div>
                    <Div onClick={() => enable('exit', 'end')} c="exit end points"><span>{timeline.exit[1]}%</span></Div>
                    <Div forwardRef={currentMark} c="currentMark"></Div>
                </Div>
                :
                <Div c="empty-wrapper">
                    <Button onClick={()=>setTimeline(defaultTimeline)}>Enable scroll animation</Button>
                </Div>
            }

        </Container>
    );
};

export default ScrollAnimation;

const Container = styled.div`
    padding:15px;
    .info-container {
        margin-bottom:15px;
        & > .caption {
            color:${({theme}) => theme.color};
            font-size:${({ theme }) => theme.fs3};
            margin-bottom:10px;
            display:flex;
            align-items:center;
            gap:10px;
            span {
                font-size:${({ theme }) => theme.fs};
                padding:4px 10px;
                border-radius:4px;
                background:${({ theme }) => theme.bg2};
                color:${({ theme }) => theme.mainColor};
                font-weight:500;
            }
        }
        & > .description {
            color:${({ theme }) => theme.color2};
            line-height:160%;
        }
    }
    .empty-wrapper {
        button{
            background:${({theme})=>theme.mainColor};
            padding:8px 16px;
            color:white;
        }
    }
    .margins-container{
        margin-bottom:15px;
        display:flex;
        flex-wrap:wrap;
        gap:10px;
        input{
            width:calc(50% - 5px);
            border:none;
            border-bottom:1px solid ${({ theme }) => theme.bg3};
            padding:6px 10px;
            outline:none;
        }
    }
    .slide-container{
        position:relative;
        margin:45px 6px 10px;
        & > .currentMark{
            position:absolute;
            left:0%;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid ${({theme})=>theme.color};
            margin-top:20px;
            margin-left:-6px;
            transition:0.05s ease;
        }
        input[type="range"] {
            -webkit-appearance:none;
            outline:none;
            transition:opacity 0.2s;
            background:${({ theme }) => theme.bg2};
            height:100%;
            position:absolute;
            width:calc(100% + 15px);
            margin-left:-7.5px;
            left:0px;
            right:0px;
            opacity:0;
            pointer-events:none;
            &::-webkit-slider-thumb {
                webkit-appearance:none;
                cursor:pointer;
            }
            &::-moz-range-thumb {
                cursor:pointer;
            }
        }
        .marks-wrap{
            display:flex;
            align-items:center;
            justify-content:space-between;
            position:absolute;
            left:0px;
            right:0px;
            height:1px;
            background:${({ theme }) => theme.color2};
            .marks{
                width:1px;
                height:15px;
                background:${({ theme }) => theme.color2};
                opacity:0.4;
                &:first-child{
                    height:25px;
                }
                &:nth-child(5){
                    height:25px;
                }
                &:last-child{
                    height:25px;
                }
            }
        }
        & > .points{
            position:absolute;
            cursor:pointer;
            &.selected{
                outline:2px solid ${({ theme }) => theme.color2};
                pointer-events:none;
            }
            & > span {
                top:-20px;
                position:absolute;
                color:${({ theme }) => theme.color2};
                opacity:0.6;
                width:30px;
                text-align:center;
                margin-left:-7px;
                user-select:none;
            }
            &.entry.start {
                left:${({ timeline }) => timeline && timeline.entry[0]}%;
                width:7px;
                height:20px;
                margin-top:-9px;
                margin-left:-2.5px;
                background:#34a54e;
                border-radius:3px;
            }
            &.entry.end {
                left:${({ timeline }) => timeline && timeline.entry[1]}%;
                width:15px;
                height:15px;
                margin-top:-7.5px;
                margin-left:-7.5px;
                background:#ece70f;
                border-radius:100%;
            }
            &.exit.start {
                left:${({ timeline }) => timeline && timeline.exit[0]}%;
                width:15px;
                height:15px;
                margin-top:-7.5px;
                margin-left:-7.5px;
                background:#ece70f;
                border-radius:100%;
            }
            &.exit.end {
                left:${({ timeline }) => timeline && timeline.exit[1]}%;
                width:7px;
                height:20px;
                margin-top:-9px;
                margin-left:-4px;
                background:#d05a3c;
                border-radius:3px;
            }
        }
        & > .lines {
            position:absolute;
            height:4px;
            margin-top:-1.5px;
            ${({ timeline }) => timeline && (`
                &.entry {
                    left:${timeline.entry[0]}%;
                    width:${timeline.entry[1] - timeline.entry[0]}%;
                    background-image: linear-gradient(to right, #34a54e, #ece70f);
                }
                &.exit {
                    left:${timeline.exit[0]}%;
                    width:${timeline.exit[1] - timeline.exit[0]}%;
                    background-image: linear-gradient(to right, #ece70f, #d05a3c);
                }
                `)}};
        }
    }
`