import propTypes from 'prop-types';
import React from 'react';

const Div = ({ c, children, forwardRef, ...props }) => {
    return (
        <div ref={forwardRef} className={c} {...props}>
            {children}
        </div>
    );
};

Div.propTypes = {
    c: propTypes.string,
    children: propTypes.node,
};

export default Div;
