import { createGlobalStyle } from 'styled-components';

export const getThemeSettings = (darkMode) => {
    const config = {
        color: darkMode ? 'white' : '#1F1F1F',
        color2: darkMode ? '#bebebe' : '#666666',
        color3: darkMode ? '#0e0e0e' : '#0e0e0e',
        bg: darkMode ? '#111111' : 'white',
        bg2: darkMode ? '#2A2A2A' : '#f6f6f6',
        bg3: darkMode ? '#6D6D6D' : '#E9E9E9',
        popupBg: darkMode ? '#2A2A2A' : 'white',
        bg4: darkMode ? 'rgba(17, 17, 17, 0.65)' : 'rgba(17, 17, 17, 0.65)',
        bg5: darkMode ? '#2A2A2A' : '#1f1f1f',
        mainColor: darkMode ? '#116DFF' : '#116DFF',
        mainColor2: darkMode ? '#f5458c' : '#f5458c',
        darkMainColor: darkMode ? '#0054da' : '#0054da',
        lightMainColor: darkMode ? '#2A2A2A' : '#CFE2FF',
        lightMainColor2: darkMode ? 'transparent' : '#E8F1FF',
        sectionBorderColor: darkMode ? 'rgba(255,255,255,0.1)' : '#e6ebf2',
    }
    return {
        ...config,
        bodyTopHeight: 40,
        btnNormalHeight: 30,
        fs: '12px',
        fs2: '14px',
        fs3: '16px',
        fs4: '18px',
        fs5: '22px',
    }
}

export const getToastSettings = () => {
    const settings = {
        position: 'top-right',
        toastOptions: {
            success: {
                iconTheme: {
                    primary: '#0A9D5B'
                }
            }
        }
    }
    return settings
}

export const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    html {
        font-size: 12px;
        font-family: 'Inter', sans-serif;
        // overflow-y:hidden;
    }
    body {
        overflow-y:hidden;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    figure,
    blockquote,
    dl,
    dd {
    padding: 0;
    margin: 0;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    button {
        background-color: transparent;
        font-family: inherit;
        cursor: pointer;
        color: inherit;
        border:none;
        position:relative;
        overflow:hidden;
        border-radius:8px;
        & > * {
            pointer-events:none;
        }
    }
    img{
        max-width:100%;
        display:block;
    }
    input,
    button,
    textarea,
    select {
        font: inherit;
    }
    span.ripple{
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        animation: ripple 600ms linear;
        background-color: rgba(255, 255, 255, 0.7);
    }
    [data-scrollable]{
        &::-webkit-scrollbar {
            width: 2px;
            height:2px;
            background:transparent;
        }
           
        &::-webkit-scrollbar-track {
        }
           
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0,0,0,0.6);
            outline: 1px solid slategrey;
        }
    }
    @keyframes ripple {
        to {
          transform: scale(4);
          opacity: 0;
        }
    }
`;
