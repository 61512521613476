import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import useSync from 'utils/useSync';

const checkPayment = ({ instance, setInstance }) => {
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: instance.sync, operation: { type: 'setState', instance, setInstance } })
    return (
        <>
            <Div c="input-wrap" style={{ position: 'relative' }}>
                <Wrapper>
                    <span>Order number</span>
                </Wrapper>
                {instance.sync ? syncElement() : (<>
                    <input value={instance.orderNumber} onChange={(e) => setInstance({ ...instance, orderNumber: e.target.value })} />
                    {syncTrigger()}
                </>)}
            </Div>
            {syncSuggestion()}
        </>
    );
};

export default checkPayment;

const Wrapper = styled.label`
    width:100%;
    display:flex !important;
    align-items:center;
    justify-content:space-between;
    & > span {
        display:inline-block;
    }
    & > button {
        cursor:pointer;
        transition:0.3s ease;
        &:hover{
            color: ${({ theme }) => theme.mainColor};
        }
    }
`
