import Div from "elements/Div";
import React from "react";
import { getReferenceContext } from 'contexts/reference';
import styled from "styled-components";

const Products = () => {
    const { setDynamicPagesSection } = React.useContext(getReferenceContext)
    return (
        <Container>
            <Div c="title-wrap" onClick={() => setDynamicPagesSection({ name: 'product', data: null })}>
                <Div c="title">Product</Div>
            </Div>
        </Container>
    )
}

export default Products

const Container = styled.div`
& > .title-wrap{
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .title{
        font-weight: 600;
        line-height: 24px;
    }
}
`