import RecursiveIterator from "recursive-iterator";

var toString = Object.prototype.toString;

/**
 * @param {*} any
 * @returns {Boolean}
 */
function isObject(any) {
    return any !== null && typeof any === 'object';
}

/**
 * @param {*} any
 * @returns {String}
 */
function getType(any) {
    return toString.call(any).slice(8, -1);
}

/**
 * @param {*} any
 * @returns {*}
 */
function shallowCopy(any) {
    var type = getType(any);
    switch (type) {
        case 'Object':
            return {};
        case 'Array':
            return [];
        case 'Date':
            return new Date(any);
        case 'RegExp':
            return new RegExp(any);
        case 'Number':
        case 'String':
        case 'Boolean':
        case 'Undefined':
        case 'Null':
            return any;
        default:
            return String(any);
    }
}

/**
 * @param {*} any
 * @param {Boolean} [deep]
 * @returns {*}
 */
function recursiveCopy(any, deep = false) {
    if (!deep || !isObject(any)) {
        return shallowCopy(any);
    }

    var map = new Map();
    var rootNode = shallowCopy(any);
    map.set(any, rootNode);

    for(var {parent, node, key} of new RecursiveIterator(any, 1, true)) {
        var parentNode = map.get(parent);
        var cloneNode = shallowCopy(node);
        parentNode[key] = cloneNode;
        map.set(node, cloneNode);
    }

    map.clear();

    return rootNode;
}

// ---------------------------------
// USAGE
// ---------------------------------

var some = {
    foo: {
        bar: 1
    }
};

const person1 = {
    "firstName": "John",
    "lastName": "Doe",
    "age": 35 
};

const person2 = {
    "firstName": "John",
    "lastName": "Doe",
    "age": 35,
};
 
const recursiveEqual = (object1, object2) => {

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject2(value1) && isObject2(value2);

    if ((isObjects && !recursiveEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

const isObject2 = (object) => {
  return object != null && typeof object === "object";
};

export {
    recursiveCopy,
    recursiveEqual
}