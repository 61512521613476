import React from 'react';
import styled from 'styled-components';
import Div from 'elements/Div';
import PropTypes from 'prop-types';
import { getBuilderContext } from 'contexts/builder';
import AttributeInstance from './Attributes/AttributeInstance';
import Button from 'elements/Button';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Help } from 'utils/svg';

const AttributeGroup = ({ name: groupName, attributes, detailedAttributes = [] }) => {
    const { selectedComponent } = React.useContext(getBuilderContext);
    const [showDetailed, setShowDetailed] = React.useState(false);
    return (
        <Container>
            {groupName && <Div c="groupName">
                <span>{groupName}</span>
                <Help />
            </Div>}
            <Div c="attributeWrap">
                {attributes.map((attribute) => (
                    <AttributeInstance key={selectedComponent.indices + attribute.name} attribute={attribute} />
                ))}
                {showDetailed
                    ? detailedAttributes.map((attribute) => (
                        <AttributeInstance key={selectedComponent.indices + attribute.name} attribute={attribute} />
                    ))
                    : ''}
                {detailedAttributes.length ? (
                    showDetailed ? (
                        <Button onClick={() => setShowDetailed(!showDetailed)}>
                            See less <BsChevronUp />
                        </Button>
                    ) : (
                        <Button onClick={() => setShowDetailed(!showDetailed)}>
                            Show more <BsChevronDown />
                        </Button>
                    )
                ) : (
                    ''
                )}
            </Div>
        </Container>
    );
};

export default AttributeGroup;

const Container = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    & > .groupName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6px;
        cursor: pointer;
        & > svg {
            pointer-events: none;
            path{
                fill: ${({theme}) => theme.color};
            }
        }
        & > span {
            pointer-events: none;
            font-weight: 500;
            line-height: 22px;
            font-size: 12px;
            color: ${({theme}) => theme.color};
        }
    }
    & > .attributeWrap {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        & > .attributeRow {
            display: flex;
            gap: 6px;
            align-items: center;
            flex-direction: column;
            width: 100%;
            & > .attributeName {
                color: ${({ theme }) => theme.color2};
                width: 100%;
                font-weight: 500;
                font-size: 9px;
                line-height: 16px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }
            & > [data-attribute] {
                flex: 1;
                width: 100%;
            }
        }
        & > button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            margin-top: 10px;
            font-size: 10px;
            color: ${({ theme }) => theme.color2};
        }
    }
`;

AttributeGroup.propTypes = {
    name: PropTypes.string,
    attributes: PropTypes.array,
};
