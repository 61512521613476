import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { FontAlignBetween, FontAlignCenter, FontAlignEnd, FontAlignLeft } from 'utils/svg';
const cases = [
    { val: 'left', icon: FontAlignLeft },
    { val: 'center', icon: FontAlignCenter },
    { val: 'right', icon: FontAlignEnd },
    { val: 'justify', icon: FontAlignBetween },
];

const Align = ({ value: initialValue, onChange: forwardChange }) => {
    const [value, setValue] = React.useState('');
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <Container>
            {cases.map((c) => (
                <Div onClick={()=>forwardChange(c.val)} open={value === c.val} key={c.val}>{<c.icon />}</Div>
            ))}
        </Container>
    );
};

export default Align;

const Container = styled.div`
    display: flex;
    gap: 6px;
    padding: 4px;
    background: ${({theme}) => theme.bg2};
    border-radius: 4px;
    width: 100%;
    & > div {
        width: 25%;
        height: 28px;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius:4px;
        cursor:pointer;
        & > svg {
            font-size: 16px;
            color: ${({ theme }) => theme.color2};
            pointer-events:none;
            path{
                fill: ${({theme}) => theme.color2};
            }
        }
        &[open], &:hover {
            background:${({theme})=>theme.bg3};
            & > svg {
                & > path{
                    stroke: ${({theme}) => theme.color2};
                }
            }
        }
    }
`;
