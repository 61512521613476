import React from "react";
import request from 'utils/request';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import Div from "elements/Div";
import LoadingFallback from 'router-entries/LoadingFallback';
import { Heart, Search, Trash } from "utils/svg";
import { recursiveCopy } from "utils/recursive";
import { getBuilderContext } from "contexts/builder";
import Button from "elements/Button";

const MySaved = ({ closePopup }) => {
    const { id: webId } = useParams()
    const [loading, setLoading] = React.useState(false)
    const [localComponents, setLocalComponents] = React.useState([])
    const { selectedComponent, addComponent } = React.useContext(getBuilderContext)
    const [searchTerm, setSearchTerm] = React.useState('')
    const [size, setSize] = React.useState(10)

    React.useEffect(() => {
        fetchLocalComponents()
    }, [])

    const handleAddComponent = (component) => {
        const copiedElement = recursiveCopy(component.data, true)
        copiedElement.saved = {
            type: 'local-component',
            id: component.id,
            imageUrl: component.imageUrl
        }
        copiedElement.name = component.name
        addComponent(copiedElement, selectedComponent.indices, 'a')
        closePopup()
    }

    const fetchLocalComponents = async () => {
        try {
            setLoading(true)
            const result = await request({ url: window.urls.web + `/local_template?size=${size}`, headers: { webId } })
            setLocalComponents(result.data)
        } catch (e) {
            console.log('err fetching component', e)
        } finally {
            setLoading(false)
        }
    }

    const deleteTemplate = async (id) => {
        try {
            if (!window.confirm('Are you sure to delete it?')) return null
            setLoading(true)
            await request({ url: window.urls.web + `/local_template/${id}`, method: 'delete', headers: { webId } })
        } catch (e) {
            console.log('err deleting component', e)
        } finally {
            setLoading(false)
            fetchLocalComponents()
        }
    }

    const filteredTemplates = localComponents.filter(template => template.name.toLowerCase().includes(searchTerm.toLowerCase()))

    return (
        <Container>
            {loading ?
                <Div c='loading'><LoadingFallback /></Div>
                :
                localComponents.length > 0 ?
                    <>
                        <Div c="search-wrap">
                            <Div c="search-bar">
                                <Search />
                                <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </Div>
                        </Div>
                        <Div c='content-wrap'>
                            {filteredTemplates.map((type, i) => (
                                <Div c="component" key={i}>
                                    <Div c="section-img" key={i} onClick={() => handleAddComponent(type)}>
                                        <img src={type.imageUrl ? type.imageUrl : "https://sanantoniosports.org/wp-content/uploads/2022/07/placeholder-image.jpeg"} alt={type.name} />
                                    </Div>
                                    <Div c="detail"><span>{type.name}</span><Trash onClick={() => deleteTemplate(type.id)} /></Div>
                                </Div>
                            ))}
                        </Div>
                        {filteredTemplates > 0 && size > 9 && <Button onClick={() => setSize(size + 10)} c="loadmore">
                            Load more
                        </Button>}
                    </> : <Div c="empty">
                        <Heart />
                        <h5>Nothing saved yet</h5>
                        <span>All the sections you`ve saved appear here. Use them across your site.</span>
                    </Div>
            }
        </Container>
    )
}

export default MySaved;

const Container = styled.div`
height: 400px;
.empty{
    padding: 100px 25px 25px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    h5{
        font-size: 18px;
    }
    span{
        font-size: 14px;
        line-height: 1.3;
    }
}
.search-wrap{
    padding: 25px;
    position: fixed;
    background:${({ theme }) => theme.bg};
    z-index: 1;
    width: 400px;
    border-radius:8px 8px 0 0;
    .search-bar{
        padding:10px;
        display:flex;
        align-items:center;
        gap:10px;
        background:${({ theme }) => theme.bg2};
        border-radius:4px;
        svg{
            width: 22px;
            height: 22px;
        }
        input{
            width: 100%;
            border: none;
            background: transparent;
            color: ${({ theme }) => theme.color};
            &:focus{
                outline: none;
            }
        }
    }
}
.loadmore{
    width: 100%;
    display: flex;
    padding: 30px 0;
    justify-content: center;
}
.content-wrap{
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding: 100px 25px 25px 25px;
    .component{
        width: calc((100% - 15px) / 2);
        .section-img{
            cursor: pointer;
            width: 100%;
            height: 140px;
            background-color: transparent;
            transition: background-color 0.5s ease;
            img{
                margin-bottom: 5px;
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
            &:hover{
                background-color: ${({ theme }) => theme.bg2};
            }
        }
        .detail{
            display: flex;
            align-items: center;
            justify-content: space-between;
            span{
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 1.4;
                font-size: ${({ theme }) => theme.fs2};
                color: ${({ theme }) => theme.color2};
            }
            svg{
                width: 14px;
                height: 14px;
                cursor: pointer;
            }
        }
    }
}
`
