import Div from 'elements/Div';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
import TypeIcon from './TypeIcon';
import * as InsertTypes from '../InsertTypes'
import { getReferenceContext } from 'contexts/reference';

const InsertDialog = ({componentKey, name, icon, closeDialog, id: templateId, ...props}) => {
    const [dimension, setDimension] = React.useState(null);
    const {InsertDialog: itself} = React.useContext(getReferenceContext)
    const InsertTypeJsx = InsertTypes[componentKey]
    React.useEffect(() => {
        defineDimesion()
        itself.current.addEventListener('close', handleClose)
    }, []);
    const defineDimesion = () => {
        const frameWrapper = document.querySelector('#frame-wrapper');
        const { top, left, height } = frameWrapper.getBoundingClientRect();
        setDimension({ top: top + window.scrollY, left: left + window.scrollX, height })
    };
    const handleClose = () => {
        if(!itself.current) return closeDialog()
        itself.current.style.animation = "swing-out-left-bck 0.3s cubic-bezier(0.600, -0.280, 0.735, 0.045) both";
        setTimeout(() => {
            closeDialog()
        }, 300);
    }
    const dragLeave = (e) => {
        e.stopPropagation()
        if(e.target === itself.current) {
            handleClose()
        }
    }
    return (
        <Container ref={itself} {...dimension} onDragLeave={dragLeave}>
            <Div c="top">
                <Div c="name">{icon && <TypeIcon icon={icon}/>}{name}</Div>
                <AiOutlineClose onClick={handleClose}/>
            </Div>
            <InsertTypeJsx templateId={templateId}/>
        </Container>
    );
};

export default InsertDialog;

const Container = styled.div`
    position:fixed;
    padding:15px;
    padding-top:2px;
    top:${({top})=>top}px;
    left:${({left})=>left - 1}px;
    height:${({height}) => height}px;
    background:${({theme})=>theme.bg};
    border:1px solid ${({theme})=>theme.sectionBorderColor};
    border-left:2px solid ${({theme})=>theme.sectionBorderColor};
    animation: swing-in-left-fwd 0.3s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    max-width:280px;
    min-width:280px;
    display:flex;
    flex-direction:column;
    & > .top {
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:15px 0px;
        border-bottom: 1px solid ${({theme})=>theme.sectionBorderColor};
        margin-bottom:15px;
        & > .name {
            display:flex;
            align-items:center;
            gap:10px;
        }
        & > svg {
            font-size:18px;
            cursor:pointer;
        }
    }
    @-webkit-keyframes fade-in{0%{opacity:0}100%{opacity:1}}@keyframes fade-in{0%{opacity:0}100%{opacity:1}}
    @-webkit-keyframes fade-out{0%{opacity:1}100%{opacity:0}}@keyframes fade-out{0%{opacity:1}100%{opacity:0}}

    @-webkit-keyframes slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}}@keyframes slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}}
    @-webkit-keyframes slide-out-left{0%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}100%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}}@keyframes slide-out-left{0%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}100%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}}

    @-webkit-keyframes swing-in-left-fwd{0%{-webkit-transform:rotateY(100deg);transform:rotateY(100deg);-webkit-transform-origin:left;transform-origin:left;opacity:0}100%{-webkit-transform:rotateY(0);transform:rotateY(0);-webkit-transform-origin:left;transform-origin:left;opacity:1}}@keyframes swing-in-left-fwd{0%{-webkit-transform:rotateY(100deg);transform:rotateY(100deg);-webkit-transform-origin:left;transform-origin:left;opacity:0}100%{-webkit-transform:rotateY(0);transform:rotateY(0);-webkit-transform-origin:left;transform-origin:left;opacity:1}}
    @-webkit-keyframes swing-out-left-bck{0%{-webkit-transform:rotateY(0);transform:rotateY(0);-webkit-transform-origin:left;transform-origin:left;opacity:1}100%{-webkit-transform:rotateY(100deg);transform:rotateY(100deg);-webkit-transform-origin:left;transform-origin:left;opacity:0}}@keyframes swing-out-left-bck{0%{-webkit-transform:rotateY(0);transform:rotateY(0);-webkit-transform-origin:left;transform-origin:left;opacity:1}100%{-webkit-transform:rotateY(100deg);transform:rotateY(100deg);-webkit-transform-origin:left;transform-origin:left;opacity:0}}
`;
