import React from 'react'
import { useParams } from 'react-router-dom';
import { getBuilderContext } from 'contexts/builder';
import { recursiveCopy } from 'utils/recursive';

const useProductCollection = (object, selectedType) => {
    const { id } = useParams()
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext)

    React.useEffect(() => {
        if (selectedType === 'Product Collection') {
            mainFunction()
        }
    }, [selectedType])

    const mainFunction = () => {
        const sourceValue = id;
        const copiedObject = recursiveCopy(object, true);
        copiedObject.sourceDetail.value = sourceValue;
        editComponent(selectedComponent.indices, 'options', 'dataSource', copiedObject);
    }

    return null;
}

export default useProductCollection