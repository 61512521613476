export const reduceStructure = (components, type) => {
    const storeReducedComponents = [];
    Object.keys(components).forEach((name) => {
        const injectingProps = {}
        const propData = { ...components[name].propData };
        Object.keys(propData).forEach((propKey) => {
            const prop = propData[propKey].defaultValue;
            injectingProps[propKey] = prop
            // Ignore unneccessary props here
            // delete injectingProps._icon
        });
        injectingProps.component = name
        injectingProps.type = type
        storeReducedComponents.push(injectingProps)
    });
    return storeReducedComponents
};
