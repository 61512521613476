import React from 'react';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { dispatchChange, getStyleValue } from 'utils/styleHelpers';
import styled from 'styled-components';
import Align from './Align';

const FontAlign = () => {
    const [value, setValue] = React.useState('')
    const { selectedComponent, editComponent } =
        React.useContext(getBuilderContext);
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext);
    const doc = frame.document;

    React.useEffect(() => {
        setInitialValue();
    }, [selectedComponent]);

    React.useEffect(() => {
        setInitialValue();
    }, [frameUpdate]);

    const setInitialValue = () => {
        const styleValue = getStyleValue(selectedComponent, 'textAlign', doc);
        setValue(styleValue);
    }

    const forwardChange = (changingValue) => {
        dispatchChange(editComponent, selectedComponent, 'textAlign', changingValue)
    }

    return (
        <Container>
            <Align value={value} onChange={forwardChange}/>
        </Container>
    );
};

export default FontAlign;

const Container = styled.div `
width: 100%;
`