import Tooltip from 'components/Tooltip';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { Blur, Horizontal, Opacity, Rotate, Scale, Vertical } from 'utils/svg';
import * as Effects from './Effects'

const ScrollEffects = () => {
    const [selected, setSelected] = React.useState('Vertical')
    const EffectComponent = Effects[selected]
    const tabComponents = {
        Vertical: Vertical,
        Horizontal: Horizontal,
        Opacity: Opacity,
        Scale: Scale,
        Rotate: Rotate,
        Blur: Blur,
    }

    return (
        <Container>
            <Div c='wrapper'>
                <Div c='title'>Effects</Div>
                <Div c='top'>
                    {Object.keys(tabComponents).map((tab) => (
                        <Tooltip text={tab} key={tab}>
                            <div
                                className={`tabs ${selected === tab ? 'active' : ''}`}
                                onClick={() => setSelected(tab)}
                            >
                                {React.createElement(tabComponents[tab])}
                            </div>
                        </Tooltip>
                    ))}
                </Div>
                <EffectComponent />
            </Div>
        </Container>
    );
};

export default ScrollEffects;

const Container = styled.div`
.wrapper{
    width: 100%;
    padding: 15px;
    .title{
        font-size: ${({ theme }) => theme.fs2};
        margin-bottom: 15px;
        font-weight: 500;
        line-height: 20px;
    }
    .top{
        width: 100%;
        display: flex;
        gap: 5px;
        .tabs{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            border-radius: 4px;
            background-color: ${({ theme }) => theme.bg2};
            transition: background-color 0.3s ease;
            cursor: pointer;
            svg{
                width: 18px;
                height: 18px;
            }
            &.active, &:hover{
                background-color: ${({ theme }) => theme.lightMainColor2};
            }
        }
    }
}
`