import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { getThemeSettings, getToastSettings, GlobalStyles } from 'utils/themeSettings';
import { Toaster } from 'react-hot-toast';
import LoaderGif from 'components/LoaderGif';

export const getCustomThemeContext = React.createContext();

export const CustomThemeContextProvider = (props) => {
    const dmLocal = localStorage.getItem('darkmode')
    const [darkMode, setDarkMode] = React.useState(dmLocal === "true" ? true : false);
    const [LOADING, SETLOADING] = React.useState(false)
    const toastSettings = getToastSettings()
    return (
        <getCustomThemeContext.Provider
            value={{ darkMode, setDarkMode, theme: getThemeSettings(darkMode), SETLOADING }}
        >
            <ThemeProvider theme={getThemeSettings(darkMode)}>
                <GlobalStyles />
                {LOADING ? <Loading><LoaderGif/></Loading>: null}
                {props.children}
                <Toaster {...toastSettings} />
            </ThemeProvider>
        </getCustomThemeContext.Provider>
    );
};

CustomThemeContextProvider.propTypes = {
    children: PropTypes.any,
};

const Loading = styled.div `
    position:fixed;
    z-index:1;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:${({theme})=>theme.bg};
    display:flex;
    justify-content:center;
    align-items:center;
    opacity:0.8;
    svg{
        width:40px;
        height:40px;
        object-fit:contain;
    }
`