import React from 'react';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getStyleValue } from 'utils/styleHelpers';
import styled from 'styled-components';
import Coordinate from './Coordinate';

const Spacing = () => {
    const [value, setValue] = React.useState({ x: '', y: '' });
    const targetRef = React.useRef();
    const { selectedComponent, editComponent } = React.useContext(getBuilderContext);
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext);
    const doc = frame.document;

    React.useEffect(() => {
        targetRef.current = doc.querySelector(
            `[data-track="${selectedComponent.indices}"]`
        );
        identifyAlignments();
    }, [selectedComponent]);

    React.useEffect(() => {
        identifyAlignments();
    }, [frameUpdate]);

    const identifyAlignments = () => {

        const display = getStyleValue(selectedComponent, 'display', doc);
        if (display !== "flex") return setValue({ x: '', y: '' })

        const flexDirection = checkDirection()

        if (flexDirection === "y") {
            const yAlignment = getStyleValue(selectedComponent, 'justifyContent', doc);
            const xAlignment = getStyleValue(selectedComponent, 'alignItems', doc);
            setValue({ x: xAlignment, y: yAlignment })
        } else {
            const xAlignment = getStyleValue(selectedComponent, 'justifyContent', doc);
            const yAlignment = getStyleValue(selectedComponent, 'alignItems', doc);
            setValue({ x: xAlignment, y: yAlignment })
        }
    };

    const checkDirection = () => {
        const { flexDirection } = getComputedStyle(targetRef.current);
        if (flexDirection === "row" || flexDirection === "row-reverse") {
            return 'x'
        } else {
            return 'y'
        }
    }

    const forwardChange = (changingValue) => {
        const { x, y } = changingValue
        const flexDirection = checkDirection()

        if (flexDirection === "y") {
            editComponent(selectedComponent.indices, [
                {
                    type: 'style',
                    changes: [
                        { key: 'justifyContent', value: y },
                        { key: 'alignItems', value: x },
                    ],
                },
            ])
        } else {
            editComponent(selectedComponent.indices, [
                {
                    type: 'style',
                    changes: [
                        { key: 'justifyContent', value: x },
                        { key: 'alignItems', value: y },
                    ],
                },
            ]);
        }
    }

    return (
        <Container>
            <Coordinate value={value} forwardChange={forwardChange} direction={'x'} />
            <Coordinate value={value} forwardChange={forwardChange} direction={'y'} />
        </Container>
    );
};

export default Spacing;

Spacing.propTypes = {
    // attributeName: PropTypes.string
}

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    gap:15px;
`