import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { AlignBetween, AlignCenter, FlexEnd, FlexStart, JustifyCenter, JustifyEnd, JustifyStart, SpaceBetween } from 'utils/svg';

const Coordinate = ({ direction, value, forwardChange }) => {
    const cases = [
        { name: 'flex-start', icon: FlexStart },
        { name: 'center', icon: AlignCenter },
        { name: 'flex-end', icon: FlexEnd },
        { name: 'space-between', icon: AlignBetween },
    ]

    const justifyContent = [
        { name: 'flex-start', icon: JustifyStart },
        { name: 'center', icon: JustifyCenter },
        { name: 'flex-end', icon: JustifyEnd },
        { name: 'space-between', icon: SpaceBetween },
    ]

    return (
        <Container direction={direction}>
            <Div c="wrap">
                {direction === 'x' ?
                    justifyContent.map((c, i) =>
                        <Div c="case" key={i}
                            open={value.x === c.name}
                            onClick={() => {
                                if (value.x === c.name) forwardChange({ ...value, x: '' })
                                else forwardChange({ ...value, x: c.name })
                            }}
                        >
                            {<c.icon />}
                        </Div>
                    )
                    :
                    cases.map((c, i) =>
                        <Div c="case" key={i}
                            open={value.y === c.name}
                            onClick={() => {
                                if (value.y === c.name) forwardChange({ ...value, y: '' })
                                else forwardChange({ ...value, y: c.name })
                            }}
                        >
                            {<c.icon />}
                        </Div>
                    )
                }
            </Div>
        </Container >
    );
};

export default Coordinate;

const Container = styled.div`
    & > .wrap {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap:wrap;
        & > .case {
            width: calc((100% - 15px) / 4);
            height: 30px;
            display: flex;
            align-items:center;
            justify-content: center;
            cursor:pointer;
            gap:3px;
            border-radius: 2px;
            svg{
                path{
                    fill: ${({theme}) => theme.color2};
                }
            }
            &:hover{
                background:${({ theme }) => theme.bg2};
            }
            &[open] {
                background:${({ theme }) => theme.bg2};
            }
        }
    }
`;
