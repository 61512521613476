import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';

const Tooltip = ({ text, children }) => {
    const [showTooltip, setShowTooltip] = React.useState(false);

    return (
        <Container>
            <Div c="children" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                {children}
            </Div>
            <Div c={`tooltip ${showTooltip && 'active'}`}>{text}</Div>
        </Container>
    );
};

export default Tooltip

const Container = styled.div`
position: relative;
z-index: 1000;
width: 100%;
.tooltip {
    position: absolute;
    top: 120%;
    right: 50%;
    opacity: 0;
    transform: translateX(50%);
    transition: opacity 0.5s;
    background: ${({theme}) => theme.bg4};
    padding: 8px 4px;
    text-align: center;
    min-width: max-content;
    max-width: 300px;
    border-radius: 4px;
    line-height: 1.4;
    color: ${({theme}) => theme.bg};
    pointer-events: none;
    &.active{
        visibility: visible;
        opacity: 1;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent ${({theme}) => theme.bg4} transparent;
    }
}
`