import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';
import toast from 'react-hot-toast';

const InitKeyDowns = () => {
    const { frame } = React.useContext(getCrossDomainContext);
    const doc = frame.document
    const { theme } = React.useContext(getCustomThemeContext)
    const selector = 'bl-context'
    const { selectedComponent, selectComponent, findLayers, addComponent, getComponent, deleteComponent, neighborLayers } = React.useContext(getBuilderContext)
    const [mousePos, setMousePos] = React.useState(null)

    React.useEffect(() => {
        if (selectedComponent) {
            doc.addEventListener('keydown', handleKeyDown)
            doc.addEventListener('keyup', handleKeyUp)
        } else {
            return () => {
                doc.removeEventListener('keydown', handleKeyDown)
            }
        }
        return () => {
            doc.removeEventListener('keydown', handleKeyDown)
            doc.removeEventListener('keyup', handleKeyUp)
        }
    }, [selectedComponent])

    React.useEffect(() => {
        doc.addEventListener('click', (e) => {
            setMousePos({ x: e.clientX, y: e.clientY })
            doc.querySelector(`#${selector}`)?.remove()
            doc.querySelector(`#${selector}-style`)?.remove()
        })
    }, [])

    const handleKeyDown = async (e) => {
        //Catch esc press
        if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
            // Do something when the "Esc" key is pressed
            if (!selectedComponent) return null
            const indices = selectedComponent.indices
            if (!indices) return null
            const firstIndex = indices.split('-')[0]
            if (!firstIndex) return null
            selectComponent(firstIndex)
        }
        if (e.key === 'Shift' || e.keyCode === 16) {
            if (!selectedComponent) return null
            const indices = selectedComponent.indices
            if (!indices) return null
            const layersMenu = findLayers(selectedComponent, indices)
            // const menus = neighborLayers(selectedComponent)
            drawContext(layersMenu, indices)
        }
        if (e.keyCode === 67 && e.key === 'c' && e.ctrlKey) {
            const type = "text/plain";
            const blob = new Blob([JSON.stringify(selectedComponent)], { type });
            const data = [new ClipboardItem({ [type]: blob })];
            await navigator.clipboard.write(data)
            toast.success('Successfully copied to clipboard')
        }
    }


    const handleKeyUp = (e) => {
        if (e.key !== 'Shift' || e.keyCode !== 16) {
            doc.querySelector(`#${selector}`)?.remove()
            doc.querySelector(`#${selector}-style`)?.remove()
        }
    }

    const drawContext = (menus, indices) => {
        //CLEANUP
        doc.querySelector(`#${selector}`)?.remove()
        doc.querySelector(`#${selector}-style`)?.remove()

        const styleTag = doc.createElement('style')
        styleTag.setAttribute('id', `${selector}-style`)
        styleTag.innerText = `
            #${selector} {
                position:absolute;
                left: ${mousePos?.x + frame.scrollX}px;
                top:  ${mousePos?.y + frame.scrollY}px;
                font-family:'Inter', sans-serif;
                pointer-events:auto;
                background: ${theme.bg};
                font-size: 12px;
                font-weight: 600;
                z-index: 10000;
            }
            #${selector} > div {
                padding: 8px 16px;
                color:${theme.color};
                pointer-events:auto;
                background-color: transparent;
                transition: background-color 300ms linear;
                min-width: 140px;
                display: flex;
                align-items: center;
                gap: 8px;
                flex-direction: row-reverse;
                justify-content: start;
            }
            #${selector} > div.active, #${selector} > div.active:hover {
                background-color: ${theme.lightMainColor2};
                font-weight: 700;
            }
            #${selector} > div:hover {
                background-color:${theme.bg2};
                cursor:pointer;
            }
            #${selector} > div img {
                width: 16px;
                height: 16px;  
            }
            #${selector} > .active{
                padding-left: 26px;
            }
            #${selector} > .child{
                padding-left: 36px;
            }
        `

        const parentDiv = doc.createElement('div')
        parentDiv.setAttribute('id', `${selector}`)

        // let draggedMenuIndex;
        menus.forEach((menu, i) => {
            const div = doc.createElement('div')
            const img = doc.createElement('img')
            img.src = '/img/section-icons/box.svg'

            div.innerText = menu.name
            div.appendChild(img)
            if (indices === menu.indice) {
                div.classList.add('active')
            }
            if (indices.length < menu.indice.length) {
                div.classList.add('child')
            }
            div.addEventListener('click', (e) => {
                selectComponent(menu.indice)
            })

            // div.draggable = true
            // div.addEventListener('dragstart', (e) => {
            //     e.dataTransfer.setData('text/plain', i.toString())
            //     div.classList.add('dragging')
            // })

            // div.addEventListener('dragend', (e) => {
            //     div.classList.remove('dragging')
            //     console.log('done')
            //     if (draggedMenuIndex) {
            //         let copiedElement = getComponent(menu.indice)
            //         addComponent(copiedElement, menus[draggedMenuIndex].indice, 'a')
            //         setTimeout(() => deleteComponent(menu.indice), 10)
            //     }
            // })
            parentDiv.appendChild(div)
        })

        // parentDiv.addEventListener('dragover', (e) => {
        //     e.preventDefault()
        //     const mouseX = e.clientX;
        //     const mouseY = e.clientY;

        //     const overlappingMenu = Array.from(parentDiv.children).find((menuElement, index) => {
        //         const rect = menuElement.getBoundingClientRect()
        //         return mouseX >= rect.left && mouseX <= rect.right && mouseY >= rect.top && mouseY <= rect.bottom
        //     })

        //     if (overlappingMenu) {
        //         draggedMenuIndex = Array.from(parentDiv.children).indexOf(overlappingMenu)
        //         console.log('Overlapped Menu:', overlappingMenu.innerText, 'Index:', draggedMenuIndex)
        //     }
        // })

        doc.body.appendChild(parentDiv)
        doc.head.appendChild(styleTag)
    }
    return null
};

export default InitKeyDowns;