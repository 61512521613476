import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { recursiveCopy } from 'utils/recursive';
import Instance from '../array/instance';
import DataSourceSelection from './DataSourceSelection';
import useBrands from "./useBrand";
import useCategory from "./useCategory";
import useProducts from "./useProducts";
import useProductCollection from "./useProductCollection";
import useCollection from "./useCollection";
import useCart from './useCart';
import useUser from './useUser';
import useBookingSlot from './useBookingSlot';
import useOrder from './useOrder';
import useBookingOrder from './useBookingOrder';

const DataSourcePopup = ({ close, object, forwardKey, forwardChange }) => {
    const handleChange = (key, value, passedIndex, alternativeOption) => {
        const copiedObject = recursiveCopy(object.value, true)
        if (alternativeOption === 'add option sync') {
            if (!copiedObject[key].sync) copiedObject[key].sync = {}
            copiedObject[key].sync = value;
        } else if (alternativeOption === 'remove option sync') {
            delete copiedObject[key].sync
        } else {
            copiedObject[key].value = value
        }
        forwardChange(forwardKey, copiedObject)
    }

    const outerWrap = React.useRef();

    const sourceType = object.value.sourceType.value
    const Collection = useCollection(object.value, sourceType)
    const Product = useProducts(object.value, sourceType)
    const Cart = useCart(object.value, sourceType)
    const Categories = useCategory(object.value, sourceType)
    const Brands = useBrands(object.value, sourceType)
    const ProductCollection = useProductCollection(object.value, sourceType)
    const User = useUser(object.value, sourceType)
    const BookingSlot = useBookingSlot(object.value, sourceType)
    const Order = useOrder(object.value, sourceType)
    const BookingOrder = useBookingOrder(object.value, sourceType)

    React.useEffect(() => {
        if (sourceType === 'Cart') {
            object.value.cartCalculate = { type: 'boolean', value: object.value.cartCalculate?.value || false };

            delete object.value.productBrand;
            delete object.value.productCategory;
            delete object.value.productCollection;
            delete object.value.productSort;
            delete object.value.orderNumber;
            delete object.value.bookingOrderNumber;
            delete object.value.service;
            delete object.value.branch;
            delete object.value.room;
            delete object.value.startDate;
            delete object.value.endDate;
        } else if (sourceType === 'Order') {
            if (!object.value.orderNumber) {
                object.value.orderNumber = { type: 'text', value: object.value.orderNumber?.value || null };
            }
            delete object.value.productBrand;
            delete object.value.productCategory;
            delete object.value.productCollection;
            delete object.value.productSort;
            delete object.value.cartCalculate;
            delete object.value.service;
            delete object.value.branch;
            delete object.value.room;
            delete object.value.startDate;
            delete object.value.endDate;
            delete object.value.bookingOrderNumber;
        }else if (sourceType === 'Booking Order') {
            if (!object.value.bookingOrderNumber) {
                object.value.bookingOrderNumber = { type: 'text', value: object.value.bookingOrderNumber?.value || null };
            }
            delete object.value.productBrand;
            delete object.value.productCategory;
            delete object.value.productCollection;
            delete object.value.productSort;
            delete object.value.cartCalculate;
            delete object.value.service;
            delete object.value.branch;
            delete object.value.room;
            delete object.value.startDate;
            delete object.value.orderNumber;
            delete object.value.endDate;
        } else if (sourceType === 'Product') {
            if (!object.value.productBrand) {
                object.value.productBrand = { type: 'productBrand', value: object.value.productBrand?.value || null };
            }
            if (!object.value.productCategory) {
                object.value.productCategory = { type: 'productCategory', value: object.value.productCategory?.value || null };
            }
            if (!object.value.productCollection) {
                object.value.productCollection = { type: 'productCollection', value: object.value.productCollection?.value || null };
            }
            if (!object.value.productSort) {
                object.value.productSort = { type: 'productSort', value: object.value.productSort?.value || null };
            }

            delete object.value.cartCalculate;
            delete object.value.orderNumber;
            delete object.value.service;
            delete object.value.branch;
            delete object.value.room;
            delete object.value.startDate;
            delete object.value.bookingOrderNumber;
            delete object.value.endDate;
        }
        else if (sourceType === 'Booking Slot') {
            if (!object.value.service) {
                object.value.service = { type: 'bookingService', value: object.value.service?.value || null };
            }
            if (!object.value.branch && object.value.service) {
                object.value.branch = { type: 'bookingBranches', value: object.value.branch?.value || null };
            }
            if (!object.value.room) {
                object.value.room = { type: 'bookingRoom', value: object.value.room?.value || null };
            }
            if (!object.value.startDate) {
                object.value.startDate = { type: 'text', value: object.value.startDate?.value || null };
            }
            if (!object.value.endDate) {
                object.value.endDate = { type: 'text', value: object.value.endDate?.value || null };
            }
            delete object.value.cartCalculate;
            delete object.value.productBrand;
            delete object.value.productCategory;
            delete object.value.productCollection;
            delete object.value.productSort;
            delete object.value.bookingOrderNumber;
            delete object.value.orderNumber;
        } else {
            delete object.value.cartCalculate;
            delete object.value.productBrand;
            delete object.value.productCategory;
            delete object.value.productCollection;
            delete object.value.productSort;
            delete object.value.orderNumber;
            delete object.value.service;
            delete object.value.branch;
            delete object.value.room;
            delete object.value.startDate;
            delete object.value.bookingOrderNumber;
            delete object.value.endDate;
        }
    }, [object.value.sourceType])

    return (
        <Container ref={outerWrap} onClick={(e) => e.target === outerWrap.current && close()}>
            <Div c="main-content">
                <Div c="left-wrapper">
                    <Div c="close" onClick={() => close()}>Close</Div>
                    <DataSourceSelection object={object} />
                </Div>
                <Div c="right-wrapper">
                    {Collection}
                    {Product}
                    {Categories}
                    {Brands}
                    {ProductCollection}
                    {Cart}
                    {User}
                    {BookingSlot}
                    {Order}
                    {BookingOrder}
                    <Div c='option'>
                        <Div c="title">Option</Div>
                        <Instance forwardKey={forwardKey} index={0} object={object.value} forwardChange={handleChange} />
                    </Div>
                </Div>
            </Div>
        </Container>
    )
};

export default DataSourcePopup;

const Container = styled.div`
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
position: fixed;
left: 0;
top: 0;
background: rgba(0, 0, 0, 0.5);
z-index: 10;
animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
& > .main-content{
    width: calc(-66px + 100vw);
    height: calc(-49.5px + 75vw);
    max-height: calc(-66px + 100vh);
    max-width: calc(-88px + 133.333vh);
    background: ${({ theme }) => theme.popupBg};
    display: flex;
    .left-wrapper{
        width: 50%;
        padding: 30px;
        padding-right: 0;
        .close{
            text-transform: uppercase;
            letter-spacing: 0.5px;
            line-height: 22px;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 30px;
        }
    }
    .right-wrapper{
        width: 50%;
        margin: 80px 30px 30px 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        overflow-y: auto;
        .option{
            height: 100%;
            .title{
                font-weight: 600;
                font-size: 22px;
                line-height: 28px;
                padding: 6px 0;
            }
            & > div{
                & > div{
                    & > div{
                        padding: 0;
                        & > div{
                            width: 50%;
                            & > input{
                                width: 100% !important;
                                max-width: 100%;
                            }
                            & > div{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
`