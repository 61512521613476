import React from 'react';
import videoReactCss from './video-react.css'
import MediaIndex from 'siro-media-library';
import { useAuth } from 'utils/useAuth';
import { useParams } from 'react-router-dom';
import Div from 'elements/Div';
import styled from 'styled-components';
import useSync from 'utils/useSync';
import VideoUrl from 'modules/Options/InputTypes/video/VideoUrl';
const Video = ({ object, forwardKey, forwardChange }) => {

    const [showMediaLibrary, setShowMediaLibrary] = React.useState(false)
    const [addFromLink, setAddFromLink] = React.useState(false)
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: object.sync, operation: { type: 'option', forwardKey, forwardChange } })
    const { accessToken } = useAuth()
    const { id: webId } = useParams();
    const onMediaSelect = (media) => {
        const sizes = media.video_files.map(size => ({ width: size.width, height: size.height, link: size.link }))
        const nearestObj = findNearestWidth(sizes, 1920)
        const mediaObject = { url: nearestObj.link, sizes, poster: media.image }
        forwardChange(forwardKey, mediaObject)
        setShowMediaLibrary(false)
    }

    const handleMediaUrl = (media) => {
        const mediaObject = { url: media }
        forwardChange(forwardKey, mediaObject)
        setAddFromLink(false)
    }

    function findNearestWidth(arr, targetWidth) {
        if (!Array.isArray(arr) || arr.length === 0) {
            return null; // Handle empty or invalid array
        }

        let nearestObj = arr[0]; // Initialize with the first object
        let minDiff = Math.abs(nearestObj.width - targetWidth);

        for (let i = 1; i < arr.length; i++) {
            const currentDiff = Math.abs(arr[i].width - targetWidth);
            if (currentDiff < minDiff) {
                nearestObj = arr[i];
                minDiff = currentDiff;
            }
        }

        return nearestObj;
    }
    
    return (
        <Div style={{ position: 'relative', width: '100%', padding: '10px 0' }}>
            {object.sync ? syncElement() : (
                <Container videoReactCss={videoReactCss}>
                    <Div c="image-container">
                        <Div c="image"><img src={object.value.poster ? object.value.poster : '/img/video.jpg'} alt="preview_img" /></Div>
                        <Div c="bottom">
                            <Div c='type'>Video</Div>
                            <Div c='flex'>
                                <Div c='text-wrap'>
                                    <span onClick={() => setShowMediaLibrary(true)}>Replace</span>
                                    <span onClick={() => setAddFromLink(true)}>Add from link</span>
                                </Div>
                                {syncTrigger()}
                            </Div>
                        </Div>
                    </Div>
                    <MediaIndex type='video' setImage={onMediaSelect} onCancel={() => setShowMediaLibrary(!showMediaLibrary)} open={showMediaLibrary} accessToken={accessToken} page={false} webId={webId} />
                    {addFromLink && <VideoUrl close={() => setAddFromLink(false)} object={object} handleMediaUrl={handleMediaUrl} />}
                </Container>
            )}
            {syncSuggestion()}
        </Div>

    );
};

export default Video;

const Container = styled.div`
position: relative;
padding: 0 20px;
.image-container{
    border-radius: 4px;
    width: 100%;
    background: ${({ theme }) => theme.bg3};
    .image{
        width: 100%;
        height: 120px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .bottom{
        background: ${({ theme }) => theme.bg2};
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        border-radius: 0 0 4px 4px;
        .type{
            color: ${({ theme }) => theme.color2};
        }
        .flex{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .text-wrap{
                display: flex;
                gap: 10px;
                span{
                    font-weight: 600;
                    text-transform: uppercase;
                    cursor: pointer;
                    color: ${({ theme }) => theme.color3};
                    line-height: 24px;
                }
            }
        }
    }
   }
`;