import React from 'react'
import Footer from './Footer'
import Header from './Header'
import PagesList from './Pages'
import InappList from './Inapp'
import { getReferenceContext } from 'contexts/reference';
import styled from 'styled-components'
import Collections from './Collections'
import Products from './Products'
import DynamicDataList from './DynamicDataList'

const Pages = () => {
    const { dynamicPagesSection, setDynamicPagesSection } = React.useContext(getReferenceContext)
    if (dynamicPagesSection == null)
        return (
            <Container>
                <PagesList />
                <Header />
                <Footer />
                <Collections />
                <Products />
                <InappList />
            </Container>
        )
    else
        return (
            <Container>
                <DynamicDataList dynamicPagesSection={dynamicPagesSection} setDynamicPagesSection={setDynamicPagesSection} />
            </Container>
        )
}

export default Pages

const Container = styled.div`
height: calc(100vh - 100px);
overflow-y: auto;
`