import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getReferenceContext } from 'contexts/reference';
import React from 'react';

const InitDragDrop = () => {
    const {PointingAt, InsertingElement, InsertDialog} = React.useContext(getReferenceContext)
    const { addComponent, moveComponent, getComponent } = React.useContext(getBuilderContext)
    const { frame } = React.useContext(getCrossDomainContext);
    const doc = frame.document;
    const dragImage = React.useRef()
    React.useEffect(() => {

        const img = doc.createElement('img')
        img.src = "/img/dragImage.jpg"
        img.style.width = "30px";
        img.style.height = "30px";
        dragImage.current = img

        doc.addEventListener('dragstart', e => {

            e.stopPropagation()
            // return null
            const kind = e.target.getAttribute('kind')
            if (kind === "component") {
                // ENCAPSULATE OUTER MOST ELEMENT OF DRAG EVENT TO PREVENT MOVING INSIDE ITS CHILDREN
                e.target.classList.add('dragging-source')
                e.target.classList.add('dragging')
                const descendants = [...e.target.querySelectorAll("*")]
                descendants.map(descendant => descendant.classList.add('dragging'))

                //EXPERIMENTAL OUTLINE
                doc.querySelector('#root').setAttribute('data-highlight', '')

                const { track } = e.target.dataset
                e.dataTransfer.setData('text/track', track)
                e.dataTransfer.effectAllowed = "move"

                const parentElement = e.target.parentElement;
                if (parentElement.getAttribute('kind') !== 'grid') {
                    e.dataTransfer.setDragImage(dragImage.current, 0, 0)
                }

                const component = getComponent(track)
                doc.dispatchEvent(
                    new CustomEvent('drawInserting', { detail: { component } })
                );
            }

        })

        doc.addEventListener('dragenter', (e) => {
            e.stopPropagation();
            // const allowedOperation = e.dataTransfer.effectAllowed;
            InsertDialog.current?.dispatchEvent(new CustomEvent('close'))
            const { track: targetTrack } = e.target.dataset;
            doc.querySelector('#root').setAttribute('data-inserting', targetTrack)

        });

        doc.addEventListener('drop', (e) => {
            const kind = e.target?.getAttribute('kind')
            if(kind === 'component' || kind === 'block') {
                const allowedOperation = e.dataTransfer.effectAllowed
                const { track: targetTrack } = e.target.dataset;
                const source = e.dataTransfer.getData('text/track')
                const { track } = e.target.dataset
                if(allowedOperation === "copy") addComponent(InsertingElement.current, targetTrack, PointingAt.current)
                if (allowedOperation === "move") moveComponent(source, track, PointingAt.current)
            }
        });

        doc.addEventListener('dragover', (e) => {
            e.stopPropagation()
            const allowedOperation = e.dataTransfer.effectAllowed;
            if (allowedOperation === 'move' || allowedOperation === 'copy') {
                const kind = e.target.getAttribute('kind')
                if(kind === 'component' || kind === 'block') {
                    e.preventDefault(); //Preventing default allows us to handle drop event
                }
            }
        });

        doc.addEventListener('dragend', e => {
            if(!e.target || e.target.nodeType !== 1) return null
            const kind = e.target.getAttribute('kind')
            if(kind !== 'component') return null
            const dragging = [...doc.querySelectorAll('.dragging')]
            dragging.forEach(draggingElement => {
                draggingElement.classList.remove('dragging')
            })
            const draggingSource = [...doc.querySelectorAll('.dragging-source')]
            draggingSource.forEach(d => d.classList.remove('dragging-source'))
            //EXPERIMENTAL OUTLINE
            doc.querySelector('#root').removeAttribute('data-highlight', '')
            frame.document.dispatchEvent(new CustomEvent('clearInsert', { detail: {}}));
        })

    }, []);
    return null;
};

export default InitDragDrop;