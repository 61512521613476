import Div from 'elements/Div';
import React from 'react';
import { Close, Settings } from '../svg';
import { FaDatabase, FaMagic } from 'react-icons/fa';
import Button from 'elements/Button';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getBuilderContext } from 'contexts/builder';
import IterateProps from './IterateProps';
import styled from 'styled-components';
import IterateStates from './IterateStates';

const useSync = ({ value: syncObject, operation }) => {
    const [showSuggestion, setShowSuggestion] = React.useState(false);
    const { frame } = React.useContext(getCrossDomainContext);
    const { selectedComponent, editComponent } = React.useContext(getBuilderContext);
    const [propsData, setPropsData] = React.useState(null);
    const [statesData, setStatesData] = React.useState({})

    React.useEffect(() => {
        // Watch showSuggestion state and fill up props
        if (showSuggestion) {
            const node = frame.document.querySelector(`[data-track="${selectedComponent.indices}"]`);
            const propEvent = new CustomEvent('getProps', { detail: { callback: setPropsData } })
            const stateEvent = new CustomEvent('getInternalState', { detail: { callback: setStatesData } })
            node?.dispatchEvent(propEvent);
            node?.dispatchEvent(stateEvent);
        }
    }, [showSuggestion])
    const onSelect = (syncObject) => {
        if (operation.type === 'option') operation.forwardChange(operation.forwardKey, syncObject, 'add option sync')
        if (operation.type === 'setState') {
            if (operation.syncKey) {
                operation.setInstance({ ...operation.instance, [operation.syncKey]: syncObject })
            } else {
                operation.setInstance({ ...operation.instance, sync: syncObject })
            }
        }
    }
    const onRemove = () => {
        if (operation.type === 'option') operation.forwardChange(operation.forwardKey, null, 'remove option sync')
        if (operation.type === 'setState') {
            const clonedInstance = { ...operation.instance }
            if (operation.syncKey) {
                delete clonedInstance[operation.syncKey]
            } else {
                delete clonedInstance.sync
            }
            operation.setInstance(clonedInstance)
        }
    }
    const handleOnSelect = (syncObject) => {
        onSelect(syncObject)
        setShowSuggestion(false)
    }
    const syncTrigger = () => (
        <Button onClick={() => setShowSuggestion(true)}>
            <FaMagic />
        </Button>
    );
    const syncElement = () => {
        if (!syncObject) return null;
        return (
            <SyncWrapper>
                {syncObject ? syncObject.syncType === 'state' ?
                    <Div c="state-wrap">
                        <Div c="syncType"><Settings /></Div>
                        <Div c="detail"><img src={`/img/icons/${syncObject.type}.svg`} />{syncObject.stateName}</Div>
                        <Close className='remove' onClick={onRemove} />
                    </Div>
                    :
                    <Div c="prop-wrap">
                        <Div c="syncType"><FaDatabase />{''}</Div>
                        <Div c="detail"><img src={`/img/icons/${syncObject.type}.svg`} />{syncObject.name}</Div>
                        <Close className='remove' onClick={onRemove} />
                    </Div>
                    : ''}
            </SyncWrapper>
        );
    };
    const syncSuggestion = () => {
        if (!showSuggestion) return null
        return (
            <Wrapper data-scrollable className="suggestion-container">
                <Div c="suggestion-wrap">
                    <Div c="suggestion-title">Data properties</Div>
                    <IterateProps propsData={propsData} handleOnSelect={handleOnSelect} />
                </Div>
                <Div c="suggestion-wrap">
                    <Div c="suggestion-title">Logical states</Div>
                    <IterateStates statesData={statesData} handleOnSelect={handleOnSelect} />
                </Div>
            </Wrapper>
        )
    }

    return { syncTrigger, syncElement, syncSuggestion };
};

export default useSync;

const Wrapper = styled.div`
    position:fixed;
    left:10vw;
    top:10vw;
    bottom:10vw;
    overflow-y:scroll;
    background:${({ theme }) => theme.bg};
    box-shadow:rgba(0, 0, 0, 0.12) 0px 4px 16px, rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
    border-radius:8px;
    min-width:200px;
    z-index: 100;
    .suggestion-wrap{
        padding:10px;
        border-bottom:1px solid rgba(0,0,0,0.1);
        .suggestion-title{
            margin-bottom:10px;
            font-weight:500;
        }
    }
`

const SyncWrapper = styled.div`
    width: fit-content;
    min-width: 160px;
    border-radius: 4px;
    background: ${({ theme }) => theme.bg2};
    & > .prop-wrap, .state-wrap {
        display:flex;
        align-items:center;
        gap: 2px;
        padding-right: 8px;
        .syncType{
            display:flex;
            align-items:center;
            gap:4px;
            font-weight:600;
            color:${({ theme }) => theme.color2};
            border-right:1px solid ${({ theme }) => theme.sectionBorderColor};
            max-width: fit-content;
            min-width: 30px;
            height: 30px;
            justify-content: center;
            svg{
                color: ${({ theme }) => theme.mainColor};
                width: 14px;
                height: 14px;
            }
        }
        .detail{
            font-weight:300;
            display:flex;
            align-items:center;
            gap:8px;
            padding: 0 8px;
        }
        .remove{
            margin-left:auto;
            cursor:pointer;
            color: rgba(255,0,0,0.6);
            width: 14px;
            height: 14px;
        }
    }
`;