import React from 'react';
import styled from 'styled-components';
import { IoIosArrowDown } from "react-icons/io";
import { getBuilderContext } from 'contexts/builder';
import Instance from './instance';
import Div from 'elements/Div';
import { recursiveCopy } from 'utils/recursive';
import Button from 'elements/Button';
import ToggleButton from 'components/ToggleButton';
import { TrashRed } from 'utils/svg';

const array = ({ object, forwardKey, forwardChange }) => {
    const [active, setActive] = React.useState(object.active);
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext);
    const handleActive = (val) => {
        setActive(val);
        editComponent(selectedComponent.indices, 'activate option', forwardKey, val);
    };
    const handleChange = (key, value, index) => {
        const clone = recursiveCopy([...object.value], true)
        clone[index][key].value = value
        forwardChange(forwardKey, clone)
    };
    const handleAdd = () => {
        const clone = recursiveCopy([...object.value], true)
        const cloneInstance = recursiveCopy(object.value[0], true)
        clone.push(cloneInstance)
        forwardChange(forwardKey, clone)
    }
    const handleRemove = (indexToRemove) => {
        const clone = recursiveCopy([...object.value], true)
        if (clone.length <= 1) return console.warn('Cant erase all of them!')
        const removed = clone.filter((x, i) => i !== indexToRemove)
        forwardChange(forwardKey, removed)
    }
    return (
        <Container>
            <Div c='array-head' onClick={() => handleActive(!active)}>
                <p>{forwardKey}</p>
                <ToggleButton isToggled={active} setIsToggled={handleActive} />
            </Div>
            <Div c="body-wrap">
                {object.value?.map((instance, i) => {
                    return (
                        <Div c="wrap" key={i}>
                            <Div c="header" onClick={e => e.target.parentElement.classList.toggle('active')}>
                                <Div c='title-box'>{i + 1}</Div>
                                <span>{forwardKey}</span>
                                <Div c="close" onClick={() => handleRemove(i)}><TrashRed /></Div>
                                <Div c="arrow">
                                    <IoIosArrowDown />
                                </Div>
                            </Div>
                            <Div c="body">
                                <Instance forwardKey={forwardKey} index={i} object={instance} forwardChange={handleChange} />
                            </Div>
                        </Div>
                    )
                })}
                <Button onClick={handleAdd}>Add new</Button>
            </Div>
        </Container>
    );
};

export default array;

const Container = styled.div`
width: 100%;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.array-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;
    & > div{
        pointer-events: none;
    }
    p{
        font-weight: 500;
        text-transform: capitalize;
        color: ${({ theme }) => theme.color};
        font-size: 12px;
        line-height: 20px;
        padding: 10px 0;
        width: 100%;
        cursor: pointer;
    }
}
.body-wrap {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 15px;
    & > .wrap {
        & > .header {
            padding: 8px 20px;
            display:flex;
            align-items:center;
            gap:5px;
            cursor:pointer;
            text-transform:capitalize;
            .title-box{
                width: 28px;
                height: 28px;
                border-radius: 4px;
                background: ${({ theme }) => theme.bg2};
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                font-weight: 600;
            }
            span{
                color: ${({ theme }) => theme.color2};
                font-size: 10px;
                font-weight: 500;
                text-transform: uppercase;
            }
            .close{
                margin-left: auto;
                color: #f36567;
                svg{
                    width: 16px;
                    height: 16px;
                }
            }
            .arrow{
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;
                transition: all 0.3s linear;
                border-radius: 4px;
                svg{
                    width: 16px;
                    height: 16px;
                }
                &:hover{
                    background: ${({ theme }) => theme.bg2};
                    svg{
                        path{
                            fill: ${({ theme }) => theme.color2};
                        }
                    }
                }
            }
        }
        .body{
            display: none;
            padding: 0 20px;
        }
        &.active{
            .header{
                background: ${({ theme }) => theme.bg2};
                & > .arrow{
                    rotate: 180deg;
                }
                .title-box{
                    background: ${({ theme }) => theme.bg};
                }
                span{
                    color: #000;
                }
            }
            .body{
                display: block;
            }
        }
    }
    button{
        padding: 10px 20px;
        border-radius: 0;
        text-transform: uppercase;
        font-weight: 600;
        color: ${({ theme }) => theme.color};
        font-size: 10px;
    }
}
`;
