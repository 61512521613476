import React from 'react';
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { dispatchChange, getStyleValue } from 'utils/styleHelpers';
import styled from 'styled-components';
import BorderController from './BorderController';

const Blur = () => {
    const [value, setValue] = React.useState({})
    const { selectedComponent, editComponent } =
        React.useContext(getBuilderContext)
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext)
    const doc = frame.document

    React.useEffect(() => {
        setInitialValue()
    }, [selectedComponent])

    React.useEffect(() => {
        setInitialValue();
    }, [frameUpdate]);

    const setInitialValue = () => {
        const styleValue = getStyleValue(selectedComponent, 'borderWidth', doc)
        setValue(styleValue)
    }

    const forwardChange = (changingValue) => {
        if (changingValue == 'off') {
            editComponent(selectedComponent.indices, [
                {
                    type: 'style', changes: [
                        { key: 'borderColor', value: 'none' },
                        { key: 'borderWidth', value: 'none' },
                        { key: 'borderStyle', value: 'none' }
                    ]
                }
            ])
        } else if (changingValue == 'default') {
            editComponent(selectedComponent.indices, [
                {
                    type: 'style', changes: [
                        { key: 'borderColor', value: '#d9d9d9' },
                        { key: 'borderWidth', value: '0px' },
                        { key: 'borderStyle', value: 'solid' }
                    ]
                }
            ])
        } else {
            dispatchChange(editComponent, selectedComponent, 'borderWidth', changingValue)
        }
    }

    return (
        <Container>
            <BorderController value={value} forwardChange={forwardChange} />
        </Container>
    );
};

export default Blur;

const Container = styled.div`
width: 100%;
`