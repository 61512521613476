import ToggleButton from 'components/ToggleButton';
import React from 'react';

const boolean = ({ value = false, onChange }) => {
    return (
        <ToggleButton isToggled={value} setIsToggled={(v) => onChange(v)} />
    );
};

export default boolean;
