import React from 'react';
import styled from 'styled-components';
import { IoIosArrowRoundDown, IoIosArrowRoundForward } from "react-icons/io";
import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { dispatchChange } from 'utils/styleHelpers';
import Div from 'elements/Div';

const Direction = () => {
    const { frame, frameUpdate } = React.useContext(getCrossDomainContext)
    const doc = frame.document
    const { selectedComponent, editComponent } = React.useContext(getBuilderContext)
    const targetRef = React.useRef()
    const [currentDirection, setCurrentDirection] = React.useState(null)
    const [allowAttribute, setAllowAttribute] = React.useState(false)

    React.useEffect(() => {
        checkAllowAttribute()
    }, [frameUpdate])

    React.useEffect(() => {
        targetRef.current = doc.querySelector(`[data-track="${selectedComponent.indices}"]`)
        checkAllowAttribute()
    }, [selectedComponent])

    const checkAllowAttribute = () => {
        if(!targetRef.current) return console.warn('Target not selected!')
        const { display } = getComputedStyle(targetRef.current);
        if(display === "flex") {
            checkDirection()
            setAllowAttribute(true)
        }
        else setAllowAttribute(false)
    }

    const checkDirection = () => {
        const { flexDirection } = getComputedStyle(targetRef.current);
        if(flexDirection === "row" || flexDirection === "row-reverse") {
            setCurrentDirection('x')
        } else {
            setCurrentDirection('y')
        }
    }

    const changeDirectionReducer = () => {
        const { flexDirection } = getComputedStyle(targetRef.current);
        switch(flexDirection) {
            case "row": forwardChange('column'); break;
            case "row-reverse": forwardChange('column-reverse'); break;
            case "column": forwardChange('row'); break;
            case "column-reverse": forwardChange('row-reverse'); break;
        }
    }

    const forwardChange = (argument) => {
        dispatchChange(editComponent, selectedComponent, 'flexDirection', argument)
    }

    return (
        <Container data-attribute data-allowattribute={allowAttribute}>
            <Div c='button' onClick={()=>currentDirection !== "x" && changeDirectionReducer()} open={currentDirection === "x"}><IoIosArrowRoundForward /></Div>
            <Div c='button' onClick={()=>currentDirection !== "y" && changeDirectionReducer()} open={currentDirection === "y"}><IoIosArrowRoundDown/></Div>
        </Container>
    );
};

export default Direction;

const Container = styled.div `
    background: ${({theme}) => theme.bg2};
    display:flex;
    padding:3px;
    border-radius:4px;
    gap:10px;
    .button {
        width: calc((100% - 10px) / 2);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 0;
        border-radius: 4px;
        cursor: pointer;
        & > svg{
            font-size: 24px;
            path{
                fill: ${({theme}) => theme.color2};
            }
        }
        &[open]{
            background: ${({theme}) => theme.bg3};
        }
    }
`