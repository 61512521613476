import React from 'react';
import styled from 'styled-components';
import useSync from 'utils/useSync';

const Richtext = ({ object, forwardChange, forwardKey }) => {
    const [val, setVal] = React.useState(object.value);
    const ref = React.useRef();
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: object.sync, operation: { type: 'option', forwardKey, forwardChange } })
    const handleChange = () => {
        setVal(ref.current.innerHTML);
        forwardChange(forwardKey, ref.current.innerHTML);
    }
    return (
        <Container>
            <div className='caption'>{forwardKey}</div>
            {object.sync ? syncElement() : (
                <div className='flex'>
                    <Editor
                        onBlur={() => handleChange()}
                        ref={ref}
                        contentEditable
                        dangerouslySetInnerHTML={{ __html: val }}
                    >
                    </Editor>
                    {syncTrigger()}
                </div>
            )}
            {syncSuggestion()}
        </Container>
    );
};

export default Richtext;

const Editor = styled.div`
padding: 8px 12px;
width: 100%;
background: ${({ theme }) => theme.bg2};
min-height: 100px;
border-radius: 4px;
margin-bottom: 10px;
&:focus{
    outline: none;
}
`;

const Container = styled.div`
width: 100%;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.flex{
    display: flex;
    align-items: start;
}
`
