import { getBuilderContext } from 'contexts/builder';
import { getCrossDomainContext } from 'contexts/crossDomain';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const InitRichtext = () => {
    const { frame } = React.useContext(getCrossDomainContext);
    const { editComponent } = React.useContext(getBuilderContext);
    const { theme } = React.useContext(getCustomThemeContext);
    const [richTextInAction, setRichTextInAction] = React.useState(null)
    const doc = frame.document;

    React.useEffect(() => {
        loadScript()
    }, []);

    React.useEffect(() => {
        doc.addEventListener('dblclick', watchRichtext);
        doc.addEventListener('mousedown', handleMouseDown);
        return () => {
            doc.removeEventListener('dblclick', watchRichtext);
            doc.removeEventListener('mousedown', handleMouseDown);
        }
    }, [richTextInAction])

    React.useEffect(() => {
        if (richTextInAction) drawHelperStyle()
        else clearHelperStyle()
    }, [richTextInAction])

    const drawHelperStyle = () => {
        const style = doc.createElement('style')
        style.setAttribute('bl-richtext-lint', '')
        style.innerText = `
            #bl-select{
                opacity: 0 !important;
            }
            #selectHelperWrap{
                opacity: 0 !important;
            }
            [bl-resize] {
                opacity: 0 !important;
            }
        `
        doc.head.appendChild(style)
    }
    const clearHelperStyle = () => {
        const existingLints = [...doc.querySelectorAll('[bl-richtext-lint]')]
        existingLints.forEach(t => t?.remove())
    }

    const handleMouseDown = (e) => {
        if (!richTextInAction) return null
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        const { track, fieldName } = richTextInAction
        const targetNode = doc.querySelector(`[kind][data-track="${track}"]`)
        const elements = [...doc.elementsFromPoint(e.clientX, e.clientY)]
        // const targetFound = elements.find(p => p === targetNode)
        const quillContainer = elements.find(p => p.classList.contains('ql-container'))
        const toolbarFound = elements.find(p => p.classList.contains('ql-toolbar'))
        const tooltipFound = elements.find(p => p.classList.contains('ql-tooltip'))
        const pickerFound = elements.find(p => p.classList.contains('ql-picker-options'))
        if (quillContainer || toolbarFound || tooltipFound || pickerFound) return null

        // If code passes down here, time to go cleanup and update

        const htmlContent = frame.document.querySelector('.ql-editor').innerHTML
        targetNode.innerHTML = htmlContent

        editComponent(track, 'options', fieldName, htmlContent);
        setRichTextInAction(null)
    }

    const loadScript = () => {
        const quillCss = doc.createElement('link')
        quillCss.setAttribute('href', 'https://cdn.quilljs.com/1.3.6/quill.snow.css')
        quillCss.setAttribute('rel', 'stylesheet')
        quillCss.setAttribute('type', 'text/css')
        doc.head.appendChild(quillCss)

        const quillScript = doc.createElement('script')
        quillScript.src = 'https://cdn.quilljs.com/1.3.6/quill.js'
        quillScript.setAttribute('id', 'quill-script')
        doc.head.appendChild(quillScript)
    }
    const watchRichtext = (e) => {
        if (!e.target.parentElement || e.target.parentElement.nodeType !== 1) return null;
        const isRichtext = e.target.dataset.richtext === 'true' ? true : false;
        if (!isRichtext) return null
        const fieldName = e.target.dataset.richtextfield;
        const dataTrack = e.target.dataset.track;

        // Making the quill wrappable
        const quillWrap = doc.createElement('div')
        quillWrap.setAttribute('id', 'quill-wrap');
        const existingHtml = e.target.innerHTML
        e.target.innerHTML = ''
        quillWrap.innerHTML = existingHtml
        e.target.appendChild(quillWrap)
        const quill = new frame.window.Quill('#quill-wrap', {
            theme: 'snow',
            modules: {
                toolbar: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['bold', 'italic'],
                    // [{ 'font': ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'] }], // Expanded font options
                ]
            },
            bounds: doc.body,
            placeholder: '// Write here'
        })
        quill.setSelection(quill.getLength())
        quill.root.click()
        doc.querySelector(`[bl-block-menu="wrapper"]`)?.remove()

        // Modifying unneccessary styles from quill
        const styleLinter = doc.createElement('style')
        let modifiedStyleLint = staticStyleLint

        //Reposition menubar
        const targetNode = doc.querySelector(`[kind][data-track="${dataTrack}"]`)
        const targetRect = targetNode.getBoundingClientRect()

        const menuBarWidth = 350
        const menubarHeight = 44
        if (targetRect.top > 100) {
            modifiedStyleLint = modifiedStyleLint + `
            .ql-toolbar.ql-snow{
                bottom:unset;
                top: ${targetRect.top - 54}px;
            }`
        }
        else {
            if (targetRect.top + targetRect.height + menubarHeight > frame.innerHeight) {
                modifiedStyleLint = modifiedStyleLint + `
                .ql-toolbar.ql-snow{
                    top:unset;
                    bottom: ${100}px;
                }`
            } else {
                modifiedStyleLint = modifiedStyleLint + `
                .ql-toolbar.ql-snow{
                    bottom:unset;
                    top: ${targetRect.top + targetRect.height + 25}px;
                }`
            }
        }

        if (targetRect.left + frame.scrollX > 0) {
            if (targetRect.left + targetRect.width + menuBarWidth > frame.innerWidth) {
                modifiedStyleLint = modifiedStyleLint + `
                .ql-toolbar.ql-snow{
                    left:unset;
                    right: 30px;
                }`
            } else {
                modifiedStyleLint = modifiedStyleLint + `
                .ql-toolbar.ql-snow{
                    right:unset;
                    left: ${targetRect.left + frame.scrollX}px;
                }`
            }
        }
        else {
            if (100 + targetRect.width + menuBarWidth > frame.innerWidth) {
                modifiedStyleLint = modifiedStyleLint + `
                .ql-toolbar.ql-snow{
                    right:unset;
                    left: 30px;
                }`
            } else {
                modifiedStyleLint = modifiedStyleLint + `
                .ql-toolbar.ql-snow{
                    right:unset;
                    left: 100px;
                }`
            }
        }

        styleLinter.innerText = modifiedStyleLint
        doc.head.appendChild(styleLinter)

        setRichTextInAction({ track: dataTrack, fieldName: fieldName })
    }
    const staticStyleLint = `
        .ql-toolbar.ql-snow{
            position:fixed;
            background: ${theme.bg};
            box-shadow:0 4px 16px rgba(0,0,0,.12), 0 0 0 1px rgba(0,0,0,.02);
            border:none;
            border-radius:8px;
            font-size:12px;
            pointer-events:auto !important;
            line-height:initial;
            letter-spacing:initial;
            word-spacing:initial
        }
        .ql-container.ql-snow{
            border:none;
            pointer-events:auto !important;
            font-family:inherit;
            font-size:inherit;
            box-sizing:content-box;
            height:100%;
        }
        .ql-editor{
            padding:0px;
            line-height:inherit;
            height:inherit;
            text-align:inherit;
            white-space:inherit;
            word-wrap:inherit;
        }
        .ql-toolbar{
            animation: fade-in-down 0.5s ease both;
            z-index:2;
        }
        @keyframes fade-in-down {
            0% {
              opacity: 0;
              transform: translateY(-10px);
            }
            100% {
              opacity: 1;
              transform: translateY(0px);
            }
        }
    `
    return null;
};

export default InitRichtext;

