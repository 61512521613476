import React from 'react';
import * as AllAttributes from 'modules/Styles/Attributes/_exports';
import Div from 'elements/Div';

const AttributeInstance = ({ attribute }) => {
    const AttributeJsx = AllAttributes[attribute.component];
    return (
        <Div c="attributeRow">
            {attribute.name ? <Div c="attributeName">{attribute.name}</Div> : ''}
            <AttributeJsx />
        </Div>
    );
};

export default AttributeInstance;
