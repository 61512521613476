import Div from "elements/Div";
import React from "react";
import styled from "styled-components";
import ToggleButton from "components/ToggleButton";

const WebAddress = ({ setLinkData, linkData }) => {
    return (
        <Container>
            <Div c='link-wrap'>
                <label>Web Address</label>
                <input type="text" placeholder="https://example.com" value={linkData.slug} onChange={(e) => setLinkData({...linkData, slug: e.target.value})}/>
            </Div>
            <Div c="toggle-wrap" onClick={() => setLinkData({ ...linkData, newWindow: !linkData.newWindow })}>
                <span>Open In New Window</span>
                <ToggleButton isToggled={linkData.newWindow} />
            </Div>
        </Container>
    )
}

export default WebAddress

const Container = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
.link-wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    label{
        letter-spacing: 0.75px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.color2};
        font-weight: 500;
        font-size: 10px;
    }
    input{
        border: none;
        background: ${({ theme }) => theme.bg2};
        padding: 12px 18px;
        border-radius: 4px;
        color: ${({ theme }) => theme.color};
        font-weight: 500;
        width: 100%;
        &:focus{
            outline: none;
        }
    }
}
.toggle-wrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 44px;
    cursor: pointer;
    align-items: center;
    padding: 12px 18px;
    background: ${({theme}) => theme.bg2};
    border-radius: 4px;
    & > div{
        pointer-events: none;
    }
    span{
        color: ${({ theme }) => theme.color};
    }
}
`