import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { IoIosArrowDown } from "react-icons/io";
import { Close } from "utils/svg";
import useSync from 'utils/useSync';


const ProductSort = ({ object, forwardKey, forwardChange }) => {
    const sort = [
        {
            label: "CreatedAt asc",
            value: "createdAt:asc"
        },
        {
            label: "CreatedAt desc",
            value: "createdAt:desc"
        },
        {
            label: "UpdatedAt asc",
            value: "updatedAt:asc"
        },
        {
            label: "UpdatedAt desc",
            value: "updatedAt:desc"
        },
        {
            label: "DiscountPerc asc",
            value: "discountPerc:asc"
        },
        {
            label: "DiscountPerc desc",
            value: "discountPerc:desc"
        },
        {
            label: "DiscountAmnt asc",
            value: "discountAmnt:asc"
        },
        {
            label: "DiscountAmnt desc",
            value: "discountAmnt:desc"
        },
        {
            label: "SellingPrice asc",
            value: "sellingPrice:asc"
        },
        {
            label: "SellingPrice desc",
            value: "sellingPrice:desc"
        }
    ]
    const [selectedSort, setSelectedSort] = React.useState(object || null)
    const [active, setActive] = React.useState(false)
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: object.sync, operation: { type: 'option', forwardKey, forwardChange } })

    const handleDropdownItem = (val) => {
        if (val !== null){
            forwardChange(forwardKey, val.value)
            setSelectedSort(val)
        }
        else{
            forwardChange(forwardKey, null)
            setSelectedSort(null)
        }
        setActive(false)
    }
    return (
        <Container>
            <div className="caption">
                <span>{forwardKey}</span>
            </div>
            {object.sync ? syncElement() : (
                <div className='flex'>
                    <div className="dropdown-wrap">
                        <Div c={`dropdown-head ${active && 'active'}`} onClick={() => setActive(!active)}>
                            <span>{selectedSort?.value ? selectedSort.value : 'Choose one'}</span>
                            {selectedSort !== null && (
                                <Div c="close" onClick={() => handleDropdownItem(null)}><Close /></Div>
                            )}
                            <Div c="arrow"><IoIosArrowDown /></Div>
                        </Div>
                        {active && (
                            <Div c="dropdown">
                                {sort.map((val, i) => (
                                    <Div c="item" key={i} onClick={() => handleDropdownItem(val)}>{val.label}</Div>
                                ))}
                            </Div>
                        )}
                    </div>
                    {syncTrigger()}
                </div>
            )}
            {syncSuggestion()}
        </Container>
    )
}

export default ProductSort;

const Container = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items:center;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.flex{
    display: flex;
    width: 100%;
    .dropdown-wrap{
        position: relative;
        width: 100%;
        .dropdown-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: ${({ theme }) => theme.bg2};
            border-radius: 4px;
            padding: 8px 12px;
            cursor: pointer;
            position: relative;
            width: 100%;
            span{
                font-size: ${({ theme }) => theme.fs};
                color: ${({ theme }) => theme.color};
                font-weight:500;
                width: 100%;
            }
            .arrow{
                background: ${({ theme }) => theme.bg2};
                transition: all 0.3s linear;
                z-index: 0;
                display: flex;
                align-items: center;
                svg{
                    width: 14px;
                    height: 14px;
                }
            }
            .close{
                position: absolute;
                right: 14px;
                transition: right 0.3s ease;
                display: flex;
                align-items: center;
                bottom: 0;
                top: 0;
                svg{
                    width: 14px;
                    height: 14px;
                    path{
                        fill: ${({ theme }) => theme.color2};
                    }
                }
            }
            &:hover{
                .close{
                    right: 28px;
                }
            }
            &.active{
                .arrow{
                    svg{
                        rotate: 180deg;
                    }
                }
            }
        }
        .dropdown{
            top: 120%;
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            top: 120%;
            border-radius: 4px;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
            background: ${({ theme }) => theme.bg2};
            max-height: 100px;
            overflow-y: auto;
            .item{
                padding: 10px 15px;
                font-size: ${({ theme }) => theme.fs};
                font-weight: 600;
                color: ${({ theme }) => theme.color};
                border-radius: 4px;
                &:hover {
                    background: ${({ theme }) => theme.lightMainColor};
                    color: ${({ theme }) => theme.mainColor};
                    cursor: pointer;
                }
            }
        }
    }
}
`