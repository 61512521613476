import Div from "elements/Div";
import React from "react";
import request from 'utils/request';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import { toast } from 'react-hot-toast';
import LoadingFallback from "router-entries/LoadingFallback";
import { IoIosArrowDown } from "react-icons/io";
import { Close } from "utils/svg";
import ToggleButton from "components/ToggleButton";

const Pages = ({ setLinkData, linkData }) => {
    const [pages, setPages] = React.useState([])
    const { id: webId } = useParams()
    const [active, setActive] = React.useState(false)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        goFetch()
    }, [])

    const goFetch = async () => {
        try {
            setLoading(true)
            const result = await request({ url: window.urls.web + `/page`, headers: { 'webId': `${webId}` } })
            setPages(result.data)
        } catch (e) {
            toast.error(e)
        } finally {
            setLoading(false)
        }
    }

    const handleDropdownItem = (slug) => {
        if (slug !== null)
            setLinkData({ ...linkData, slug: slug })
        else
            setLinkData({ ...linkData, slug: null })
        setActive(!active)
    }

    return (
        <Container>
            <Div c='link-wrap'>
                <label>Page</label>
                <Div c="dropdown-wrap">
                    <Div c={`dropdown-head ${active && 'active'}`} onClick={() => setActive(!active)}>
                        <span>{linkData?.slug ? linkData?.slug : 'Choose one'}</span>
                        {linkData?.slug && <Div c='close' onClick={() => handleDropdownItem(null)}><Close /></Div>}
                        <Div c='arrow'><IoIosArrowDown /></Div>
                    </Div>
                    {active && <Div c='dropdown'>
                        {loading ? <LoadingFallback /> : pages?.map((val, i) => (
                            <Div c="item" key={i} onClick={() => handleDropdownItem(val.slug)}>
                                {val.name} <span>{val.slug}</span>
                            </Div>
                        ))}
                    </Div>}
                </Div>
            </Div>
            <Div c="toggle-wrap" onClick={() => setLinkData({ ...linkData, newWindow: !linkData.newWindow })}>
                <span>Open In New Window</span>
                <ToggleButton isToggled={linkData.newWindow} />
            </Div>
        </Container>
    )
}

export default Pages

const Container = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
& > .title{
    font-weight: 600;
    font-size: ${({ theme }) => theme.fs4};
}
.link-wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 100% !important;
    label{
        letter-spacing: 0.75px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.color2};
        font-weight: 500;
        font-size: 10px;
    }
    .dropdown-wrap{
        width: 100%;
        position: relative;
        .dropdown-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: ${({ theme }) => theme.bg2};
            border-radius: 4px;
            padding: 12px 18px;
            cursor: pointer;
            position: relative;
            span{
                font-size: ${({ theme }) => theme.fs};
                color: ${({ theme }) => theme.color};
                font-weight:500;
            }
            .arrow{
                background: ${({ theme }) => theme.bg2};
                transition: all 0.3s linear;
                z-index: 1;
                display: flex;
                align-items: center;
                svg{
                    width: 14px;
                    height: 14px;
                }
            }
            .close{
                position: absolute;
                right: 15px;
                transition: right 0.3s ease;
                display: flex;
                align-items: center;
                top: 0;
                bottom: 0;
                margin: auto;
                svg{
                    width: 14px;
                    height: 14px;
                    path{
                        fill: ${({ theme }) => theme.color2};
                    }
                }
            }
            &:hover{
                .close{
                    right: 32px;
                }
            }
            &.active{
                .arrow{
                    svg{
                        rotate: 180deg;
                    }
                }
            }
        }
        .dropdown{
            top: 120%;
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            top: 120%;
            border-radius: 4px;
            background: ${({ theme }) => theme.bg};
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
            min-width: 100px;
            background: ${({ theme }) => theme.bg2};
            height: 300px;
            overflow-y: auto;
            .item{
                padding: 12px 18px;
                font-size: ${({ theme }) => theme.fs};
                font-weight: 600;
                color: ${({ theme }) => theme.color};
                border-radius: 4px;
                &:hover {
                    background: ${({ theme }) => theme.lightMainColor};
                    color: ${({ theme }) => theme.mainColor};
                    cursor: pointer;
                }
                span{
                    color: ${({ theme }) => theme.color2};
                    font-weight: 400;
                }
            }
        }
    }
}
.toggle-wrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 44px;
    cursor: pointer;
    align-items: center;
    padding: 12px 18px;
    background: ${({theme}) => theme.bg2};
    border-radius: 4px;
    & > div{
        pointer-events: none;
    }
    span{
        color: ${({ theme }) => theme.color};
    }
}
`