import { getCrossDomainContext } from 'contexts/crossDomain';
import Div from 'elements/Div';
import React from 'react';
import Select from '../InputTypes/Select';
import { populateState } from 'utils/vanillaFunctions';

const decrementState = ({ instance, setInstance }) => {
    const { frame } = React.useContext(getCrossDomainContext);
    const [availableStates, setAvailableStates] = React.useState([]);
    React.useEffect(() => {
        const target = frame.document.querySelector(`[data-track="${instance._indices}"]`);
        const populatedStates = populateState(target);
        setAvailableStates(Object.keys(populatedStates));
    }, []);
    return (
        <>
            <Div c="input-wrap">
                <label>Choose state</label>
                <Select options={availableStates} value={instance.stateName} onChange={(v) => setInstance({ ...instance, stateName: v })} />
            </Div>
            <Div c="input-wrap">
                <label>Decrement by</label>
                <input type='number' value={instance.incrementBy} onChange={(e) => setInstance({ ...instance, incrementBy: e.target.value })} />
            </Div>
        </>
    );
};

export default decrementState;
