import MainLayout from 'containers/MainLayout';
import InitGadgets from 'gadgets';
import React from 'react';

const App = () => {
    return (
        <React.Fragment>
            <MainLayout />
            <InitGadgets />
        </React.Fragment>
    );
};

export default App;
