import { getCrossDomainContext } from 'contexts/crossDomain';
import { getReferenceContext } from 'contexts/reference';
import { getCustomThemeContext } from 'contexts/theme';
import React from 'react';

const Indicator = () => {
    const { PointingAt } = React.useContext(getReferenceContext);
    const { frame } = React.useContext(getCrossDomainContext);
    const { theme } = React.useContext(getCustomThemeContext);
    const { Highlight, Indicator: itself, Appending } =
        React.useContext(getReferenceContext);
    const doc = frame.document;
    React.useEffect(() => {
        createShapeNode();
        createAppendNode();
        doc.addEventListener('dragover', (e) => {
            if (!e.target.hasAttribute('kind')) return null;
            const kind = e.target.getAttribute('kind');
            if (kind === 'component' || kind === 'block') drawIndicator(e)
        });
        doc.addEventListener('drop', (e) => {
            if (!e.target.hasAttribute('kind')) return null;
            const kind = e.target.getAttribute('kind');
            if (kind === 'component' || kind === 'block') clearIndicator()
        });
        doc.addEventListener('dragleave', (e) => {
            e.preventDefault()
            if (!e.target.hasAttribute('kind')) return null;
            const kind = e.target.getAttribute('kind');
            if (kind === 'component' || kind === 'block') clearIndicator()
        })
    }, []);
    const createAppendNode = () => {
        const append = doc.createElement('div')
        append.style.cssText = `
            position:absolute;
            pointer-events:none;
            box-sizing:border-box;
            // background:${theme.mainColor};
            background:rgba(4, 150, 255,0.3);
            // background-color: #b7875600;
            // background-size: 10px 10px;
            // background-image: repeating-linear-gradient(45deg, #0a9d5b 0, #0a9d5b 1px, rgba(229,229,247,0) 0, rgba(229,229,247,0) 50%);
        `
        doc.body.appendChild(append)
        Appending.current = append
    }
    const createShapeNode = () => {
        const shape = doc.createElement('div');
        shape.style.cssText = `
            position:absolute;
            pointer-events:none;
            background: ${theme.mainColor};
            // border: 1px solid ${theme.color};
            height:4px;
            width:4px;
            box-sizing:border-box;
            animation: blink-2 0.6s infinite both;
        `;
        doc.body.appendChild(shape);
        itself.current = shape;
    };

    const clearIndicator = () => {
        itself.current.style.top = 'unset';
        itself.current.style.left = 'unset';
        itself.current.style.width = 'unset';
        itself.current.style.height = 'unset';

        Appending.current.style.left = `unset`
        Appending.current.style.top = `unset`
        Appending.current.style.width = `unset`
        Appending.current.style.height = `unset`

    }

    function drawIndicator(event) {
        const { clientY, clientX, target } = event;
        const { offsetTop, offsetLeft, parentElement } = target;
        const { width, height, top, left } = target.getBoundingClientRect();
        const xCursorPixel = clientX - left; // Relative to the target
        const yCursorPixel = clientY - top; // Relative to the target
        const yCursorPercent = yCursorPixel / (height / 100); // Relative to the target
        const xCursorPercent = xCursorPixel / (width / 100); // Relative to the target
        const { display, flexDirection } = getComputedStyle(parentElement);
        let axis = 'y';

        if (display === 'flex') {
            if (flexDirection === 'row' || flexDirection === 'row-reverse') {
                axis = 'x';
            }
        }

        //CLEAN UP APPENDING NODE
        Appending.current.style.left = `unset`
        Appending.current.style.top = `unset`
        Appending.current.style.width = `unset`
        Appending.current.style.height = `unset`

        if (axis === 'x') {
            itself.current.style.top = `${top + frame.scrollY}px`;
            itself.current.style.width = `4px`;
            itself.current.style.height = `${height}px`;
            if (xCursorPercent <= 20) {
                itself.current.style.left = `${left + frame.scrollX}px`;
                itself.current.style.display = `block`;
                PointingAt.current = 'u'
            }
            else if (xCursorPercent > 20 && xCursorPercent <= 80) {
                const targetData = target.dataset
                const appendable = targetData.appendable === "true" ? true : false
                if(!target.hasChildNodes() && appendable) {
                    itself.current.style.left = `${(left + frame.scrollX) + width / 2}px`;
                    itself.current.style.display = `none`;
                    PointingAt.current = 'm';

                    const widthPadding = 15
                    const heightPadding = 15
                    Appending.current.style.left = `${left + frame.scrollX + widthPadding}px`
                    Appending.current.style.top = `${top + frame.scrollY + heightPadding}px`
                    Appending.current.style.width = `${width - widthPadding * 2}px`
                    Appending.current.style.height = `${height - heightPadding * 2}px`
                }
            }
            else if(xCursorPercent > 80) {
                itself.current.style.left = `${left + frame.scrollX + width}px`;
                itself.current.style.display = `block`;
                PointingAt.current = 'd'
            }
        } else {
            itself.current.style.left = `${left + frame.scrollX}px`;
            itself.current.style.width = `${width}px`;
            itself.current.style.height = `4px`;
            if (yCursorPercent <= 20) {
                itself.current.style.top = `${top + frame.scrollY}px`;
                itself.current.style.display = `block`;
                PointingAt.current = 'u';
            }
            else if (yCursorPercent > 20 && yCursorPercent <= 80) {
                const targetData = target.dataset
                const appendable = targetData.appendable === "true" ? true : false
                if(!target.hasChildNodes() && appendable) {
                    itself.current.style.top = `${(top + frame.scrollY) + height / 2}px`;
                    itself.current.style.display = `none`;
                    PointingAt.current = 'm';

                    const widthPadding = 15
                    const heightPadding = 15
                    Appending.current.style.left = `${left + frame.scrollX + widthPadding}px`
                    Appending.current.style.top = `${top + frame.scrollY + heightPadding}px`
                    Appending.current.style.width = `${width - widthPadding * 2}px`
                    Appending.current.style.height = `${height - heightPadding * 2}px`
                }
            }
            else if(yCursorPercent > 80) {
                itself.current.style.top = `${top + frame.scrollY + height}px`;
                itself.current.style.display = `block`;
                PointingAt.current = 'd';
            }
        }

        doc.querySelector('#root').setAttribute('data-pointing', PointingAt.current)
        Highlight.current.dispatchEvent(
            new CustomEvent('draw', { detail: { target } })
        );
    }

    return null;
};

export default Indicator;
