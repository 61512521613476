import Div from "elements/Div";
import React from "react";
import styled from "styled-components";
import { getBuilderContext } from "contexts/builder";
import { recursiveCopy } from "utils/recursive";
import { Basket, Booking, Check, Payment, Users } from "utils/svg";

const DataSourceSelection = ({ object }) => {
    const dataTypes = [
        { icon: <Booking />, value: 'Collection'},
        { icon: <Booking />, value: 'Categories'},
        { icon: <Basket />, value: 'Product'},
        { icon: <Basket />, value: 'Product Collection'},
        { icon: <Booking />, value: 'Brands'},
        { icon: <Basket />, value: 'Cart'},
        { icon: <Payment />, value: 'Order'},
        { icon: <Payment />, value: 'Booking Order'},
        { icon: <Users />, value: 'User'},
        { icon: <Booking />, value: 'Booking Slot'},
    ]
    const [selectedSourceType, setSelectedSourceType] = React.useState(object.value.sourceType.value || null)
    const { editComponent, selectedComponent } = React.useContext(getBuilderContext)

    const handleSourceType = (val) => {
        const copiedObject = recursiveCopy(object.value, true)
        copiedObject.sourceType.value = val
        editComponent(selectedComponent.indices, 'options', 'dataSource', copiedObject)
        setSelectedSourceType(val)
    }

    return (
        <Container>
            <Div c='title-wrap'>
                <h5>Source type</h5>
                <p>Select the source type you need and continue.</p>
            </Div>
            <Div c='source-types'>
                {dataTypes.map((type, i) => (
                    <Div c={`type-wrap ${selectedSourceType === type.value && 'active'}`} key={i} onClick={() => handleSourceType(type.value)}>
                        <Div c="type">{type.icon}<Div c="text-wrap"><p>{type.value}</p></Div></Div>
                        <Div c='check-icon'><Check /></Div>
                    </Div>
                ))}
            </Div>
        </Container>
    )
}

export default DataSourceSelection

const Container = styled.div`
height: calc(100% - 62px);
.title-wrap{
    margin-bottom: 30px;
    h5{
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        padding: 6px 0;
    }
    p{
        font-size: 16px;
        line-height: 22px;
        color: ${({ theme }) => theme.color2};
        font-weight: 500;
    }
}
.source-types{
    display: flex;
    gap: 10px;
    width: 100%;
    max-height: calc(100% - 114px);
    overflow-y: auto;
    flex-direction: column;
    .type-wrap{
        border: 1px solid ${({ theme }) => theme.sectionBorderColor};
        padding: 14px;
        display: flex;
        cursor: pointer;
        transition: all linear 0.2s;
        align-items: center;
        justify-content: space-between;
        gap: 14px;
        .type{
            display: flex;
            align-items: center;
            gap: 15px;
            svg{
                width: 22px;
                height: 22px;
            }
            .text-wrap{
                p{
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 500;
                }
                span{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 400;
                    color: ${({ theme }) => theme.color2};
                }
            }
        }
        .check-icon{
            opacity: 0;
            display: flex;
            align-items: center;
        }
        &.active{
            border: 1px solid ${({ theme }) => theme.color};
            .check-icon{
                opacity: 1;
                svg{
                    fill: rgb(27, 117, 79);
                }
            }
        }
    }
}
`