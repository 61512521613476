import React from 'react';
import styled from 'styled-components';
import MediaIndex from 'siro-media-library';
import { useAuth } from 'utils/useAuth';
import { useParams } from 'react-router-dom';
import Div from 'elements/Div';
import { FiPlus } from "react-icons/fi";
import { Trash } from 'utils/svg';
const BgImageHandler = ({ value: initialValue, onChange: forwardChange }) => {
    const [value, setValue] = React.useState('');
    const [showMediaLibrary, setShowMediaLibrary] = React.useState(false)
    const { accessToken } = useAuth()
    const { id: webId } = useParams();
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    const onMediaSelect = (media) => {
        forwardChange(`url(${media.url})`)
        setShowMediaLibrary(false)
    }

    return (
        <Container>
            {value ?
                <Div c='media'>
                    <img src={extractImagePath(value)} />
                    <Div c='bottom'>
                        <Div c='type'>Image</Div>
                        <span onClick={() => setShowMediaLibrary(true)}>Replace</span>
                        <Trash onClick={() => forwardChange(null)}/>
                    </Div>
                </Div>
                :
                <Div c='media empty'>
                    <Div c='add' onClick={() => setShowMediaLibrary(true)}><FiPlus /></Div>
                    <span>Add an Image</span>
                </Div>
            }
            {showMediaLibrary && <MediaIndex setImage={onMediaSelect} onCancel={() => setShowMediaLibrary(!showMediaLibrary)} open={showMediaLibrary} accessToken={accessToken} page={false} webId={webId} />}
        </Container>
    );
};

export default BgImageHandler;

const Container = styled.div`
    .media{
        width: 100%;
        height: fit-content;
        border-radius: 4px;
        background: ${({ theme }) => theme.bg3};
        img{
            width: 100%;
            height: 120px;
            object-fit: contain;
        }
        &.empty{
            background: transparent;
            border: 1px dashed ${({ theme }) => theme.sectionBorderColor};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            height: 120px;
            .add{
                background: ${({ theme }) => theme.bg2};
                border-radius: 50%;
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                & > svg{
                    width: 18px;
                    height: 18px;
                    color: ${({ theme }) => theme.color};
                }
            }
            span{
                color: ${({ theme }) => theme.color2};
            }
        }
        .bottom{
            background: ${({ theme }) => theme.bg2};
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            border-radius: 0 0 4px 4px;
            position: relative;
            .type{
                color: ${({ theme }) => theme.color2};
            }
            & > svg{
                width: 16px;
                height: 16px;
                position: absolute;
                right: 10px;
                bottom: 15px;
                cursor: pointer;
                path: {
                    fill: ${({ theme }) => theme.color2};
                }
            }
            span{
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                color: ${({ theme }) => theme.color3};
                line-height: 24px;
            }
        }
    }
`;

function extractImagePath(backgroundImageString) {
    try {
        // Use a regular expression to extract the path between 'url(' and ')'
        const match = backgroundImageString.match(/url\(['"]?(.*?)['"]?\)/);

        // If there is a match, return the captured path, otherwise return null
        return match ? match[1] : null;
    } catch (e) {
        return null
    }

}