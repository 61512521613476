import Div from 'elements/Div';
import React from 'react';

const emptyCart = ({instance, setInstance}) => {
    return (
        <Div c="input-wrap">
        </Div>
    )
}

export default emptyCart;
