import App from 'App';
import Frame from 'Frame';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import AuthEntry from 'router-entries/AuthEntry';
import BuilderEntry from 'router-entries/BuilderEntry';
import ConfigEntry from 'router-entries/ConfigEntry';
import WebEntry from 'router-entries/WebEntry';
import PageEntry from 'router-entries/PageEntry';
import { ProvideAuth } from 'utils/useAuth';
import { basePaths } from 'config';
import { WebglobalContextProvider } from 'contexts/webglobal';

const root = ReactDOM.createRoot(document.getElementById('root'));
window.urls = { ...basePaths };

root.render(
    <BrowserRouter>
        <ProvideAuth>
            <WebglobalContextProvider>
                <Routes>
                    <Route path="/" element={<AuthEntry />}>
                        {/* <Route path="" element={<PageEntry />}>
                            <Route path="" element={<BuilderEntry />}>
                                <Route index element={<App />} />
                            </Route>
                        </Route> */}
                        <Route path=":id" element={<WebEntry />}>
                            <Route path="" element={<PageEntry />}>
                                <Route path="" element={<BuilderEntry />}>
                                    <Route index element={<App />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="" element={<ConfigEntry />}>
                            <Route path="/frame" element={<Frame />} />
                        </Route>
                    </Route>
                </Routes>
            </WebglobalContextProvider>
        </ProvideAuth>
    </BrowserRouter>
);
