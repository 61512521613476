import React from 'react';
import styled from 'styled-components';

const ToggleButton = ({ isToggled, setIsToggled }) => {
  return (
    <Container>
      <label className="toggle">
        <input type="checkbox" checked={isToggled} />
        <span className="slider" onClick={() => setIsToggled(!isToggled)}></span>
      </label>
    </Container>
  );
};

export default ToggleButton;

const Container = styled.div`
cursor: pointer;
.toggle {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.bg3};
    border-radius: 16px;
    transition: background-color 0.3s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
  }
  input:checked + .slider {
    background-color: ${({ theme }) => theme.mainColor};
  }
  input:checked + .slider:before {
    transform: translateX(12px);
  }
}
`