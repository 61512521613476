import ToggleButton from 'components/ToggleButton';
import React from 'react';
import styled from 'styled-components';

const Boolean = ({ object, forwardKey, forwardChange }) => {
    const [value, setValue] = React.useState(object.value)
    const handleChange = (val) => {
        setValue(val)
        forwardChange(forwardKey, val)
    }
    return (
        <Container onClick={() => handleChange(!value)}>
            <div className="caption">
                <span>{forwardKey}</span>
            </div>
            <ToggleButton isToggled={value} setIsToggled={handleChange} />
        </Container>
    )
}

export default Boolean;

const Container = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items:center;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
& > div{
    pointer-events: none;
}
`