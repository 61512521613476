import StyleController from 'components/StyleController';
import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';

const DraggableModule = ({ close }) => {
    const [position, setPosition] = React.useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = React.useState(false)
    const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 })

    React.useEffect(() => {
        const savedPosition = localStorage.getItem('rightBarPosition');
        if (savedPosition) {
            setPosition(JSON.parse(savedPosition));
            // setPosition({ x: window.innerWidth, y: 0 });
        } else {
            setPosition({ x: window.innerWidth - 100, y: 0 });
        }
    }, []);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartPosition({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        })
    }

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        setPosition({
            x: e.clientX - startPosition.x,
            y: e.clientY - startPosition.y,
        })
    }

    const handleMouseUp = () => {
        setIsDragging(false);
        localStorage.setItem('rightBarPosition', JSON.stringify(position));
    }

    return (
        <Container>
            <Div
                style={{
                    position: 'absolute',
                    left: `${position.x}px`,
                    top: `${position.y}px`,
                }}
            >
                <StyleController close={close} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} />
            </Div>
        </Container>
    );
};

export default DraggableModule;

const Container = styled.div`
`;
