import Div from "elements/Div";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import request from "utils/request";
import { Search } from 'utils/svg';
import LoadingFallback from 'router-entries/LoadingFallback';
import { ArrowLeft } from "utils/svg";

const DynamicDataList = ({ dynamicPagesSection, setDynamicPagesSection }) => {
    const { id } = useParams()
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [searchQuery, setSearchQuery] = React.useState('')
    const navigate = useNavigate()

    const fetch = async () => {
        try {
            setLoading(true)
            const result = await request({
                url: window.urls.content + `/collection/cd/listCd`,
                method: 'post',
                headers: { webid: id },
                body: { ctId: dynamicPagesSection.data._id },
            })
            setData(result.data.data)
        } catch (e) {
            console.log(e, 'err fetching collection')
        } finally { setLoading(false) }
    }

    const fetchProducts = async (search) => {
        try {
            const result = await request({
                url: search ? window.urls.product + `/product/search?search=${search}` : window.urls.product + `/product`,
                method: 'get',
                headers: { webid: id },
            })
            setData(search ? result.data.hits.hits : result.data)
        } catch (e) {
            console.log(e, 'err fetching products')
        }
    }

    React.useEffect(() => {
        if (dynamicPagesSection.name == 'collection')
            fetch()
        else if (dynamicPagesSection.name == 'product')
            fetchProducts()
    }, [])

    const handle = (list) => {
        if (dynamicPagesSection.name == 'collection') {
            let newUrl = `?slug=/${dynamicPagesSection.data._source.name}&type=page&sourceType=collection&sourceId=${list._id}`
            navigate(newUrl);
        } else if (dynamicPagesSection.name == 'product') {
            let newUrl = `?slug=/products&type=page&sourceType=product&sourceId=${list._id}`
            navigate(newUrl);
        }
    }

    const handleBlur = () => {
        setTimeout(() => {
            fetchProducts(searchQuery)
        }, 150);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                fetchProducts(searchQuery)
            }, 150);
        }
    };

    return (
        <Container>
            <Div c="top">
                <Div c="back" onClick={() => setDynamicPagesSection(null)}>
                    <ArrowLeft />
                    <span>Буцах</span>
                </Div>
            </Div>
            {dynamicPagesSection.name == 'product' &&
                <Div c="search-wrap">
                    <Div c="search-bar">
                        <Search />
                        <input type="text" placeholder="Search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} onKeyDown={handleKeyDown} onBlur={handleBlur} />
                    </Div>
                </Div>
            }
            {loading ? <LoadingFallback /> :
                <Div c="list-wrap">
                    {data?.map((list, i) => (
                        dynamicPagesSection.name == 'collection' ?
                            <Div c='list' key={i} onClick={() => handle(list)}>
                                <Div c="image">
                                    {list._source.data.map((source) => {
                                        if (source.type == 'media')
                                            return <img src={source.value.media.url} alt="image" />
                                    })}
                                </Div>
                                <Div c='text'>
                                    {list._source.data.map((source) => {
                                        if (source.type == 'text')
                                            return <h5>{source.value.text}</h5>
                                    })}
                                </Div>
                            </Div> : dynamicPagesSection.name == 'product' &&
                            <Div c='list' key={i} onClick={() => handle(list)}>
                                <Div c="image"><img src={list._source.image ? list._source.image : 'https://sanantoniosports.org/wp-content/uploads/2022/07/placeholder-image.jpeg'} alt="image" /></Div>
                                <Div c='text'>
                                    <h5>{list._source.name}</h5>
                                    <h5>{list._source.price}</h5>
                                </Div>
                            </Div>
                    ))}
                </Div>
            }
        </Container>
    )
}

export default DynamicDataList

const Container = styled.div`
.top{
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        border-radius: 8px;
        background: ${({ theme }) => theme.bg2};
        cursor: pointer;
        width: fit-content;
        svg{
            width: 14px;
            height: 14px;
        }
        span{
            font-size: ${({ theme }) => theme.fs};
            font-weight: 500;
            color: ${({ theme }) => theme.color};
        }
    }
}
.search-wrap{
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    .search-bar{
        padding:10px;
        display:flex;
        align-items:center;
        gap:10px;
        border-radius:4px;
        svg{
            width: 22px;
            height: 22px;
        }
        input{
            width: 100%;
            border: none;
            background: transparent;
            color: ${({ theme }) => theme.color};
            &:focus{
                outline: none;
            }
        }
    }
}
.list-wrap{
    display: flex;
    flex-direction: column;
    .list{
        display: flex;
        padding: 15px;
        gap: 10px;
        align-items: center;
        transition: all 0.3s linear;
        cursor: pointer;
        border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
        &:hover{
            background: ${({ theme }) => theme.bg2};
            .image{
                background: ${({ theme }) => theme.bg};
            }
        }
        .image{
            width: 44px;
            height: 44px;
            background: ${({ theme }) => theme.bg2};
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .text{
            width: calc(100% - 54px);
            h5{
                overflow: hidden;
                white-space: nowrap;
                display: none;
                &:nth-of-type(1){
                    color: ${({ theme }) => theme.color};
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    text-transform: uppercase;
                    display: flex;
                }
                &:nth-of-type(2){
                    color: ${({ theme }) => theme.color2};
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    font-weight: 400;
                }
            }
        }
    }
}
`