import React from 'react';
import styled from 'styled-components';
import HeadingLeft from './HeadingLeft';
import HeadingMiddle from './HeadingMiddle';
import HeadingRight from './HeadingRight';

const Heading = () => {
    return (
        <Container>
            <HeadingLeft />
            <HeadingMiddle />
            <HeadingRight />
        </Container>
    );
};

export default Heading;

const Container = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
    height: 52px;
    position: relative;
    width: 100%;
`;