import Div from 'elements/Div';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingFallback from 'router-entries/LoadingFallback';
import styled from 'styled-components';
import request from 'utils/request';
import PagePopup from './PagePopup';
import { FiPlus } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { Settings, Trash } from 'utils/svg';
import { toast } from 'react-hot-toast';

const Footer = () => {
    const { id: webId } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [pages, setPages] = React.useState([]);
    const [searchParams] = useSearchParams();
    const slug = searchParams.get('slug');
    const [popupInstance, setPopupInstance] = React.useState(null);
    const navigate = useNavigate();
    const [isActive, setActive] = React.useState(false)

    React.useEffect(() => {
        goFetch();
    }, []);
    const goFetch = async () => {
        try {
            setLoading(true);
            const result = await request({ url: window.urls.web + `/footer`, headers: { 'webId': `${webId}` } });
            setPages(result.data);
        } catch (e) {
            toast.error(e)
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (e, pageId) => {
        e.preventDefault()
        e.stopPropagation()
        try {
            setLoading(true);
            const result = await request({ url: window.urls.web + `/page/${pageId}`, method: 'delete' })
            toast.success(result.message)
        } catch (e) {
            toast.error(e)
        } finally {
            setLoading(false);
            goFetch()
        }
    };

    return loading ? (
        <LoadingFallback />
    ) : (
        <Container>
            <Div c={`title-wrap ${isActive && "active"}`} onClick={() => setActive(!isActive)}>
                <Div c="title">Footer</Div>
                <Div c="icons">
                    <Div c="add" onClick={() => setPopupInstance({})}>
                        <FiPlus />
                    </Div>
                    <Div c="arrow">
                        <IoIosArrowDown />
                    </Div>
                </Div>
            </Div>
            {isActive &&
                <Div c="main-wrap">
                    <Div c="pages-wrap">
                        {pages.map((page) => (
                            <Div c="page" key={page.id}>
                                {slug !== page.slug ? <Trash onClick={(e) => handleDelete(e, page.id)} className="delete" /> : ''}
                                <Div c="name-wrap" onClick={() => navigate(`/${webId}?type=footer&id=${page.id}`)}>
                                    <span>{page.name}</span>
                                    <span className='slug'>{page.slug}</span>
                                </Div>
                                <Settings onClick={() => setPopupInstance({ ...page })} className="edit" />
                            </Div>
                        ))}
                    </Div>
                </Div>
            }
            {popupInstance && <PagePopup type="page" refetch={goFetch} close={() => setPopupInstance(null)} instance={popupInstance} />}
        </Container>
    );
};

export default Footer;

const Container = styled.div`
    & > .title-wrap{
        padding: 8px 16px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > .title{
            font-weight: 600;
            line-height: 24px;
        }
        .icons{
            display: flex;
            align-items: center;
            gap: 5px;
            .add, .arrow{
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;
                transition: all 0.3s linear;
                border-radius: 4px;
                svg{
                    width: 16px;
                    height: 16px;
                }
                &:hover{
                    background: ${({ theme }) => theme.bg2};
                    svg{
                        path{
                            fill: ${({ theme }) => theme.color2};
                        }
                    }
                }
            }
        }
        &.active{
            & > .icons{
                & > .arrow{
                    rotate: 180deg;
                }
            }
        }
    }
    & > .main-wrap{
        width: 100%;
        & > .pages-wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            & > .page {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 6px;
                color: ${({ theme }) => theme.color2};
                text-transform: capitalize;
                background-color: ${({ theme }) => theme.bg};
                transition: all 0.5s ease;
                border-radius: 0;
                position: relative;
                padding: 0;
                width: 100%;
                & > .name-wrap {
                    width: 220px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: flex;
                    align-items: flex-end;
                    gap: 5px;
                    padding: 15px 35px;
                    span{
                        font-size: ${({ theme }) => theme.fs};
                        font-weight: 500;
                        color: ${({ theme }) => theme.color};
                        &.slug{
                            color: ${({ theme }) => theme.color2};
                            font-weight: 400;
                            font-size: 10px;
                            display: none;
                        }
                    }
                }
                & > svg{
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                    path: {
                        fill: ${({ theme }) => theme.color};
                    }
                }
                & > .edit {
                    margin: auto;
                    pointer-events: auto;
                    position: absolute;
                    right: -30px;
                    top: 0;
                    bottom: 0;
                    transition: right 0.3s ease;
                }
                & > .delete {
                    position: absolute;
                    pointer-events: auto;
                    left: -30px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    transition: left 0.3s ease;
                }
                &:hover, &[open]{
                    background-color: ${({ theme }) => theme.bg2};
                    & > .name-wrap{
                        .slug{
                            display: block;
                        }
                    }
                    & > .delete{
                        left: 10px;
                    }
                    & > .edit{
                        right: 10px;
                    }
                }
            }
        }
    }
`;
