import ToggleButton from "components/ToggleButton";
import Div from "elements/Div";
import React from "react";
import styled from "styled-components";
import { Add, Trash } from 'utils/svg';
import Select from '../../modules/Actions/InputTypes/Select'
import Options from "./Options";

const Object = ({ change }) => {
    const stateTypes = ["boolean", "text", "number", "select"]
    const [object, setObject] = React.useState([{ type: null, key: null, value: null }])
    const handleAddOption = () => {
        setObject((prevObject) => [
            ...prevObject,
            { type: null, key: null, value: null }
        ])
    }
    const handleRemoveOption = (i) => {
        setObject((prevObject) => prevObject.filter((_, index) => index !== i))
    }

    React.useEffect(() => {
        if (object.length == 1)
            change(...object)
        else if (object.length > 1)
            change(object)
    }, [object])

    return (
        <Container>
            <Div c='object'>
                <Div c='wrap'>
                    <Div c='trash'></Div>
                    <Div c='type'><span>Type</span></Div>
                    <Div c='key'><span>Key</span></Div>
                    <Div c='value'><span>Value</span></Div>
                </Div>
                {object?.map((option, i) => (
                    <Div c='wrap' key={i}>
                        <Div c='trash' onClick={() => handleRemoveOption(i)}><Trash /></Div>
                        <Div c='type'>
                            <Select
                                options={stateTypes}
                                value={option.type || null}
                                onChange={(val) => {
                                    setObject((prevObject) => {
                                        const updatedObject = [...prevObject];
                                        updatedObject[i].type = val;
                                        // Clear key and value when type changes
                                        updatedObject[i].key = null;
                                        updatedObject[i].value = null;
                                        return updatedObject;
                                    })
                                }}
                            />
                        </Div>
                        <Div c='key'><input placeholder='Add key ...' onChange={(e) => {
                            setObject(prevObject => {
                                const updatedObject = [...prevObject];
                                updatedObject[i].key = e.target.value;
                                return updatedObject;
                            });
                        }} /></Div>
                        <Div c='value'>
                            {option.type == 'boolean' ?
                                <ToggleButton isToggled={option.value || false} setIsToggled={(val) => {
                                    setObject(prevObject => {
                                        const updatedObject = [...prevObject];
                                        updatedObject[i].value = val;
                                        return updatedObject;
                                    });
                                }} />
                                : option.type == 'select' ?
                                    <Options options={option.value || null}
                                        change={(val) => {
                                            setObject(prevObject => {
                                                const updatedObject = [...prevObject];
                                                updatedObject[i].value = Array.isArray(updatedObject[i].value) ? [...updatedObject[i].value, val] : [val];
                                                return updatedObject;
                                            });
                                        }}
                                        remove={(val) => {
                                            setObject(prevObject => {
                                                const updatedObject = [...prevObject];
                                                updatedObject[i].value = val;
                                                return updatedObject;
                                            });
                                        }}
                                    />
                                    :
                                    <input placeholder='Add value ...' type={option.type || 'text'} value={option.value || null} onChange={(e) => {
                                        setObject(prevObject => {
                                            const updatedObject = [...prevObject];
                                            updatedObject[i].value = e.target.value;
                                            return updatedObject;
                                        });
                                    }} />
                            }
                        </Div>
                    </Div>
                ))}
                <Div c='add-wrap'><Div c='add' onClick={handleAddOption}><Add /><span>Add option</span></Div><Div c='line'></Div></Div>
            </Div>
        </Container>
    )
}


export default Object

const Container = styled.div`
.object{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    .wrap{
        width: 100%;
        display: flex;
        align-items: start;
        gap: 15px;
        height: 100%;
        &:first-of-type{
            align-items: end;
        }
        & > div{
            height: 100%;
            & > input{
                margin-top: 5px;
                width:100%;
                border: none;
                padding: 8px 12px;
                background: ${({ theme }) => theme.bg2};
                color: ${({ theme }) => theme.color};
                font-size: ${({ theme }) => theme.fs};
                border-radius: 4px;
                &:focus{
                    outline: none;
                }
                &::placeholder{
                    font-weight: 400;
                }
            }
            & > span{
                font-weight: 600;
                text-transform: uppercase;
                color: ${({ theme }) => theme.color2};
                font-size: 10px;
                line-height: 20px;
            }
        }
        .trash{
            width: 24px;
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 35px;
            svg{
                width: 16px;
                height: 16px;
            }
        }
        .key{
            width: 30%;
        }
        .type{
            width: 30%;
        }
        .value{
            width: calc(40% - 24px);
        }
    }
    .add-wrap{
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        .add{
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            svg{
                width: 14px;
                height: 14px;
            }
            span{
                text-transform: uppercase;
                color: ${({ theme }) => theme.color};
                font-weight: 500;
            }
        }
        .line{
            width: 0;
            transition: width .6s;
            height: 2px;
            background: ${({ theme }) => theme.color};
        }
        &:hover{
            .line{
                width: 120px;
            }
        }
    }
}
`