import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import request from 'utils/request';
import LoadingFallback from 'router-entries/LoadingFallback';
import TemplatesDialog from '../components/TemplatesDialog';
import { getReferenceContext } from 'contexts/reference';

const TemplateTypes = () => {
    const { id: webId } = useParams();
    const [templateTypes, setTemplateTypes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { setTemplateTypeInstance } = React.useContext(getReferenceContext)
    const [selectedType, setSelectedType] = React.useState(null);
    const fetchTypes = async () => {
        try {
            setLoading(true);
            const result = await request({ url: window.urls.web + `/template_type`, headers: { webId } });
            setTemplateTypes(result.data);
        } catch (e) {
            console.log(e, 'err template type');
        } finally {
            setLoading(false);
        }
    };
    const deleteType = async (id) => {
        try{
            if(!window.confirm('You sure you want to delete type?')) return null
            setLoading(true)
            await request({ url: window.urls.web + `/template_type/${id}`, method: 'delete' });
        } catch(e) {
            console.log(e, 'err delete template type')
        } finally { setLoading(false) }
    };
    React.useEffect(() => {
        fetchTypes();
    }, []);
    return (
        <Container data-scrollable='true'>
            {loading ?
                <Div c="loading-wrap"><LoadingFallback /></Div>    
                :
                templateTypes.map((type) => (
                    <Button c="type-wrap" key={type.id} onClick={() => setSelectedType({ componentKey: 'Templates', name: type.name, id: type.id })}>
                        <img src={type.imageUrl} />
                        <Div c="name">{type.name}</Div>
                        <Div c="icons-wrap">
                            <AiOutlineEdit
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setTemplateTypeInstance({ ...type });
                                }}
                            />
                            <AiOutlineDelete
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deleteType(type.id);
                                }}
                                className="delete"
                            />
                        </Div>
                    </Button>
                ))
            }
            <Button c="add" onClick={() => setTemplateTypeInstance({})}>
                Add new type +
            </Button>
            {selectedType ? <TemplatesDialog {...selectedType} closeDialog={() => setSelectedType(null)} /> : ''}
        </Container>
    );
};

export default TemplateTypes;

const Container = styled.div`
    height: 100%;
    overflow-y: scroll;
    & > .loading-wrap {
        max-height:100px;
        & > div {
            max-height:100px;
        }
    }
    & > .add {
        margin: auto;
        text-align: center;
        padding: 15px;
        color: ${({ theme }) => theme.mainColor};
        width:100%;
    }
    & > .type-wrap {
        width:100%;
        padding: 8px 0px;
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;
        & > img {
            width: 120px;
            height: 60px;
            object-fit: cover;
            border-radius: 8px;
            border: 2px solid ${({ theme }) => theme.sectionBorderColor};
        }
        .icons-wrap {
            position: absolute;
            top: 4px;
            right: -2px;
            font-size: 16px;
            display: flex;
            gap: 4px;
            color: ${({ theme }) => theme.color2};
            pointer-events: auto;
            opacity: 0;
            transition: 0.3s ease;
            & > .delete {
                color: rgba(255, 0, 0, 0.8);
            }
            svg:hover {
                cursor: pointer;
            }
        }
        &:hover {
            & > .icons-wrap {
                opacity: 1;
            }
        }
    }
`;
