import propTypes from 'prop-types';
import React from 'react';
import { addRipple } from 'utils/vanillaFunctions';

const Button = ({ c, children, ...props }) => {
    const itself = React.useRef();

    React.useEffect(() => {
        itself.current.addEventListener('click', (e) => {
            addRipple(e);
        });
    }, []);

    return (
        <button ref={itself} className={c} {...props}>
            {children}
        </button>
    );
};

Button.propTypes = {
    children: propTypes.node,
    c: propTypes.string,
};

export default Button;
