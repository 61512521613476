import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from 'utils/useAuth';
import Authenticating from './Authenticating';

const AuthEntry = () => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? <Outlet /> : <Authenticating isAuthenticated={isAuthenticated}/>;
};

export default AuthEntry;
