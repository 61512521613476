import Div from "elements/Div";
import React from "react";
import styled from "styled-components";
import ToggleButton from "components/ToggleButton";
import { useAuth } from 'utils/useAuth';
import { useParams } from 'react-router-dom';
import MediaIndex from 'siro-media-library';

const File = ({ setLinkData, linkData }) => {
    const [showMediaLibrary, setShowMediaLibrary] = React.useState(false)
    const { id: webId } = useParams()
    const { accessToken } = useAuth()

    const onMediaSelect = (media) => {
        setLinkData({ ...linkData, slug: media.url })
        setShowMediaLibrary(false)
    }

    return (
        <Container>
            <Div c='link-wrap'>
                <label>File</label>
                <Div c="image-container">
                    <Div c="image">
                        <img src={linkData.slug} alt="preview_img" />
                    </Div>
                    <Div c="bottom">
                        <Div c='type'>{linkData.type}</Div>
                        <Div c='flex'>
                            <span onClick={() => setShowMediaLibrary(true)}>Replace</span>
                        </Div>
                    </Div>
                </Div>
                <MediaIndex setImage={onMediaSelect} onCancel={() => setShowMediaLibrary(!showMediaLibrary)} open={showMediaLibrary} accessToken={accessToken} page={false} webId={webId} />
            </Div>
            <Div c="toggle-wrap" onClick={() => setLinkData({ ...linkData, newWindow: !linkData.newWindow })}>
                <span>Open In New Window</span>
                <ToggleButton isToggled={linkData.newWindow} />
            </Div>
        </Container>
    )
}

export default File

const Container = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
.link-wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    label{
        letter-spacing: 0.75px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.color2};
        font-weight: 500;
        font-size: 10px;
    }
    .image-container{
        border-radius: 4px;
        width: 100%;
        background: ${({ theme }) => theme.bg3};
        .image{
            width: 100%;
            height: 120px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .bottom{
            background: ${({ theme }) => theme.bg2};
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            border-radius: 0 0 4px 4px;
            .type{
                color: ${({ theme }) => theme.color2};
            }
            .flex{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                span{
                    font-weight: 600;
                    text-transform: uppercase;
                    cursor: pointer;
                    color: ${({ theme }) => theme.color3};
                    line-height: 24px;
                }
            }
        }
   }
}
.toggle-wrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 44px;
    cursor: pointer;
    align-items: center;
    padding: 12px 18px;
    background: ${({ theme }) => theme.bg2};
    border-radius: 4px;
    & > div{
        pointer-events: none;
    }
    span{
        color: ${({ theme }) => theme.color};
    }
}
`