import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import { BiChevronRight } from 'react-icons/bi';
import styled from 'styled-components';
import InsertDialog from './InsertDialog';
import TypeIcon from './TypeIcon';

const ListGroups = ({ groups }) => {
    const [selectedType, setSelectedType] = React.useState(null);
    return (
        <Container>
            {groups.map((group) => (
                <Div c="group" key={group.name}>
                    <Div c="name">{group.name}</Div>
                    <Div c="wrap">
                        {group.types.map((type) => (
                            <Button open={selectedType === type} onClick={()=>setSelectedType(type)} c="type" key={type.name}>
                                <TypeIcon icon={type.icon}/>
                                <Div c="name">{type.name}</Div>
                                <Div c="caret">
                                    <BiChevronRight />
                                </Div>
                            </Button>
                        ))}
                    </Div>
                </Div>
            ))}
            {selectedType ? (
                <InsertDialog {...selectedType} closeDialog={()=>setSelectedType(null)}/>
            ) : ''}
        </Container>
    );
};

export default ListGroups;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 30px;
    & > .group {
        & > .name {
            margin-bottom: 15px;
            font-size: ${({ theme }) => theme.fs2};
        }
        & > .wrap {
            display: flex;
            flex-direction: column;
            gap: 8px;
            & > .type {
                display: flex;
                align-items: center;
                padding: 10px;
                border-radius: 8px;
                gap: 10px;
                transition: 0.3s ease;
                &[open] {
                    background: ${({ theme }) => theme.bg2};
                }
                &:hover {
                    cursor: pointer;
                    background: ${({ theme }) => theme.bg2};
                }
                & > .name {
                    font-size: ${({ theme }) => theme.fs2};
                }
                & > .caret {
                    margin-left: auto;
                    font-size: 20px;
                }
            }
        }
    }
`;
