import Div from 'elements/Div';
import React from 'react';
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';
import styled from 'styled-components';

const IterateProps = ({ handleOnSelect, propsData }) => {
    const onSelect = ({ key, pointer, type }) => {
        const name = pointer.replace(/.value/g, "").replace(/\./g, '->')
        handleOnSelect({ name, key, pointer, type, syncType: 'prop' })
    }
    const renderNest = (key, instance, pointer) => {
        if(!instance) return null
        if (instance.type === 'object') {
            if (!pointer) pointer = `${key}.value`;
            else pointer += `.${key}.value`;
            return (
                <Div key={key} c="object-wrap">
                    <Div c="object-title sphere">
                        <img src={`/img/icons/${instance.type}.svg`}/>
                        <Div onClick={()=>onSelect({ key, pointer, type: instance.type })} c="property">{key}</Div>
                        {/* <Div c="pointer">{pointer}</Div> */}
                    </Div>
                    {Object.keys(instance.value).map((subKey) => renderNest(subKey, instance.value[subKey], pointer))}
                </Div>
            );
        } else if (instance.type === 'array') {
            if (!pointer) pointer = `${key}.value`;
            else pointer += `.${key}.value`;
            const toggle = e => {
                e.target.toggleAttribute('open')
                e.target.parentElement.parentElement.toggleAttribute('open')
            }
            return (
                <Div c="array-container">
                    <Div c="sphere">
                        <Div c="caret" onClick={toggle}>
                            <AiFillCaretRight className='closed'/><AiFillCaretDown className='open'/>
                        </Div>
                        <Div onClick={()=>onSelect({ key, pointer, type: instance.type })} c="property">{key} ({instance.value?.length})</Div>
                        {/* <Div c="pointer">{pointer}</Div> */}
                    </Div>
                    <Div c="array-wrap">
                        {instance.value?.map((subInstance, index) =>{
                            return(
                                <Div c="array-instance" key={index}>
                                    <Div c="sphere">
                                    <Div c="caret" onClick={toggle}>
                                        <AiFillCaretRight className='closed'/><AiFillCaretDown className='open'/>
                                    </Div>
                                        <Div onClick={()=>onSelect({ key, pointer, type: instance.type })} c="property">[{index}]</Div>
                                        {/* <Div c="pointer">{`${pointer}.[${index}]`}</Div> */}
                                    </Div>
                                    <Div c="array-instance-wrap">
                                        {Object.keys(subInstance).map((subKey) => renderNest(subKey, subInstance[subKey], `${pointer}[${index}]`))}
                                    </Div>
                                </Div>
                            )
                        })}
                    </Div>
                </Div>
            )
        } else {
            if (!pointer) pointer = `${key}.value`;
            else pointer += `.${key}.value`;
            return (
                <Div c="sphere">
                    <img src={`/img/icons/${instance.type}.svg`}/>
                    <Div onClick={()=>onSelect({ key, pointer, type: instance.type })} c="property">{key}</Div>
                    {/* <Div c="pointer">{pointer}</Div> */}
                </Div>
            );
        }
    };
    return <Container>{propsData && Object.keys(propsData).map((key) => renderNest(key, propsData[key]))}</Container>;
};

export default IterateProps;

const Container = styled.div`
    .object-wrap{
        padding-left:10px;
        position:relative;
        & > .object-title{
            margin-left:-10px;
        }
    }
    .sphere{
        background:white;
        padding:4px 6px;
        width:fit-content;
        box-shadow:0px 0px 2px 1px rgba(0,0,0,0.1);
        border-radius:4px;
        margin-bottom:8px;
        display:flex;
        align-items:center;
        gap:4px;
        position:relative;
        & > .pointer {
            display:none;
            position:absolute;
            left:0px;
            top:100%;
            margin-top:8px;
            background:black;
            color:white;
            padding:4px 6px;
            border-radius:4px;
            z-index:1;
        }
        & > .property {
            font-weight:500;
            opacity:0.7;
            cursor:pointer;
            &:hover{
                opacity:1;
            }
        }
        & > img {
            width:20px;
            height:20px;
        }
        & > .caret{
            width:20px;
            background:rgba(0,0,0,0.05);
            border:1px solid rgba(0,0,0,0.1);
            display:flex;
            justify-content:center;
            align-items:center;
            border-radius:2px;
            cursor:pointer;
            svg{
                opacity:0.45;
                pointer-events:none;
            }
            .open{
                display:none;
            }
            .closed{
                display:flex;
            }
        }
        & > .caret[open]{
            .open{
                display:flex;
            }
            .closed{
                display:none;
            }
        }
        &:hover{
            & > .pointer{
                display:block;
            }
        }
    }
    .array-container{
        .array-wrap{
            padding-left:10px;
            display:none;
            .array-instance{
                .array-instance-wrap{
                    padding-left:10px;
                    display:none;
                }
            }
            .array-instance[open]{
                .array-instance-wrap{
                    display:block;
                }
            }
        }
    }
    .array-container[open]{
        & > .array-wrap {
            display:block;
        }
    }
`;
