export const cssUnits = ['px', 'em', 'rem', '%', 'wh', 'vh'];
export const getAttributeValues = (argument) => {
    const fallback = ['inherit', 'initial', 'unset', 'auto']
    if (argument === 'marginTop') return ['auto', 'unset'];
    if (argument === 'position') return ['relative', 'absolute', 'fixed', 'unset'];
    if (argument === 'overflow') return ['visible', 'hidden', 'scroll', 'auto'];
    if (argument === 'backgroundRepeat') return ['repeat', 'repeat-x', 'repeat-y', 'no-repeat', 'initial', 'inherit'];
    if (argument === 'backgroundSize') return ['auto', 'length', 'cover', 'contain', 'initial', 'inherit'];
    if (argument === 'backgroundPosition') return ['top', 'bottom', 'left', 'right', 'center', 'inherit'];
    if (argument === 'flexWrap') return ['nowrap', 'wrap', 'wrap-reverse', 'initial', 'inherit'];
    if (argument === 'display') return ['none', 'inline', 'block', 'flex', 'grid', 'inherit', 'initial'];
    if (argument === 'objectFit') return ['none', 'fill', 'block', 'contain', 'cover', 'scale-down'];
    if (argument === 'fontWeight') return ['light', 'normal', 'medium', 'semi bold', 'bold'];
    if (argument === 'transitionTimingFunction') return ['inherit', 'linear', 'ease-in', 'ease-out',' ease-in-out'];
    if (argument === 'transitionProperty') return ['none', 'all', 'width', 'opacity',' background-color'];
    if (argument === 'borderStyle') return ['solid', 'dashed', 'dotted'];
    if (argument === 'width')
        return [
            ['Fill', '100%'],
            ['Stretch', 'auto'],
            ['Shrink', 'fit-content'],
        ];
    if (argument === "fontFamily") {
        if (!window.fonts) return fallback
        const fonts = window.fonts.map(font => ([font.key, font.value]))
        return fonts
    }
    if (argument === "cursor") return ["auto", "default", "pointer", "none", "help", "progress", "crosshair", "not-allowed", "grab"]
    return fallback;
};
