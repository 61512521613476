import Div from 'elements/Div';
import React from 'react';
import styled from 'styled-components';
import { IoIosArrowDown } from "react-icons/io";
import { useParams } from 'react-router-dom';
import request from 'utils/request';
import { Close } from "utils/svg";
import { FaCheck } from "react-icons/fa";
import useSync from 'utils/useSync';


const ProductCategory = ({ object, forwardKey, forwardChange }) => {
    const [category, setCategory] = React.useState(null)
    const { id } = useParams()
    const [searchQuery, setSearchQuery] = React.useState(object.value || '')
    const [active, setActive] = React.useState(false)
    const { syncTrigger, syncElement, syncSuggestion } = useSync({ value: object.sync, operation: { type: 'option', forwardKey, forwardChange } })
    const [selectedCategory, setSelectedCategory] = React.useState(null)
    const [depth1, setDepth1] = React.useState(null)
    const [depth2, setDepth2] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const handleSearch = (event) => {
        setSearchQuery(event.target.value)
    }

    const fetchCategory = async () => {
        try {
            const result = await request({
                url: window.urls.product + `/category/search`,
                method: 'post',
                headers: { webid: id },
                body: {},
            })
            setCategory(result.data.data)
        } catch (e) {
            console.log(e, 'err fetching category')
        }
    }
    const fetchDepthCategory = async (category) => {
        try {
            const result = await request({
                url: window.urls.product + `/category/${category._id}`,
                method: 'get',
                body: {},
            })

            const hitsList = result['data']['_source']['children']['categories_by_depth']['buckets'].length > 0
                ?
                result['data']['_source']['children']['categories_by_depth']['buckets'][0]['categories_by_parent']['buckets'][0]['categories']['hits']['hits']
                :
                null
            if (category._source.depth == 0)
                setDepth1(hitsList)
            else
                setDepth2(hitsList)

            setLoading(true)
        } catch (e) {
            console.log(e, 'err fetching depth 1 category')
        }
    }

    React.useEffect(() => {
        fetchCategory()
    }, [])

    const filteredCategories = category
        ? category.filter((item) =>
            item._source && item._source.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : [];

    const handleDropdownItem = (val) => {
        if (val !== null) {
            forwardChange(forwardKey, val._source.path)
            setSelectedCategory(val)
            setSearchQuery(val._source.name)
        }
        else {
            forwardChange(forwardKey, null)
            setSelectedCategory(null)
            setSearchQuery('')
        }
        setActive(false)
    }

    React.useEffect(() => {
        if (selectedCategory !== null) {
            setSearchQuery(selectedCategory._source.name)
            fetchDepthCategory(selectedCategory)
        } else {
            setDepth1(null)
            setDepth2(null)
        }

        if (selectedCategory?._source.depth == 0) {
            setDepth2(null)
        }
    }, [selectedCategory])

    const CategoryRenderer = ({ data, caption, check }) => (
        <Div c='categories'>
            <Div c='caption'>{caption}</Div>
            {data && data.map((item, i) => (
                <Div c={`category-wrap ${item._id === selectedCategory?._id && 'active'}`} key={i} onClick={() => check && handleDropdownItem(item)}>
                    {check ? <Div c='check'><FaCheck /></Div> : ''}
                    <Div c='category' key={i}>
                        <span>{item._source.name}</span>
                    </Div>
                </Div>
            ))}
        </Div>
    )
    return (
        <Container>
            <div className="caption">
                <span>{forwardKey}</span>
            </div>
            <div className='flex'>
                {object.sync ? syncElement() : (
                    <div className='category-child'>
                        <div className="dropdown-wrap">
                            <Div c={`dropdown-head ${active && 'active'}`} onClick={() => setActive(!active)}>
                                <input type='text' placeholder='Choose one' value={searchQuery} onChange={handleSearch} />
                                {selectedCategory !== null && (
                                    <Div c="close" onClick={() => handleDropdownItem(null)}><Close /></Div>
                                )}
                                <Div c="arrow"><IoIosArrowDown /></Div>
                            </Div>
                            {active && (
                                <Div c="dropdown">
                                    {filteredCategories.map((val, i) => (
                                        <Div c="item" key={i} onClick={() => handleDropdownItem(val)}>{val._source.name}</Div>
                                    ))}
                                </Div>
                            )}
                        </div>
                        {depth1 && <CategoryRenderer data={depth1} caption={`Categories in Depth 1`} check={true} />}
                        {depth2 && <CategoryRenderer data={depth2} caption={`Categories in Depth 2`} check={false} />}
                    </div>
                )}
                {syncTrigger()}
            </div>
            {syncSuggestion()}
        </Container>
    )
}

export default ProductCategory;

const Container = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items:center;
padding: 0 20px;
cursor: pointer;
border-bottom: 1px solid ${({ theme }) => theme.sectionBorderColor};
.flex{
    display: flex;
    padding: 10px 0;
    .category-child{
        display: flex;
        flex-direction: column;
        gap: 5px;
        .dropdown-wrap{
            position: relative;
            width: 100%;
            .dropdown-head{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: ${({ theme }) => theme.bg2};
                border-radius: 4px;
                padding: 8px 12px;
                cursor: pointer;
                position: relative;
                span{
                    font-size: ${({ theme }) => theme.fs};
                    color: ${({ theme }) => theme.color};
                    font-weight:500;
                }
                input{
                    font-size: ${({ theme }) => theme.fs};
                    color: ${({ theme }) => theme.color};
                    font-weight:500;
                    background: transparent;
                    width: 90%;
                    border: none;
                    &::placeholder{
                        font-size: ${({ theme }) => theme.fs};
                        color: ${({ theme }) => theme.color};
                        font-weight:500;
                    }
                    &:focus{
                        outline: none;
                    }
                }
                .arrow{
                    background: ${({ theme }) => theme.bg2};
                    transition: all 0.3s linear;
                    z-index: 0;
                    display: flex;
                    align-items: center;
                    svg{
                        width: 14px;
                        height: 14px;
                    }
                }
                .close{
                    position: absolute;
                    right: 14px;
                    transition: right 0.3s ease;
                    display: flex;
                    align-items: center;
                    bottom: 0;
                    top: 0;
                    svg{
                        width: 14px;
                        height: 14px;
                        path{
                            fill: ${({ theme }) => theme.color2};
                        }
                    }
                }
                &:hover{
                    .close{
                        right: 28px;
                    }
                }
                &.active{
                    .arrow{
                        svg{
                            rotate: 180deg;
                        }
                    }
                }
            }
            .dropdown{
                top: 120%;
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                top: 120%;
                border-radius: 4px;
                box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
                min-width: 100px;
                background: ${({ theme }) => theme.bg2};
                max-height: 200px;
                overflow-y: auto;
                .item{
                    padding: 10px 15px;
                    font-size: ${({ theme }) => theme.fs};
                    font-weight: 600;
                    color: ${({ theme }) => theme.color};
                    border-radius: 4px;
                    &:hover {
                        background: ${({ theme }) => theme.lightMainColor};
                        color: ${({ theme }) => theme.mainColor};
                        cursor: pointer;
                    }
                }
            }
        }
        .categories{
            display: flex;
            flex-direction: column;
            gap: 5px;
            .caption{
                color: ${({theme}) => theme.color};
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 600;
                padding: 0;
            }
            .category-wrap{
                display: flex;
                gap: 5px;
                height: 30px;
                .check{
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    background: ${({ theme }) => theme.bg2};
                    & > svg{
                        display: none;
                    }
                }
                .category{
                    width: calc(100% - 30px);
                    height: 100%;
                    padding: 8px 12px;
                    background: ${({ theme }) => theme.bg2};
                    border-radius: 4px;
                    cursor: pointer;
                }
                &.active{
                    .check{
                        & > svg{
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
`